export enum PaymentRequirementTypes {
  CreditCardDetails = 0,
  Deposit = 1,
  FullPayment = 2,
}
export enum MenuOrderingStyle {
  Casual = 1,
  FixedOrderOfService = 2,
}

export interface GroupHeadingProductSize {
  enabled?: boolean;
  upgradePrice?: number;
  isReducedMenu?: boolean;
  isAlwaysIncluded?: boolean;
  order?: number;
}
export interface GroupHeadingProductSizes {
  [productSizeId: string]: GroupHeadingProductSize;
}

export interface GroupHeadingProduct {
  productSizeIds: GroupHeadingProductSizes;
  order?: number;
  landingPage?: boolean;
  limitless?: boolean;
  note?: string;
  _key?: string;
}

export interface GroupHeadingProducts {
  [productId: string]: GroupHeadingProduct;
}

export enum GroupHeadingType {
  food = 1,
  beverage = 2,
  foodInclusion = 3,
  drinkInclusion = 4,
  foodBeverage = 5,
  beverageMenus = 6,
}

export enum MenuInclusionsType {
  Limitless = "limitless",
  Fixed = "fixed",
}

export enum MenuOrderingAllocationType {
  PerBooking = "perBooking",
  PerPerson = "perPerson",
}

export interface MenuInclusionsAllocationRule {
  quantityLimit: number;
  minutes: number;
  minPax?: number;
  maxPax?: number;
}

export interface GroupHeadings {
  [groupHeadingId: string]: GroupHeading;
}
export interface GroupHeading {
  numberOptions?: number;
  orderLimitIntervalMinutes?: any;
  orderLimitIntervalItem?: any;
  isTimedOrderLimit?: boolean;
  displayName?: string;
  order?: number;
  menuHeadingId?: string;
  subHeadingId?: string;
  productGroupId?: string;
  notIncluded?: boolean;
  groupHeadingType: GroupHeadingType;
  inclusionType?: MenuInclusionsType;
  fixedQuantityType?: MenuOrderingAllocationType;
  perBookingQuantityLimits?: MenuInclusionsAllocationRule[];
  // limit?: boolean;
  // limitValue?: number;
  inclusionQuantityLimit?: number;
  inclusionMinutesLimit?: number;
  isEnableSubheading?: boolean;
  additional?: {
    isEnabled?: boolean;
    products?: GroupHeadingProducts;
  };
  special?: {
    isEnabled?: boolean;
    products?: GroupHeadingProducts;
  };
  products?: GroupHeadingProducts;
  groupHeadings?: GroupHeadings;
  minimumServiceDuration?: number;
  _key?: string;
  onlyHostCanOrder?: boolean;
}

export interface Menu {
  id?: string;
  name: string;
  widgetDisplayName: string;
  menuDisplayName: string;
  restaurantId: string;
  menuDescription: string;
  menuCategoryId: string;
  order?: number;
  file?: any;
  // menuTypes: {
  //   standard?: boolean;
  //   qrCode?: boolean;
  //   takeaway?: boolean;
  // };
  functionMenuTypeId?: string;
  menuPayments?: MenuPayments;
  allAreaGroups?: boolean;
  areas?: {
    [areaId: string]: boolean;
  };
  tableClassIds?: {
    [tableClassId: string]: boolean;
  };
  food?: {
    groupHeadings?: GroupHeadings;
  };
  beverage?: {
    groupHeadings?: GroupHeadings;
  };
  foodInclusions?: {
    [fixedProductId: string]: {
      groupHeadings?: GroupHeadings;
    };
  };
  drinkInclusions?: {
    [fixedProductId: string]: {
      groupHeadings?: GroupHeadings;
    };
  };
  lastUpdated: number;
  includeNoClass?: boolean;
  payLater?: boolean;
  flatTableOfContents?: boolean;
  useMenuHeadingToGroupCasualDocket?: boolean;
  enabled: boolean;
  courses?: Courses;
  enableOnlineOrdering?: boolean;
  serviceStyle?: string;
  orderingStyle?: MenuOrderingStyle;
  batchSettings: BatchSettings;
  experiences?: {
    enabled?: boolean;
    dateFrom: string;
    dateTo: string;
    blockedDays: string[];
  };
  landing?: {
    heading?: string;
    products: {
      [productId: string]: {
        order: number;
      };
    };
  };
  flags?: FeatureFlagMap<"ignoreLimitlessConstraint" | "ignoreLimitlessConstraintUntil">;
}

export interface Duration {
  minPax: number;
  maxPax: number;
  vip: number;
  supervip: number;
  standard: number;
  widgetShortenedDuration?: number;
}

export interface Durations {
  [durationId: string]: Duration;
}

export interface Courses {
  [courseId: string]: Course;
}

export interface Course {
  _key?: string;
  name: string;
  menuId: string;
  durations: Durations;
  active: boolean;
  enableTermsConditions: boolean;
  menuTermsConditions: string;
  termsAndConditionsImage: {
    fileName: string;
    url: string;
  }[];
}

export interface SubHeading {
  name: string;
  enableSecondTier?: boolean;
  subheadings?: {
    [subheadingId: string]: SubHeading;
  };
  order?: number;
  products?: MenuHeadingProducts;
}

export interface MenuHeadingProducts {
  [productId: string]: MenuHeadingProduct;
}

export interface MenuHeadingProduct {
  order?: number;
  productSizeIds: {
    [productSizeId: string]: boolean;
  };
}

export interface SubHeadings {
  [subheadingId: string]: SubHeading;
}

export enum MenuHeadingGroupType {
  FOOD = "food",
  DRINK = "drink",
}
export interface MenuHeading {
  enabled: boolean;
  groupType: MenuHeadingGroupType;
  name: string;
  productGroupId: string;
  restaurantId: string;
  shortName: string;
  subheadings: SubHeadings;
  enableSubHeadings?: boolean;
  completionTime?: number;
  inclusionsAtSubheadingLevel?: boolean;
  inclusionSubheadingType: "" | "fixed" | "limitless";
  type: "dynamic" | "static";
  products?: MenuHeadingProducts;
}

export interface MenuHeadings {
  [menuHeadingId: string]: MenuHeading;
}

export interface Menus {
  [menuId: string]: Menu;
}

export interface MenuCategory {
  _key?: string;
  enabled?: boolean;
  isDefault?: boolean;
  name: string;
  restaurantId: string;
}

export interface MenuCategories {
  [menuCategoryId: string]: MenuCategory;
}

export interface TimeRule {
  from: number;
  to: number;
  unitAdult: number;
  adultAmount: number;
  unitChild: number;
  childAmount: number;
}

export interface PaymentType {
  paymentRequirementTypeId: number;
  timeRules: TimeRule[];
  feeTypeId: number;
  roundingTypeId: number;
  nearestTypeId: number;
  amount: number;
  childDepositAmount: number;
  adultDepositAmount: number;
  noShowFeeTypeId: number;
  adultNoShowFeeAmount: number;
  childNoShowFeeAmount: number;
  noShowRoundingTypeId?: number;
  noShowNearestTypeId?: number;
  enableNoShow: boolean;
  enableCancellation: boolean;
  noShowProductId?: string;
  productId?: string;
}

export interface MenuPayment {
  // applyFixedPricing?: boolean;
  defaultToOneMenuOption?: boolean;
  guestsAndMenuOptionsRequired?: boolean;
  menuOptionsRequired?: boolean;
  productIds?: {
    [productId: string]: boolean;
  };
}

export interface MenuPayments {
  fixed?: MenuPayment;
  pricingAdjustments?: any;
  paymentTypes?: PaymentType[];
  enableMinimumSpend?: boolean;
  minimumSpendPerChild?: number;
  minimumSpendPerAdult?: number;
  enablePreAuthorisation?: boolean;
  paymentRequirementTypeId?: PaymentRequirementTypes;
}

export interface MenuBatchSettings {
  [menuId: string]: BatchSettings;
}
export interface BatchSettings {
  [id: string]: BatchSetting;
}

export interface CourseGroups {
  [id: string]: CourseGroup;
}

export interface CourseGroup {
  _key?: string;
  menuHeadingIds: Array<string>;
  name: string;
  courseAbbreviation?: string;
  order: number;
  batchSettingId?: string;
}
export interface BatchSetting {
  id?: string;
  courseGroups?: CourseGroups;
  orderingStyle: MenuOrderingStyle;
  name: string;
  batchWindows: BatchWindows;
  menuHeadingIds?: Array<string>;
  menuHeadings?: { [id: string]: BatchSettingMenuHeadingMap };
  order?: number;
  noBatchNotifications?: number;
}

export interface BatchWindows {
  [id: string]: BatchWindow;
}
export interface BatchWindow {
  _key?: string;
  batchTimeWindow: number;
  maxPax: number;
  minPax: number;
  order: number;
  /**
   * @deprecated This has been moved to BatchSetting. Remove it from here after code to move this is merged
   */
  noBatchNotifications?: number;
}
export interface BatchSettingMenuHeadingMap {
  complimentaryHeadings?: Array<string>;
  overrideHeadings?: Array<string>;
  overrideCourseGroupKeys?: string[];
}

export enum PackageMenuTypes {
  food = 1,
  beverage = 2,
  foodbeverage = 3,
}

export type PackagePriceType = "fixedProductPrice" | "individualProductPrice";

export interface Package {
  priceType?: PackagePriceType;
  _key?: string;
  description?: string;
  enabled?: boolean;
  isCanape?: boolean;
  standardRequiresPrepayment?: boolean;
  maxPax?: number;
  minPax?: number;
  name?: string;
  food?: {
    groupHeadings: GroupHeadings;
  };
  beverage?: {
    groupHeadings: GroupHeadings;
  };
  foodbeverage?: {
    groupHeadings: GroupHeadings;
  };
  pricing?: {
    [productId: string]: number;
  };
  restaurantId?: string;
  serviceStyles?: string[];
  standardServiceStyles?: string[];
  type?: "adult" | "child";
  order: number;
}

export interface AdditionalItemsCategory {
  name: string;
  enabled?: boolean;
}

export interface AdditionalItemsCategories {
  [categoryId: string]: AdditionalItemsCategory;
}

export interface Packages {
  [packageId: string]: Package;
}

// Beverage Menus Package
export interface BeverageMenuPackage {
  _key?: string;
  description: string;
  enabled?: boolean;
  maxPax: number;
  minPax: number;
  name: string;
  beverageMenus?: {
    groupHeadings: GroupHeadings;
  };
  restaurantId?: string;
  barTab?: boolean;
  beverageOnConsumption: boolean;
}

// Beverage Menus Packages
export interface BeverageMenuPackages {
  [packageId: string]: BeverageMenuPackage;
}

export interface MenuInclusionSettingCountdowns {
  [customerId: string]: MenuInclusionSettingCountdown[];
}

export interface MenuInclusionSettingCountdown {
  menuHeadingId?: string;
  groupHeadingId: string;
  resetTime: number;
}

export interface MenuInclusionSettingsByCustomer {
  [customerId: string]: MenuInclusionSettingsByMenuHeading;
}
export interface MenuInclusionSettingsByMenuHeading {
  [groupHeadingId: string]: MenuInclusionSetting;
}

export interface GroupInclusionSetupFromHeading {
  inclusionType?: MenuInclusionsType;
  fixedQuantityType?: MenuOrderingAllocationType;
  inclusionQuantityLimit?: number;
  perBookingQuantityLimits?: MenuInclusionsAllocationRule[];
  inclusionMinutesLimit?: number;
  displayName?: string;
  menuHeadingId?: string;
  onlyHostCanOrder?: boolean;
}

export interface MenuInclusionSetting {
  groupInclusionSetupFromHeading?: GroupInclusionSetupFromHeading;
  limit: number;
  limitReached: boolean;
  quantityLeft: number;
  quantityOrdered: number;
}
export interface InclusionSettingsByProduct {
  [productId: string]: { [sizeId: string]: MenuInclusionSetting };
}

export interface FunctionInclusionByProductCategory {
  [productCategory: number]: {
    settingsByProduct?: InclusionSettingsByProduct;
    settingsByHeading?: MenuInclusionSettingsByMenuHeading;
    packages?: {
      [id: string]: {
        settingsByProduct?: InclusionSettingsByProduct;
        settingsByHeading?: MenuInclusionSettingsByMenuHeading;
      };
    }; // if productcategory is food additional/beverageadditional/combinatioon addtional then it can have multiple packages
  };
}
export interface FunctionBookingAdditionalItem {
  productId: string;
  sizes: { [productSizeId: string]: boolean };
}

export interface FunctionTimelineNoteCategory {
  [timelineNoteCategoryId: string]: { name: string; enabled: boolean };
}

export interface FunctionTimelineNote {
  [timelineNoteId: string]: { note: string; enabled: boolean; categoryId: string };
}

export type FeatureFlagMap<T extends string> = Partial<{
  [P in T]: string;
}>;

export type FirebasePackageNameType = "food" | "beverage" | "foodbeverage" | "beverageMenus" | "drinkInclusions" | "foodInclusions";

export interface ProductOnlyLimitlessSetting {
  productLimitless: boolean;
  productLimitlessQuantityLimit: number;
  productLimitlessQuantityInBasket: number;
}
