import { priceFormatter } from "_utils/format";
import { getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";

const TransactionModal = ({ onClose, id }) => {
  const [transaction, setTransaction] = useState({});
  const [restaurant, setRestaurant] = useState({});

  const { client, tenantId } = getConfig();
  const paymentTypes = { 1: "CASH", 2: "STRIPE", 3: "ROUNDING", 4: "GIFTCERTIFICATE", 5: "PROMOTION", 6: "VOUCHER" };

  useEffect(() => {
    const getTransaction = async () => {
      const transactionDoc = await firebase.firestore().doc(`${client}/payments/transactions/${id}`).get();
      if (transactionDoc.exists) {
        setTransaction(transactionDoc.data());
        const restaurantSnapshot = await firebase.database().ref(`${tenantId}/restaurants/${transactionDoc.data().restaurantId}`).once("value");
        setRestaurant(restaurantSnapshot.val());
      }
    };
    getTransaction().then();
  }, []);

  return (
    <Modal isOpen toggle={() => onClose(false)} scrollable size="lg">
      <ModalHeader toggle={() => onClose(false)} tag="h4">
        Transaction Details
      </ModalHeader>
      <ModalBody>
        {Object.keys(transaction) < 1 ? (
          "No Transaction Found"
        ) : (
          <Table className="table table-striped table-bordered">
            <tbody>
              <tr>
                <td>Date</td>
                <td>{moment(transaction.date, "YYYYMMDD").format("DD/MM/YYYY")}</td>
              </tr>
              <tr>
                <td>Line Items</td>
                <td>
                  {(transaction.lineItems || []).map((item, key) => (
                    <Table key={key} className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{item.name}</td>
                        </tr>
                        <tr>
                          <td>Price</td>
                          <td>{priceFormatter.format(item.price)}</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>{item.quantity}</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>{priceFormatter.format(item.total)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ))}
                </td>
              </tr>
              <tr>
                <td>Payments</td>
                <td>
                  {(transaction.payments || []).map((item, key) => (
                    <Table key={key} className="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td>Type</td>
                          <td>{paymentTypes[item.paymentTypeId]}</td>
                        </tr>
                        <tr>
                          <td>Value</td>
                          <td>{priceFormatter.format(item.value)}</td>
                        </tr>
                        {item.paymentTypeId == 2 && (
                          <>
                            <tr>
                              <td>Transaction Id</td>
                              <td>{item.transactionId}</td>
                            </tr>
                            <tr>
                              <td>Card</td>
                              <td>{item.card}</td>
                            </tr>
                            <tr>
                              <td>Brand</td>
                              <td>{item.brand}</td>
                            </tr>
                            <tr>
                              <td>Currency</td>
                              <td>{item.currency}</td>
                            </tr>
                            <tr>
                              <td>Transaction Location</td>
                              <td>{item.transactionLocationType}</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  ))}
                </td>
              </tr>
              <tr>
                <td>Restaurant</td>
                <td>{restaurant?.name}</td>
              </tr>
              <tr>
                <td>Grand Total</td>
                <td>{priceFormatter.format(transaction.grandTotal)}</td>
              </tr>
              <tr>
                <td>ResButler Fee</td>
                <td>{priceFormatter.format(transaction.resbutlerFee)}</td>
              </tr>
              <tr>
                <td>Payment Service Fee</td>
                <td>{priceFormatter.format(transaction.serviceFee)}</td>
              </tr>
              <tr>
                <td>Venue Surcharge</td>
                <td>{priceFormatter.format(transaction.venueSurcharge)}</td>
              </tr>
              <tr>
                <td>Tip</td>
                <td>{priceFormatter.format(transaction?.tipAmount || 0)}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>{}</ModalFooter>
    </Modal>
  );
};

export default TransactionModal;
