import { Table } from "reactstrap";

const RestaurantRow = ({ readOnly, restaurantId, input, setInput, restaurants }) => {
  return (
    <tr>
      <td>{restaurants[restaurantId].name}</td>
      <td>
        <div className="checkbox c-checkbox">
          <label className="mb-2">
            <input
              type="checkbox"
              checked={input.restaurants.includes(restaurantId)}
              disabled={readOnly}
              onChange={() =>
                setInput({
                  ...input,
                  restaurants: input.restaurants.includes(restaurantId) ? input.restaurants.filter((id) => id !== restaurantId) : [...input.restaurants, restaurantId],
                })
              }
            />
            <span className="fa fa-check" />
          </label>
        </div>
      </td>
    </tr>
  );
};

const VoucherIssuedFormRestaurants = ({ readOnly, input, setInput, restaurants }) => {
  return (
    <div>
      <Table style={{ backgroundColor: "white" }}>
        <thead>
          <tr>
            <th>Restaurants</th>
            <th>Enabled</th>
          </tr>
        </thead>
        <tbody>{Object.entries(restaurants).map(([k, v]: any) => v.enabled && <RestaurantRow readOnly={readOnly} restaurants={restaurants} restaurantId={k} input={input} setInput={setInput} key={k} />)}</tbody>
      </Table>
    </div>
  );
};

export default VoucherIssuedFormRestaurants;
