import _ from 'lodash';
import { combineReducers } from 'redux';

import {
  GET_SUBSCRIPTION_DETAILS,
  GET_SYSTEM_PRODUCTS,
  GET_RESTAURANT_APPLICABLE_PERIOD_START,
  GET_RESTAURANT_APPLICABLE_PERIOD_END,
  GET_RESTAURANT_REVIEW_DATE,
  GET_RESTAURANT_MODULES_CHECKBOX_FIELDS,
  GET_GROUP_MODULES_CHECKBOX_FIELDS,
  GET_SUBSCRIPTION_TABS,
  GET_GROUP_SUBSCRIPTION_DETAILS,
  GET_GROUP_APPLICABLE_PERIOD_START,
  GET_GROUP_APPLICABLE_PERIOD_END,
  GET_GROUP_REVIEW_DATE,
} from './ActionTypes';

const initialState = {
  byId: {},
  subscriptionDetails: {
    contact: {},
    modules: {
      groupModules: 0,
      restaurantModules: {},
    },
    groupSubscriptionDetails: {},
    groupCommissionFees: {},
  },
  tabs: 1,
  systemProducts: [],
  groupSubDetailsField: {
    groupApplicablePeriodStart: [],
    groupApplicablePeriodEnd: [],
    groupReviewDate: [],
  },
  restaurantModulesCheckboxField: [],
  groupModulesCheckboxField: [],
  restaurantSubDetailsField: {
    restaurantApplicablePeriodStart: [],
    restaurantApplicablePeriodEnd: [],
    restaurantReviewDate: [],
  },
};

function restaurantApplicablePeriodStart(state = initialState.restaurantSubDetailsField.restaurantApplicablePeriodStart, action) {
  switch (action.type) {
    case GET_RESTAURANT_APPLICABLE_PERIOD_START:
      return action.restaurantApplicablePeriodStart;
    default:
      return state;
  }
}

function restaurantApplicablePeriodEnd(state = initialState.restaurantSubDetailsField.restaurantApplicablePeriodEnd, action) {
  switch (action.type) {
    case GET_RESTAURANT_APPLICABLE_PERIOD_END:
      return action.restaurantApplicablePeriodEnd;
    default:
      return state;
  }
}

function restaurantReviewDate(state = initialState.restaurantSubDetailsField.restaurantReviewDate, action) {
  switch (action.type) {
    case GET_RESTAURANT_REVIEW_DATE:
      return action.restaurantReviewDate;
    default:
      return state;
  }
}

function groupModulesCheckboxField(state = initialState.groupModulesCheckboxField, action) {
  switch (action.type) {
    case GET_GROUP_MODULES_CHECKBOX_FIELDS:
      return action.groupModulesCheckboxField;
    default:
      return state;
  }
}

function restaurantModulesCheckboxField(state = initialState.restaurantModulesCheckboxField, action) {
  switch (action.type) {
    case GET_RESTAURANT_MODULES_CHECKBOX_FIELDS:
      return action.restaurantModulesCheckboxField;
    default:
      return state;
  }
}

function groupApplicablePeriodStart(state = initialState.groupSubDetailsField.groupApplicablePeriodStart, action) {
  switch (action.type) {
    case GET_GROUP_APPLICABLE_PERIOD_START:
      return action.groupApplicablePeriodStart;
    default:
      return state;
  }
}
function groupApplicablePeriodEnd(state = initialState.groupSubDetailsField.groupApplicablePeriodEnd, action) {
  switch (action.type) {
    case GET_GROUP_APPLICABLE_PERIOD_END:
      return action.groupApplicablePeriodEnd;
    default:
      return state;
  }
}
function groupReviewDate(state = initialState.groupSubDetailsField.groupReviewDate, action) {
  switch (action.type) {
    case GET_GROUP_REVIEW_DATE:
      return action.groupReviewDate;
    default:
      return state;
  }
}

function subscriptionDetails(state = initialState.subscriptionDetails, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_DETAILS:
      return action.subscriptionDetails;
    default:
      return state;
  }
}

function tabs(state = initialState.tabs, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_TABS:
      return action.tabs;
    default:
      return state;
  }
}

function systemProducts(state = initialState.systemProducts, action) {
  switch (action.type) {
    case GET_SYSTEM_PRODUCTS:
      return action.systemProducts;
    default:
      return state;
  }
}

function groupSubscriptionDetails(state = initialState.subscriptionDetails.groupSubscriptionDetails, action) {
  switch (action.type) {
    case GET_GROUP_SUBSCRIPTION_DETAILS: {
      return action.groupSubscriptionDetails;
    }
    default:
      return state;
  }
}

export function filterProducts(products, isGroup) {
  if (!products) return;
  if (isGroup) {
    return _.filter(products, (val) => {
      return val.isGroup === isGroup;
    });
  } else {
    return _.filter(products, (val) => {
      return !val.isGroup;
    });
  }
}

export function sortProductsByGroupModuleName(products) {
  const productsSorted = _.sortBy(products, (product) => product.groupName);

  return productsSorted;
}

export function groupProductsByGroupModuleName(products) {
  const productsSorted = _.sortBy(products, (product) => product.groupName);

  const productsResult = _.chain(productsSorted)
    .groupBy('groupName')
    .map((value, key) => ({ groupModuleName: key, products: value }))
    .value();

  return productsResult;
}

export default combineReducers({
  subscriptionDetails,
  tabs,
  systemProducts,
  groupSubscriptionDetails,
  groupApplicablePeriodStart,
  groupApplicablePeriodEnd,
  groupReviewDate,
  restaurantModulesCheckboxField,
  groupModulesCheckboxField,
  restaurantApplicablePeriodStart,
  restaurantApplicablePeriodEnd,
  restaurantReviewDate,
  groupProductsByGroupModuleName,
});
