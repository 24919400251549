import axios from "axios";
import { toast } from "react-toastify";
import { PaymentTypeId } from "../../../resbutler-utils/types/transaction";

export const PAYMENT_METHODS = {
  EFTPOS: { text: "EFTPOS", value: PaymentTypeId.Eftpos },
  CASH: { text: "Cash", value: PaymentTypeId.Cash },
  GIFTCERTIFICATE: { text: "Gift Certificate", value: PaymentTypeId.GiftCertficate },
  OFFLINE: { text: "OFFLINE", value: PaymentTypeId.Offline },
  TAB: { text: "Payment Tab", value: PaymentTypeId.Tab },
};

export const onReceiptPrintClick = async (printerId, restaurantId, resbutlerApis, purchaseId = null, transactionId = null, isCustomerReceipt = true) => {
  await axios.post(`${resbutlerApis}/onsite/printing/receipt`, { restaurantId, printerId, purchaseId, transactionId, isCustomerReceipt });
  toast.success(`Successfully printed`, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

export const onPrint = async (printerId, restaurantId, resbutlerApis, printData) => {
  await axios.post(`${resbutlerApis}/onsite/printing/print`, { restaurantId, printerId, printData });
  toast.success(`Successfully printed`, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

export const openCashDrawer = async (tillId, resbutlerApis) => {
  await axios.post(`${resbutlerApis}/onsite/payments/openDrawer`, {
    tillId,
  });
};

export const getTransactionByCustomer = (transactions, booking, tab) => {
  if (booking) {
    const paidWithTabTransactions = transactions.filter((t) => t.payments && t.payments.length > 0 && t.payments.find((payment) => payment.paymentTypeId === 8 && payment.tabId === tab._key));
    const TillTabTransactions = transactions.filter((t) => t.payments.find((payment) => payment.paymentTypeId !== 8 && payment.tabId === tab._key));
    const totalTabAmount = TillTabTransactions.reduce((accumulator, currentValue) => accumulator + (currentValue.grandTotal - currentValue.serviceFee - currentValue.transactionFee), 0);
    const totalPaidAmount = paidWithTabTransactions.reduce((accumulator, currentValue) => accumulator + currentValue.grandTotal, 0);
    return { paidWithTabTransactions, totalTabAmount, totalPaidAmount };
  } else {
    const paidWithTabTransactions = transactions.filter((t) => t.payments && t.payments.length > 0 && t.payments.find((payment) => payment.paymentTypeId === 8 && payment.tabId === tab._key));
    const totalTabAmount = tab.amount;
    const totalPaidAmount = paidWithTabTransactions.reduce((accumulator, currentValue) => accumulator + currentValue.grandTotal, 0);
    return { paidWithTabTransactions, totalTabAmount, totalPaidAmount };
  }
};
