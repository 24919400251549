import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useEffect, useState } from "react";
import { ProductSearchResult } from "resbutler-utils/types/product";

let ref = null;

const useFunctionAdditionalItems = (restaurantId: string): [boolean, ProductSearchResult[], (v: ProductSearchResult[]) => Promise<void>] => {
  const [loading, setLoading] = useState(true);
  const [functionAdditionalItems, setFunctionAdditionalItems] = useState<ProductSearchResult[]>([]);
  const { tenantId } = getConfig();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      ref = firebase
        .database()
        .ref(`${tenantId}/functionsAdditionalItems/${restaurantId}`)
        .on(
          "value",
          (snapshot) => {
            setFunctionAdditionalItems(snapshot.val() || []);
            setLoading(false);
          },
          catchExceptionCallback
        );
    };
    if (restaurantId) fetchData();
    return () => {
      if (ref?.off) ref.off();
    };
  }, [restaurantId]);

  async function saveFunctionAdditionalItems(values: ProductSearchResult[]) {
    await firebase.database().ref(`${tenantId}/functionsAdditionalItems/${restaurantId}`).set(values);
  }

  return [loading, functionAdditionalItems, saveFunctionAdditionalItems];
};

export default useFunctionAdditionalItems;
