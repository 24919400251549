import { combineReducers } from "redux";
import { weekDays } from "core/utilities";
import _ from "lodash";

import { GET_TIME_ZONES, GET_RESTAURANTS_TABS } from "./ActionTypes";

const initialState = {
  zones: [],
  tabs: 1,
};

function zones(state = initialState.zones, action) {
  switch (action.type) {
    case GET_TIME_ZONES:
      return action.zones;
    default:
      return state;
  }
}

export function getRestaurantAreas(restaurants, restaurantId, areaGroups) {
  const results = [];
  const restaurant = restaurants[restaurantId];
  if (restaurant) {
    for (const groupAreaId in restaurant.areaGroups) {
      const areaGroup = _.find(areaGroups, (a) => a.abbreviation === groupAreaId && restaurant.areaGroups[groupAreaId]);
      if (areaGroup) {
        results.push(areaGroup);
      }
    }
  }
  return results;
}

export function getRestaurantById(restaurants, restaurantId) {
  return restaurants[restaurantId] || null;
}

export function getEditorOptions(allocations, allocationId) {
  let editorOptions = {};
  for (let i = 0; i < weekDays.length; i++) {
    editorOptions = {
      ...editorOptions,
      [weekDays[i]]: "",
    };
    if (allocations[allocationId] && allocations[allocationId].labelId) {
      editorOptions = {
        ...editorOptions,
        ...allocations[allocationId].labelId,
      };
    }
  }
  return editorOptions;
}

export function getAllocationOptions(allocations, allocationId) {
  let allocationOptions = {};
  for (let i = 0; i < weekDays.length; i++) {
    allocationOptions = {
      ...allocationOptions,
      [weekDays[i]]: "",
    };
    if (allocations[allocationId] && allocations[allocationId].allocationRuleId) {
      allocationOptions = {
        ...allocationOptions,
        ...allocations[allocationId].allocationRuleId,
      };
    }
  }
  return allocationOptions;
}

export function getRestaurants(restaurants, user) {
  const restaurants1 = {};
  for (const restaurantId in restaurants) {
    if (restaurants[restaurantId].enabled === true && user && user.restaurants && user.restaurants[restaurantId]) {
      restaurants1[restaurantId] = restaurants[restaurantId];
    }
  }
  return restaurants1;
}

function tabs(state = initialState.tabs, action) {
  switch (action.type) {
    case GET_RESTAURANTS_TABS:
      return action.tabs;
    default:
      return state;
  }
}

export default combineReducers({
  zones,
  tabs,
});
