import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { Field, Form } from "react-final-form";
import { Col, Row, Table } from "reactstrap";
import { catchExceptionCallback } from "../../../resbutler-ui/utilities";

const IssuedGiftCertificatesRestaurants = (props) => {
  const { restaurants, giftCertificateId, giftCertificate, tenantId } = props;

  const save = async (values, giftCertificateId) => {
    try {
      await firebase.database().ref(`${tenantId}/giftCertificates/${giftCertificateId}`).update(values);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <Row>
      <Col lg={6} sm={12} className="px-0 py-0">
        <Form
          initialValues={giftCertificate}
          onSubmit={(values) => save(values, giftCertificateId)}
          render={({ handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Table responsive condensed>
                  <thead>
                    <tr>
                      <th>Restaurant</th>
                      <th>Enabled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(restaurants).map((restaurantId) => (
                      <tr key={restaurantId}>
                        <td>{restaurants[restaurantId].name}</td>
                        <td>
                          <div className="checkbox c-checkbox">
                            <label className="mb-1" htmlFor={`enabled-${restaurantId}`}>
                              <Field id={`enabled-${restaurantId}`} name={`restaurants[${restaurantId}]`} disabled checked={!values.restaurantId || values.restaurantId === restaurantId} component="input" type="checkbox" />
                              <span className="fa fa-check" />
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </form>
            );
          }}
        ></Form>
      </Col>
    </Row>
  );
};

export default IssuedGiftCertificatesRestaurants;
