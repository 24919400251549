import { combineReducers } from "redux";
import { GET_CLIENT_BY_ID } from "./ActionTypes";

const initialState = {
  client: {},
};

function client(state = initialState.client, action) {
  switch (action.type) {
    case GET_CLIENT_BY_ID:
      return action.client;
    default:
      return state;
  }
}

export default combineReducers({
  client,
});
