import algoliasearch from "algoliasearch/lite";
import { useAppSelector } from "components/Hooks/hooks";
import { getConfig } from "core/utilities";
import "firebase/compat/firestore";
import _, { find, keys } from "lodash";
import { useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { Button, Col, Row, Table } from "reactstrap";
import { ProductSearchModal } from "../resbutler-ui/components/ProductSearch/ProductSearchModal";

// eslint-disable-next-line no-undef
const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA);

const DisplayFixedProduct = ({ productId, onDelete }) => {
  const products = useAppSelector((state) => state.root.products);
  const product = find(products, (p) => p.id === productId);

  return product ? (
    <tr key={`fixed-products-${productId}`}>
      <td>{product.name}</td>
      <td>
        <Button type="button" color="danger" onClick={onDelete}>
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
      </td>
    </tr>
  ) : null;
};

const Pricing = () => {
  const {
    client: clientId,
    tenantId,
    config: { publicStorageBucket },
  } = getConfig();
  const { restaurantId, productSizes } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
      productSizes: state.root.productSizes,
    };
  });

  const values = useFormState().values;
  const [show, setShow] = useState(false);
  const form = useForm();

  return (
    <Row>
      <Col lg={12}>
        <div className="form-group">
          <label htmlFor="edit1">Select Fixed Price Product</label>
          <br />
          <Button id="edit1" color="primary" type="button" aria-label="Edit" onClick={() => setShow(true)}>
            <i className="fa fa-edit" aria-hidden="true" />
          </Button>
        </div>
        <Field name="pricing">
          {({ input: { value }, meta }) => {
            return (
              <>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <td>Product</td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {keys(value).map((productId) => {
                      return <DisplayFixedProduct key={productId} productId={productId} onDelete={() => form.change(`pricing.${productId}`, undefined)} />;
                    })}
                  </tbody>
                </Table>
                {meta.touched && meta.error && <span className="validate-form">{meta.error}</span>}
              </>
            );
          }}
        </Field>
        <ProductSearchModal
          clientId={clientId}
          publicStorageBucket={publicStorageBucket}
          initialValues={keys(values.pricing).map((productId) => {
            return { productId, sizes: {} };
          })}
          open={show}
          limitToOne={false}
          setOpen={setShow}
          onCloseData={async (data) => {
            form.change(
              "pricing",
              _.reduce(
                data,
                (obj, param) => {
                  obj[param.productId] = true;
                  return obj;
                },
                {}
              )
            );
          }}
          query={"typeId:128"}
          tenantId={tenantId}
          searchClient={searchClient}
          restaurantId={restaurantId}
          productSizes={productSizes}
        />
      </Col>
    </Row>
  );
};

export default Pricing;
