import "firebase/auth";
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";

import { useAppSelector } from "components/Hooks/hooks";
import { catchExceptionCallback, getConfig } from "core/utilities";
import { GiftCertificate } from "resbutler-utils/types/GiftCertificate";
import { Customer } from "resbutler-utils/types/customer";
import IssuedGiftCertificatesForm from "./IssuedGiftCertificatesForm";

function Default() {
  const { tenantId, client } = getConfig();
  const [giftCertificate, setGiftCertificate] = useState<GiftCertificate>(null);
  const { id: giftCertificateId } = useParams();
  const restaurants = useAppSelector((state) => state.root.restaurants);

  useEffect(() => {
    async function load() {
      try {
        const giftCertificateSnapshot = await firebase.database().ref(`${tenantId}/giftCertificates/${giftCertificateId}`).once("value");
        const gc = giftCertificateSnapshot.val() as GiftCertificate;
        let recipient = null;
        if (gc?.recipientCustomerId) {
          const doc = await firebase.firestore().doc(`${client}/crm/customers/${gc.recipientCustomerId}`).get();
          recipient = doc.data() as Customer;
        }
        setGiftCertificate({
          ...gc,
          recipientFirstName: giftCertificate.recipientCustomerId ? recipient.firstName : giftCertificate.recipientFirstName,
          recipientLastName: giftCertificate.recipientCustomerId ? recipient.lastName : giftCertificate.recipientLastName,
          recipientEmail: giftCertificate.recipientCustomerId ? recipient.email : giftCertificate.recipientEmail,
        });
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    load();
  }, [giftCertificateId]);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Gift Certificate Issued" parentHref="/gift-certificates/issued" subHeaderText={`Gift Certificate ${giftCertificate && giftCertificate.voucherNumber ? giftCertificate.voucherNumber : ""}`} showRestaurants={false} />
      {giftCertificate ? <IssuedGiftCertificatesForm giftCertificate={giftCertificate} restaurants={restaurants} /> : null}
    </ContentWrapper>
  );
}

export default Default;
