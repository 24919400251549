import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import _ from "lodash";
import { useEffect, useState } from "react";

/**
 * @deprecated Please don't use this
 */
const useCrudServices = (restaurantId = "", entityName = "", onlyEnabled = false) => {
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState({});
  const [entity, setEntity] = useState({});
  const [entityId, setEntityId] = useState("");
  const { tenantId } = getConfig();
  const path = restaurantId ? `${tenantId}/${entityName}/${restaurantId}` : `${tenantId}/${entityName}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let snapshot = null;
        if (entityName) {
          snapshot = await firebase.database().ref(path).once("value");
          if (snapshot.exists()) {
            const list = snapshot.val();
            if (onlyEnabled) {
              setEntities(_.pickBy(list, (list) => list.enabled));
            } else {
              setEntities(list);
            }
          }
        }
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [entityName, restaurantId]);

  async function saveEntity(values) {
    try {
      await firebase.database().ref(`${path}/${entityId}`).update(values);
      setEntities(values);
    } catch (error) {
      catchExceptionCallback(error);
    }
  }

  function getNewEntityId() {
    try {
      setLoading(true);
      const newId = firebase.database().ref(`${path}`).push().key;
      setEntityId(newId);
      return newId;
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  async function getEntityById(entityId) {
    try {
      setLoading(true);
      const snapshot = await firebase.database().ref(`${path}/${entityId}`).once("value");
      setEntityId(entityId);
      if (snapshot.exists()) setEntity(snapshot.val());
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  return [loading, entities, entityId, entity, saveEntity, getNewEntityId, getEntityById];
};

export default useCrudServices;
