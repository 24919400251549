import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { groupBy, map, replace, sortBy } from "lodash";
import { useEffect, useState } from "react";
import Select from "react-select";

interface CalendarItem {
  week: string;
  month: string;
}

const PeriodSelector = ({ onChangeYear, onChangeMonth, onChangeWeek, calendar, mode, value }) => {
  const [calendarData, setCalendarData] = useState([]);
  const { client } = getConfig();

  useEffect(() => {
    const getCalendarData = async () => {
      setCalendarData([]);

      try {
        const snapshots = await firebase.firestore().collection(`${client}/calendar/calendarData`).where("year", "==", value.year).get();
        let data = snapshots.docs.map((snap) => snap.data()) as CalendarItem[];
        data = sortBy(data, (item: CalendarItem) => Number(replace(item.week, /[^\d]/g, "")));
        setCalendarData(data);
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    if (value?.year) getCalendarData();
  }, [value?.year]);

  const weekOrMonthOptions =
    mode === "week"
      ? Object.keys(groupBy(calendarData, "week")).map((item) => ({
          value: item,
          label: item,
        }))
      : Object.keys(groupBy(calendarData, "month")).map((item) => ({
          value: item,
          label: item,
        }));

  const resetMonth = () => {
    onChangeMonth(undefined);
  };

  const resetWeek = () => {
    onChangeWeek(undefined);
  };

  const handleChangeYear = (e) => {
    resetMonth();
    resetWeek();
    onChangeYear(e.value);
  };

  const handleChangeMonthOrWeek = (e) => {
    if (mode === "week") {
      resetMonth();
      onChangeWeek(e.value);
    } else {
      resetWeek();
      onChangeMonth(e.value);
    }
  };

  return (
    <div className="d-flex flex-wrap gap-md flex-grow-1">
      <div className="flex-grow-1">
        <Select
          options={map(calendar, (c, key) => ({
            value: key,
            label: c.name,
          }))}
          onChange={handleChangeYear}
          value={
            value?.year
              ? {
                  value: value.year,
                  label: calendar[value.year].name
                }
              : null
          }
          isDisabled={!mode}
          placeholder="Select year..."
        />
      </div>

      <div className="flex-grow-1">
        <Select
          options={weekOrMonthOptions}
          isDisabled={!value?.year && !mode}
          onChange={handleChangeMonthOrWeek}
          placeholder={mode === "week" ? "Select week..." : "Select month..."}
          value={
            mode === "week"
              ? value?.week
                ? {
                    value: value.week,
                    label: value.week,
                  }
                : null
              : value?.month
              ? {
                  value: value.month,
                  label: value.month,
                }
              : null
          }
        />
      </div>
    </div>
  );
};

export default PeriodSelector;
