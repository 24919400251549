import React from "react";

const defaultOptions = [
  { name: "Enabled Only", value: true },
  { name: "Disabled Only", value: false },
];
const withShowAllOption =  Array.prototype.concat([{ name: "Show All", value: "" }], defaultOptions);

export const getFilterName = (types, value) => {
  const itemDisplay = types.find((item) => item.value === value);
  if (itemDisplay) return itemDisplay.name;
  return "Show All";
};

export const filterListByDisabled = (list, value) => {
  let responseList = undefined;
  if (list?.length >= 0 && value !== undefined) {
    if (value === false) {
      responseList = list.filter((item) => item.disabled === !value);
    } else {
      responseList = list.filter((item) => item.disabled === !value || item.disabled === undefined);
    }
  } else if (list && value !== undefined) {
    let auxKeysList = {};
    if (value === false) {
      auxKeysList = Object.keys(list).filter((key) => list[key].disabled === !value);
    } else {
      auxKeysList = Object.keys(list).filter((key) => list[key].enabled === !value || list[key].disabled === undefined);
    }
    responseList = {};
    auxKeysList.map((key) => (responseList[key] = list[key]));
  }
  return responseList || list;
};

export const filterList = (list, value) => {
  let responseList = undefined;
  if (list?.length >= 0 && value !== null) {
    if (value === false) {
      responseList = list.filter((item) => item.enabled === value || item.enabled === undefined || item.disabled === !value);
    } else {
      responseList = list.filter((item) => item.enabled === value || item.disabled === !value);
    }
  } else if (list && value !== null) {
    let auxKeysList = {};
    if (value === false) {
      auxKeysList = Object.keys(list).filter((key) => list[key].enabled === value || list[key].enabled === undefined || list[key].disabled === !value);
    } else {
      auxKeysList = Object.keys(list).filter((key) => list[key].enabled === value || list[key].disabled === !value);
    }
    responseList = {};
    auxKeysList.map((key) => (responseList[key] = list[key]));
  }
  return responseList || list;
};

export class DropdownListFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdownValue: true };
  }

  componentDidMount() {
    setTimeout(() => this.props.onSelect(this.state.dropdownValue), 3000);
  }

  render() {
    const { onSelect, disabled, className, label, helpText, hideShowAll = false } = this.props;
    let { types } = this.props;
    if (!types) {
      types = hideShowAll ? defaultOptions : withShowAllOption;
    }

    const innerContent = (
      <>
        {label ? <label>{label}</label> : null}
        <select
          className="form-control"
          disabled={disabled}
          onChange={(e) => {
            // eslint-disable-next-line no-debugger
            let v = null;
            if (e.target.value === "true") v = true;
            else if (e.target.value === "false") v = false;
            onSelect(v);
            this.setState({ dropdownValue: v });
          }}
          value={this.state.dropdownValue}
        >
          {types.map((type, i) => (
            <option key={i} value={type.value}>
              {type.name}
            </option>
          ))}
        </select>
        {helpText ? <div className="help-text">{helpText}</div> : null}
      </>
    );

    if (!className) {
      return innerContent;
    }

    return <div className={className}>{innerContent}</div>;
  }
}
