import { catchExceptionCallback } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { map } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Field, useFormState } from "react-final-form";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { Restaurants } from "resbutler-utils/types/Restaurant";
import { VoucherSetup } from "resbutler-utils/types/Voucher";
import { Product, ProductSizes } from "resbutler-utils/types/product";
import { rgbToHex } from "../../utils/colorConverter";

const styles = {
  divider: { marginLeft: "30%", marginRight: "30%", borderWidth: 2 },
  fontSizeMedium: { fontSize: 20 },
  fontSizeLg: { fontSize: 40 },
  logoSize: { maxWidth: 250, maxHeight: 250 },
};

const getExpiryDate = (voucher: VoucherSetup) => {
  let expiryDate = "" as any;
  if (voucher.generationType == "oneOff") {
    expiryDate = moment(voucher.voucherGenerateOn?.seconds * 1000 || voucher.voucherGenerateOn).add(voucher.voucherExpiryNumber, voucher.voucherExpiryType);
  } else {
    if (voucher.voucherGenerateOn == "firstDayOfMonth") {
      expiryDate = moment().startOf("M").add(voucher.voucherExpiryNumber, voucher.voucherExpiryType);
    } else if (voucher.voucherGenerateOn == "lastDayOfMonth") {
      expiryDate = moment().endOf("M").add(voucher.voucherExpiryNumber, voucher.voucherExpiryType);
    }
  }
  return expiryDate;
};
const EmailTemplatePreview = ({ template, voucher, restaurants, products, productSizes }: { template: any; voucher: VoucherSetup; restaurants: Restaurants; products: Product[]; productSizes: ProductSizes }) => {
  const headerColor = template.brandColor ? rgbToHex(template.brandColor) : "";
  const backgroundColor = template.backgroundColour ? rgbToHex(template.backgroundColour) : "";
  const buttonBackgroundColor = template.buttonBackgroundColor ? rgbToHex(template.buttonBackgroundColor) : "";
  const buttonfontSize = template.buttonSize == "small" ? "13px" : template.buttonSize == "medium" ? "15px" : "18px";
  const footerBackgroundColor = template.footerBackgroundColor ? rgbToHex(template.footerBackgroundColor) : "black";
  const footerTextColor = template.footerTextColor ? rgbToHex(template.footerTextColor) : "white";

  return (
    <>
      <div style={{ backgroundColor: backgroundColor, color: "black" }}>
        {template.logo ? (
          <div className="p-4" style={{ backgroundColor: headerColor }}>
            <img className="d-block m-auto" style={styles.logoSize} src={template.logo} />
          </div>
        ) : null}
        {template.largeImage1 ? <img width="100%" height="100%" src={typeof template.largeImage1 !== "string" ? template.largeImage1 : template.largeImage1} /> : null}
        {template.headerText1 ? (
          <h2 className="text-center mt-3 pt-3 pb-3" style={styles.fontSizeMedium}>
            <span dangerouslySetInnerHTML={{ __html: template.headerText1 }} />
          </h2>
        ) : null}
        {template.bodyText1 ? (
          <p className="text-center mb-2">
            <span dangerouslySetInnerHTML={{ __html: template.bodyText1 }} />
          </p>
        ) : null}

        <div className="ml-4 mr-4">
          <hr className="border-dark" style={styles.divider} />
          <p className="text-center mb-2" style={styles.fontSizeMedium}>
            {voucher.discountType === "%" || voucher.discountType === "$" ? (
              <>
                <span style={styles.fontSizeLg}>{voucher.discountType == "%" ? `${voucher.discountAmount}%` : `$${voucher.discountAmount}`}</span> Discount
              </>
            ) : null}

            {voucher.discountType === "product" ? (
              <span>
                {map(voucher.complementaryProducts, (item1, index) => {
                  const product = products?.[item1.productId];
                  const sizes = item1.sizes;

                  const isLastItem = index === voucher.complementaryProducts.length - 1;
                  const separator = index === 0 ? "" : isLastItem ? " & " : ", ";

                  return (
                    <Fragment key={item1.productId}>
                      {separator}
                      {product.name}&nbsp; (
                      {map(sizes, (val, key) => {
                        if (val === false) {
                          return null;
                        }

                        const size = productSizes?.[key];
                        return size.name;
                      })
                        .filter(Boolean)
                        .join(", ")}
                      )
                    </Fragment>
                  );
                })}
              </span>
            ) : null}
          </p>
          <hr className="border-dark" style={styles.divider} />
        </div>

        <div className="text-center mb-3">
          <p className="font-weight-bold">XXXXXXXXX</p>
          <p>The voucher can be used when making a new booking or at the venue</p>
        </div>

        {voucher.restaurants.length > 0 ? (
          <div className="text-center mb-3">
            <h5 className="font-weight-bold">Reedemable at</h5>
            {voucher.restaurants.map((item, index) => {
              return <p key={index}>{restaurants[item].name}</p>;
            })}
          </div>
        ) : null}

        {getExpiryDate(voucher) ? (
          <p className="text-center mb-3">
            <h5 className="font-weight-bold">Expiry</h5>
            <p>{moment(getExpiryDate(voucher)).format("DD/MM/YYYY")}</p>
          </p>
        ) : null}

        <div className="text-center mt-4">
          {template.buttonText1 ? (
            <Button
              className="text-center font-weight-bold text-white border rounded d-inline-block"
              style={{
                backgroundColor: buttonBackgroundColor,
                borderColor: buttonBackgroundColor,
                fontSize: buttonfontSize,
                letterSpacing: 0,
                padding: "10px 30px",
              }}
            >
              {template.buttonText1}
            </Button>
          ) : null}

          <div className="mt-4">
            {template.linkFacebook ? (
              <a href={template.linkFacebook}>
                <img src="http://cdn.mcauto-images-production.sendgrid.net/1584870273e06a86/a062bdd4-77e5-4f08-85b0-0b9a3ef96425/50x50.png" className="pr-2" />
              </a>
            ) : null}
            {template.linkInstagram ? (
              <a href={template.linkInstagram}>
                <img src="http://cdn.mcauto-images-production.sendgrid.net/1584870273e06a86/e2ae32fa-40da-4fc8-8f77-f317031a3f7c/50x50.png" className="pr-2" />
              </a>
            ) : null}
            {template.linkTwitter ? (
              <a href={template.linkTwitter}>
                <img src="http://cdn.mcauto-images-production.sendgrid.net/1584870273e06a86/7150a119-80f1-4546-a8ad-fd5b6aa58cbc/50x50.png" className="pr-2" />
              </a>
            ) : null}
            {template.linkYouTube ? (
              <a href={template.linkYouTube}>
                <img src="http://cdn.mcauto-images-production.sendgrid.net/1584870273e06a86/6dadba90-7fa0-4435-9a9e-d3c04429f887/50x50.png" />
              </a>
            ) : null}
          </div>
          {template.address ? (
            <div style={{ width: "920", height: "110px", fontSize: "12px", fontFamily: "calibri", color: footerTextColor, backgroundColor: footerBackgroundColor, marginTop: "15px" }}>
              <br />
              {template.address ? <p>{template.address}</p> : null}
              {template.website ? <p>{template.website}</p> : null}
              <a href="" style={{ color: footerTextColor }}>
                <u>Unsubscribe</u>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const PreviewEmail = ({ openPreview, setOpenPreview, voucher, restaurants, clientId, products, productSizes }: { openPreview: boolean; setOpenPreview: any; voucher: VoucherSetup; restaurants: Restaurants; clientId: string; products: Product[]; productSizes: ProductSizes }) => {
  const [newsLetters, setNewsLetters] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async (template, id) => {
      try {
        setLoading(true);
        const snapshot = await firebase.firestore().doc(`${clientId}/crm/emailBranding/${id}`).get();
        setNewsLetters({ ...template, ...snapshot.data() });
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (voucher?.template.templateId) load(voucher.template, voucher.template.templateId);
  }, [voucher]);

  const previewToggle = () => setOpenPreview(!openPreview);
  return (
    <Modal scrollable isOpen={openPreview} size="lg" style={{ maxWidth: "800px", width: "100%" }} toggle={previewToggle} centered  zIndex={9999}>
      <ModalHeader toggle={previewToggle}>Preview Email</ModalHeader>
      <ModalBody>{!loading ? <EmailTemplatePreview template={newsLetters} voucher={voucher} restaurants={restaurants} products={products} productSizes={productSizes} /> : <Spinner />}</ModalBody>
      <ModalFooter>
        <Button color="light" onClick={previewToggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const VoucherTemplate = ({ disabled, templates, restaurants, clientId, products, productSizes }: { disabled: boolean; templates: any; restaurants: Restaurants; clientId: string; products: Product[]; productSizes: ProductSizes }) => {
  const voucher = useFormState<VoucherSetup>().values;
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <Row>
      <Col>
        <Field name="sendEmail">
          {({ input: { onChange, value } }) => {
            return (
              <FormGroup>
                <div className="checkbox c-checkbox">
                  <label className="mb-1">
                    <input checked={value} type="checkbox" onChange={() => onChange(!value)} disabled={disabled} />
                    <span className="fa fa-check" />
                    Send Email
                  </label>
                </div>
              </FormGroup>
            );
          }}
        </Field>

        {voucher.sendEmail && (
          <>
            <h5>
              <b>Customise Email</b>
            </h5>

            <Field name="template.templateId">
              {({ input: { onChange, value }, meta }) => (
                <FormGroup>
                  <Label>
                    Email Branding Template <span className="text-danger">*</span>
                  </Label>
                  <select className="form-control" onChange={onChange} value={value} disabled={disabled}>
                    <option value="">Please Select</option>
                    {templates.map((template, index) => (
                      <option key={`option-voucher-${index}`} value={template._key}>
                        {template.name}
                      </option>
                    ))}
                  </select>
                  <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                </FormGroup>
              )}
            </Field>

            <Field name="template.headerText1">
              {({ input: { onChange, value }, meta }) => {
                return (
                  <FormGroup>
                    <Label>
                      Header Text 1 <span className="text-danger">*</span>
                    </Label>
                    <input value={value} type="text" className="form-control" onChange={({ target: { value: headerText1 } }) => onChange(headerText1)} disabled={disabled} />
                    <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                  </FormGroup>
                );
              }}
            </Field>

            <Field name="template.bodyText1">
              {({ input: { onChange, value }, meta }) => {
                return (
                  <FormGroup>
                    <Label>
                      Body Text 1 <span className="text-danger">*</span>{" "}
                    </Label>
                    <textarea value={value} className="form-control" onChange={({ target: { value: bodyText1 } }) => onChange(bodyText1)} disabled={disabled} />
                    <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                  </FormGroup>
                );
              }}
            </Field>

            <div>
              <Button className="float-right" id="campaign-save" type="button" onClick={() => setOpenPreview(true)} disabled={disabled || !voucher.template?.templateId}>
                Preview
              </Button>
              <div className="clearfix" />
            </div>
            <hr />
            {openPreview && <PreviewEmail openPreview={openPreview} setOpenPreview={setOpenPreview} voucher={voucher} restaurants={restaurants} clientId={clientId} products={products} productSizes={productSizes} />}
          </>
        )}
      </Col>
    </Row>
  );
};

export default VoucherTemplate;
