import classnames from "classnames";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { selectCustomStyles } from "core/utilities";
import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Card, CardBody, Col, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { denormalize } from "../../_utils/firebase";
import { buildReactSelectOptions } from "../../_utils/react-select";
import { getRestaurants } from "../../system/RestaurantReducer";
import MembershipAppSettingsFormCore from "./MembershipAppSettingsFormCore";
import PwaAppForm from "./PwaAppForm";

const MembershipAppSettings = () => {
  const restaurants = useSelector((state: any) => buildReactSelectOptions(denormalize(getRestaurants(state.root.restaurants, state.root.user))));
  const [restaurantId, setRestaurantId] = useState(null);
  const [restaurantIdPwa, setRestaurantIdPwa] = useState(null);

  const [tab, setTab] = useState(0);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" headerText="Membership App Settings" showRestaurants={false} />
      <Col md={10} xl={8}>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames([{ active: tab === 0 }, "px-2"])} onClick={() => setTab(0)}>
              Group Membership App
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames([{ active: tab === 1 }, "px-2"])} onClick={() => setTab(1)}>
              Restaurant Membership App
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames([{ active: tab === 2 }, "px-2"])} onClick={() => setTab(2)}>
              Group PWA
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames([{ active: tab === 3 }, "px-2"])} onClick={() => setTab(3)}>
              Restaurant PWA
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="p-3" activeTab={tab}>
          {/* GROUP */}
          <TabPane tabId={0}>
            <MembershipAppSettingsFormCore restaurantId={restaurantId} />
          </TabPane>
          {/* INDIVIDUAL */}
          <TabPane tabId={1}>
            <Card className="card-default">
              <CardBody>
                <FormGroup>
                  <Label>Select Restaurant</Label>
                  <Select options={restaurants} defaultValue={restaurantId} onChange={({ value }) => setRestaurantId(value)} styles={selectCustomStyles} />
                </FormGroup>
              </CardBody>
            </Card>
            {restaurantId && <MembershipAppSettingsFormCore restaurantId={restaurantId} />}
          </TabPane>
          <TabPane tabId={2}>
            <PwaAppForm restaurantId={restaurantId} />
          </TabPane>
          <TabPane tabId={3}>
            <Card className="card-default">
              <CardBody>
                <FormGroup>
                  <Label>Select Restaurant</Label>
                  <Select options={restaurants} defaultValue={restaurantIdPwa} onChange={({ value }) => setRestaurantIdPwa(value)} />
                </FormGroup>
              </CardBody>
            </Card>
            {restaurantIdPwa && <PwaAppForm restaurantId={restaurantIdPwa} />}
          </TabPane>
        </TabContent>
      </Col>
    </ContentWrapper>
  );
};

export default MembershipAppSettings;
