import { forEach, groupBy } from "lodash";

const productFilters = {
  "all-products": {
    value: "all-products",
    label: "All Products",
  },
  "product-groups": {
    value: "product-groups",
    label: "Product Groups",
  },
};

export interface IReportByProductTreePayload {
  calendar: string;
  restaurantId: string;
  clientId: string;
  firstPeriod?: any;
  secondPeriod?: any;
  mode?: string;
  firstDateFrom?: string;
  firstDateTo?: string;
  secondDateFrom?: string;
  secondDateTo?: string;
}

const normalizeResults = (results, filterTreeData, filterValue) => {
  let result = {};

  const data = groupBy(results, "groupId");

  forEach(data, (item, key) => {
    let lines = [] as any;

    forEach(item, (i) => {
      let shouldShowByMealPeriod = filterValue.includes(filterTreeData[0].value);
      let shouldShowByArea = filterValue.includes(filterTreeData[1].value);
      let shouldShowByTableClass = filterValue.includes(filterTreeData[2].value);

      // Meal period
      if (!shouldShowByMealPeriod) {
        shouldShowByMealPeriod = !!i.mealId && filterValue.includes(i.mealId);
      }

      // Area
      if (!shouldShowByArea) {
        shouldShowByArea = !!i.areaId && filterValue.includes(i.areaId);
      }

      // Table class
      if (!shouldShowByTableClass) {
        shouldShowByTableClass = !!i.tableClassId && filterValue.includes(i.tableClassId);
      }

      // Top level filtration
      if (shouldShowByMealPeriod && shouldShowByArea && shouldShowByTableClass) {
        lines.push(...i.lines);
      }
    });

    lines = groupBy(lines, "productId");

    result = {
      ...result,
      [key]: {
        productGroupType: item[0].productGroupType,
        lines,
      },
    };
  });

  return result;
};

export { normalizeResults, productFilters };
