import jsPDF from "jspdf";
import "jspdf-autotable";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export default function generatePDF(tableElement: HTMLElement, title: string, filename = "report", scale = 1) {
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: "letter",
  });

  const titleX = 0.1;
  const titleY = 0.5;
  const titleFontSize = 14;

  let titleAdded = false;

  pdf.autoTable({
    html: tableElement,
    startY: titleY + (titleAdded ? 0 : 0.25),
    margin: { top: 0.1, left: 0.1, right: 0.1 },
    didDrawPage() {
      if (!titleAdded) {
        pdf.setFont("helvetica");
        pdf.setFontSize(titleFontSize);
        pdf.text(title, titleX, titleY);
        titleAdded = true;
      }
    },
    didParseCell(data: { cell: { styles: { fontSize: number } } }) {
      data.cell.styles.fontSize *= scale;
    },
  });

  pdf.save(`${filename}.pdf`);
}
