import { GET_PRODUCTS, GET_PRODUCT_GROUPS, GET_RESTAURANTS, GET_ROOT_USER, WINDOW_GET_SIZE } from "ActionTypes";
import { useAppSelector } from "components/Hooks/hooks";
import useMenus from "components/Hooks/useMenus";
import useProductAmmendments from "components/Hooks/useProductAdmmendments";
import useProductGroups from "components/Hooks/useProductGroups";
import useProductSizes from "components/Hooks/useProductSizes";
import useProducts from "components/Hooks/useProducts";
import useRefreshToken from "components/Hooks/useRefreshToken";
import { clearUsers } from "core/auth";
import { getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "loaders.css/loaders.css";
import { useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { shallowEqual, useDispatch } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getSubscriptionDetails, getSystemProducts } from "system/subscription-details/SubscriptionDetailsAction";
import { GET_GROUP_AREA_TYPES } from "../../ActionTypes";
import Footer from "./Footer";
import Header from "./Header";
import Offsidebar from "./Offsidebar";
import Sidebar from "./Sidebar";

const Base = () => {
  const { user } = useAppSelector((state) => {
    return {
      user: state.root.user,
    };
  }, shallowEqual);

  useRefreshToken();

  const dispatch = useDispatch();

  const [loadingProducts, products] = useProducts();
  const [loadingProductGroups, productGroups] = useProductGroups();

  useProductSizes();
  useMenus();
  useProductAmmendments();

  useEffect(() => {
    if (!loadingProducts && !loadingProductGroups && user) {
      dispatch({ type: GET_PRODUCTS, products: products });
      dispatch({ type: GET_PRODUCT_GROUPS, productGroups: productGroups });
    }
  }, [user, loadingProducts, loadingProductGroups]);

  useEffect(() => {
    dispatch({ type: WINDOW_GET_SIZE, width: window.innerWidth, height: window.innerHeight });
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userRecord = await currentUser.getIdTokenResult();
        const { tenantId, pageTitle } = getConfig();
        window.document.title = "Revenue - " + pageTitle;

        const response = await Promise.all([
          firebase.database().ref(`${tenantId}/users`).child(userRecord.claims.user_id).once("value"),
          firebase.database().ref(`${tenantId}/restaurants`).orderByChild("enabled").equalTo(true).once("value"),
          firebase.database().ref(`${tenantId}/areaGroups`).orderByChild("enabled").equalTo(true).once("value"),
        ]);

        const restaurantId = firebase.database().ref(`${tenantId}/restaurants`).push().key;
        const rootUser = Object.assign({ defaultRestaurant: restaurantId }, response[0].val() || {});
        const restaurants = response[1].val();
        dispatch({ type: GET_RESTAURANTS, restaurants });
        dispatch({ type: GET_ROOT_USER, loading: false, user: Object.assign({}, rootUser, { uid: currentUser.uid, customClaims: { roles: userRecord.claims.roles } }) });
        dispatch({ type: GET_GROUP_AREA_TYPES, types: response[2].val() });
        dispatch(getSystemProducts());
        dispatch(getSubscriptionDetails());
      } else {
        clearUsers();
        unsubscribe();
        if (window.location.pathname !== "/login") window.location.href = `/login?returnUrl=${window.location.pathname}`;
      }
    });
  }, []);

  if (!user) {
    return (
      <div style={{ left: "50%", position: "absolute", marginLeft: "-50px", top: "50%", marginTop: "-15px" }}>
        <div className="ball-pulse-rise">
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="wrapper">
        <LoadingBar style={{ zIndex: 200, backgroundColor: "#fff", height: 2 }} />
        <Header />
        <Sidebar />
        <Offsidebar />
        <section className="section-container">
          <Outlet />
        </section>
        <Footer />
      </div>
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} style={{ zIndex: 9999 }} />
    </>
  );
};

export default Base;
