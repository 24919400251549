import { BUTTON_ERROR } from "components/Buttons/ActionTypes";
import { catchExceptionCallback, getConfig } from "core/utilities";
import "firebase/compat/database";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import * as types from "./ActionTypes";

export function getClientWidgetLink(values, buttonId) {
  return (dispatch) => {
    try {
      dispatch(showLoading());
      let restaurantResponse = "";
      values.widgetType === "group" ? (restaurantResponse = "") : (restaurantResponse = values.restaurantId);

      const data = getConfig();
      const clientWidgetLink = values.widgetType === "group" ? `${data.widgetPath}/giftCertificates?clientId=${data.client}` : `${data.widgetPath}/giftCertificates?clientId=${data.client}&restaurantId=${values.restaurantId}`;
      const clientCodeBlock = `<script type="text/javascript">
          var clientId = '${data.client}';
          var restaurantId = '${restaurantResponse}'; //set restaurantId for single restaurant only, otherwise leave blank
          var themeFile = '';
</script>
<script src="${data.widgetPath}/assets/js/client-widget.js"></script>`;
      const clientButtonLink = `${data.widgetPath}/?clientId=${data.client}`;
      const clientjQueryCode = `$(document).on('click', '#widgetBtn', function(e){
            e.preventDefault();
            loadGCWidget('${data.client}', '${restaurantResponse}', true)
    });`;
      const clientJavaScriptCode = `var openWidgetBtn = document.getElementById('#widgetBtn');
            openWidgetBtn.onclick = function() {
            loadGCWidget('${data.client}', '${restaurantResponse}', true);
    };`;
      const clientHtmlCode = `<a href="#" onclick="loadGCWidget('${data.client}', '${restaurantResponse}', true); return true;">Gift Certificate</a>`;

      dispatch({ type: types.GET_CLIENT_BUILDER_WIDGET_LINK, clientWidgetLink, clientCodeBlock, clientButtonLink, clientjQueryCode, clientJavaScriptCode, clientHtmlCode });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch({ type: BUTTON_ERROR, id: buttonId });
    }
  };
}
