import { chain, findLastKey, forEach, groupBy, set } from "lodash";
import { Menu } from "resbutler-utils/types/Menu";

export interface IReportByMenuPayload {
  calendar: string;
  restaurantId: string;
  clientId: string;
  firstPeriod?: any;
  secondPeriod?: any;
  mode?: string;
  firstDateFrom?: string;
  firstDateTo?: string;
  secondDateFrom?: string;
  secondDateTo?: string;
}

export const getMenuGroupHierarchy = (menuIds: string[] = [], menus: Menu[]) => {
  const menuByMenuHeadings = menuIds.reduce((results, menuId) => {
    const menu = menus.find((item) => item.id === menuId);

    if (menu) {
      const menuCategoryId = menu.menuCategoryId;

      if (results?.[menuCategoryId]) {
        results = {
          ...results,
          [menuCategoryId]: [...results[menuCategoryId], menuId],
        };
      } else {
        results = {
          ...results,
          [menuCategoryId]: [menuId],
        };
      }
    }

    return results;
  }, {});

  return menuByMenuHeadings;
};

const normalizeResults = (results, filterTreeData, filterValue) => {
  let result = {};

  const groupedByMenuId = groupBy(results, "menuId") as any;

  forEach(groupedByMenuId, (item2, menuId) => {
    let lines = [] as any;
    let groupHeadings = {};
    const mealIds = [];
    const areaIds = [];
    const tableClassIds = [];

    forEach(item2, (i) => {
      let shouldShowByMealPeriod = filterValue.includes(filterTreeData[0].value);
      let shouldShowByArea = filterValue.includes(filterTreeData[1].value);
      let shouldShowByTableClass = filterValue.includes(filterTreeData[2].value);

      // Meal period
      if (!shouldShowByMealPeriod) {
        shouldShowByMealPeriod = !!i.mealId && filterValue.includes(i.mealId);
      }

      // Area
      if (!shouldShowByArea) {
        shouldShowByArea = !!i.areaId && filterValue.includes(i.areaId);
      }

      // Table class
      if (!shouldShowByTableClass) {
        shouldShowByTableClass = !!i.tableClassId && filterValue.includes(i.tableClassId);
      }

      // Top level filtration
      if (shouldShowByMealPeriod && shouldShowByArea && shouldShowByTableClass) {
        lines.push(...i.lines);

        const productIds = Object.keys(groupBy(i.lines, "productId"));

        if (groupHeadings?.[i.groupHeadingName]) {
          groupHeadings = {
            ...groupHeadings,
            [i.groupHeadingName]: [...groupHeadings[i.groupHeadingName], ...productIds],
          };
        } else {
          groupHeadings = {
            ...groupHeadings,
            [i.groupHeadingName]: productIds,
          };
        }
      }
    });

    lines = chain(lines).filter((l) => 
      ((l.lineItemTypeId === '2' || l.lineItemTypeId === '3') &&
      (l.preparationType === "Food" || l.preparationType === "Beverage" || l.preparationType === "Beveragess")) ||
      // fixed price product
      (l.fixProductPrice)
    ).groupBy("productId").value();

    result = {
      ...result,
      [menuId]: {
        mealIds,
        areaIds,
        tableClassIds,
        groupHeadings,
        lines,
      },
    };
  });

  return result;
};

const groupByMenuHeadings = (menu: Menu, productIds: string[], reports) => {
  const lines = reports.lines;

  return productIds.reduce(
    (results, productId) => {
      const line = lines[productId][0];

      const findProductAndGroupByMenuHeading = (type = "food", productId) => {
        const headingId = findLastKey(reports.groupHeadings, (items) => items.includes(productId));

        if (results?.[type]?.[headingId]) {
          set(results, `${type}.${headingId}`, [...results[type][headingId], productId])
        } else {
          set(results, `${type}.${headingId}`, [productId])
        }
      };

      if (line?.fixProductPrice) {
        results.fixedProducts.push(productId);
      } else if (line?.preparationType === "Food") {
        findProductAndGroupByMenuHeading("food", productId);
      } else if (line?.preparationType === "Beverage" || line?.preparationType === "Beveragess") {
        findProductAndGroupByMenuHeading("drinks", productId);
      }

      return results;
    },
    {
      fixedProducts: [],
      food: {},
      drinks: {},
    }
  );
};

export { groupByMenuHeadings, normalizeResults };
