import { chain, groupBy, map } from "lodash";
import { useMemo } from "react";

import { Areas, TableClasses } from "resbutler-utils/types/Box";
import { Meals } from "resbutler-utils/types/Meals";
import { ProductGroups } from "resbutler-utils/types/product";
import { ProductCategoryId } from "resbutler-utils/types/ProductCategoryId";

const useFilterTreeData = (meals: Meals, areas: Areas, tableClasses: TableClasses) => {
  const treeData = useMemo(() => {
    return [
      {
        title: "All Meal Periods",
        value: "all-meal-periods",
        children: map(meals, (meal, key) => ({
          title: meal.name,
          value: key,
        })),
      },
      {
        title: "All Areas",
        value: "all-areas",
        children: map(areas, (area, key) => ({
          title: area.name,
          value: key,
        })),
      },
      {
        title: "All Table Classes",
        value: "all-table-classes",
        children: map(tableClasses, (tableClass, key) => ({
          title: tableClass.name,
          value: key,
        })),
      },
    ];
  }, [meals, areas, tableClasses]);

  return treeData;
};

export const useProductGroupTreeData = (data: ProductGroups) => {
  const filteredData = chain(data)
    .map((obj, id) => ({ ...obj, id })) // add id property
    .orderBy('order')
    .filter((obj) => obj.productCategoryId === ProductCategoryId.SaleProducts && obj.enabled)
    .value();

  // group by parentGroup
  const groupedData = groupBy(filteredData, "parentGroup");

  // create hierarchy
  function createHierarchy(parentId) {
    const children = map(groupedData[parentId], (obj) => ({
      value: obj.id,
      title: obj.name,
      children: createHierarchy(obj.id), // recursive
    }));

    return children.length ? children : [];
  }

  // root level
  const result = map(groupedData["-1"], (obj) => ({
    value: obj.id,
    title: obj.name,
    children: createHierarchy(obj.id),
  }));

  return result;
};

export default useFilterTreeData;
