import { filter, flatMap, forEach, groupBy, reduce, values } from "lodash";

export interface IReportByProductTreeAreaPayload {
  calendar: string;
  restaurantId: string;
  clientId: string;
  dateFrom?: string;
  dateTo?: string;
  mode?: string;
  period?: any;
}

const productFilters = {
  "all-products": {
    value: "all-products",
    label: "All Products",
  },
  "product-groups": {
    value: "product-groups",
    label: "Product Groups",
  },
};

const normalizeResults = (results, filterTreeData, filterValue) => {
  let result = {};

  const data = groupBy(results, "groupId");

  forEach(data, (item, key) => {
    let lines = [] as any;

    forEach(item, (i) => {
      let shouldShowByMealPeriod = filterValue.includes(filterTreeData[0].value);
      let shouldShowByTableClass = filterValue.includes(filterTreeData[2].value);

      // Meal period
      if (!shouldShowByMealPeriod) {
        shouldShowByMealPeriod = !!i.mealId && filterValue.includes(i.mealId);
      }

      // Table class
      if (!shouldShowByTableClass) {
        shouldShowByTableClass = !!i.tableClassId && filterValue.includes(i.tableClassId);
      }

      // Top level filtration
      if (shouldShowByMealPeriod && shouldShowByTableClass) {
        lines.push(...i.lines);
      }
    });

    lines = groupBy(lines, "productId");

    result = {
      ...result,
      [key]: {
        productGroupType: item[0].productGroupType,
        lines,
      },
    };
  });

  return result;
};

const filterResults = (results, filterTreeData, filterValue) => {
  return reduce(
    results,
    (result, item, key) => {
      let filteredLines = item.lines

      // filter out by area
      if (!filterValue.includes(filterTreeData[1].value)) {
        const flattenedLinesByProductId = flatMap(values(item.lines));

        filteredLines = groupBy(
          filter(flattenedLinesByProductId, (line) => line.areaId && filterValue.includes(line.areaId)),
          "productId"
        );
      }

      return {
        ...result,
        [key]: {
          ...item,
          lines: filteredLines,
        },
      };
    },
    {}
  );
};

export { filterResults, normalizeResults, productFilters };
