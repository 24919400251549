export const GET_GROUP_AREAS = "GET_GROUP_AREAS";
export const GET_MAESTRO = "GET_MAESTRO";
export const GET_FUNCTION_MAESTRO = "GET_FUNCTION_MAESTRO";
export const GET_MAESTRO_BY_RESTAURANT_ID = "GET_MAESTRO_BY_RESTAURANT_ID";
export const GET_BOOKING_FORM = "GET_BOOKING_FORM";
export const GET_BOOKING_FORM_MEALS = "GET_BOOKING_FORM_MEALS";
export const MAESTRO_GET_MEALS = "MAESTRO_GET_MEALS";
export const MAESTRO_GET_MEALS_COMPLETE = "MAESTRO_GET_MEALS_COMPLETE";
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const GET_MAESTRO_FURNITURE = "GET_MAESTRO_FURNITURE";
export const GET_MAESTRO_CUSTOMERS = "GET_MAESTRO_CUSTOMERS";
export const GET_MAESTRO_SHOW_TRACKER = "GET_MAESTRO_SHOW_TRACKER";
export const GET_MAESTRO_TRACKER = "GET_MAESTRO_TRACKER";
export const GET_MAESTRO_BUTLER_SERVICE_GROUP = "GET_MAESTRO_BUTLER_SERVICE_GROUP";
export const MAESTRO_TRANSACTIONS = "MAESTRO_TRANSACTIONS";
export const GET_MENU_PRODUCTS = "GET_MENU_PRODUCTS";
export const TAKE_AWAY_SETTINGS = "TAKE_AWAY_SETTINGS";
export const SHOW_EXPORT = "SHOW_EXPORT";
export const SHOW_PRINT = "SHOW_PRINT";
export const HIDE_PRINT = "HIDE_PRINT";
export const SHOW_RESET_SECTION = "SHOW_RESET_SECTION";
export const FLOOR_PLAN_CONFIG_SECTION_ID = "FLOOR_PLAN_CONFIG_SECTION_ID";
export const GET_ALL_OPENING_TIMES = "GET_ALL_OPENING_TIMES";
export const MAESTRO_SORT_FILTER = "MAESTRO_SORT_FILTER";

export const MEAL_SELECTED = "MEAL_SELECTED";

export const SHOW_RUN_SHEET = "SHOW_RUN_SHEET";
export const SHOW_RUN_SHEET_IMPLICIT = "SHOW_RUN_SHEET_IMPLICIT"; // show a hidden runSheet
export const SHOW_FUNCTION_RUN_SHEET = "SHOW_FUNCTION_RUN_SHEET";
export const CLOSE_FUNCTION_RUN_SHEET = "CLOSE_FUNCTION_RUN_SHEET";
export const SHOW_RUN_SHEET_TRANSACTION = "SHOW_RUN_SHEET_TRANSACTION";
export const SHOW_RUN_SHEET_CUSTOMER = "SHOW_RUN_SHEET_CUSTOMER";
export const SHOW_MODAL_CUSTOMER_FORM = "SHOW_MODAL_CUSTOMER_FORM";

export const CLOSE_RUN_SHEET_TRANSACTION = "CLOSE_RUN_SHEET_TRANSACTION";
export const CLOSE_RUN_SHEET_CUSTOMER = "CLOSE_RUN_SHEET_CUSTOMER";
export const CLOSE_RUN_SHEET = "CLOSE_RUN_SHEET";
export const CLOSE_EDIT_TABLE = "CLOSE_EDIT_TABLE";
export const CLOSE_EDIT_JOIN = "CLOSE_EDIT_JOIN";

export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";
export const GET_CUSTOMER_HISTORY_MEALS = "GET_CUSTOMER_HISTORY_MEALS";

export const MAESTRO_CHANGE = "MAESTRO_CHANGE";
export const MAESTRO_FILTER = "MAESTRO_FILTER";
export const MAESTRO_TREE_FILTER = "MAESTRO_TREE_FILTER";
export const MAESTRO_GET_SIZE = "MAESTRO_GET_SIZE";
export const GET_MAESTRO_TABLE_CLASSES = "GET_MAESTRO_TABLE_CLASSES";
export const GET_EDITOR_OPTIONS = "GET_EDITOR_OPTIONS";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const BOOKING_UPDATED = "BOOKING_UPDATED";
export const RUN_SHEET_TAB = "RUN_SHEET_TAB";

export const GRID_EXPANDED = "GRID_EXPANDED";

export const SET_TIMELINE = "SET_TIMELINE";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_MODAL_FUNCTION_BOOKING = "SHOW_MODAL_FUNCTION_BOOKING";
export const GET_FUNCTIONS_MAESTRO = "GET_FUNCTIONS_MAESTRO";
export const GET_TAKEAWAYS_MAESTRO = "GET_TAKEAWAYS_MAESTRO";
export const GET_UPSALE_PRODUCTS = "GET_UPSALE_PRODUCTS";
export const GET_ORDERS_MAESTRO = "GET_ORDERS_MAESTRO";

export const GANTT_TOGGLE = "GANTT_TOGGLE";
export const ENABLE_LIGHT_THEME = "ENABLE_LIGHT_THEME";

export const MAESTRO_SLIDER_CHANGE = "MAESTRO_SLIDER_CHANGE";
export const MAESTRO_TABLE_ROW = "MAESTRO_TABLE_ROW";

export const CHANGE_TREE = "CHANGE_TREE";

export const SHOW_SERVICE_SETUP = "SHOW_SERVICE_SETUP";

export const SHOW_QRCODE_PAGE = "SHOW_QRCODE_PAGE";

export const SHOW_TABLE_BLOCKS = "SHOW_TABLE_BLOCKS";
export const SHOW_UTILITIES = "SHOW_UTILITIES";
export const SHOW_MESSAGES = "SHOW_MESSAGES";

export const GET_BLOCKS = "GET_BLOCKS";
export const CHANGE_MEAL_PERIOD = "CHANGE_MEAL_PERIOD";
export const DATE_CHANGE = "DATE_CHANGE";
export const SHOW_TYPE = "SHOW_TYPE";
export const SHOW_PAX_ANALYSIS = "SHOW_PAX_ANALYSIS";
export const SHOW_LIMITS = "SHOW_LIMITS";
export const ACTIVE_BOOKING_MESSAGE = "ACTIVE_BOOKING_MESSAGE";
export const SHOW_NEW_MESSAGE = "SHOW_NEW_MESSAGE";
export const CHANGE_MESSAGE_CATEGORY = "CHANGE_MESSAGE_CATEGORY";
export const MAESTRO_FUNCTION_STATUS_FILTER = "MAESTRO_FUNCTION_STATUS_FILTER";
export const MAESTRO_TABLE_ROW_FUNCTION = "MAESTRO_TABLE_ROW_FUNCTION";
export const MAESTRO_MODE = "MAESTRO_MODE";
export const CLEAR_TABLE_ROW_FUNCTION = "CLEAR_TABLE_ROW_FUNCTION";
export const CLEAR_TABLE_ROW_STANDARD = "CLEAR_TABLE_ROW_STANDARD";
export const STANDBY_TOGGLE = "STANDBY_TOGGLE";

export const SET_MAESTRO_VIEW = "SET_MAESTRO_VIEW";
