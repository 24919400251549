import { combineReducers } from 'redux';

import { LOGIN_ERROR } from './ActionTypes';

const initialState = ({
    error: '',
});

function error(state = initialState.error, action) {
    switch (action.type) {
        case LOGIN_ERROR:
            return action.error;
        default:
            return state;
    }
}

export default combineReducers({
    error,
});
