import PrivateRoute from "components/PrivateRoute";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

/* loader component for Suspense*/
import Menu from "Menu";

import PageLoader from "./components/Common/Pageloader";
import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";

/* Used to render a lazy component with react-router */

const Insights = lazy(() => import("./Insights"));
const CustomerForm = lazy(() => import("crm/customers/CustomerForm"));
const CustomerList = lazy(() => import("crm/customers/CustomerList"));
const DefaultMenu = lazy(() => import("menus-and-pricing/MenuList"));
const Login = lazy(() => import("./pages/Login"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Challenge = lazy(() => import("./pages/Challenge"));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  "/login",
  "/challenge",
  /* See full project for reference */
];

function MenuPrivateRoutes() {
  let PrivateRouteList = [];
  const RenderPrivateRoute = ({ routes }) => {
    return routes.forEach((route) => {
      return PrivateRouteList.push(<Route key={route.path} path={route.path} element={React.createElement(route.component, route.props)} />);
    });
  };

  Menu.forEach((item) => {
    //Second menu link level
    if (item.submenu) {
      item.submenu.forEach((submenu) => {
        if (submenu.routes) RenderPrivateRoute(submenu);
        //Third menu link level
        if (submenu.submenuchild)
          submenu.submenuchild.forEach((child) => {
            RenderPrivateRoute(child);
          });
      });
    } else if (item.routes) {
      RenderPrivateRoute(item);
    }
  });
  return PrivateRouteList;
}

const MyRoutes = () => {
  // // Animations supported
  // //      'rag-fadeIn'
  // //      'rag-fadeInRight'
  // //      'rag-fadeInLeft'

  if (listofPages.indexOf(window.location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <Suspense fallback={null}>
        <Routes>
          <Route element={<BasePage />}>
            <Route path="/login" element={<Login />} />
            <Route path="/challenge" element={<Challenge />} />
          </Route>
        </Routes>
      </Suspense>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route element={<Base />}>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<DefaultMenu />} />
              <Route path={"/insights"} element={<Insights />} />
              <Route path={"/crm/customers/:id"} element={<CustomerForm />} />
              <Route path={"/crm/customers"} element={<CustomerList />} />
              {MenuPrivateRoutes()}
              <Route element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    );
  }
};

export default MyRoutes;
