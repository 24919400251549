import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import algoliasearch from "algoliasearch/lite";
import { useAppSelector } from "components/Hooks/hooks";
import useFunctionAdditionalItems from "components/Hooks/useFunctionAdditionalItems";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { catchExceptionCallback, getConfig } from "core/utilities";
import "firebase/compat/firestore";
import _, { filter, find, keys, map, pickBy } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, Col, Collapse, Row, Table } from "reactstrap";
import { ProductSearchModal } from "resbutler-ui/components/ProductSearch/ProductSearchModal";
import { Product, ProductSearchResult, ProductSizes, ProductTypeId } from "resbutler-utils/types/product";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA);

export const changeRestaurantHandler = (dispatch, restaurantId) => {
  dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId });
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const RenderProductInSize = ({ restaurantId, product, productSizeId, sizes, onDelete }: { onDelete: any; restaurantId: string; product: Product; productSizeId: string; productSizeIds?: any; sizes: ProductSizes; deleteProduct?: any }) => {
  return product ? (
    <tr>
      <td>{sizes[productSizeId]?.name}</td>
      <td>{product?.restaurants && product.restaurants[restaurantId] ? formatter.format(product.restaurants[restaurantId].price[productSizeId]) : null}</td>
      <td>
        <Button color="danger" onClick={onDelete}>
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
      </td>
    </tr>
  ) : null;
};

const RenderProduct = ({ productId, productSizeIds, sizes, restaurantId, deleteProduct, rankUp, rankDown, isLoading, onDeleteProductSize }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const products = useAppSelector((state) => state.root.products);
  const product = find(products, (p) => p.id === productId);

  return product ? (
    <>
      <tr>
        <td>
          <Button type="button" onClick={toggle}>
            <i className={!isOpen ? "fa fa-chevron-right" : "fa fa-chevron-down"} aria-hidden="true" />
          </Button>
          <b> {product.name}</b>
        </td>

        <td align="right">
          <span style={{ marginRight: "50px" }}>
            <button className="btn btn-secondary btn-sm border-0" type="button" onClick={rankUp}>
              <i className="fa fa-arrow-up"></i>
            </button>

            <button className="btn btn-secondary btn-sm border-0" type="button" onClick={rankDown}>
              <i className="fa fa-arrow-down"></i>
            </button>
          </span>
        </td>
        <td align="right">
          <span style={{ marginRight: "50px" }}></span>
          <Button type="button" color="danger" onClick={deleteProduct}>
            <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        </td>
      </tr>
      <tr style={{ display: isOpen ? "block" : "none" }}>
        <td>
          <Collapse isOpen={isOpen}>
            <Row>
              <Col lg={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product Size</th>
                      <th>Standard Pricing</th>

                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {productSizeIds &&
                      !isLoading &&
                      Object.keys(productSizeIds).map((productSizeId, i) => (
                        <RenderProductInSize
                          onDelete={() => onDeleteProductSize(productId, productSizeId)}
                          key={`${productId}-${productSizeId}-${i}`}
                          restaurantId={restaurantId}
                          productSizeId={productSizeId}
                          productSizeIds={productSizeIds}
                          sizes={sizes}
                          product={product}
                          deleteProduct={deleteProduct}
                        />
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Collapse>
        </td>
      </tr>
    </>
  ) : null;
};

const AdditionalItems = () => {
  const dispatch = useDispatch();
  const { restaurantId, productSizes } = useAppSelector((state) => ({
    restaurantId: state.root.restaurantId,
    productSizes: state.root.productSizes,
  }));
  const {
    client: clientId,
    config: { publicStorageBucket },
  } = getConfig();

  const [open, setOpen] = useState(false);
  const { tenantId } = getConfig();
  const [, additionalItems, saveFunctionAdditionalItems] = useFunctionAdditionalItems(restaurantId);
  const [isLoading, setIsLoading] = useState(false);

  const saveProduct = async (results: ProductSearchResult[]) => {
    await saveFunctionAdditionalItems(_.uniqBy(results, "productId"));
  };

  const deleteProduct = async (productId: string) => {
    await saveFunctionAdditionalItems(_.filter(additionalItems, (b) => b.productId !== productId));
  };

  const deleteProductSize = async (productId: string, productSizeId: string) => {
    try {
      setIsLoading(true);
      const productOneIndex = _.findIndex(additionalItems, (b) => b.productId === productId);
      if (additionalItems[productOneIndex].sizes[productSizeId]) {
        // delete whole product if only one size exist
        if (keys(additionalItems[productOneIndex].sizes).length === 1) {
          await saveFunctionAdditionalItems(filter(additionalItems, (b) => b.productId !== productId));
        } else {
          await saveFunctionAdditionalItems([
            ...additionalItems.slice(0, productOneIndex),
            {
              ...additionalItems[productOneIndex],
              sizes: pickBy(additionalItems[productOneIndex].sizes, (s, k) => k !== productSizeId),
            },
            ...additionalItems.slice(productOneIndex + 1),
          ]);
        }
      }
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateOrder = (action: string, productId: string) => {
    const sortedProducts = _.chain(additionalItems).sortBy("order").value();
    const productIndex = sortedProducts.findIndex((product) => product.productId === productId);
    const requiredIndex = action === "up" ? productIndex - 1 : productIndex + 1;
    if (requiredIndex >= 0 && requiredIndex < sortedProducts.length) {
      const product = sortedProducts[productIndex];
      const swapProduct = sortedProducts[requiredIndex];
      sortedProducts[requiredIndex] = product;
      sortedProducts[productIndex] = swapProduct;
      saveFunctionAdditionalItems(sortedProducts);
    }
  };

  const renderCategories = () => {
    return (
      <Card className="card-default">
        <CardBody>
          <Button
            className="mb-2"
            onClick={() => {
              setOpen(true);
            }}
            color="primary"
          >
            Add Product
          </Button>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Product Name</th> <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {map(additionalItems, (d, i) => {
                return (
                  <RenderProduct
                    rankDown={() => updateOrder("down", d.productId)}
                    rankUp={() => updateOrder("up", d.productId)}
                    key={`${d.productId}-${i}`}
                    deleteProduct={() => deleteProduct(d.productId)}
                    restaurantId={restaurantId}
                    productId={d.productId}
                    productSizeIds={d.sizes}
                    sizes={productSizes}
                    onDeleteProductSize={(productId: string, productSizeId: string) => deleteProductSize(productId, productSizeId)}
                    isLoading={isLoading}
                  />
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  return (
    <ContentWrapper>
      <ContentHeading
        parentText="Functions"
        headerText="Additional Items"
        subHeaderText="Additional Items"
        subHeaderHref="/functions/functions-additional-items/additional-items"
        showRestaurants
        onRestaurantChange={(e) => {
          changeRestaurantHandler(dispatch, e);
        }}
      />

      {renderCategories()}
      {open && (
        <ProductSearchModal
          clientId={clientId}
          publicStorageBucket={publicStorageBucket}
          initialValues={additionalItems}
          query={`typeId:${ProductTypeId.fixedPrice}`}
          open={open}
          setOpen={setOpen}
          onCloseData={(selectedProducts) => {
            const filteredProducts = selectedProducts.filter((product) => {
              const trueSizes = _.pickBy(product.sizes, function (v, k) {
                return v === true;
              });
              return keys(trueSizes).length > 0;
            });
            saveProduct(filteredProducts);
          }}
          productSizes={productSizes}
          tenantId={tenantId}
          searchClient={searchClient}
          restaurantId={restaurantId}
        />
      )}
    </ContentWrapper>
  );
};

export default AdditionalItems;
