export const GET_DATA = 'GET_DATA';

export const GET_TABLE_INFO = 'GET_TABLE_INFO';
export const GET_TABLE_LOADING = 'GET_TABLE_LOADING';


export const GET_PIE_CHART_INFO = 'GET_PIE_CHART_INFO';
export const GET_PIE_CHART_LOADING = 'GET_PIE_CHART_LOADING';



