import { useAppSelector } from "components/Hooks/hooks";
import { renderField, requiredField } from "core/react-utils";
import "firebase/compat/database";
import { keys, pickBy } from "lodash";
import TreeSelect from "rc-tree-select";
import { useEffect, useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { Col, Row } from "reactstrap";
import { getRestaurantsSelector } from "selectors/selectors";

const getStandardMenuHeadingsTree = (serviceStyles) => {
  const standardMenuHeadingTree = [];
  keys(pickBy(serviceStyles, (item) => item.enabled === true && item.isStandard)).map((item) => {
    standardMenuHeadingTree.push({
      label: serviceStyles[item].name,
      title: serviceStyles[item].name,
      value: item,
      key: item,
    });
  });
  return standardMenuHeadingTree;
};

const getMenuHeadingsTree = (serviceStyles) => {
  const menuHeadingTree = [];
  keys(pickBy(serviceStyles, (item) => item.enabled === true)).map((item) => {
    menuHeadingTree.push({
      label: serviceStyles[item].name,
      title: serviceStyles[item].name,
      value: item,
      key: item,
    });
  });
  return menuHeadingTree;
};

const CreateEditForm = ({ menuPackage, packageName, serviceStyles, functionPackageType }: { menuPackage: any; packageName: any; serviceStyles: any; functionPackageType?: "functionAdditionals" | "functionPackages" }) => {

  const [menuSelected, setMenuSelected] = useState([]);
  const restaurants = useAppSelector((state) => getRestaurantsSelector(state));
  const values = useFormState().values;
  const form = useForm();
  const standardMenuHeadingTree = getStandardMenuHeadingsTree(serviceStyles);
  const menuHeadingTree = getMenuHeadingsTree(serviceStyles);

  useEffect(() => {
    if (menuPackage) setMenuSelected(menuPackage.serviceStyles);
  }, [menuPackage]);

  return (
    <Row>
      <Col>
        <Field id="name" name="name" type="text" component={requiredField as any} label="Name" className="form-group" />
        <Field id="description" name="description" type="text" component={renderField as any} label="Description" className="form-group" />

        {functionPackageType != "functionAdditionals" && (
          <div>
            <p>{packageName == "beverage" ? "Beverage" : "Food"} Package Type</p>

            <div>
              <Field name="type" type="radio">
                {({ input: { onChange } }) => {
                  return (
                    <label className="radio c-radio mr-5">
                      <input type="radio" value="adult" checked={values.type === "adult"} onChange={onChange} />
                      <span className="fa fa-circle" />
                      Adult
                    </label>
                  );
                }}
              </Field>
            </div>

            <div className="mt-2 mb-2">
              <Field name="type" type="radio">
                {({ input: { onChange }, meta }) => {
                  return (
                    <>
                      <label className="radio c-radio mr-5">
                        <input type="radio" value="child" checked={values.type === "child"} onChange={onChange} />
                        <span className="fa fa-circle" />
                        Child
                      </label>
                      {meta.touched && meta.error && (
                        <div>
                          <span className="validate-form">{meta.error}</span>
                        </div>
                      )}
                    </>
                  );
                }}
              </Field>
            </div>
          </div>
        )}

        <Row>
          <Col lg={6}>
            <Field id="minpax" name="minPax" type="number" min="0" step="1" parse={(value) => Number(value)} component={requiredField as any} label="Min Pax" className="form-group" />
          </Col>
          <Col lg={6}>
            <Field id="maxpax" name="maxPax" type="number" min="0" step="1" parse={(value) => Number(value)} component={requiredField as any} label="Max Pax" className="form-group" />
          </Col>
        </Row>

        {functionPackageType == "functionAdditionals" ? (
          <Row className="form-group">
            <Col>
              <Field name="priceType">
                {({ input: { onChange, value }, meta }) => (
                  <div>
                    <label>Select Price Type</label>
                    <select className="form-control" onChange={onChange} value={value}>
                      <option value="">Please Select</option>
                      {[
                        { label: "Fixed Product Price", value: "fixedProductPrice" },
                        { label: "Individual Product Price", value: "individualProductPrice" },
                      ].map((item, index) => (
                        <option key={`price-type-${index}`} value={item.value}>
                          {" "}
                          {item.label}
                        </option>
                      ))}
                    </select>
                    {meta.touched && meta.error && <span className="validate-form">{meta.error}</span>}
                  </div>
                )}
              </Field>
            </Col>
          </Row>
        ) : (
          <div className="checkbox c-checkbox">
            <label className="mb-2" htmlFor="standardRequiresPrepayment1">
              <Field<boolean> id="standardRequiresPrepayment1" name="standardRequiresPrepayment" component="input" type="checkbox" />
              <span className="fa fa-check" />
              Requires prepayment on Standard
            </label>
          </div>
        )}

        <Row className="form-group">
          <Col>
            <label> Standard Service Styles</label>
            <TreeSelect
              className="form-control tree-select"
              style={{ width: "100%" }}
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ overflow: "auto" }}
              dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
              treeLine
              value={values.standardServiceStyles}
              placeholder={"Select Standard Service Styles"}
              showIcon={false}
              maxTagTextLength={50}
              autoClearSearchValue
              treeData={standardMenuHeadingTree}
              treeCheckable
              onChange={(ids) => {
                form.change(`standardServiceStyles`, ids);
              }}
              maxTagPlaceholder={(valueList) => {
                return `+${valueList.length}`;
              }}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col>
            <label>Function Service Styles</label>
            <TreeSelect
              className="form-control tree-select"
              style={{ width: "100%" }}
              choiceTransitionName="rc-tree-select-selection__choice-zoom"
              dropdownStyle={{ overflow: "auto" }}
              dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
              treeLine
              value={menuSelected}
              placeholder={"Select Function Service Styles"}
              showIcon={false}
              maxTagTextLength={50}
              autoClearSearchValue
              treeData={menuHeadingTree}
              treeCheckable
              onChange={(ids) => {
                form.change(`serviceStyles`, ids);
                setMenuSelected([...ids]);
              }}
              maxTagPlaceholder={(valueList) => {
                return `+${valueList.length}`;
              }}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col>
            <Field name="restaurantId">
              {({ input, meta }) => (
                <>
                  <label>Restaurant</label>
                  <select {...input} disabled={input.value} className={"form-control"}>
                    {Object.keys(restaurants).map((key) => (
                      <option key={key} value={key}>
                        {restaurants[key].name}
                      </option>
                    ))}
                  </select>
                  {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                </>
              )}
            </Field>
          </Col>
        </Row>
        {(packageName === "food" || packageName === "foodbeverage") ? <Row className="form-group mb-3">
          <Col md="6">
            <Field name={`isCanape`} type="checkbox">
              {({ input, meta }) => (
                <div className="checkbox c-checkbox">
                  <label className="m-0">
                    <input type="checkbox" className="form-check-input" {...input} />
                    <span className="fa fa-check"></span>Canapes Included
                  </label>
                  {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Col>
        </Row> : <></>}

        <Row className="form-group mb-3">
          <Col md="6">
            <Field name={`enabled`} type="checkbox">
              {({ input, meta }) => (
                <div className="checkbox c-checkbox">
                  <label className="m-0">
                    <input type="checkbox" className="form-check-input" {...input} />
                    <span className="fa fa-check"></span>Enabled
                  </label>
                  {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default CreateEditForm;
