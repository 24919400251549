import { ButlerType } from "./Enums";
import { FunctionProductCategory } from "./FunctionProductCategory";
import { OrderAdditions, OrderModifiers, OrderPreparations } from "./Order";
import { GroupHeadingHierachy, PriceOption, ProductGroupPriceAdjustmentType } from "./product";
import { ProductCategoryId } from "./ProductCategoryId";
import { ProductGroupHierachy } from "./ProductGroupHierachy";

export const TransactionTypes = {
  ThirdParty: 1,
  GiftCertificate: 2,
  ButlerItem: 3,
  PaymentRequirement: 4,
  Cancellation: 5,
  MenuOrder: 6,
};

export enum CreditCardId {
  Visa = 1,
  Mastercard = 2,
  Amex = 3,
  Discover = 4,
  JCB = 5,
  DinersClub = 6,
  UnionPay = 7,
  AliPay = 8,
}

export const CreditCardTypes = [
  {
    id: 1,
    name: "Visa",
  },
  {
    id: 2,
    name: "Mastercard",
  },
  {
    id: 3,
    name: "Amex",
  },
  {
    id: 4,
    name: "Discover",
  },
  {
    id: 5,
    name: "JCB",
  },
  {
    id: 6,
    name: "Diners Club",
  },
  {
    id: 7,
    name: "Union Pay",
  },
  {
    id: 8,
    name: "Ali Pay",
  },
];

/**
 * @deprecated The method should not be used, instead use productCategoryId
 */
export enum LineItemTypeId {
  // id1: productId, id2: productSizeId, id3: menuId, id4: courseId
  Butler = 1,
  // id1: menuId, id2: courseId, id3: productId
  Menu = 2,
  // id1: productId,
  // id2: sizeId,
  // id3: orderItemId,
  // id4: orderId,
  // id6: menuHeadingId ??
  // id7: menuId ??,
  // id8: foodPackageId ??,
  // id9: beveragePackageId ??,
  // id10: foodAndBeveragePackageId ??
  // id11: beverageMenuPackageId ??
  Product = 3,
  // id1: productId, id2: sizeId, id3: packageId, id4: beverageMenuId
  Function = 6,
  // id1: menuId, id2: courseId, id3: productId
  Deposit = 7,
  Cashout = 8,
  // id1: productId, id2: packageId
  Package = 9,
  // id1: giftCertificateId
  // id2: accountId
  GiftCertificate = 10,
}

export interface OrderSetup {
  intentId: string;
  used: boolean;
}

export interface OrderSetups {
  [orderSetupId: string]: OrderSetup;
}

export interface GiftCertificateSetups {
  [id: string]: GiftCertificateSetup;
}

export interface GiftCertificateSetup {
  intentId: string;
  used: boolean;
}

export interface FunctionSetup {
  intentId: string;
  used: boolean;
}

export interface FunctionSetups {
  [functionSetupId: string]: FunctionSetup;
}

export interface CustomerTips {
  type: "%" | "$";
  value: number;
}

export interface LineItem {
  id: string;
  customerId?: string;
  name?: string;
  description?: string;
  inclusive?: boolean;
  inclusiveProduct?: boolean;
  reason?: string;
  modifiers?: OrderModifiers;
  additions?: OrderAdditions;
  preparations?: OrderPreparations;
  addOns?: { name: string; price: number }[];
  requirements?: Array<string>;
  id1: string;
  id2?: string;
  id3?: string;
  id4?: string;
  id6?: string;
  id7?: string;
  id8?: string;
  id9?: string;
  id10?: string;
  id11?: string;
  text?: string[];
  combinations?: LineItem[]; // only 1 deep
  standardPrice: number; // what is diff between standardPrice and price?
  price: number;
  butlerTypeId?: ButlerType;
  quantity: number;
  salesTax?: number;
  standardSalesTax?: number;
  nettExSalesTax?: number;
  standardTotal: number;
  total: number;
  /**
   * @deprecated The method should not be used, instead use productCategoryId
   */
  lineItemTypeId: LineItemTypeId;
  functionLineItemCategoryId?: FunctionProductCategory;
  productGroupHierachy: ProductGroupHierachy[];
  groupHeadingHierachy?: GroupHeadingHierachy[];
  priceWithoutAdjustment?: number;
  priceWithoutAdjustmentTotal?: number;
  productCategoryId: ProductCategoryId;
} // & (ProductLineItemType | RefundOrManualLineItemType);

// type ProductLineItemType = {
//   LineItemTypeId: LineItemTypeId.Product;
//   id1: string; //productId
//   id2: string; //sizeId
//   id3: string; //orderItemId
//   id4: string; //orderId
//   id6?: string; //menuHeadingId
//   id7?: string; //menuId
//   id8?: string; // foodPackageId ??,
//   id9?: string; // beveragePackageId ??,
//   id10?: string; // foodAndBeveragePackageId ??
//   id11?: string; // beverageMenuPackageId ??
// };

// type RefundOrManualLineItemType = {
//   LineItemTypeId: LineItemTypeId.Refund | LineItemTypeId.Manual;
//   id1: string; //accountId
//   id2: string; //productId
//   id3: string; //productSizeId
//   id4: string; //menuId
// };

export interface PaymentSession {
  bookingId: string;
  customerId: string;
  customerTips: CustomerTips;
  redemptions: Redemptions;
  id?: string;
}

export interface Redemptions {
  voucherRedemption?: { voucherCode: string; valid?: boolean; redeemAmount?: number; voucherType?: number; productsRedeemed?: any };
  giftCertificateRedemption?: { giftCertificateCode: string; voucherValid?: boolean; redeemAmount?: number };
  promoCodeRedemption?: { voucherCode: string; valid?: boolean; redeemAmount?: number };
}

export interface TillDepositTabAllocations {
  [customerId: string]: { depositAllocationAmount: number };
}

export interface GiftCertificatePaymentSummary {
  lineItems: LineItem[];
  grandTotal: number;
  serviceFee: number;
  resbutlerFee: number;
}

export enum PaymentTypeId {
  Cash = 1,
  Stripe = 2,
  Offline = 3,
  GiftCertficate = 4,
  Eftpos = 7,
  Tab = 8,
  BankTransfer = 9,
  Deposit = 10,
  CreditAccount,
  Contra,
  ManualCreditCard,
  ManualCash,
}

export enum TransactionType {
  // GiftCertficate = 2,
  Payment = 6,
  Refund = 7,
}
export interface PaymentSummary {
  lineItems: Array<LineItem>;
  paymentTypeId: PaymentTypeId;
  surcharges?: TransactionSurcharge[];
  serviceCharge?: TransactionSurcharge;
  giftCertificateRedemption: any;
  voucherRedemption: any;
  promoCodeRedemption: any;
  depositAppliedAmount?: number;
  depositTabId?: string;
  purchaseAmount: number; // customAmount (from till) || lineItemTotal + surcharges+serviceFee + redemptions + priceadjustments(till) + depositAmount
  tipAmount?: number;
  serviceFeeAmount: number; // Amount based on percentage purchaseAmount + tipAmount
  cashoutAmount: number;
  grandTotal: number;
  resbutlerFeeAmount: number;
  totalSalesTax: number;
  totalStandardSalesTax: number;
  paymentPriceAdjustments?: PaymentPriceAdjustment[];
  requiredPaymentTypeId?: number;
  noShow?: {
    unitAdult: number;
    unitChild: number;
    childAmount: number;
    adultAmount: number;
  };
  cancellationRules?: any;
  depositTabAllocations?: TillDepositTabAllocations;
}

export interface ServiceSurcharge {
  id: string;
  name: string;
  priceOptionId: string;
  priceOption: PriceOption;
}
export interface TransactionSurcharge {
  _key: string;
  id: string; // financialProductId
  name: string;
  value: number;
  priceOptionId: string;
}

export interface PaymentType {
  _key: string;
  paymentTypeId: PaymentTypeId;
  purchaseId?: string;
  transactionRefId?: string;
  value: number;
  manualCreditCardTypeId?: CreditCardId;
  card?: string;
  cardHolderName?: string;
  brand?: string;
  cashReceived?: number;
  currency?: string;
  transactionLocationType?: string;
  timeStamp: number;
  country?: string;
  tipAmount?: number;
  cashoutAmount?: number;
  serviceFee?: number;
  transactionFee?: number;
  cashChange?: number;
  tabId?: string;
  note?: string;
  bankReference?: string; // if paymenttypeid is bankTransfer
  customerId?: string; // if payment type is tab
  accountGroupId?: string;
  accountProductId?: string;
}

export interface PaymentPriceAdjustmentPayload {
  _key: string;
  orderItemId?: string;
  change: number;
  type: "$" | "%";
  accountGroupId: string;
  accountProductId: string;
  accountGroupName: string;
  accountProductName: string;
  accountGroupAdjustmentType: ProductGroupPriceAdjustmentType;
}

export interface PaymentPriceAdjustment {
  _key: string;
  lineItemId?: string;
  change: number;
  type: "$" | "%";
  accountGroupId: string;
  accountProductId: string;
  accountGroupName: string;
  accountProductName: string;
  amount: number;
  accountGroupAdjustmentType: ProductGroupPriceAdjustmentType;
}
export interface Transaction {
  _key?: string;
  bookingId: string;
  /** @deprecated  */
  customerId: string;
  date: string;
  functionId?: string;
  grandTotal: number;
  invoiceId?: string;
  lineItems: LineItem[];
  mealId: string;
  operatorId: string;
  orderNumber?: number;
  paymentPriceAdjustments?: PaymentPriceAdjustment[];
  payments: Array<PaymentType>;
  promoCode: string;
  promoCodeAmount: number;
  resbutlerFee: number;
  restaurantId: string;
  revenueTimeStamp?: number;
  salesTax: number;
  serviceCharge?: TransactionSurcharge;
  serviceFee: number;
  surcharges?: TransactionSurcharge[];
  tillId?: string;
  time: string;
  timeStamp: number;
  tipAmount: number;
  transactionFee: number;
  transactionIdRef: string;
  transactionNumber: number;
  typeId: TransactionType;
  voucherAmount: number;
  voucherCode: string;
  voucherType?: number;
  voucherRedeemedItems?: TransactionVoucherRedeemedItem[];
  closedTabId?: string;
}

export interface TransactionVoucherRedeemedItem {
  lineItemId: string;
  amountRedeemed: number;
}

export interface FunctionPaymentSummary {
  discount?: number;
  purchaseAmount: number;
  lineItems: LineItem[];
  grandTotal: number;
  serviceFeeAmount: number;
  resbutlerFeeAmount: number;
  totalSalesTax: number;
  totalStandardSalesTax: number;
  invoiceId?: string;
  minimumSpendAdjustment?: number;
  minimumSpend?: number;
  surcharge?: number;
  serviceCharge?: number;
}

export interface CustomerMapping {
  customerId: string;
  restaurantId: string;
  stripeCustomerId: string;
}

export interface CustomerMappings {
  [mappingId: string]: CustomerMapping;
}

export enum CashPaymentSetting {
  NoCash = "No Cash Restaurant",
  PayAtTheEnd = "Pay with cash at the end",
  PayAsYouGo = "Pay with cash now",
}

export type CashPaymentConfig = {
  setting: CashPaymentSetting;
  visible: boolean;
  allow: boolean;
  label: CashPaymentSetting.NoCash | CashPaymentSetting.PayAsYouGo | CashPaymentSetting.PayAtTheEnd;
};
