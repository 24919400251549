import _ from "lodash";

export const customerGroupNonMemberTypes = {
  bookings_only: {
    id: "bookings_only",
    accountName: "Bookings Only",
    isNonMember: true,
    isSystem: true,
  },
  gift_certificates_purchases: {
    id: "gift_certificates_purchases",
    accountName: "Gift Certificates Purchase",
    isNonMember: true,
    isSystem: true,
  },
  bookings_gift_certificates: {
    id: "bookings_gift_certificates",
    accountName: "Bookings & Gift Certificates",
    isNonMember: true,
    isSystem: true,
  },
  guests_only: {
    id: "guests_only",
    accountName: "Guests Only",
    isNonMember: true,
    isSystem: true,
  },
};

export const getNonMemberGroups = (levels) => {
  return levels.filter((level) => level.isNonMember === true);
};

export const getMemberGroups = (levels) => {
  return levels.filter((level) => level.isNonMember !== true);
};

export const getEnabledGroups = (levels) => {
  return levels.filter((level) => level.enabled !== false);
};

export const renderRestaurants = (allRestaurants) => {
  return _.pickBy(allRestaurants, (r) => r.enabled);
};
