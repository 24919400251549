import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { isEmpty, isUndefined, map, round } from "lodash";
import moment from "moment";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Select from "react-select";
import { Button, Card, Col, Row, Spinner, Table } from "reactstrap";

import ManagementPeriodSelector from "components/explorer/ManagementPeriodSelector";
import ToggleWrapper from "components/explorer/ToggleWrapper";
import { useAppSelector } from "components/Hooks/hooks";
import useAreas from "components/Hooks/useAreas";
import useMeals from "components/Hooks/useMeals";
import useTableClasses from "components/Hooks/useTableClasses";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";

import { catchExceptionCallback, getConfig } from "core/utils";
import { buildDataFromDocs } from "resbutler-utils/utils";

import tableToCSV from "explorer/tableToCSV";
import tableToPDF from "explorer/tableToPDF";
import { calendarTypes, displayAmount, displayUnit, getGrandTotalForProductRevenue, getProductGroupHierarchyFromProductGroupIds, getSubTotal, getTotal, managementCalendarModes } from "explorer/utils";

import useFilterTreeData, { useProductGroupTreeData } from "./useFilterData";
import { IReportByYieldPayload, normalizeResults } from "./utils";

const RevenueReport = () => {
  const { client, resbutlerApis } = getConfig();
  const dateStore = useRef(null);

  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const products = useAppSelector((state) => state.root.products);
  const productGroups = useAppSelector((state) => state.root.productGroups);

  const [, areas] = useAreas(restaurantId);
  const [, meals] = useMeals(restaurantId);
  const [, tableClasses] = useTableClasses(restaurantId);

  const productGroupsTreeData = useProductGroupTreeData(productGroups);
  const defaultProductGroupsTreeData = [];

  const getDefaultProductGroupData = (items) => {
    items.forEach((item) => {
      defaultProductGroupsTreeData.push(item.value);

      if (item.children) {
        item.children.forEach((item1) => {
          defaultProductGroupsTreeData.push(item1.value);

          if (item1.children) {
            getDefaultProductGroupData(item1.children);
          }
        });
      }
    });
  };

  getDefaultProductGroupData(productGroupsTreeData);

  const filterTreeData = useFilterTreeData(meals, areas, tableClasses);
  const defaultFilterTreeData = [];

  filterTreeData.forEach((item) => {
    defaultFilterTreeData.push(item.value);

    if (item.children) {
      item.children.forEach((item1) => {
        defaultFilterTreeData.push(item1.value);
      });
    }
  });

  const [results, setResults] = useState([]);
  const [filterTreeDataValue, setFilterTreeDataValue] = useState([]);
  const [productGroupsTreeDataValue, setProductGroupsTreeDataValue] = useState([]);
  const [calendar, setCalendar] = useState({});
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (productGroupsTreeDataValue.length === 0 || productGroupsTreeDataValue.length !== productGroupsTreeDataValue.length) {
      setProductGroupsTreeDataValue(defaultProductGroupsTreeData);
    }
  }, [defaultProductGroupsTreeData]);

  useEffect(() => {
    if (filterTreeDataValue.length === 0) {
      setFilterTreeDataValue(defaultFilterTreeData);
    }
  }, [defaultFilterTreeData]);

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        const calendarSnap = await firebase.firestore().collection(`${client}/calendar/calendar`).get();
        setCalendar(buildDataFromDocs(calendarSnap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getCalendarData();
  }, []);

  const initialValues = useMemo(
    () => ({
      restaurants: [],
      calendar: calendarTypes.dateRange.value,
      filterTree: defaultFilterTreeData,
    }),
    []
  );

  const loadReports = async (values) => {
    try {
      const params = {
        calendar: values.calendar,
        restaurantId,
        clientId: client,
      } as IReportByYieldPayload;
      if (values.calendar === calendarTypes.management.value) {
        params.firstPeriod = values?.firstPeriod;
        params.secondPeriod = values?.secondPeriod;
        params.mode = values?.mode;
        dateStore.current = {
          firstPeriod: values?.firstPeriod,
          secondPeriod: values.secondPeriod,
        };
      } else {
        params.firstDateFrom = values.firstDateFrom;
        params.firstDateTo = values.firstDateTo;
        params.secondDateFrom = values.secondDateFrom;
        params.secondDateTo = values.secondDateTo;
        dateStore.current = {
          firstDateFrom: values.firstDateFrom,
          firstDateTo: values.firstDateTo,
          secondDateFrom: values.secondDateFrom,
          secondDateTo: values.secondDateTo,
        };
      }
      setResults([]);
      const API_URL = `${resbutlerApis}/bq/revenue-by-yield`;
      const response = await axios.get(API_URL, { params });
      setResults(response.data);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToCSV = async (e) => {
    try {
      e.preventDefault();
      await tableToCSV(tableRef.current, ",", "revenue-report-by-booking-type");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToPDF = async (e) => {
    try {
      e.preventDefault();
      await tableToPDF(tableRef.current, "Revenue Report by Booking Type", "revenue-report-by-booking-type", 0.65);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const validate = (values: IReportByYieldPayload) => {
    const errors = {} as IReportByYieldPayload;

    if (values) {
      if (values.calendar === calendarTypes.management.value) {
        if (!values.mode) {
          errors.mode = "This is required.";
        }
        if (!values.firstPeriod) {
          errors.firstPeriod = "Please input first period values.";
        } else {
          if (!values.firstPeriod.year) {
            errors.firstPeriod = "Please input first period values.";
          }
          if (values.mode === managementCalendarModes.week.value && !values.firstPeriod.week) {
            errors.firstPeriod = "Please input first period values.";
          }
          if (values.mode === managementCalendarModes.month.value && !values.firstPeriod.month) {
            errors.firstPeriod = "Please input first period values.";
          }
        }
      } else {
        if (!values.firstDateFrom || !values.firstDateTo) {
          errors.firstDateFrom = "Please input first period date range.";
        }
      }
    }

    return errors;
  };

  const renderReports = (productGroupKeys, depth = 0) => {
    return map(productGroupKeys, (items, productGroupId) => {
      const productGroup = productGroups[productGroupId];

      const item = filteredResults?.[productGroupId];
      const linesByProductId = item?.lines;

      const firstVarianceSubTotal = getSubTotal(linesByProductId, "firstPeriodSellingAmount") - getSubTotal(linesByProductId, "firstPeriodStandardTotalPrice");
      const firstVarianceSubTotalPercent = (firstVarianceSubTotal / getSubTotal(linesByProductId, "firstPeriodStandardTotalPrice") || 1) * 100;

      const secondVarianceSubTotal = getSubTotal(linesByProductId, "secondPeriodSellingAmount") - getSubTotal(linesByProductId, "secondPeriodStandardTotalPrice");
      const secondVarianceSubTotalPercent = (secondVarianceSubTotal / getSubTotal(linesByProductId, "secondPeriodStandardTotalPrice") || 1) * 100;

      const quantitySubTotalVarianceUnitsOnPeriods = (getSubTotal(linesByProductId, "firstPeriodUnit") || 0) - (getSubTotal(linesByProductId, "secondPeriodUnit") || 0);
      const quantitySubTotalVariancePercentageOnPeriods = (quantitySubTotalVarianceUnitsOnPeriods / (getSubTotal(linesByProductId, "secondPeriodUnit") || 1)) * 100;

      const sellingSubTotalPriceVarianceOnPeriods = (getSubTotal(linesByProductId, "firstPeriodSellingAmount") || 0) - (getSubTotal(linesByProductId, "secondPeriodSellingAmount") || 0);
      const sellingSubTotalPriceVariancePercentageOnPeriods = (sellingSubTotalPriceVarianceOnPeriods / (getSubTotal(linesByProductId, "secondPeriodSellingAmount") || 1)) * 100;

      const standardSubTotalPriceVarianceOnPeriods = (getSubTotal(linesByProductId, "firstPeriodStandardTotalPrice") || 0) - (getSubTotal(linesByProductId, "secondPeriodStandardTotalPrice") || 0);
      const standardSubTotalPriceVariancePercentageOnPeriods = (standardSubTotalPriceVarianceOnPeriods / (getSubTotal(linesByProductId, "secondPeriodStandardTotalPrice") || 1)) * 100;

      return (
        <ToggleWrapper key={productGroupId} defaultOpen={true}>
          {(open, toggle) => {
            return (
              <>
                <tr>
                  <td className="divider-left">
                    <button className="toggle-btn flex align-items-center" onClick={toggle}>
                      {[...Array(depth)].map((p) => (
                        <Fragment key={`depth-${p}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                      ))}

                      <i className={`fa fa-chevron-${open ? "down" : "up"} mr-2`} aria-hidden="true"></i>
                      <b>{productGroup.name}</b>
                    </button>
                  </td>

                  {/* period 1 */}
                  <td className="divider-left divider-right--light"></td>
                  <td></td>
                  <td className="divider-right--light"></td>
                  <td></td>
                  <td className="divider-right"></td>

                  {/* period 2 */}
                  <td className="divider-left divider-right--light"></td>
                  <td></td>
                  <td className="divider-right--light"></td>
                  <td></td>
                  <td className="divider-right"></td>

                  <td align="center"></td>
                  <td align="center" className="divider-right--light"></td>
                  <td align="center"></td>
                  <td align="center" className="divider-right--light"></td>
                  <td align="center"></td>
                  <td align="center" className="divider-right"></td>
                </tr>

                {open ? (
                  <>
                    {isUndefined(item) === false ? (
                      <>
                        {map(linesByProductId, (lines, productId) => {
                          const product = products?.[productId];

                          const firstVarianceAmount = getTotal(lines, "firstPeriodSellingAmount") - getTotal(lines, "firstPeriodStandardTotalPrice");
                          const firstVariancePercent = (firstVarianceAmount / getTotal(lines, "firstPeriodStandardTotalPrice") || 1) * 100;

                          const secondVarianceAmount = getTotal(lines, "secondPeriodSellingAmount") - getTotal(lines, "secondPeriodStandardTotalPrice");
                          const secondVariancePercent = (firstVarianceAmount / getTotal(lines, "secondPeriodStandardTotalPrice") || 1) * 100;

                          const quantityVarianceUnitsOnPeriods = (getTotal(lines, "firstPeriodUnit") || 0) - (getTotal(lines, "secondPeriodUnit") || 0);
                          const quantityVariancePercentageOnPeriods = (quantityVarianceUnitsOnPeriods / (getTotal(lines, "secondPeriodUnit") || 1)) * 100;

                          const sellingPriceVarianceOnPeriods = (getTotal(lines, "firstPeriodSellingAmount") || 0) - (getTotal(lines, "secondPeriodSellingAmount") || 0);
                          const sellingPriceVariancePercentageOnPeriods = (sellingPriceVarianceOnPeriods / (getTotal(lines, "secondPeriodSellingAmount") || 1)) * 100;

                          const standardPriceVarianceOnPeriods = (getTotal(lines, "firstPeriodStandardTotalPrice") || 0) - (getTotal(lines, "secondPeriodStandardTotalPrice") || 0);
                          const standardPriceVariancePercentageOnPeriods = (standardPriceVarianceOnPeriods / (getTotal(lines, "secondPeriodStandardTotalPrice") || 1)) * 100;

                          return (
                            <tr key={`${productId}-${productGroupId}`} className="small">
                              <td className="pl-6 pb-0 divider-left">{product?.name || lines[0]?.productName}</td>

                              {/* period 1 */}
                              <td align="center" className="divider-left divider-right--light">
                                {displayUnit(getTotal(lines, "firstPeriodUnit"))}
                              </td>
                              <td align="center">{displayAmount(getTotal(lines, "firstPeriodSellingAmount"))}</td>
                              <td align="center" className="divider-right--light">
                                {displayAmount(getTotal(lines, "firstPeriodStandardTotalPrice"))}
                              </td>
                              <td align="center">{displayAmount(firstVarianceAmount)}</td>
                              <td align="center" className="divider-right">
                                {firstVariancePercent ? round(firstVariancePercent, 2) + "%" : null}
                              </td>

                              {/* period 2 */}
                              <td align="center" className="divider-left divider-right--light">
                                {displayUnit(getTotal(lines, "secondPeriodUnit"))}
                              </td>
                              <td align="center">{displayAmount(getTotal(lines, "secondPeriodSellingAmount"))}</td>
                              <td align="center" className="divider-right--light">
                                {displayAmount(getTotal(lines, "secondPeriodStandardTotalPrice"))}
                              </td>
                              <td align="center">{displayAmount(secondVarianceAmount)}</td>
                              <td align="center" className="divider-right">
                                {secondVariancePercent ? round(secondVariancePercent, 2) + "%" : null}
                              </td>

                              {/* period 1 v period 2 */}
                              <td align="center">{displayUnit(quantityVarianceUnitsOnPeriods)}</td>
                              <td align="center" className="divider-right--light">
                                {quantityVariancePercentageOnPeriods ? round(quantityVariancePercentageOnPeriods, 2) + "%" : null}
                              </td>
                              <td align="center">{displayAmount(sellingPriceVarianceOnPeriods)}</td>
                              <td align="center" className="divider-right--light">
                                {sellingPriceVariancePercentageOnPeriods ? round(sellingPriceVariancePercentageOnPeriods, 2) + "%" : null}
                              </td>
                              <td align="center">{displayAmount(standardPriceVarianceOnPeriods)}</td>
                              <td align="center" className="divider-right">
                                {standardPriceVariancePercentageOnPeriods ? round(standardPriceVariancePercentageOnPeriods, 2) + "%" : null}
                              </td>
                            </tr>
                          );
                        })}

                        <tr className="small">
                          <td className="pl-6 pb-0 pr-0 pt-0 divider-left">
                            <div className="pt-1 pr-2 divider-top">Subtotal</div>
                          </td>

                          {/* period 1 */}
                          <td align="center" className="divider-left divider-top divider-right--light">
                            {displayUnit(getSubTotal(linesByProductId, "firstPeriodUnit"))}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(getSubTotal(linesByProductId, "firstPeriodSellingAmount"))}
                          </td>
                          <td align="center" className="divider-right--light divider-top">
                            {displayAmount(getSubTotal(linesByProductId, "firstPeriodStandardTotalPrice"))}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(firstVarianceSubTotal)}
                          </td>
                          <td align="center" className="divider-right divider-top">
                            {firstVarianceSubTotalPercent ? round(firstVarianceSubTotalPercent, 2) + "%" : null}
                          </td>

                          {/* period 2 */}
                          <td align="center" className="divider-left divider-top divider-right--light">
                            {displayUnit(getSubTotal(linesByProductId, "secondPeriodUnit"))}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(getSubTotal(linesByProductId, "secondPeriodSellingAmount"))}
                          </td>
                          <td align="center" className="divider-right--light divider-top">
                            {displayAmount(getSubTotal(linesByProductId, "secondPeriodStandardTotalPrice"))}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(secondVarianceSubTotal)}
                          </td>
                          <td align="center" className="divider-right divider-top">
                            {secondVarianceSubTotalPercent ? round(secondVarianceSubTotalPercent, 2) + "%" : null}
                          </td>

                          {/* period 1 v period 2 */}
                          <td align="center" className="divider-top">
                            {displayUnit(quantitySubTotalVarianceUnitsOnPeriods)}
                          </td>
                          <td align="center" className="divider-right--light divider-top">
                            {quantitySubTotalVariancePercentageOnPeriods ? round(quantitySubTotalVariancePercentageOnPeriods, 2) + "%" : null}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(sellingSubTotalPriceVarianceOnPeriods)}
                          </td>
                          <td align="center" className="divider-right--light divider-top">
                            {sellingSubTotalPriceVariancePercentageOnPeriods ? round(sellingSubTotalPriceVariancePercentageOnPeriods, 2) + "%" : null}
                          </td>
                          <td align="center" className="divider-top">
                            {displayAmount(standardSubTotalPriceVarianceOnPeriods)}
                          </td>
                          <td align="center" className="divider-right divider-top">
                            {standardSubTotalPriceVariancePercentageOnPeriods ? round(standardSubTotalPriceVariancePercentageOnPeriods, 2) + "%" : null}
                          </td>
                        </tr>
                      </>
                    ) : null}

                    {Object.keys(items).length ? renderReports(items, depth + 1) : null}
                  </>
                ) : null}
              </>
            );
          }}
        </ToggleWrapper>
      );
    });
  };

  const filteredResults = normalizeResults(results, filterTreeData, filterTreeDataValue, productGroupsTreeDataValue);
  const productGroupHierarchy = getProductGroupHierarchyFromProductGroupIds(Object.keys(filteredResults), productGroups);

  const firstVarianceGrandTotal = getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodSellingAmount") - getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodStandardTotalPrice");
  const firstVarianceGrandTotalPercent = (firstVarianceGrandTotal / getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodStandardTotalPrice") || 1) * 100;

  const secondVarianceGrandTotal = getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodSellingAmount") - getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodStandardTotalPrice");
  const secondVarianceGrandTotalPercent = (secondVarianceGrandTotal / getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodStandardTotalPrice") || 1) * 100;

  const quantityTotalVarianceUnitsOnPeriods = (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodUnit") || 0) - (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodUnit") || 0);
  const quantityTotalVariancePercentageOnPeriods = (quantityTotalVarianceUnitsOnPeriods / (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodUnit") || 1)) * 100;

  const sellingTotalPriceVarianceOnPeriods = (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodSellingAmount") || 0) - (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodSellingAmount") || 0);
  const sellingTotalPriceVariancePercentageOnPeriods = (sellingTotalPriceVarianceOnPeriods / (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodSellingAmount") || 1)) * 100;

  const standardTotalPriceVarianceOnPeriods = (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodStandardTotalPrice") || 0) - (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodStandardTotalPrice") || 0);
  const standardTotalPriceVariancePercentageOnPeriods = (standardTotalPriceVarianceOnPeriods / (getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodStandardTotalPrice") || 1)) * 100;

  return (
    <ContentWrapper>
      <ContentHeading headerText="Revenue Yield Report" showRestaurants />

      <Card className="card-default py-3 px-4">
        <Row>
          <Col sm={12} lg={4} className="py-2">
            <Row className="mb-4">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">
                  <b>Product Group</b>
                </label>
              </Col>
              <Col sm={12} lg={8}>
                <TreeSelect
                  className="form-control tree-select"
                  style={{ width: "100%" }}
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ height: 250, overflow: "auto" }}
                  dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                  onDropdownVisibleChange={() => true}
                  treeLine
                  placeholder={<i>None</i>}
                  multiple
                  showIcon={false}
                  maxTagTextLength={25}
                  autoClearSearchValue
                  treeNodeFilterProp="title"
                  treeData={productGroupsTreeData}
                  treeCheckable
                  treeDefaultExpandAll
                  value={productGroupsTreeDataValue}
                  // showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={20}
                  onChange={(ids) => setProductGroupsTreeDataValue(ids)}
                  maxTagPlaceholder={(valueList) => {
                    return `+${valueList.length}`;
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">Filter</label>
              </Col>

              <Col sm={12} lg={8}>
                <TreeSelect
                  className="form-control tree-select"
                  style={{ width: "100%" }}
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ height: 250, overflow: "auto" }}
                  dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                  onDropdownVisibleChange={() => true}
                  treeLine
                  placeholder={<i>None</i>}
                  multiple
                  showIcon={false}
                  maxTagTextLength={25}
                  autoClearSearchValue
                  treeNodeFilterProp="title"
                  treeData={filterTreeData}
                  treeCheckable
                  treeDefaultExpandAll
                  value={filterTreeDataValue}
                  showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={20}
                  onChange={(ids) => setFilterTreeDataValue(ids)}
                  maxTagPlaceholder={(valueList) => {
                    return `+${valueList.length}`;
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Form
            initialValues={initialValues}
            onSubmit={loadReports}
            validate={validate}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, values, form }) => {
              return (
                <>
                  <Col sm={12} lg={8} className="divider-cols">
                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center h-100 gap-sm">
                      <div className="mb-3 flex align-items-center gap-md">
                        <label className="m-0">
                          <b>Calendar</b>
                        </label>

                        <div className="d-flex gap-xl flex-wrap">
                          <Field name="calendar">
                            {({ input: { value, onChange } }) => (
                              <div className="form-group gap-md d-flex m-0">
                                {Object.values(calendarTypes).map((option, index) => (
                                  <label key={`render-units-${index}`} className="radio c-radio m-0 d-flex align-items-center">
                                    <input type="radio" value={option.value} checked={option.value === value} onChange={() => onChange(option.value)} />
                                    <span className="fa fa-circle" />
                                    {option.label}
                                  </label>
                                ))}
                              </div>
                            )}
                          </Field>

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="d-flex align-items-center gap-md">
                              <label className="mb-0">Mode</label>

                              <div style={{ width: 160 }}>
                                <Field name="mode">
                                  {({ input: { value, onChange }, meta }) => (
                                    <>
                                      <Select
                                        isSearchable={false}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        blurInputOnSelect={false}
                                        options={map(managementCalendarModes, (item) => item)}
                                        value={managementCalendarModes?.[value]}
                                        onChange={(e) => {
                                          onChange(e.value);

                                          form.change("firstPeriod", undefined);
                                          form.change("secondPeriod", undefined);
                                        }}
                                        placeholder="Select mode"
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex align-items-end gap-lg justify-content-between flex-wrap">
                        <div className="d-flex flex-grow-1 align-items-end gap-md">
                          {values.calendar == calendarTypes.dateRange.value ? (
                            <div className="d-flex align-items-start gap-md flex-grow-1">
                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="startDate" className="mb-0 text-nowrap">
                                  First Period
                                </label>

                                <Field name="firstDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="startDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.firstDateFrom, "YYYY-MM-DD").toDate(), moment(values.firstDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("firstDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("firstDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="endDate" className="mb-0 text-nowrap">
                                  Second Period
                                </label>

                                <Field name="secondDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="endDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.secondDateFrom, "YYYY-MM-DD").toDate(), moment(values.secondDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("secondDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("secondDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="flex-grow-1">
                              <div className="form-group mb-2 flex-grow-1">
                                <Field name="firstPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="startDate" className="mb-0">
                                          First Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 flex-grow-1">
                                <Field name="secondPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="endDate" className="mb-0">
                                          Second Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          <Button id="category-save" type="submit" className="d-flex justify-content-center align-items-center gap-sm" color="primary" disabled={submitting}>
                            {submitting ? <Spinner size="sm" /> : null} Generate Report
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Col>
                </>
              );
            }}
          />
        </Row>
      </Card>

      {isEmpty(filteredResults) ? null : (
        <>
          <div className="d-flex align-items-center gap-sm justify-content-end mb-2">
            <Button onClick={handleExportToCSV} type="button" color="primary">
              Download CSV
            </Button>
            <Button onClick={handleExportToPDF} type="button" color="primary">
              Download PDF
            </Button>
          </div>

          <Card className="card-default py-2 px-4 overflow-auto">
            <Table borderless className="explorer-table" innerRef={tableRef}>
              <thead>
                <tr>
                  <td width={360}></td>

                  <td colSpan={5} align="center" className="divider-bottom text-decoration-underline">
                    {dateStore.current?.firstDateTo ? (
                      <>
                        {moment(dateStore.current.firstDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.firstDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.firstPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.firstPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.firstPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.firstPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.firstPeriod?.year ? `${calendar[dateStore.current.firstPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>

                  <td colSpan={5} align="center" className="divider-bottom text-decoration-underline">
                    {dateStore.current?.secondDateTo ? (
                      <>
                        {moment(dateStore.current.secondDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.secondDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.secondPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.secondPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.secondPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.secondPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.secondPeriod?.year ? `${calendar[dateStore.current.secondPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>

                  <td colSpan={6} align="center" className="divider-bottom text-decoration-underline">
                    {dateStore.current?.firstDateTo ? (
                      <>
                        {moment(dateStore.current.firstDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.firstDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.firstPeriod?.month ? <>{`${dateStore.current.firstPeriod.month}`},</> : null}
                        {dateStore.current?.firstPeriod?.week ? <>{`${dateStore.current.firstPeriod.week}`},</> : null}
                        &nbsp;
                        {dateStore.current?.firstPeriod?.year ? `${calendar[dateStore.current.firstPeriod.year]?.name}` : null}
                      </>
                    )}
                    &nbsp;&nbsp;&nbsp;v&nbsp;&nbsp;&nbsp;
                    {dateStore.current?.secondDateTo ? (
                      <>
                        {moment(dateStore.current.secondDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.secondDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.secondPeriod?.month ? <>{`${dateStore.current.secondPeriod.month}`},</> : null}
                        {dateStore.current?.secondPeriod?.week ? <>{`${dateStore.current.secondPeriod.week}`},</> : null}
                        &nbsp;
                        {dateStore.current?.secondPeriod?.year ? `${calendar[dateStore.current.secondPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>
                </tr>

                <tr className="small">
                  <td className="divider-top divider-left"></td>

                  {/* period 1 */}
                  <td align="center" className="divider-left divider-bottom divider-right--light">
                    Quantity Sold
                  </td>
                  <td align="center" className="divider-bottom">
                    Selling Price <br />
                    (Ordering App/Till)
                  </td>
                  <td align="center" className="divider-bottom divider-right--light">
                    Standard Price <br />
                    (Product Tree)
                  </td>
                  <td align="center" className="divider-bottom">
                    Variance ($)
                  </td>
                  <td align="center" className="divider-right divider-bottom">
                    Variance (%)
                  </td>

                  {/* period 2 */}
                  <td align="center" className="divider-left divider-right--light divider-bottom">
                    Quantity Sold
                  </td>
                  <td align="center" className="divider-bottom">
                    Selling Price <br />
                    (Ordering App/Till)
                  </td>
                  <td align="center" className="divider-right--light divider-bottom">
                    Standard Price <br />
                    (Product Tree)
                  </td>
                  <td align="center" className="divider-bottom">
                    Variance ($)
                  </td>
                  <td align="center" className="divider-right divider-bottom">
                    Variance (%)
                  </td>

                  <td colSpan={2} align="center" className="divider-right--light divider-bottom">
                    Quantity Variance
                  </td>
                  <td colSpan={2} align="center" className="divider-right--light divider-bottom">
                    Selling Price (Ordering App/Till)
                  </td>
                  <td colSpan={2} align="center" className="divider-right divider-bottom">
                    Standard Price Variance (Product Tree)
                  </td>
                </tr>

                <tr className="small">
                  <td className="divider-left"></td>

                  {/* period 1 */}
                  <td className="divider-left divider-bottom divider-right--light"></td>
                  <td className="divider-bottom"></td>
                  <td className="divider-right--light divider-bottom"></td>
                  <td className="divider-bottom"></td>
                  <td className="divider-right divider-bottom"></td>

                  {/* period 2 */}
                  <td className="divider-left divider-right--light divider-bottom"></td>
                  <td className="divider-bottom"></td>
                  <td className="divider-right--light divider-bottom"></td>
                  <td className="divider-bottom"></td>
                  <td className="divider-right divider-bottom"></td>

                  <td align="center" className="divider-bottom">
                    Variance (Units)
                  </td>
                  <td align="center" className="divider-right--light divider-bottom">
                    Variance (%)
                  </td>
                  <td align="center" className="divider-bottom">
                    Variance ($)
                  </td>
                  <td align="center" className="divider-right--light divider-bottom">
                    Variance (%)
                  </td>
                  <td align="center" className="divider-bottom">
                    Variance ($)
                  </td>
                  <td align="center" className="divider-right divider-bottom">
                    Variance (%)
                  </td>
                </tr>
              </thead>

              <tbody>
                {renderReports(productGroupHierarchy)}

                <tr>
                  <td className="total-cells divider-full">
                    <i>Total Sales</i>
                  </td>

                  {/* period 1 */}
                  <td align="center" className="total-cells divider-left divider-bottom divider-right--light divider-top">
                    {displayUnit(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodUnit"))}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodSellingAmount"))}
                  </td>
                  <td align="center" className="total-cells divider-right--light divider-bottom divider-top">
                    {displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodStandardTotalPrice"))}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(firstVarianceGrandTotal)}
                  </td>
                  <td align="center" className="total-cells divider-right divider-bottom divider-top">
                    {firstVarianceGrandTotalPercent ? round(firstVarianceGrandTotalPercent, 2) + "%" : null}
                  </td>

                  {/* period 2 */}
                  <td align="center" className="total-cells divider-left divider-bottom divider-right--light divider-top">
                    {displayUnit(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodUnit"))}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodSellingAmount"))}
                  </td>
                  <td align="center" className="total-cells divider-right--light divider-bottom divider-top">
                    {displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodStandardTotalPrice"))}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(secondVarianceGrandTotal)}
                  </td>
                  <td align="center" className="total-cells divider-right divider-bottom divider-top">
                    {secondVarianceGrandTotalPercent ? round(secondVarianceGrandTotalPercent, 2) + "%" : null}
                  </td>

                  {/* period 1 v period 2 */}
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayUnit(quantityTotalVarianceUnitsOnPeriods)}
                  </td>
                  <td align="center" className="total-cells divider-right--light divider-bottom divider-top">
                    {quantityTotalVariancePercentageOnPeriods ? round(quantityTotalVariancePercentageOnPeriods, 2) + "%" : null}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(sellingTotalPriceVarianceOnPeriods)}
                  </td>
                  <td align="center" className="total-cells divider-right--light divider-bottom divider-top">
                    {sellingTotalPriceVariancePercentageOnPeriods ? round(sellingTotalPriceVariancePercentageOnPeriods, 2) + "%" : null}
                  </td>
                  <td align="center" className="total-cells divider-bottom divider-top">
                    {displayAmount(standardTotalPriceVarianceOnPeriods)}
                  </td>
                  <td align="center" className="total-cells divider-right divider-bottom divider-top">
                    {standardTotalPriceVariancePercentageOnPeriods ? round(standardTotalPriceVariancePercentageOnPeriods, 2) + "%" : null}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueReport;
