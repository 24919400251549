import { getEnabledProductGroups } from "RootReducer";
import classnames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import { AdditionalItemsCategories, BeverageMenuPackage, MenuHeadings } from "resbutler-utils/types/Menu";
import { ProductSizes } from "resbutler-utils/types/product";
import MenuItem, { createDynamicGroupHeadings, createStaticGroupHeadings } from "../../menus-and-pricing/menu-heading/MenuItem";
import BeverageMenusForm from "./BeverageMenusForm";

const BeverageMenuModal = (props: { productSizes: ProductSizes; packageId: string; isNew: boolean; isOpen: boolean; toggle: () => void; selectedPackage: BeverageMenuPackage; menuHeadings: MenuHeadings; additionalItemsCategories: AdditionalItemsCategories }) => {
  const [tab, setTab] = useState(1);

  const { productSizes, isOpen, toggle, packageId, selectedPackage, menuHeadings, isNew, additionalItemsCategories } = props;

  const [initialValues, setInitialValues] = useState<BeverageMenuPackage>(null);

  const [removedProducts, setRemovedProducts] = useState({});
  const { products, productGroups, restaurantId } = useAppSelector((state) => {
    return {
      products: state.root.products,
      productGroups: getEnabledProductGroups(state.root.productGroups),
      restaurantId: state.root.restaurantId,
    };
  });

  useEffect(() => {
    for (const groupHeadingId in selectedPackage?.beverageMenus?.groupHeadings) {
      const gh = selectedPackage.beverageMenus.groupHeadings[groupHeadingId];
      if (menuHeadings[gh.menuHeadingId].type === "static") {
        createStaticGroupHeadings(menuHeadings[gh.menuHeadingId], gh, gh.groupHeadingType, gh.menuHeadingId);
      } else {
        setRemovedProducts(createDynamicGroupHeadings(menuHeadings[gh.menuHeadingId], gh, groupHeadingId, productGroups, products, productSizes, gh.groupHeadingType, gh.menuHeadingId));
      }
    }
    setInitialValues({ restaurantId, enabled: true, ...selectedPackage });
  }, []);

  const validate = (values) => {
    const errors = {} as any;
    if (!values.minPax) {
      errors.minPax = "Min pax should not be empty";
    }
    if (!values.maxPax) {
      errors.maxPax = "Max pax should not be empty";
    }
    if (values.minPax > values.maxPax) {
      errors.minPax = "Min pax cannot exceed max pax value";
    }
    if (!values.name) {
      errors.name = "Package Name should not be empty";
    }
    if (!values.beverageOnConsumption && !values.barTab) {
      errors.barTab = "Bar tab or beverage on consumption must be selected";
    }
    return errors;
  };

  const onSave = async (values: BeverageMenuPackage) => {
    try {
      const { client } = getConfig();
      await firebase.firestore().doc(`${client}/menus/beverageMenus/${packageId}`).set(values);
      toggle();
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <Modal isOpen={isOpen} size="xl" toggle={toggle} scrollable>
      <Form<BeverageMenuPackage>
        initialValues={initialValues}
        onSubmit={onSave}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, submitting }) => (
          <form onSubmit={handleSubmit} className="modal-content">
            <ModalHeader toggle={toggle}>{isNew ? "Create New Package" : `Edit ${selectedPackage?.name}`}</ModalHeader>
            <ModalBody>
              <div className="col-md-10">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: tab === 1 })} onClick={() => setTab(1)}>
                      Create/Edit
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: tab === 2 })} onClick={() => setTab(2)}>
                      Menu Items
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="p-4" id="menu-create-edit" activeTab={tab}>
                  <TabPane tabId={1}>{<BeverageMenusForm />}</TabPane>
                  <TabPane tabId={2}>
                    <MenuItem groupHeadings={values.beverageMenus?.groupHeadings} menuOrPackage={values} type="beverageMenus" path="beverageMenus" productSizes={productSizes} pkg menuHeadings={menuHeadings} removedProducts={removedProducts} />
                  </TabPane>
                </TabContent>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" className="float-right" onClick={toggle}>
                Close
              </Button>
              <Button type="submit" disabled={submitting} color="success" className="float-right">
                Save {submitting && <Spinner size="sm" />}
              </Button>
              <div className="clearfix" />
            </ModalFooter>
          </form>
        )}
      ></Form>
    </Modal>
  );
};

export default BeverageMenuModal;
