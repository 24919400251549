import { getConfig } from "core/utilities";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GiftCertificateModals } from "resbutler-ui/components/GiftCertificateModal";

export const GiftCertificateNumber = ({ giftCertificate }) => {
  const [modalOpen, setModalOpen] = useState<any>(false);
  const { client: clientId, resbutlerApis } = getConfig();
  const restaurants = useSelector((state: any) => state.root.restaurants);

  return (
    <>
      <p className="text-primary cursor-pointer " onClick={() => setModalOpen(true)}>
        {giftCertificate.voucherNumber}
      </p>
      {modalOpen ? <GiftCertificateModals giftCertificateId={giftCertificate._key} giftCertificate={giftCertificate} setModalOpen={setModalOpen} clientId={clientId} resbutlerApis={resbutlerApis} restaurants={restaurants} /> : null}
    </>
  );
};
