import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useEffect, useState } from "react";
import { Packages } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";

type TPackageName = "food" | "beverage" | "foodbeverage";

const usePackages = (restaurantId: string, packageName: TPackageName): [boolean, Packages] => {
  const [packages, setPackages] = useState<Packages>({});
  const [loading, setLoading] = useState(true);
  const { client } = getConfig();

  async function fetchData() {
    try {
      setPackages({});
      setLoading(true);
      const response = await firebase.firestore().collection(`${client}/menus/${packageName}Packages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get();
      setPackages(buildDataFromDocs(response));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (restaurantId) fetchData();
  }, [restaurantId]);

  return [loading, packages];
};

export default usePackages;
