
export const getReactSelectValue = (opts, val) => {
  const parsedVal = val ? val : [];
  return opts.filter((o) => parsedVal.includes(o.value));
};

export const buildReactSelectOptions = (items, valueField = "id", labelField = "name") => {
  return items.map((item) => {
    return { value: item[valueField], label: item[labelField] };
  });
};
