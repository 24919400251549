import axios from "axios";
import { filter, forEach, keys, map } from "lodash";
import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from "reactstrap";
import { PermissionType } from "../../../resbutler-utils/types/Operator";
import { PaymentService, Tills } from "../../../resbutler-utils/types/Till";
import { PurchaseTypeId, RefundModel } from "../../../resbutler-utils/types/purchase";
import { LineItemTypeId, PaymentType, PaymentTypeId, Transaction, TransactionSurcharge } from "../../../resbutler-utils/types/transaction";
import { getLineItemMenuId, getLineItemProductId, getLineItemSizeId } from "../../../resbutler-utils/utils/transactionUtils";
import { TillInputField } from "../../components/Till/TillInput";
import { catchExceptionCallback } from "../../utilities";
import { priceFormatter } from "../../utils/transactionDetail";
interface RefundData {
  description: string;
  includePaymentServiceFee: boolean;
  includeTip: boolean;
  lineItems: { [id: string]: { lineItemId: string; menuId?: string; productId: string; productSizeId: string; refundAmount: number; selected: boolean } };
  surcharges?: { [_key: string]: { _key: string; id: string; name: string; priceOptionId: string; refundAmount: number; selected: boolean } };
  serviceCharge?: { _key: string; id: string; name: string; priceOptionId: string; refundAmount: number; selected: boolean };
  operatorPin: string;
}
const getRefundItemsFromValues = (refundData: RefundData, payment: PaymentType) => {
  const refundLineItemsAndValues = [];
  const surchargesItems: TransactionSurcharge[] = [];
  let serviceChargeItem: TransactionSurcharge = null;
  map(refundData.lineItems, (refundLineItem, lineItemId) => {
    if (refundLineItem.selected) {
      refundLineItemsAndValues.push({ id: lineItemId, productId: refundLineItem.productId, refundAmount: Number(refundLineItem.refundAmount), menuId: refundLineItem.menuId || null, productSizeId: refundLineItem.productSizeId || null });
    }
  });

  map(refundData.surcharges, (surchargeItem, _key) => {
    if (surchargeItem.selected) {
      surchargesItems.push({ _key, id: surchargeItem.id, value: Number(surchargeItem.refundAmount), priceOptionId: surchargeItem.priceOptionId, name: surchargeItem.name });
    }
  });

  if (refundData?.serviceCharge?.selected) {
    serviceChargeItem = { _key: refundData.serviceCharge._key, id: refundData.serviceCharge.id, value: Number(refundData.serviceCharge.refundAmount), priceOptionId: refundData.serviceCharge.priceOptionId, name: refundData.serviceCharge.name };
  }

  const tipAmount = refundData.includeTip ? payment.tipAmount : 0;
  const transactionFee = refundData.includePaymentServiceFee ? payment.transactionFee || 0 : 0;
  const serviceFee = refundData.includePaymentServiceFee ? payment.serviceFee : 0;
  const productRefundAmount = refundLineItemsAndValues.reduce((acc, r) => {
    acc += Number(r.refundAmount);
    return acc;
  }, 0);
  const surchargesRefundAmount = surchargesItems.reduce((acc, r) => {
    acc += Number(r.value);
    return acc;
  }, 0);

  const refundAmount = productRefundAmount + transactionFee + serviceFee + tipAmount + surchargesRefundAmount + (serviceChargeItem ? serviceChargeItem.value : 0);
  return { refundLineItemsAndValues, surchargesItems, serviceChargeItem, tipAmount, transactionFee, serviceFee, refundAmount, productRefundAmount };
};

const PaymentConfirm = ({ setOpen, refundData, payment, onRefund, loading }) => {
  const { refundAmount } = getRefundItemsFromValues(refundData, payment);

  return (
    <Modal size="lg" scrollable isOpen zIndex={3002}>
      <ModalHeader className="mx-1" toggle={() => !loading && setOpen(false)}>
        <div className="text-center">Confirm Refund</div>
      </ModalHeader>
      <ModalBody>
        <>
          <div style={{ marginTop: "20px" }}>
            <label>Total</label>
            <br />
            <label style={{ fontWeight: "bold" }}>{priceFormatter.format(refundAmount)}</label>
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" className="col-lg-2 btn-lg text-nowrap" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          color="success"
          className="col-lg-2 btn-lg text-nowrap"
          disabled={loading}
          onClick={() => {
            onRefund();
          }}
        >
          Confirm
          {loading ? <Spinner type="grow" color="light" size="sm" /> : null}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface TransactionRefundModalProps {
  payment: PaymentType;
  resbutlerApis: string;
  setTransactionPaymentForRefund: (payment: PaymentType) => void;
  setPaying?: (p: boolean) => void;
  paymentProvider?: PaymentService;
  offlineMode: boolean;
  date?: string;
  mealId?: string;
  tills?: Tills;
  refundTransactions: Transaction[];
  setTransactionOpen: (b: boolean) => void;
  sessionId?: string;
  transaction: Transaction;
  selectedTransactionId: string;
  restaurantId: string;
  onScreenKeyboard?: boolean;
  // selectedPaymentRefund: PaymentType;
  tillId: string;
  transactionNumber: number;
  operatorId: string;
}

export default function TransactionRefundModal({ payment, setTransactionPaymentForRefund, resbutlerApis, setPaying = null, onScreenKeyboard, offlineMode, paymentProvider, date = null, mealId = null, tills, refundTransactions, setTransactionOpen, sessionId = null, transaction, selectedTransactionId, restaurantId, tillId, transactionNumber, operatorId }: TransactionRefundModalProps) {
  const [loading, setLoading] = useState(false);
  const [refundData, setRefundData] = useState<RefundData>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [isPaymentServiceFeeRefunded, isTipRefunded, totalRefundedForPayment, totalRefundLeftForProducts] = useMemo(() => {
    let totalRefundedForPayment = 0;
    let isPaymentServiceFeeRefunded = false;
    let isTipRefunded = false;
    let tipAmount = 0;
    let surchargeAmount = 0;
    if (refundTransactions?.length > 0) {
      refundTransactions.forEach((rf) => {
        const refundedPayment = rf.payments.find((p) => p._key === payment._key);
        if (refundedPayment) {
          totalRefundedForPayment = totalRefundedForPayment + refundedPayment.value;
          if (refundedPayment.tipAmount) {
            isTipRefunded = true;
            tipAmount = refundedPayment.tipAmount;
          }
          if (refundedPayment.transactionFee || refundedPayment.serviceFee) {
            isPaymentServiceFeeRefunded = true;
            surchargeAmount = refundedPayment.transactionFee || refundedPayment.serviceFee;
          }
        }
      });
    }
    const totalRefundLeftForProducts = +(payment.value - payment.tipAmount - (payment.transactionFee || payment.serviceFee || 0) - (totalRefundedForPayment - tipAmount - surchargeAmount)).toFixed(2);
    return [isPaymentServiceFeeRefunded, isTipRefunded, totalRefundedForPayment, totalRefundLeftForProducts];
  }, [refundTransactions]);

  const getProductRefundedAmount = (lineItem) => {
    let refundTotal = 0;
    if (refundTransactions?.length > 0) {
      for (let i = 0; i < refundTransactions.length; i++) {
        for (let k = 0; k < refundTransactions[i].lineItems.length; k++) {
          if (refundTransactions[i].lineItems[k].id === lineItem.id) {
            refundTotal = refundTotal + refundTransactions[i].lineItems[k].total;
          }
        }
      }
    }
    return refundTotal;
  };

  const getSurchargeRefundedAmount = (surcharge: TransactionSurcharge) => {
    let refundTotal = 0;
    forEach(refundTransactions, (transaction) => {
      forEach(transaction.surcharges, (refundSurcharge) => {
        if (surcharge._key === refundSurcharge._key) {
          refundTotal = refundTotal + refundSurcharge.value;
        }
      });
    });
    return refundTotal;
  };

  function onSave(values) {
    setRefundData(values);
    setConfirmOpen(true);
  }

  async function onRefund() {
    try {
      setLoading(true);
      //const onsiteRefund = transactionNumber && selectedPaymentRefund.paymentTypeId !== PaymentTypeId.Stripe;
      const { refundLineItemsAndValues, tipAmount, transactionFee, serviceFee, refundAmount, surchargesItems, serviceChargeItem } = getRefundItemsFromValues(refundData, payment);
      // const data = getConfig();
      await axios.post(`${resbutlerApis}/crm/operator/check-operator-permission`, { code: refundData.operatorPin, restaurantId, permission: PermissionType.issueRefunds });
      if (setPaying) setPaying(true);
      const payload: RefundModel = {
        paymentKey: payment._key,
        terminalId: tills[tillId]?.terminalId,
        paymentTypeId: offlineMode ? PaymentTypeId.Offline : payment.paymentTypeId,
        refundAmount,
        operatorId,
        sessionId,
        suppressMerchantPassword: tills[tillId]?.suppressMerchantPassword,
        date,
        mealId,
        transactionNumber,
        description: refundData.description,
        transactionFee,
        serviceFee,
        tipAmount,
        refundLineItemsAndValues,
        refundSurcharges: surchargesItems,
        refundServiceCharge: serviceChargeItem,
        tillId,
        operatorPin: refundData.operatorPin,
        transactionId: selectedTransactionId,
        restaurantId,
        typeId: PurchaseTypeId.Refund,
      };
      await paymentProvider.refund(payload);
      setTransactionOpen(false);
      setTransactionPaymentForRefund(null);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  const validate = (values: RefundData) => {
    const errors: any = {};
    if (!values.description) {
      errors.description = "Description is empty";
    }
    if (!values.operatorPin) {
      errors.operatorPin = "Operator Pin is empty";
    }

    let isAnyItemSelected = false;
    if (keys(values.lineItems).length > 0) {
      const selectedProducts = filter(values.lineItems, (s) => s.selected === true);
      if (selectedProducts.length > 0) {
        isAnyItemSelected = true;
        const refundAmounts = filter(selectedProducts, (s) => !s.refundAmount);
        if (refundAmounts.length > 0) errors.formError = "Please enter refund amount";
        else {
          selectedProducts.forEach((sp) => {
            const lineItem = transaction.lineItems.find((li) => li.id === sp.lineItemId);
            const refundAvailable = lineItem.total - getProductRefundedAmount(lineItem);
            if (refundAvailable < sp.refundAmount) {
              errors.formError = "Refund Amount cannot exceed available amount that can be refunded";
              return;
            }
          });
        }
      }
    }

    if (keys(values.surcharges).length > 0) {
      const selectedItems = filter(values.surcharges, (s) => s.selected === true);
      if (selectedItems.length > 0) {
        isAnyItemSelected = true;
        const emptyRefundAmounts = filter(selectedItems, (s) => !s.refundAmount);
        if (emptyRefundAmounts.length > 0) errors.formError = "Please enter refund amount";
        else {
          selectedItems.forEach((si) => {
            const surcharge = transaction.surcharges.find((s) => s._key === si._key);
            const refundAvailable = surcharge.value - getSurchargeRefundedAmount(surcharge);
            if (refundAvailable < si.refundAmount) {
              errors.formError = "Refund Amount cannot exceed available amount that can be refunded";
              return;
            }
          });
        }
      }
    }

    if (values.serviceCharge?.selected) {
      isAnyItemSelected = true;
      if (!values.serviceCharge.refundAmount) errors.formError = "Please enter refund amount";
      else {
        const serviceCharge = transaction.serviceCharge;
        const refundAvailable = serviceCharge.value - getSurchargeRefundedAmount(serviceCharge);
        if (refundAvailable < values.serviceCharge.refundAmount) errors.formError = "Refund Amount cannot exceed available amount that can be refunded";
      }
    }
    const { productRefundAmount } = getRefundItemsFromValues(values, payment);
    if (!isAnyItemSelected) errors.formError = "Please select at least one item";
    else if (productRefundAmount > totalRefundLeftForProducts) errors.formError = "Total Refund Amount exceeds amount that can be refunded for this payment.";
    return errors;
  };
  const isPaymentServiceFeeDisabled = (!payment.serviceFee && !payment.transactionFee) || isPaymentServiceFeeRefunded;
  const isTipDisabled = !payment.tipAmount || isTipRefunded;
  return (
    <Modal scrollable isOpen size="lg" zIndex={99999} top>
      <Form<RefundData>
        validate={validate}
        initialValues={{ includePaymentServiceFee: !isPaymentServiceFeeDisabled, includeTip: !isTipDisabled }}
        onSubmit={onSave}
        render={({ handleSubmit, submitting, form }) => {
          const { errors, touched } = form.getState();
          return (
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader className="mx-1" toggle={() => !submitting && setTransactionPaymentForRefund(null)}>
                <div className="text-center">Refund {`(${selectedTransactionId})`}</div>
              </ModalHeader>
              <ModalBody>
                {
                  <Table bordered size="sm" className="fw-bold">
                    <tbody>
                      <tr className="fs-5">
                        <td colSpan={2}>Payment Detail</td>
                      </tr>
                      <tr>
                        <td>Card Ending with</td>
                        <td>{payment.card}</td>
                      </tr>
                      {payment.tipAmount ? (
                        <tr>
                          <td>Tip</td>
                          <td>{priceFormatter.format(payment.tipAmount)}</td>
                        </tr>
                      ) : null}
                      {payment.serviceFee ? (
                        <tr>
                          <td>Payment Service Fee</td>
                          <td>{priceFormatter.format(payment.serviceFee)}</td>
                        </tr>
                      ) : null}

                      {payment.transactionFee ? (
                        <tr>
                          <td>Transaction Service Fee</td>
                          <td>{priceFormatter.format(payment.transactionFee)}</td>
                        </tr>
                      ) : null}
                      {payment.cashoutAmount ? (
                        <tr>
                          <td>Cashout Amount</td>
                          <td>{priceFormatter.format(payment.cashoutAmount)}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Total</td>
                        <td>{priceFormatter.format(payment.value)}</td>
                      </tr>
                      <tr>
                        <td>Refunded</td>
                        <td>{priceFormatter.format(totalRefundedForPayment)}</td>
                      </tr>
                      <tr>
                        <td>Refund Amount Available</td>
                        <td>{priceFormatter.format(payment.value - totalRefundedForPayment)}</td>
                      </tr>
                    </tbody>
                  </Table>
                }
                {payment.value - totalRefundedForPayment === 0 ? (
                  "Full Amount already refunded"
                ) : (
                  <>
                    <Field id="description1" name="description" onScreenKeyboardEnabled={false} type="text" component={TillInputField as any} label="Description" placeholder="Description" />
                    <Table className="mt-2" bordered striped>
                      <thead>
                        <tr>
                          <td></td>
                          <td>Item</td>
                          <td>Qty</td>
                          <td>Price</td>
                          <td>Paid</td>
                          <td>Refunded</td>
                          <td>Remaining</td>
                          <td>Refund Amount</td>
                        </tr>
                      </thead>
                      <tbody>
                        <Field name="lineItems">
                          {({ input: { onChange, value } }) => (
                            <>
                              {transaction.lineItems.map((l, i) => {
                                const productId = getLineItemProductId(l);
                                const productSizeId = getLineItemSizeId(l);
                                const menuId = getLineItemMenuId(l);
                                const lineItemId = l.id;
                                return (
                                  <React.Fragment key={lineItemId}>
                                    <tr>
                                      <td>
                                        <div className="checkbox c-checkbox">
                                          <label className="mt-2" htmlFor={`lineItem-${lineItemId}`}>
                                            <input id={`lineItem-${lineItemId}`} type="checkbox" disabled={l.total - getProductRefundedAmount(l) <= 0 || l.lineItemTypeId === LineItemTypeId.Cashout} onChange={(e) => onChange({ ...value, [lineItemId]: { ...value[lineItemId], lineItemId, selected: e.target.checked, productId, productSizeId, menuId } })} />
                                            <span className="fa fa-check" />
                                          </label>
                                        </div>
                                      </td>
                                      <td>{l.name}</td>
                                      <td>{l.quantity}</td>
                                      <td>{priceFormatter.format(l.price)}</td>
                                      <td>{priceFormatter.format(l.total)}</td>
                                      <td>{priceFormatter.format(getProductRefundedAmount(l))}</td>
                                      <td>{priceFormatter.format(l.total - getProductRefundedAmount(l))}</td>
                                      <td>
                                        <TillInputField
                                          disabled={!value[lineItemId]?.selected || l.total - getProductRefundedAmount(l) <= 0 || l.lineItemTypeId === LineItemTypeId.Cashout}
                                          onScreenKeyboardEnabled={onScreenKeyboard}
                                          input={{
                                            id: `lineItem-${lineItemId}`,
                                            className: "form-control label",
                                            type: "number",
                                            value: value[lineItemId]?.refundAmount,
                                            step: "0.01",
                                            onChange: (e) => {
                                              onChange({ ...value, [lineItemId]: { ...value[lineItemId], refundAmount: e } });
                                            },
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </>
                          )}
                        </Field>

                        <Field name="surcharges">
                          {({ input: { onChange, value } }) => (
                            <>
                              {map(transaction.surcharges, (surcharge, i) => {
                                const surchargeId = surcharge._key;
                                if (!surchargeId) return null;
                                return (
                                  <React.Fragment key={`refund-surcharges-${i}`}>
                                    <tr>
                                      <td>
                                        <div className="checkbox c-checkbox">
                                          <label className="mt-2" htmlFor={`refund-surcharges-${i}`}>
                                            <input id={`refund-surcharges-${i}`} type="checkbox" disabled={surcharge.value - getSurchargeRefundedAmount(surcharge) <= 0} onChange={(e) => onChange({ ...value, [surchargeId]: { ...value[surchargeId], selected: e.target.checked, _key: surcharge._key, name: surcharge.name, id: surcharge.id, priceOptionId: surcharge.priceOptionId } })} />
                                            <span className="fa fa-check" />
                                          </label>
                                        </div>
                                      </td>
                                      <td>{surcharge.name}</td>
                                      <td>1</td>
                                      <td>{priceFormatter.format(surcharge.value)}</td>
                                      <td>{priceFormatter.format(surcharge.value)}</td>
                                      <td>{priceFormatter.format(getSurchargeRefundedAmount(surcharge))}</td>
                                      <td>{priceFormatter.format(surcharge.value - getSurchargeRefundedAmount(surcharge))}</td>
                                      <td>
                                        <TillInputField
                                          disabled={!value[surchargeId]?.selected || surcharge.value - getSurchargeRefundedAmount(surcharge) <= 0}
                                          onScreenKeyboardEnabled={onScreenKeyboard}
                                          input={{
                                            id: `surcharge-${surchargeId}`,
                                            className: "form-control label",
                                            type: "number",
                                            value: value[surchargeId]?.refundAmount,
                                            step: "0.01",
                                            onChange: (e) => {
                                              onChange({ ...value, [surchargeId]: { ...value[surchargeId], refundAmount: e } });
                                            },
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </>
                          )}
                        </Field>

                        {transaction.serviceCharge?._key ? (
                          <Field name="serviceCharge">
                            {({ input: { onChange, value } }) => {
                              const serviceCharge = transaction.serviceCharge;
                              const _key = serviceCharge._key;
                              return (
                                <>
                                  <React.Fragment key={`refund-service-charge-${_key}`}>
                                    <tr>
                                      <td>
                                        <div className="checkbox c-checkbox">
                                          <label className="mt-2" htmlFor={`refund-surcharges-${_key}`}>
                                            <input id={`refund-surcharges-${_key}`} type="checkbox" disabled={serviceCharge.value - getSurchargeRefundedAmount(serviceCharge) <= 0} onChange={(e) => onChange({ ...value, selected: e.target.checked, _key, id: serviceCharge.id, name: serviceCharge.name, priceOptionId: serviceCharge.priceOptionId })} />
                                            <span className="fa fa-check" />
                                          </label>
                                        </div>
                                      </td>
                                      <td>{serviceCharge.name}</td>
                                      <td>1</td>
                                      <td>{priceFormatter.format(serviceCharge.value)}</td>
                                      <td>{priceFormatter.format(serviceCharge.value)}</td>
                                      <td>{priceFormatter.format(getSurchargeRefundedAmount(serviceCharge))}</td>
                                      <td>{priceFormatter.format(serviceCharge.value - getSurchargeRefundedAmount(serviceCharge))}</td>
                                      <td>
                                        <TillInputField
                                          disabled={!value?.selected || serviceCharge.value - getSurchargeRefundedAmount(serviceCharge) <= 0}
                                          onScreenKeyboardEnabled={onScreenKeyboard}
                                          input={{
                                            id: `surcharge-${_key}`,
                                            className: "form-control label",
                                            type: "number",
                                            value: value?.refundAmount,
                                            step: "0.01",
                                            onChange: (e) => {
                                              onChange({ ...value, refundAmount: e });
                                            },
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                </>
                              );
                            }}
                          </Field>
                        ) : null}
                        <tr>
                          <td colSpan={8}> {!!errors.formError && touched && <span className="validate-form">{errors.formError}</span>}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1" htmlFor="includePaymentServiceFee">
                        <Field id="includePaymentServiceFee" name="includePaymentServiceFee" component="input" type="checkbox" disabled={isPaymentServiceFeeDisabled} />
                        <span className="fa fa-check" />
                        Include Payment Service Fee
                      </label>
                    </div>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1" htmlFor="includeTip">
                        <Field id="includeTip" name="includeTip" component="input" type="checkbox" disabled={isTipDisabled} />
                        <span className="fa fa-check" />
                        Include Tip
                      </label>
                    </div>
                    <Field id="code1" name="operatorPin" label="Operator Pin" type="number" autoComplete="off" onScreenKeyboardEnabled={onScreenKeyboard} component={TillInputField as any} placeholder="Operator Pin" inputStyle={{ WebkitTextSecurity: "disc" }} />
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button type="button" className="col-lg-2 col-sm-4 btn-lg text-nowrap" color="light" disabled={submitting} onClick={() => setTransactionPaymentForRefund(null)}>
                  Close
                </Button>
                {payment.value - totalRefundedForPayment === 0 ? null : (
                  <Button type="submit" color="success" className="col-lg-2 col-sm-4 btn-lg text-nowrap" disabled={loading || submitting}>
                    {loading ? (
                      <>
                        <Spinner type="grow" color="light" size="sm" />
                        Refunding...
                      </>
                    ) : (
                      "Refund"
                    )}
                  </Button>
                )}
              </ModalFooter>
            </form>
          );
        }}
      ></Form>
      {confirmOpen && <PaymentConfirm loading={loading} setOpen={setConfirmOpen} onRefund={onRefund} refundData={refundData} payment={payment} />}
    </Modal>
  );
}
