import { useAppSelector } from "components/Hooks/hooks";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import _, { find, isEmpty } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { ProductEventActionType, ProductLog } from "resbutler-utils/types/EventType";
import { Menu } from "resbutler-utils/types/Menu";

export type INotification = {
  affectedMenus: string[];
  affectedPackages?: any;
} & ProductLog;

export type INotifications = {
  [notificationId: string]: INotification;
};

interface IProps {
  notifications: INotifications;
}

const generateEventMessage = (notification: INotification): string => {
  const { action, metadata } = notification;

  if (action === ProductEventActionType.AddProduct) {
    return `The product '${metadata.newName}' has been added.`;
  }

  if (action === ProductEventActionType.AddProductGroup) {
    return `The product group '${metadata.newName}' has been added.`;
  }

  if (action === ProductEventActionType.RenameProduct) {
    return `The name of the product '${metadata.oldName}' has been changed to '${metadata.newName}'.`;
  }

  if (action === ProductEventActionType.RenameProductGroup) {
    return `The name of the product group '${metadata.oldName}' has been changed to '${metadata.newName}'.`;
  }

  if (action === ProductEventActionType.ChangeProductGroup) {
    return `The product '${metadata.newName}' has been transferred from the '${metadata.group1}' group to the '${metadata.group2}' group.`;
  }

  if (action === ProductEventActionType.EnableProduct) {
    return `The product '${metadata.newName}' has been enabled.`;
  }

  if (action === ProductEventActionType.DisableProduct) {
    return `The product '${metadata.newName}' has been disabled.`;
  }

  if (action === ProductEventActionType.EnableProductGroup) {
    return `The product group '${metadata.newName}' has been enabled.`;
  }

  if (action === ProductEventActionType.DisableProductGroup) {
    return `The product group '${metadata.newName}' has been disabled.`;
  }

  if (action === ProductEventActionType.AddProductInMenuHeading) {
    return `The product '${metadata.name}' has been added.`;
  }

  if (action === ProductEventActionType.DeleteProductInMenuHeading) {
    return `The product '${metadata.name}' has been removed.`;
  }

  return "";
};

const Notifications = ({ notifications }: IProps) => {
  const menus = useAppSelector((state) => state.root.menus);

  return (
    <div className="notification-container">
      <div className="notification-card-container">
        <div className="notification-list">
          <div className="notification-title">Menu Notification {`(${Object.keys(notifications).length})`}</div>

          {_.map(notifications, (notification, notificationId) => (
            <NotificationItem key={`notification-${notificationId}`} notification={notification} notificationId={notificationId} menus={menus} />
          ))}
        </div>
      </div>
    </div>
  );
};

const NotificationItem = ({ notification, notificationId, menus }: { notification: INotification; notificationId: string; menus: Menu[] }) => {
  const [loadingMarkAsRead, setLoadingMarkAsRead] = useState(false);
  const [openAffectedMenuNotificationModal, setOpenAffectedMenuNotificationModal] = useState(false);

  const timestamp = notification.timestamp;
  const eventMessage = generateEventMessage(notification);

  const toggleAffectedMenuNotificationModal = () => setOpenAffectedMenuNotificationModal((v) => !v);

  const handleMarkAsRead = async () => {
    try {
      setLoadingMarkAsRead(true);

      const { client } = getConfig();
      const collectionRef = firebase.firestore().collection(`${client}/events/products`);
      const snapshot = await collectionRef.get();

      if (!snapshot.empty) {
        const docRef = collectionRef.doc(notificationId);
        await docRef.update({ read: true });
      }
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoadingMarkAsRead(false);
      setOpenAffectedMenuNotificationModal(false);
    }
  };

  return (
    <Fragment>
      <div className="notification-item" onClick={() => setOpenAffectedMenuNotificationModal(true)}>
        <div className="notification-item-inner">
          <p className="timing-details">
            <span>{moment(timestamp * 1000).format("DD")}</span> <span>{moment(timestamp * 1000).format("MMMM")}</span> <span>{moment(timestamp * 1000).format("YYYY")}</span>
            <span>,</span> <span>{moment(timestamp * 1000).format("dddd")}</span>
            <span>,</span> <span>{moment(timestamp * 1000).format("LT")}</span>
          </p>

          {isEmpty(notification.affectedMenus) === false ? (
            <p className="notification-menu-list-container">
              {notification.affectedMenus.length === 1 ? (
                <>
                  <b>1 menu</b> is affected.
                </>
              ) : (
                <>
                  <b>{notification.affectedMenus.length} menus</b> are affected.
                </>
              )}
            </p>
          ) : null}

          {isEmpty(notification.affectedPackages) === false ? (
            <p className="notification-menu-list-container">
              {notification.affectedPackages.length === 1 ? (
                <>
                  <b>1 Package</b> is affected.
                </>
              ) : (
                <>
                  <b>{notification.affectedPackages.length} Packages</b> are affected.
                </>
              )}
            </p>
          ) : null}
          <p className="event-message">{eventMessage}</p>
        </div>
      </div>

      <Modal size="md" scrollable isOpen={openAffectedMenuNotificationModal} toggle={toggleAffectedMenuNotificationModal}>
        <ModalHeader toggle={toggleAffectedMenuNotificationModal}>
          <b>Notification Details</b>
        </ModalHeader>

        <ModalBody>
          <Card className="card-default mb-4">
            <CardBody>{eventMessage}</CardBody>

            <CardFooter>
              <div className="d-flex justify-content-end">
                <Button color="primary" size="sm" onClick={handleMarkAsRead}>
                  {loadingMarkAsRead ? <Spinner size="sm" className="mr-2" /> : null}
                  Mark as read
                </Button>
              </div>
            </CardFooter>
          </Card>

          <Card className="card-default mb-4">
            <CardBody>
              {notification.affectedMenus.length > 0 ? (
                <div>
                  <p>
                    <b>Affected menu list:</b>
                  </p>
                  <ul>
                    {notification.affectedMenus.map((menuId) => {
                      const menu = find(menus, (m) => m.id === menuId);
                      if (menu) {
                        return (
                          <li key={`notification-${notificationId}-menu-${menuId}`}>
                            <Link to={`/menus-and-pricing/menus/${menuId}`}>{menu.name}</Link>
                          </li>
                        );
                      }
                      return null
                    })}
                  </ul>
                </div>
              ) : (
                "- Empty -"
              )}

              {notification.affectedPackages.length > 0 ? (
                <div>
                  <p>
                    <b>Affected Packages:</b>
                  </p>
                  <ul>
                    {notification.affectedPackages.map((item, index) => {
                      return (
                        <li key={`notification-${notificationId}-menu-${item._key}-${index}`}>
                          <Link to={`#`}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                "- Empty -"
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Notifications;
