import { priceFormatter } from "_utils/format";
import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import { map } from "lodash";
import moment from "moment";
import { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { VoucherCustomer } from "../../crm/vouchers/issued/VoucherIssuedList";
import { getGiftCertficates, searchGiftCertificates, statusFilterOptions } from "../GiftCertificateAction";
// import { Link } from "react-router-dom";
// import { GiftCertificateNumber } from "./GiftCertificateNumber";
import { GiftCertificateNumber } from "./GiftCertificateNumber";

const sortTable = (a, b, order) => {
  if (order === "asc") {
    return b - a;
  }
  return a - b; // desc
};

export const DataToNewColumns = (data, restaurants /* , customers */) => {
  return data
    .filter((d) => restaurants[d.restaurantId])
    .map((item) => {
      item["createdAtSort"] = moment(item.createdAt).isValid() ? item.createdAt : 0;
      item["expiryDateSort"] = moment(item.expiryDate).isValid() ? item.expiryDate : 0;
      item["restaurantListNames"] = getRestaurantNames(item.restaurantId, restaurants, item.restaurants);
      return item;
    });
};

const getRestaurantNames = (restaurantId, restaurants, restaurantListId) => {
  const restaurantListNames = "";
  if (!restaurantId) {
    return "";
  }
  if (restaurantId && !restaurantListId) {
    return restaurants[restaurantId].name;
  }

  /* _.keys(restaurants).map((key) => {
        restaurantListNames += restaurantListNames ? `, ` + restaurants[key].name : restaurants[key].name
    }); */

  return restaurantListNames;
};

/* const getCustomerFullName = (customer) => {
  if (!customer.UID_Search) return;
  const { firstName, lastName } = customer;
  if (firstName && !lastName) {
    return `${firstName}`;
  } else if (!firstName && lastName) {
    return `${lastName}`;
  } else if (!firstName && !lastName) {
    return '';
  }
  return `${lastName}, ${firstName}`;
}; */
// const GetFullName = (firstName,lastName) => {
//   if (firstName && !lastName) {
//     return `${firstName}`;
//   } else if (!firstName && lastName) {
//     return `${lastName}`;
//   } else if (!firstName && !lastName) {
//     return '';
//   }
//   return `${lastName}, ${firstName}`;
// };

const giftCertificateColumns = () => [
  {
    text: "Restaurants",
    dataField: "restaurantListNames",
    sort: false,
    width: 500,
  },
  {
    text: "Gift Certificate Number",
    dataField: "voucherNumber",
    formatter: (cell, row) => <GiftCertificateNumber giftCertificate={row} />,
    sort: true,
  },
  {
    text: "3rd Party Gift Certificate Number",
    // dataField: "voucherNumber",
    sort: true,
  },
  {
    text: "Issuer",
    // dataField: "voucherNumber",
    sort: true,
  },
  {
    text: "Purchaser",
    dataField: "customerId",
    sort: true,
    formatter: (customerId) => <VoucherCustomer customerId={customerId} />,
  },
  {
    text: "Recipient",
    hidden: [].includes("recipientCustomerId"),
    dataField: "recipientCustomerId",
    formatter: (recipientCustomerId) => (recipientCustomerId ? <VoucherCustomer customerId={recipientCustomerId} /> : ""),
  },
  {
    text: "Amount Issued",
    dataField: "amount",
    sort: true,
    formatter: (amount) => priceFormatter.format(amount),
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
  {
    text: "Amount Redeemed",
    dataField: "amountRedeemed",
    sort: true,
    formatter: (amount) => priceFormatter.format(amount || 0),
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
  {
    text: "Amount Remaining",
    dataField: "amountRemaining",
    sort: true,
    formatter: (_undefined, row) => priceFormatter.format(row.amount - (row.amountRedeemed || 0)),
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
  {
    text: "Issued Date",
    dataField: "createdAtSort",
    sort: true,
    formatter: (_undefined, row) => {
      if (row.createdAt) {
        return moment(row.createdAt).format("DD/MM/YYYY");
      }
      return "";
    },
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
  {
    dataField: "expiryDateSort",
    sort: true,
    text: "Expiry Date",
    formatter: (_undefined, row) => {
      if (row.expiryDate) {
        return moment(row.expiryDate, "YYYYMMDD").format("DD/MM/YYYY");
      }
      return "";
    },
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
  /* {
        dataField: 'fullCustomerNameSort',
        sort: true,
        text: 'Customer',
    }, */

  {
    text: "Enabled",
    sort: true,
    dataField: "enabled",
    formatter: (_undefined, row) => {
      return row.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />;
    },
    sortFunc: (a, b, order) => sortTable(a, b, order),
  },
];

class BootstrapTableComponent extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      filterEnabled: true,
      showByStatus: statusFilterOptions.all.value,
      restaurants: [props.restaurantId],
      search: "",
      columns: giftCertificateColumns(),
    };
  }

  async componentDidMount() {
    this.props.dispatch(getGiftCertficates());
    this.fieldChanged = this.fieldChanged.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterEnabled != this.state.filterEnabled) {
      const field: any = { target: {} };
      field.target.name = "filterEnabled";
      this.fieldChanged(field, this.state.filterEnabled);
    }
  }

  fieldChanged(field, value) {
    this.setState({ [field.target.name]: value }, () => {
      this.props.dispatch(searchGiftCertificates(this.props.giftCertificates, this.state.search, this.state.filterEnabled, this.state.showByStatus, this.state.restaurants, "search-gift-certificates"));
    });
  }

  render() {
    const { giftCertificates, searchResults, restaurants } = this.props;
    const data = DataToNewColumns(searchResults, restaurants);

    const options = {
      sizePerPage: 20,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
      ],
    };

    return (
      <>
        <Form
          initialValues={this.state}
          onSubmit={() => this.props.dispatch(searchGiftCertificates(giftCertificates, this.state.search, this.state.filterEnabled, this.state.showByStatus, this.state.restaurants, "search-gift-certificates"))}
          // validate={this.validate}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Row className="align-items-end mb-4">
                  <Col sm={6} lg={4}>
                    <div className="form-group mb-0">
                      <div className="input-group with-focus">
                        <Field type="text" name="search" placeholder="Search by Gift Certificate Number, Purchaser or Recipient" component="input" className="form-control" onChange={(event) => this.fieldChanged(event, event.target.value)} />
                        <div className="input-group-append">
                          <Button type="submit" color="warning">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} lg={3}>
                    <label className="mb-0">Restaurants</label>
                    <Select
                      closeMenuOnSelect
                      isMulti
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      blurInputOnSelect={false}
                      options={map(restaurants, (restaurant, key) => ({
                        label: restaurant.name,
                        value: key,
                      }))}
                      value={map(this.state.restaurants, (resId) => {
                        const restaurant = restaurants[resId];

                        return {
                          label: restaurant.name,
                          value: resId,
                        };
                      })}
                      onChange={(e) => {
                        this.fieldChanged(
                          {
                            target: {
                              name: "restaurants",
                            },
                          },
                          map(e, (e) => e.value)
                        );
                      }}
                    />
                  </Col>

                  <Col sm={3} lg={3}>
                    <div className="form-group mb-0">
                      <label className="mb-0">Status</label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.fieldChanged(
                            {
                              target: {
                                name: "showByStatus",
                              },
                            },
                            e.target.value
                          );
                        }}
                        value={this.state.showByStatus}
                      >
                        {map(statusFilterOptions, (item, key) => {
                          return (
                            <option key={key} value={key}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>

                  <Col sm={3} lg={2}>
                    <DropdownListFilter
                      id="tableFilter1"
                      name="enableFilter"
                      className="form-group mb-0"
                      getName={getFilterName}
                      onSelect={(e) => {
                        this.setState({ filterEnabled: e });
                      }}
                    />
                  </Col>
                </Row>
              </form>
            );
          }}
        ></Form>
        <Row>
          <ToolkitProvider keyField="_key" data={data} columns={this.state.columns} search>
            {(props) => (
              <div>
                <Col sm={12}>
                  <Card className="card-default">
                    <CardBody>
                      <div className="form-group col-lg-12">
                        <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            )}
          </ToolkitProvider>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    giftCertificates: state.giftCertificates.byId,
    searchResults: state.giftCertificates.searchResults,
    searchParam: state.giftCertificates.searchParam,
    restaurantId: state.root.restaurantId,
    //customers: state.giftCertificates.giftCertificateCustomers,
  };
}

export default connect(mapStateToProps)(BootstrapTableComponent);
