// https://casesandberg.github.io/react-color/#examples

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export default class SketchControlV2 extends React.Component {
  state = {
    displayColorPicker: false,
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.props.onChange(color.rgb)
  };

  render() {
    const {r,g,b,a} = this.props.color || {r: '', g: '', b: '', a: ''}

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${r}, ${g}, ${b}, ${a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '9',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={{...styles.swatch, opacity: this.props?.disabled ? .4 : 1}} onClick={this.props?.disabled ? null : this.handleClick}>
          <div style={styles.color} />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker color={this.props.color} onChange={this.handleChange} />
        </div> : null }
          </div>
    )
  }
}
