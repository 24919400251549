import { useAppSelector } from "components/Hooks/hooks";
import { renderSketchControl, requiredField } from "core/react-utils";
import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { showLoading } from "react-redux-loading-bar";
import Select from "react-select";
import { Button, Card, CardBody, CardFooter, Col, Row, Spinner } from "reactstrap";
import ImageUploader from "resbutler-ui/components/Uploader/ImageUploader";
import { Restaurants } from "resbutler-utils/types/Restaurant";
import SunEditor, { buttonList } from "suneditor-react";
import { LOAD_RESTAURANT_GALLERY } from "../ActionTypes";
import { deleteRestaurantLogo, getGiftCertficateRestaurantSettings, getRestaurantImages, saveRestaurantLogo } from "../GiftCertificateAction";
import GiftCertificateRestaurantPreview from "./GiftCertificateRestaurantPreview";

function GiftCertificateRestaurantSettings(props: { restaurantId: string; restaurants: Restaurants; restaurantSettings: any }) {
  const { restaurants, restaurantSettings } = props;
  const [openPreview, setOpenPreview] = useState(false);

  const { restaurantId, progressBarRestaurantImages, saving } = useAppSelector((state) => {
    return {
      progressBarRestaurantImages: state.giftCertificates.progressBarRestaurantImages,
      // images: state.giftCertificates.imagesRestaurant,
      saving: state.giftCertificates.savingRestaurant,
      restaurantId: state.root.restaurantId,
    };
  });

  const dispatch = useDispatch();

  const renderRestaurantImages = (images) => {
    const results = [];
    images.map((item) => {
      if (item.url !== undefined) results.push({ url: item.url, fileName: item.name });
    });
    return results;
  };

  async function save(values) {
    try {
      values.redeemQuantity = Number(values.redeemQuantity);
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}`).update(values);
    } catch (error) {
      catchExceptionCallback(error);
    }
  }

  // form validation
  const validate = (values) => {
    const errors: any = {};
    if (!values.restaurantId) {
      errors.restaurantId = "Restaurant is required.";
    }
    if (values.redeemQuantity < 0 || !values.redeemQuantity) {
      errors.redeemQuantity = "Please enter valid quantity!";
    }
    return errors;
  };

  // useEffect(() => {
  //   dispatch({ type: DISPLAY_PROGRESS_BAR_RESTAURANT_IMAGES, loading: true });
  //   dispatch({ type: LOAD_RESTAURANT_GALLERY, loadRestaurantGallery: true });
  // }, [restaurantId, restaurantSettings, loadRestaurantGallery]);

  function onRestaurantSelect(e) {
    if (!e) return;
    dispatch(showLoading());
    dispatch({ type: LOAD_RESTAURANT_GALLERY, loadRestaurantGallery: false });
    dispatch(getRestaurantImages(e));
    dispatch(getGiftCertficateRestaurantSettings(e));
  }

  const { client } = getConfig();

  // const displayContent = !restaurantId ? "hide-tab" : "";
  // let bridgeImages = images;

  // const handleSaveChange = (file) => {
  //   const name = file.fileName;
  //   const url = file.url;
  //   const thumbUrl = file.thumbUrl;
  //   const addedData = {
  //     url,
  //     thumbUrl,
  //     name,
  //     sizeFile: file.sizeFile,
  //     fileName: file.fileName,
  //   };
  //   bridgeImages.push(addedData);
  // };
  const togglePreview = () => setOpenPreview((v) => !v);

  return (
    <Form
      initialValues={{ gallery: [], ...restaurantSettings }}
      keepDirtyOnReinitialize
      validate={validate}
      onSubmit={(values) => save(values)}
      render={({ handleSubmit, form, submitting, values }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Card className="card-default">
                    <CardBody>
                      <div className="form-group">
                        <Field name="restaurantId">
                          {({ input: { onChange, value } }) => (
                            <div className={`form-group`}>
                              <label htmlFor="restaurantId1">
                                Restaurant <span className="validate-form">*</span>
                              </label>
                              <Select
                                styles={{
                                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                closeMenuOnSelect={true}
                                options={Object.entries(restaurants).map(([id, r]) => ({ value: id, label: r.name }))}
                                value={{
                                  value,
                                  label: restaurants[value]?.name,
                                }}
                                onChange={(restaurant) => {
                                  onChange(restaurant.value);
                                  onRestaurantSelect(restaurant.value);
                                }}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="form-group">
                        <div className="form-group" style={{ display: "none" }}>
                          <label htmlFor="restaurant-logo">Restaurant Logo</label>
                          <div id="restaurant-logo" className="well dropzone" style={{ opacity: "1", minHeight: "213px", maxWidth: 500, marginBottom: 0 }}>
                            <div className="dropzone-previews" />
                            <div className="ball-beat" style={{ display: saving["restaurant-logo"] || progressBarRestaurantImages ? "block" : "none" }}>
                              <div />
                              <div />
                              <div />
                            </div>
                          </div>
                        </div>
                        <ImageUploader
                          client={client}
                          sectionName="Restaurant Logo"
                          onDelete={() => {
                            dispatch(deleteRestaurantLogo(restaurantId));
                          }}
                          currentImages={[
                            {
                              url: restaurantSettings?.url || null,
                              fileName: restaurantSettings?.name !== undefined ? restaurantSettings?.name : null,
                            },
                          ]}
                          setImage={(output) => {
                            dispatch(saveRestaurantLogo(output, restaurantId));
                          }}
                          path="giftcertificates"
                          disabled={false}
                          isMultiple={false}
                        />
                        <Field id="bridge-images" name="gallery">
                          {({ input: { onChange, value } }) => {
                            return (
                              <ImageUploader
                                client={client}
                                sectionName="Gift Certificate Restaurant Gallery"
                                onDelete={(url) => {
                                  onChange(value.filter((item) => item.url !== url));
                                }}
                                currentImages={renderRestaurantImages(value)}
                                setImage={(outputs) => {
                                  const bridgeImages = [];
                                  for (const file of outputs) {
                                    const name = file.fileName;
                                    const url = file.url;
                                    const thumbUrl = file.thumbUrl;
                                    const addedData = {
                                      url,
                                      thumbUrl,
                                      name,
                                      sizeFile: file.sizeFile,
                                      fileName: file.fileName,
                                    };
                                    bridgeImages.push(addedData);
                                  }
                                  onChange(bridgeImages);
                                }}
                                isMultiple={true}
                                path="giftcertificatesGallery"
                                disabled={false}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email-background-color1">Email Background Colour</label>
                        <Field id="email-background-color1" name="emailBackgroundColor" component={renderSketchControl} onChangeColor={(color) => form.change("emailBackgroundColor", color.rgb)} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email-button-color1">Email Button Colour</label>
                        <Field id="email-button-color1" name="emailButtonColor" component={renderSketchControl} onChangeColor={(color) => form.change("emailButtonColor", color.rgb)} />
                      </div>
                      <div className="form-group">
                        <Field id="redeem-quantity" name="redeemQuantity" label="Redeem Quantity" helpText={`For unlimited redeems, input "0"`} type="number" component={requiredField as any} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="wysiwig-editor1">Terms &amp; Conditions</label>
                        <Field id="terms-conditions" name="terms">
                          {({ input: { onChange, value } }) => {
                            return <SunEditor setContents={value} setOptions={{ height: 200, buttonList: buttonList.complex }} onChange={(content) => onChange(content)} />;
                          }}
                        </Field>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button onClick={togglePreview}>Preview</Button>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button id="gift-certificate-save" type="submit" color="success" className="float-right" disabled={submitting}>
                        Save {submitting && <Spinner size="sm" />}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </form>

            <GiftCertificateRestaurantPreview
              open={openPreview}
              toggle={togglePreview}
              settings={{
                emailBackgroundColor: values.emailBackgroundColor,
                logo: values.url,
                gallery: values.gallery?.[0]?.url,
                restaurantId: values.restaurantId,
              }}
            />
          </>
        );
      }}
    />
  );
}

export default GiftCertificateRestaurantSettings;
