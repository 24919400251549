import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import { useAppSelector } from "components/Hooks/hooks";
import useMenuHeadings from "components/Hooks/useMenuHeadings";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { getListItem } from "core/react-utils";
import { getConfig } from "core/utilities";
import { capitalizeFirstLetter, catchExceptionCallback } from "core/utils";
import firebase from "firebase/compat/app";
import { chain, entries, orderBy, pickBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { AdditionalItemsCategories, Packages } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import PackageModal from "./PackageModal";
import "./list.scss";

let unsubscribe = null;

export default function PackageList(props) {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ enabled: true });
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState<Packages>({});
  const [selectedPackage, setSelectedPackage] = useState("");
  const { client } = getConfig();

  const [additionalItemsCategories, setAdditionalItemsCategories] = useState<AdditionalItemsCategories>({});

  useEffect(() => {
    async function fetch() {
      try {
        const snap = await firebase.firestore().collection(`${client}/menus/additionalItemsCategories`).get();
        setAdditionalItemsCategories(pickBy(buildDataFromDocs(snap.docs) as AdditionalItemsCategories, (c) => c.enabled));
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    fetch();
  }, []);

  const { restaurantId, restaurants, productSizes } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
      restaurants: state.root.restaurants,
      productSizes: state.root.productSizes,
    };
  });

  const [, menuHeadings] = useMenuHeadings(restaurantId);
  const packageName = props.packageName.replace(/_and_/g, " & ");
  const firebasePackageName = props.packageName.replace(/_and_/g, "");
  const packageId = firebase.firestore().collection(`${client}/menus/${firebasePackageName}Packages/`).doc().id;

  useEffect(() => {
    const fetchAllPackages = async () => {
      setLoading(true);
      unsubscribe = firebase
        .firestore()
        .collection(`${client}/menus/${firebasePackageName}Packages`)
        .where("restaurantId", "==", restaurantId)
        .onSnapshot((snap) => {
          const packages1 = buildDataFromDocs(snap.docs);
          setPackages(packages1 as Packages);
          setLoading(false);
        });
    };
    if (restaurantId) fetchAllPackages();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [restaurantId, firebasePackageName]);

  const packages1 = entries(packages).reduce((accu, [packageId, pkg]) => {
    if (filter.enabled === null || (filter.enabled && pkg.enabled) || (!filter.enabled && !pkg.enabled)) {
      if (pkg.type !== undefined && !(pkg.type in accu)) {
        accu[pkg.type] = {};
      }
      if ("type" in pkg) {
        accu[pkg.type][packageId] = pkg;
      }
    }
    return accu;
  }, {} as { [type: string]: Packages });

  const updateRank = async (packageId1: string, packageId2: string, index1: number, index2: number) => {
    try {
      await Promise.all([firebase.firestore().doc(`${client}/menus/${firebasePackageName}Packages/${packageId1}`).set({ order: index2 }, { merge: true }), firebase.firestore().doc(`${client}/menus/${firebasePackageName}Packages/${packageId2}`).set({ order: index1 }, { merge: true })]);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <ContentWrapper>
      <ContentHeading
        parentText="Function Menus"
        headerText="Function Menus"
        subHeaderText={`${capitalizeFirstLetter(packageName)} Packages`}
        showRestaurants={true}
        restaurants={restaurants}
        restaurantId={restaurantId}
        onRestaurantChange={(restaurantId) => dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId: restaurantId })}
      />
      <Row>
        <Col lg={12} sm={12}>
          <div className="justify-content-between row">
            <div className="col-12 col-sm-4">
              <Button color="primary text-capitalize" onClick={() => setSelectedPackage(packageId)}>
                New {packageName} Package
              </Button>
            </div>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group m-0" getName={getFilterName} onSelect={(e) => setFilter({ enabled: e })} />
            </div>
          </div>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            orderBy(entries(packages1), [0]).map(([groupType, pkges]) => {
              const sortedPackages = chain(pkges)
                .keys()
                .orderBy((key) => pkges[key]?.order)
                .value();

              return (
                <React.Fragment key={groupType}>
                  <h2 className="group-name text-capitalize mt-2">{groupType}</h2>
                  <Card className="card-default">
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Menu Description</th>
                            <th>Menu Type</th>
                            <th>Enabled</th>
                            <th>Order</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {sortedPackages.map((packageId, index) => {
                            const pkg = pkges[packageId];

                            return (
                              <tr key={packageId}>
                                <td>{pkg.name}</td>
                                <td>{pkg.description}</td>
                                <td className="text-capitalize">
                                  {pkg.type} {packageName} Package
                                </td>
                                <td>{pkg.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td>{getListItem(undefined, sortedPackages, index, updateRank)}</td>
                                <td>
                                  <Link to="#" onClick={() => setSelectedPackage(packageId)}>
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </React.Fragment>
              );
            })
          )}
        </Col>
        {!loading && selectedPackage ? (
          <PackageModal
            isOpen
            toggle={() => setSelectedPackage("")}
            packageId={selectedPackage}
            firebasePackageName={firebasePackageName}
            selectedPackage={packages[selectedPackage]}
            productSizes={productSizes}
            menuHeadings={menuHeadings}
            isNew={packages[selectedPackage] == undefined}
            additionalItemsCategories={additionalItemsCategories}
          />
        ) : null}
      </Row>
    </ContentWrapper>
  );
}
