
import { combineReducers } from 'redux';
import { BUTTON_SAVING, BUTTON_SAVED, BUTTON_ERROR, BUTTON_COMPLETE } from './ActionTypes';

const initialState = {
    saving: {},
};

function saving(state = initialState.saving, action) {
    switch (action.type) {
        case BUTTON_SAVING:
            return {
                ...state,
                [action.id]: { saved: false, saving: true },
            };
        case BUTTON_SAVED:
            return {
                ...state,
                [action.id]: { saved: true, saving: false },
            };
        case BUTTON_ERROR:
            return {
                ...state,
                [action.id]: { error: true, saving: false },
            };
        case BUTTON_COMPLETE:
            return {
                ...state,
                [action.id]: { saved: false, saving: false },
            };
        default:
            return state;
    }
}

export default combineReducers({
    saving,
});
