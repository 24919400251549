import { renderSketchControl, requiredField } from "core/react-utils";
import { isNil } from "lodash";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, CardFooter, Spinner } from "reactstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { GIFT_CERTIFICATE_SETTINGS } from "../ActionTypes";

import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

function GiftCertificateFormSettings(props) {
  // const { editorState } = useAppSelector((state) => {
  //   return {
  //     editorState: state.giftCertificates.editorState,
  //   };
  // });

  const dispatch = useDispatch();

  function validate(values) {
    const errors: any = {};
    if (values.redeemQuantity < 0 || isNil(values.redeemQuantity)) errors.redeemQuantity = "Please enter valid quantity!";
    return errors;
  }

  async function saveSettings(settings) {
    try {
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificateSettings`).update(settings);
      dispatch({ type: GIFT_CERTIFICATE_SETTINGS, settings });
    } catch (error) {
      catchExceptionCallback(error);
    }
  }

  // save(dispatch, values, buttonId) {
  //   values.redeemQuantity = Number(values.redeemQuantity);
  //   dispatch(saveSettings(values, buttonId));
  // }

  const { settings } = props;

  return (
    <Form
      initialValues={settings}
      onSubmit={(values) => saveSettings(values)}
      validate={validate}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className="card-default">
              <CardBody>
                <div className="form-group">
                  <label htmlFor="email-background-color1">Email Header Colour</label>
                  <Field id="email-background-color1" name="emailBackgroundColor" component={renderSketchControl} onChangeColor={(color) => form.change("emailBackgroundColor", color.rgb)} />
                </div>
                <div className="form-group">
                  <label htmlFor="email-button-color1">Email Button Colour</label>
                  <Field id="email-button-color1" name="emailButtonColor" component={renderSketchControl} onChangeColor={(color) => form.change("emailButtonColor", color.rgb)} />
                </div>
                <div className="form-group">
                  <Field id="redeem-quantity" name="redeemQuantity" label="Redeem Quantity" helpText={`For unlimited redeems, input "0"`} type="number" component={requiredField as any} />
                </div>
                <div className="form-group">
                  <label htmlFor="wysiwig-editor1">Terms &amp; Conditions</label>
                  <Field id="terms-conditions" name="terms">
                    {({ input: { onChange, value } }) => {
                      return <SunEditor setContents={value} setOptions={{ height: 200, buttonList: buttonList.complex }} onChange={(content) => onChange(content)} />;
                    }}
                  </Field>
                </div>
              </CardBody>
              <CardFooter>
                <Button id="gift-certificate-save" type="submit" color="success" className="float-right" disabled={submitting}>
                  Save {submitting && <Spinner size="sm" />}
                </Button>
              </CardFooter>
            </Card>
          </form>
        );
      }}
    ></Form>
  );
}

export default GiftCertificateFormSettings;
