import { GET_ROOT_MENUS, GET_ROOT_MENUS_LOADING } from "ActionTypes";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { sortBy } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Menu } from "resbutler-utils/types/Menu";

export async function updateRank(menu1: Menu, menu2: Menu, index1: number, index2: number) {
  try {
    const { client, tenantId } = getConfig();
    const update = {};
    update[`${tenantId}/menus/${menu1.id}`] = { ...menu1, order: index2 };
    update[`${tenantId}/menus/${menu2.id}`] = { ...menu2, order: index1 };
    await Promise.all([firebase.database().ref().update(update), firebase.firestore().doc(`${client}/menus/menus/${menu1.id}`).set({ order: index2 }, { merge: true }), firebase.firestore().doc(`${client}/menus/menus/${menu2.id}`).set({ order: index1 }, { merge: true })]);
  } catch (error) {
    catchExceptionCallback(error);
  }
}
const useMenus = () => {
  const { client } = getConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_ROOT_MENUS_LOADING, loading: true });
    const unsubscribe = firebase
      .firestore()
      .collection(`${client}/menus/menus`)
      .where("enabled", "==", true)
      .onSnapshot((snap) => {
        dispatch({
          type: GET_ROOT_MENUS,
          menus: sortBy(
            snap.docs.map((m) => {
              return { ...m.data(), id: m.id } as Menu;
            }) as Menu[],
            "order"
          ),
        });
        dispatch({ type: GET_ROOT_MENUS_LOADING, loading: false });
      }, catchExceptionCallback);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);
};

export default useMenus;
