import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";
import { TableClasses } from "resbutler-utils/types/Box";

const useTableClasses = (restaurantId: string): [boolean, TableClasses] => {
  const [loading, setLoading] = useState(true);
  const [tableClasses, setTableClasses] = useState<TableClasses>({});
  useEffect(() => {
    async function loadAsync() {
      try {
        const { tenantId } = getConfig();
        setLoading(true);
        const snap = await firebase.database().ref(`${tenantId}/tableClasses`).orderByChild("restaurantId").equalTo(restaurantId).get();
        setTableClasses(pickBy(snap.val(), (t) => t.enabled));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    loadAsync();
  }, []);

  return [loading, tableClasses];
};

export default useTableClasses;
