import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { capitalize, chain, forEach, isEmpty, map, round, sumBy } from "lodash";
import moment from "moment";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Select from "react-select";
import { Button, Card, Col, Row, Spinner, Table } from "reactstrap";

import { useAppSelector } from "components/Hooks/hooks";
import useAreas from "components/Hooks/useAreas";
import useMeals from "components/Hooks/useMeals";
import useMenuHeadings from "components/Hooks/useMenuHeadings";
import useTableClasses from "components/Hooks/useTableClasses";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import ManagementPeriodSelector from "components/explorer/ManagementPeriodSelector";
import { catchExceptionCallback, getConfig } from "core/utils";
import { Menu } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";

import tableToCSV from "explorer/tableToCSV";
import tableToPDF from "explorer/tableToPDF";

import { calendarTypes, displayAmount, displayUnit, getSubTotal, getSubTotalPercent, getTotal, getTotalPercent, managementCalendarModes } from "../utils";
import useFilterTreeData from "./useFilterData";
import { IReportByMenuPayload, normalizeResults } from "./utils";

const RevenueReport = () => {
  const [results, setResults] = useState([]);

  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const products = useAppSelector((state) => state.root.products);

  const menusRaw = useAppSelector((state) => state.root.menus);
  const menus = chain(menusRaw)
    .filter((menu: Menu) => menu.enabled && menu.restaurantId === restaurantId)
    .value();
  const menuOptions = chain(menus)
    .filter((menu: Menu) => {
      return Object.keys(menu?.menuPayments?.fixed?.productIds || {}).length > 0;
    })
    .map((menu: Menu) => ({
      label: menu.name,
      value: menu.id,
    }))
    .value();

  const dateStore = useRef(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const [, menuHeadings] = useMenuHeadings(restaurantId);
  const [, areas] = useAreas(restaurantId);
  const [, meals] = useMeals(restaurantId);
  const [, tableClasses] = useTableClasses(restaurantId);

  const filterTreeData = useFilterTreeData(meals, areas, tableClasses);
  const defaultFilterTreeData = [];

  // normalize filter tree default data
  filterTreeData.forEach((item) => {
    defaultFilterTreeData.push(item.value);

    if (item.children) {
      item.children.forEach((item1) => {
        defaultFilterTreeData.push(item1.value);
      });
    }
  });

  const [menuFilterValue, setMenuFilterValue] = useState("");
  const [menuFilterValueError, setMenuFilterValueError] = useState("");
  const [filterTreeDataValue, setFilterTreeDataValue] = useState(defaultFilterTreeData);

  const [calendar, setCalendar] = useState({});
  const { client, resbutlerApis } = getConfig();

  const initialValues = useMemo(
    () => ({
      restaurants: [],
      calendar: calendarTypes.dateRange.value,
      filterTree: defaultFilterTreeData,
    }),
    []
  );

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        const calendarSnap = await firebase.firestore().collection(`${client}/calendar/calendar`).get();
        setCalendar(buildDataFromDocs(calendarSnap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getCalendarData();
  }, []);

  const loadReports = async (values) => {
    if (!menuFilterValue) {
      setMenuFilterValueError("This is required.");
      return true;
    } else {
      setMenuFilterValueError("");
    }

    try {
      const params = {
        // menuIds: '32tJ2l2NGmqaNiGO1dmg',
        menuIds: menuFilterValue,
        calendar: values.calendar,
        restaurantId,
        clientId: client,
      } as IReportByMenuPayload;

      if (values.calendar === calendarTypes.management.value) {
        params.firstPeriod = values?.firstPeriod;
        params.secondPeriod = values?.secondPeriod;
        params.mode = values?.mode;

        dateStore.current = {
          firstPeriod: values?.firstPeriod,
          secondPeriod: values.secondPeriod,
        };
      } else {
        params.firstDateFrom = values.firstDateFrom;
        params.firstDateTo = values.firstDateTo;
        params.secondDateFrom = values.secondDateFrom;
        params.secondDateTo = values.secondDateTo;

        dateStore.current = {
          firstDateFrom: values.firstDateFrom,
          firstDateTo: values.firstDateTo,
          secondDateFrom: values.secondDateFrom,
          secondDateTo: values.secondDateTo,
        };
      }

      setResults([]);
      const API_URL = `${resbutlerApis}/bq/revenue-by-menu-gain-or-loss`;
      const response = await axios.get(API_URL, { params });
      setResults(response.data);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToCSV = async (e) => {
    try {
      e.preventDefault();
      await tableToCSV(tableRef.current, ",", "fixed-price-menus-report");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToPDF = async (e) => {
    try {
      e.preventDefault();
      await tableToPDF(tableRef.current, "Fixed Price Menus (Gain/Loss)", "fixed-price-menus-report");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const validate = (values: IReportByMenuPayload) => {
    const errors = {} as IReportByMenuPayload;

    if (values) {
      if (values.calendar === calendarTypes.management.value) {
        if (!values.mode) {
          errors.mode = "This is required.";
        }

        if (!values.firstPeriod) {
          errors.firstPeriod = "Please input first period values.";
        } else {
          if (!values.firstPeriod.year) {
            errors.firstPeriod = "Please input first period values.";
          }

          if (values.mode === managementCalendarModes.week.value && !values.firstPeriod.week) {
            errors.firstPeriod = "Please input first period values.";
          }

          if (values.mode === managementCalendarModes.month.value && !values.firstPeriod.month) {
            errors.firstPeriod = "Please input first period values.";
          }
        }
      } else {
        if (!values.firstDateFrom || !values.firstDateTo) {
          errors.firstDateFrom = "Please input first period date range.";
        }
      }
    }

    return errors;
  };

  if (isEmpty(menus) || isEmpty(menuHeadings)) {
    return null;
  }

  const normalizedResults = normalizeResults(
    results,
    menus.find((m) => m.id === menuFilterValue),
    filterTreeData,
    filterTreeDataValue
  );

  const getTotalLinesFromGroupings = (item) => {
    let linesFromFoodInclusions = [];
    forEach(item["Food"], (menuHeadings) => {
      const tempLines = [];

      forEach(menuHeadings, (linesByProductId) => {
        tempLines.push(...linesByProductId);
      });

      linesFromFoodInclusions = [...linesFromFoodInclusions, ...tempLines];
    });

    let linesFromDrinkInclusions = [];
    forEach(item["Beveragess"], (menuHeadings) => {
      const tempLines = [];

      forEach(menuHeadings, (linesByProductId) => {
        tempLines.push(...linesByProductId);
      });

      linesFromDrinkInclusions = [...linesFromDrinkInclusions, ...tempLines];
    });

    return [...linesFromFoodInclusions, ...linesFromDrinkInclusions];
  };

  const getGrandTotalLinesFromFixedProducts = () => {
    const temp = { ...normalizedResults };
    delete temp["additional-items"];
    let lines = [];

    forEach(temp, (item) => {
      lines = [...lines, ...getTotalLinesFromGroupings(item)];
    });

    return lines;
  };

  const getGrandTotalLinesFromAdditionalItems = () => {
    let linesFromFood = [];

    forEach(normalizedResults["additional-items"]?.["Food"], (menuHeadings) => {
      const tempLines = [];

      forEach(menuHeadings, (linesByProductId) => {
        tempLines.push(...linesByProductId);
      });

      linesFromFood = [...linesFromFood, ...tempLines];
    });

    let linesFromDrinks = [];
    forEach(normalizedResults["additional-items"]?.["Beveragess"], (menuHeadings) => {
      const tempLines = [];

      forEach(menuHeadings, (linesByProductId) => {
        tempLines.push(...linesByProductId);
      });

      linesFromDrinks = [...linesFromDrinks, ...tempLines];
    });

    return [...linesFromFood, ...linesFromDrinks];
  };

  // raw lines collection for total calculations
  const linesCollectionFromFixedProducts = getGrandTotalLinesFromFixedProducts();
  const linesCollectionFromAdditionalItems = getGrandTotalLinesFromAdditionalItems();
  const allLinesCollection = [...linesCollectionFromFixedProducts, ...linesCollectionFromAdditionalItems];

  const firstPeriodStandardTotalPrice = sumBy(linesCollectionFromFixedProducts, (l) => l.firstPeriodUnit * l.firstPeriodStandardValuePrice);
  const secondPeriodStandardTotalPrice = sumBy(linesCollectionFromFixedProducts, (l) => l.secondPeriodUnit * l.firstPeriodStandardValuePrice);
  const totalFirstPeriodGainAndLoss = firstPeriodStandardTotalPrice - getTotal(linesCollectionFromFixedProducts, "firstPeriodStandardTotalPrice");
  const totalSecondPeriodGainAndLoss = secondPeriodStandardTotalPrice - getTotal(linesCollectionFromFixedProducts, "secondPeriodStandardTotalPrice");

  const grandTotalFixedProductsVariancePercentage = getTotalPercent(linesCollectionFromFixedProducts, "varianceAmountPercent");
  const grandTotalFixedProductsVarianceUnitPercentage = getTotalPercent(linesCollectionFromFixedProducts, "varianceUnitPercent");

  const grandTotalAdditionalItemsVariancePercentage = getTotalPercent(linesCollectionFromAdditionalItems, "varianceAmountPercent");
  const grandTotalAdditionalItemsVarianceUnitPercentage = getTotalPercent(linesCollectionFromAdditionalItems, "varianceUnitPercent");

  return (
    <ContentWrapper>
      <ContentHeading headerText="Fixed Price Menus (Gain/Loss)" showRestaurants />

      <Card className="card-default py-3 px-4">
        <Row>
          <Col sm={12} lg={4} className="py-2">
            <Row className="mb-4">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">
                  <b>Menus</b>
                </label>
              </Col>
              <Col sm={12} lg={8}>
                <Select
                  closeMenuOnSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  blurInputOnSelect={false}
                  options={map(menuOptions)}
                  value={menuOptions?.[menuFilterValue]}
                  onChange={(e) => {
                    if (e?.value) {
                      setResults([]);
                      setMenuFilterValue(e.value);
                      setMenuFilterValueError("");
                    }
                  }}
                  placeholder="Please Select"
                />

                {menuFilterValueError ? <div className="text-danger">{menuFilterValueError}</div> : null}
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">Filter</label>
              </Col>

              <Col sm={12} lg={8}>
                <TreeSelect
                  className="form-control tree-select"
                  style={{ width: "100%" }}
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ height: 250, overflow: "auto" }}
                  dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                  onDropdownVisibleChange={() => true}
                  treeLine
                  placeholder={<i>None</i>}
                  multiple
                  showIcon={false}
                  maxTagTextLength={25}
                  autoClearSearchValue
                  treeNodeFilterProp="title"
                  treeData={filterTreeData}
                  treeCheckable
                  treeDefaultExpandAll
                  value={filterTreeDataValue}
                  showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={20}
                  onChange={(ids) => setFilterTreeDataValue(ids)}
                  maxTagPlaceholder={(valueList) => {
                    return `+${valueList.length}`;
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Form
            initialValues={initialValues}
            onSubmit={loadReports}
            validate={validate}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, values, form }) => {
              return (
                <>
                  <Col sm={12} lg={8} className="total-cells divider-top-cols">
                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center h-100 gap-sm">
                      <div className="mb-3 flex align-items-center gap-md">
                        <label className="m-0">
                          <b>Calendar</b>
                        </label>

                        <div className="d-flex gap-xl flex-wrap">
                          <Field name="calendar">
                            {({ input: { value, onChange } }) => (
                              <div className="form-group gap-md d-flex m-0">
                                {Object.values(calendarTypes).map((option, index) => (
                                  <label key={`render-units-${index}`} className="radio c-radio m-0 d-flex align-items-center">
                                    <input type="radio" value={option.value} checked={option.value === value} onChange={() => onChange(option.value)} />
                                    <span className="fa fa-circle" />
                                    {option.label}
                                  </label>
                                ))}
                              </div>
                            )}
                          </Field>

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="d-flex align-items-center gap-md">
                              <label className="mb-0">Mode</label>

                              <div style={{ width: 160 }}>
                                <Field name="mode">
                                  {({ input: { value, onChange }, meta }) => (
                                    <>
                                      <Select
                                        isSearchable={false}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        blurInputOnSelect={false}
                                        options={map(managementCalendarModes, (item) => item)}
                                        value={managementCalendarModes?.[value]}
                                        onChange={(e) => {
                                          onChange(e.value);

                                          form.change("firstPeriod", undefined);
                                          form.change("secondPeriod", undefined);
                                        }}
                                        placeholder="Select mode"
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex align-items-end gap-lg justify-content-between flex-wrap">
                        <div className="d-flex flex-grow-1 align-items-end gap-md">
                          {values.calendar == calendarTypes.dateRange.value ? (
                            <div className="d-flex align-items-start gap-md flex-grow-1">
                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="startDate" className="mb-0 text-nowrap">
                                  First Period
                                </label>

                                <Field name="firstDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="startDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.firstDateFrom, "YYYY-MM-DD").toDate(), moment(values.firstDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("firstDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("firstDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="endDate" className="mb-0 text-nowrap">
                                  Second Period
                                </label>

                                <Field name="secondDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="endDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.secondDateFrom, "YYYY-MM-DD").toDate(), moment(values.secondDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("secondDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("secondDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="flex-grow-1">
                              <div className="form-group mb-2 flex-grow-1">
                                <Field name="firstPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="startDate" className="mb-0">
                                          First Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 flex-grow-1">
                                <Field name="secondPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="endDate" className="mb-0">
                                          Second Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          <Button id="category-save" type="submit" className="fixed-width-btn d-flex justify-content-center align-items-center gap-sm" color="primary" disabled={submitting}>
                            {submitting ? <Spinner size="sm" /> : null} Generate Report
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Col>
                </>
              );
            }}
          />
        </Row>
      </Card>

      {isEmpty(allLinesCollection) ? null : (
        <>
          <div className="d-flex align-items-center gap-sm justify-content-end mb-2">
            <Button onClick={handleExportToCSV} type="button" color="primary">
              Download CSV
            </Button>
            <Button onClick={handleExportToPDF} type="button" color="primary">
              Download PDF
            </Button>
          </div>

          <Card className="card-default py-2 px-4 overflow-auto">
            <Table borderless className="explorer-table" innerRef={tableRef}>
              <thead>
                <tr>
                  <td colSpan={2} width="280px" className="divider-top divider-left text-center text-decoration-underline">
                    {menus.find((m) => m.id === menuFilterValue)?.name}
                  </td>

                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    {dateStore.current?.firstDateTo ? (
                      <>
                        {moment(dateStore.current.firstDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.firstDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.firstPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.firstPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.firstPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.firstPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.firstPeriod?.year ? `${calendar[dateStore.current.firstPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    {dateStore.current?.secondDateTo ? (
                      <>
                        {moment(dateStore.current.secondDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.secondDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.secondPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.secondPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.secondPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.secondPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.secondPeriod?.year ? `${calendar[dateStore.current.secondPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    Quantity
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    Amount
                  </td>
                </tr>
              </thead>

              <tbody>
                <>
                  {map(normalizedResults, (inclusionsItems, key: string) => {
                    if (key !== "additional-items") {
                      const fixedProduct = products.find((p) => p.id === key);
                      const lines = getTotalLinesFromGroupings(inclusionsItems);

                      const firstPeriodTotalQuantity = sumBy(lines, "firstPeriodUnit");
                      const secondPeriodTotalQuantity = sumBy(lines, "secondPeriodUnit");

                      const firstPeriodStandardTotalPrice = sumBy(lines, (l) => l.firstPeriodUnit * l.firstPeriodStandardValuePrice);
                      const secondPeriodStandardTotalPrice = sumBy(lines, (l) => l.secondPeriodUnit * l.secondPeriodStandardValuePrice);

                      const totalFirstPeriodGainAndLoss = firstPeriodStandardTotalPrice - getTotal(lines, "firstPeriodStandardTotalPrice");
                      const totalSecondPeriodGainAndLoss = secondPeriodStandardTotalPrice - getTotal(lines, "secondPeriodStandardTotalPrice");

                      const totalVariancePercentage = getTotalPercent(lines, "varianceAmountPercent");
                      const totalVarianceUnitPercentage = getTotalPercent(lines, "varianceUnitPercent");

                      return (
                        <Fragment>
                          <tr>
                            <td colSpan={2} className="divider-left font-weight-bold text-decoration-underline">
                              {fixedProduct?.name}
                            </td>

                            <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Quantity</td>
                            <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Amount ($)</td>

                            <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Quantity</td>
                            <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Amount ($)</td>

                            <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Variance (Qty)</td>
                            <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Variance (%)</td>

                            <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Variance ($)</td>
                            <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Variance (%)</td>
                          </tr>

                          <tr className="small">
                            <td colSpan={2} className="pl-4 font-weight-bold divider-left">
                              Revenue
                            </td>

                            <td className="divider-right--light divider-left text-right">{displayUnit(firstPeriodTotalQuantity)}</td>
                            <td className="divider-right divider-left text-right">{displayAmount(firstPeriodStandardTotalPrice)}</td>

                            <td className="divider-right--light divider-left text-right">{displayUnit(secondPeriodTotalQuantity)}</td>
                            <td className="divider-right divider-left text-right">{displayAmount(secondPeriodStandardTotalPrice)}</td>

                            <td className="divider-right--light divider-left text-right">*</td>
                            <td className="divider-right divider-left text-right">*</td>

                            <td className="divider-right--light divider-left text-right">*</td>
                            <td className="divider-right divider-left text-right">*</td>
                          </tr>

                          <tr className="small">
                            <td colSpan={2} className="pl-4 font-weight-bold divider-left">
                              Inclusions dispensed at standard price
                            </td>

                            <td className="divider-right--light divider-left text-right" />
                            <td className="divider-right divider-left text-right" />

                            <td className="divider-right--light divider-left text-right" />
                            <td className="divider-right divider-left text-right" />

                            <td className="divider-right--light divider-left text-right" />
                            <td className="divider-right divider-left text-right" />

                            <td className="divider-right--light divider-left text-right" />
                            <td className="divider-right divider-left text-right" />
                          </tr>

                          {map(inclusionsItems, (linesItemsByMenuHeading: any, key: string) => {
                            return (
                              <Fragment>
                                <tr className="small">
                                  <td colSpan={2} className="pl-4 font-weight-bold divider-left text-decoration-underline">
                                    &nbsp; {key === "Food" ? "Food Inclusions" : "Drink Inclusions"}
                                  </td>

                                  <td className="divider-right--light divider-left text-right" />
                                  <td className="divider-right divider-left text-right" />

                                  <td className="divider-right--light divider-left text-right" />
                                  <td className="divider-right divider-left text-right" />

                                  <td className="divider-right--light divider-left text-right" />
                                  <td className="divider-right divider-left text-right" />

                                  <td className="divider-right--light divider-left text-right" />
                                  <td className="divider-right divider-left text-right" />
                                </tr>

                                {map(linesItemsByMenuHeading, (linesItemsByProductId, menuHeadingId) => {
                                  const menuHeadingName = menuHeadings?.[menuHeadingId]?.name || menuHeadingId;

                                  const subTotalVariancePercentage = getSubTotalPercent(linesItemsByProductId, "varianceAmountPercent");
                                  const subTotalVarianceUnitPercentage = getSubTotalPercent(linesItemsByProductId, "varianceUnitPercent");

                                  return (
                                    <Fragment>
                                      {menuHeadingName !== "null" ? (
                                        <tr className="small">
                                          <td colSpan={2} className="pl-4 font-weight-bold divider-left text-decoration-underline">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {menuHeadingName}
                                          </td>

                                          <td className="divider-right--light divider-left text-right" />
                                          <td className="divider-right divider-left text-right" />

                                          <td className="divider-right--light divider-left text-right" />
                                          <td className="divider-right divider-left text-right" />

                                          <td className="divider-right--light divider-left text-right" />
                                          <td className="divider-right divider-left text-right" />

                                          <td className="divider-right--light divider-left text-right" />
                                          <td className="divider-right divider-left text-right" />
                                        </tr>
                                      ) : null}

                                      {map(linesItemsByProductId, (lineItems, productId) => {
                                        const product = products.find((p) => p.id === productId);

                                        const totalVariancePercentage = getTotalPercent(lineItems, "varianceAmountPercent");
                                        const totalVarianceUnitPercentage = getTotalPercent(lineItems, "varianceUnitPercent");

                                        return (
                                          <Fragment>
                                            <tr className="small">
                                              <td colSpan={2} className="pl-4 divider-left text-decoration-underline">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {product?.name}
                                              </td>

                                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "firstPeriodUnit"))}</td>
                                              <td className="divider-right divider-left text-right">{displayAmount(getTotal(lineItems, "firstPeriodStandardTotalPrice"))}</td>

                                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "secondPeriodUnit"))}</td>
                                              <td className="divider-right divider-left text-right">{displayAmount(getTotal(lineItems, "secondPeriodStandardTotalPrice"))}</td>

                                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "varianceUnit"))}</td>
                                              <td className="divider-right divider-left text-right">{totalVarianceUnitPercentage ? round(totalVarianceUnitPercentage, 2) + "%" : null}</td>

                                              <td className="divider-right--light divider-left text-right">{displayAmount(getTotal(lineItems, "varianceAmount")) || null}</td>
                                              <td className="divider-right divider-left text-right">{totalVariancePercentage ? round(totalVariancePercentage, 2) + "%" : null}</td>
                                            </tr>
                                          </Fragment>
                                        );
                                      })}

                                      <tr className="small">
                                        <td className="pl-4 divider-left">&nbsp;&nbsp;</td>
                                        <td className="pl-0 total-cells">
                                          <i>Sub-total</i>
                                        </td>

                                        <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "firstPeriodUnit"))}</td>
                                        <td className="total-cells divider-top text-right">{displayAmount(getSubTotal(linesItemsByProductId, "firstPeriodStandardTotalPrice"))}</td>

                                        <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "secondPeriodUnit"))}</td>
                                        <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getSubTotal(linesItemsByProductId, "secondPeriodStandardTotalPrice"))}</td>

                                        <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "varianceUnit"))}</td>
                                        <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVarianceUnitPercentage ? round(subTotalVarianceUnitPercentage, 2) + "%" : null}</td>

                                        <td className="total-cells divider-top divider-right--light divider-left text-right">{displayAmount(getSubTotal(linesItemsByProductId, "varianceAmount"))}</td>
                                        <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVariancePercentage ? round(subTotalVariancePercentage, 2) + "%" : null}</td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}
                              </Fragment>
                            );
                          })}

                          <>
                            <tr className="small">
                              <td colSpan={2} className="pl-4 font-weight-bold divider-left">
                                <i>Total Inclusions</i>
                              </td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "firstPeriodUnit"))}</td>
                              <td className="total-cells divider-top text-right">{displayAmount(getTotal(lines, "firstPeriodStandardTotalPrice"))}</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "secondPeriodUnit"))}</td>
                              <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getTotal(lines, "secondPeriodStandardTotalPrice"))}</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "varianceUnit"))}</td>
                              <td className="total-cells divider-top divider-right divider-left text-right">{totalVarianceUnitPercentage ? round(totalVarianceUnitPercentage, 2) + "%" : null}</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">{displayAmount(getTotal(lines, "varianceAmount"))}</td>
                              <td className="total-cells divider-top divider-right divider-left text-right">{totalVariancePercentage ? round(totalVariancePercentage, 2) + "%" : null}</td>
                            </tr>

                            <tr className="small">
                              <td colSpan={2} className="pl-4 font-weight-bold divider-left">
                                <i>Gain/(loss)</i>
                              </td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right"></td>
                              <td className={`total-cells divider-top text-right ${totalFirstPeriodGainAndLoss > 0 ? null : "text-danger"}`}>{displayAmount(totalFirstPeriodGainAndLoss)}</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right"></td>
                              <td className={`total-cells divider-top text-right ${totalSecondPeriodGainAndLoss > 0 ? null : "text-danger"}`}>{displayAmount(totalSecondPeriodGainAndLoss)}</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                              <td className="total-cells divider-top divider-right divider-left text-right">*</td>

                              <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                              <td className="total-cells divider-top divider-right divider-left text-right">*</td>
                            </tr>
                          </>
                        </Fragment>
                      );
                    }

                    return null;
                  })}

                  <>
                    <tr className="small">
                      <td colSpan={2} className="font-weight-bold divider-left">
                        <i>Total Revenue</i>
                      </td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromFixedProducts, "firstPeriodUnit"))}</td>
                      <td className="total-cells divider-top text-right">{displayAmount(firstPeriodStandardTotalPrice)}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromFixedProducts, "secondPeriodUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(secondPeriodStandardTotalPrice)}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">*</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">*</td>
                    </tr>

                    <tr className="small">
                      <td colSpan={2} className="font-weight-bold divider-left">
                        <i>Grand Total Inclusions</i>
                      </td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromFixedProducts, "firstPeriodUnit"))}</td>
                      <td className="total-cells divider-top text-right">{displayAmount(getTotal(linesCollectionFromFixedProducts, "firstPeriodStandardTotalPrice"))}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromFixedProducts, "secondPeriodUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getTotal(linesCollectionFromFixedProducts, "secondPeriodStandardTotalPrice"))}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromFixedProducts, "varianceUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{grandTotalFixedProductsVarianceUnitPercentage ? round(grandTotalFixedProductsVarianceUnitPercentage, 2) + "%" : null}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{getTotal(linesCollectionFromFixedProducts, "variantAmount")}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{grandTotalFixedProductsVariancePercentage ? round(grandTotalFixedProductsVariancePercentage, 2) + "%" : null}</td>
                    </tr>

                    <tr className="small">
                      <td colSpan={2} className="font-weight-bold divider-left">
                        <i>Gain/(loss)</i>
                      </td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right"></td>
                      <td className={`total-cells divider-top text-right ${totalFirstPeriodGainAndLoss > 0 ? null : "text-danger"}`}>{displayAmount(totalFirstPeriodGainAndLoss)}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right"></td>
                      <td className={`total-cells divider-top text-right ${totalSecondPeriodGainAndLoss > 0 ? null : "text-danger"}`}>{displayAmount(totalSecondPeriodGainAndLoss)}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">*</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">*</td>
                    </tr>
                  </>
                </>

                <>
                  <tr>
                    <td colSpan={2} className="divider-left font-weight-bold text-decoration-underline">
                      Additional Paid Items
                    </td>

                    <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline"></td>
                    <td className="divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline"></td>

                    <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline"></td>
                    <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline"></td>

                    <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline"></td>
                    <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline"></td>

                    <td className="small divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline"></td>
                    <td className="small divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline"></td>
                  </tr>

                  {map(normalizedResults["additional-items"], (linesItemsByMenuHeading, key) => {
                    return (
                      <>
                        <tr className="small">
                          <td colSpan={2} className="pl-4 font-weight-bold divider-left text-decoration-underline">
                            &nbsp; {key === "Food" ? "Food" : "Drinks"}
                          </td>

                          <td className="divider-right--light divider-left text-right" />
                          <td className="divider-right divider-left text-right" />

                          <td className="divider-right--light divider-left text-right" />
                          <td className="divider-right divider-left text-right" />

                          <td className="divider-right--light divider-left text-right" />
                          <td className="divider-right divider-left text-right" />

                          <td className="divider-right--light divider-left text-right" />
                          <td className="divider-right divider-left text-right" />
                        </tr>

                        {map(linesItemsByMenuHeading, (linesItemsByProductId, menuHeadingId) => {
                          const menuHeadingName = menuHeadings?.[menuHeadingId]?.name || menuHeadingId;

                          const subTotalVariancePercentage = getSubTotalPercent(linesItemsByProductId, "varianceAmountPercent");
                          const subTotalVarianceUnitPercentage = getSubTotalPercent(linesItemsByProductId, "varianceUnitPercent");

                          return (
                            <>
                              <tr className="small">
                                <td colSpan={2} className="pl-4 font-weight-bold divider-left text-decoration-underline">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {capitalize(menuHeadingName)}
                                </td>

                                <td className="divider-right--light divider-left text-right" />
                                <td className="divider-right divider-left text-right" />

                                <td className="divider-right--light divider-left text-right" />
                                <td className="divider-right divider-left text-right" />

                                <td className="divider-right--light divider-left text-right" />
                                <td className="divider-right divider-left text-right" />

                                <td className="divider-right--light divider-left text-right" />
                                <td className="divider-right divider-left text-right" />
                              </tr>

                              {map(linesItemsByProductId, (lineItems, productId) => {
                                const product = products.find((p) => p.id === productId);

                                const totalVariancePercentage = getTotalPercent(lineItems, "varianceAmountPercent");
                                const totalVarianceUnitPercentage = getTotalPercent(lineItems, "varianceUnitPercent");

                                return (
                                  <Fragment>
                                    <tr className="small">
                                      <td colSpan={2} className="pl-4 divider-left text-decoration-underline">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {product?.name}
                                      </td>

                                      <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "firstPeriodUnit"))}</td>
                                      <td className="divider-right divider-left text-right">{displayAmount(getTotal(lineItems, "firstPeriodAmount"))}</td>

                                      <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "secondPeriodUnit"))}</td>
                                      <td className="divider-right divider-left text-right">{displayAmount(getTotal(lineItems, "secondPeriodAmount"))}</td>

                                      <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lineItems, "varianceUnit"))}</td>
                                      <td className="divider-right divider-left text-right">{totalVarianceUnitPercentage ? round(totalVarianceUnitPercentage, 2) + "%" : null}</td>

                                      <td className="divider-right--light divider-left text-right">{getTotal(lineItems, "varianceAmount")}</td>
                                      <td className="divider-right divider-left text-right">{totalVariancePercentage ? round(totalVariancePercentage, 2) + "%" : null}</td>
                                    </tr>
                                  </Fragment>
                                );
                              })}

                              <tr className="small">
                                <td className="pl-4 divider-left">&nbsp;</td>
                                <td className="pl-0 total-cells">
                                  <i>Sub-total</i>
                                </td>

                                <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "firstPeriodUnit"))}</td>
                                <td className="total-cells divider-top text-right">{displayAmount(getSubTotal(linesItemsByProductId, "firstPeriodAmount"))}</td>

                                <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "secondPeriodUnit"))}</td>
                                <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getSubTotal(linesItemsByProductId, "secondPeriodUnit"))}</td>

                                <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesItemsByProductId, "varianceUnit"))}</td>
                                <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVarianceUnitPercentage ? round(subTotalVarianceUnitPercentage, 2) + "%" : null}</td>

                                <td className="total-cells divider-top divider-right--light divider-left text-right">{getSubTotal(linesItemsByProductId, "varianceAmount")}</td>
                                <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVariancePercentage ? round(subTotalVariancePercentage, 2) + "%" : null}</td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  })}

                  <>
                    <tr>
                      <td colSpan={2} className="font-weight-bold divider-left">
                        <i>Total Paid Items</i>
                      </td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromAdditionalItems, "firstPeriodUnit"))}</td>
                      <td className="total-cells divider-top text-right">{displayAmount(getTotal(linesCollectionFromAdditionalItems, "firstPeriodAmount"))}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromAdditionalItems, "secondPeriodUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getTotal(linesCollectionFromAdditionalItems, "secondPeriodAmount"))}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getTotal(linesCollectionFromAdditionalItems, "varianceUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{grandTotalAdditionalItemsVarianceUnitPercentage ? round(grandTotalAdditionalItemsVarianceUnitPercentage, 2) + "%" : null}</td>

                      <td className="total-cells divider-top divider-right--light divider-left text-right">{getTotal(linesCollectionFromAdditionalItems, "varianceAmount")}</td>
                      <td className="total-cells divider-top divider-right divider-left text-right">{grandTotalAdditionalItemsVariancePercentage ? round(grandTotalAdditionalItemsVariancePercentage, 2) + "%" : null}</td>
                    </tr>

                    <tr>
                      <td colSpan={2} className="font-weight-bold divider-left divider-bottom">
                        <i>Grand Total Revenue</i>
                      </td>

                      <td className="total-cells divider-top divider-right--light divider-bottom divider-left text-right">{displayUnit(getTotal(allLinesCollection, "firstPeriodUnit"))}</td>
                      <td className="total-cells divider-top divider-bottom text-right">{displayAmount(getTotal(allLinesCollection, "firstPeriodAmount") + firstPeriodStandardTotalPrice)}</td>

                      <td className="total-cells divider-top divider-right--light divider-bottom divider-left text-right">{displayUnit(getTotal(allLinesCollection, "secondPeriodUnit"))}</td>
                      <td className="total-cells divider-top divider-right divider-left divider-bottom text-right">{displayAmount(getTotal(linesCollectionFromAdditionalItems, "secondPeriodAmount") + secondPeriodStandardTotalPrice)}</td>

                      <td className="total-cells divider-top divider-right--light divider-bottom divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left divider-bottom text-right">*</td>

                      <td className="total-cells divider-top divider-right--light divider-bottom divider-left text-right">*</td>
                      <td className="total-cells divider-top divider-right divider-left divider-bottom text-right">*</td>
                    </tr>
                  </>
                </>
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueReport;
