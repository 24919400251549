import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";
import { Areas } from "resbutler-utils/types/Box";

const useAreas = (restaurantId, enabledOnly = true): [boolean, Areas] => {
  const [loading, setLoading] = useState(true);
  const [areas, setAreas] = useState(null);
  const { tenantId } = getConfig();

  const fetchData = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase.database().ref(`${tenantId}/areas`).orderByChild("restaurantId").equalTo(restaurantId).once("value");
      if (snapshot.exists()) setAreas(snapshot.val());
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantId) fetchData();
  }, [restaurantId]);

  return [loading, pickBy(areas, (m) => !enabledOnly || m.enabled)];
};

export default useAreas;
