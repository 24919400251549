import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { renderDate, renderField, requiredField } from "core/react-utils";
import { flow } from "lodash";
import { findAllLevels } from "loyalty/membership-levels/LevelAction";
import { useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { Field, Form } from "react-final-form";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Col, Row, Spinner } from "reactstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { GET_MEMBERSHIP_OFFER_BY_ID, GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE, getMembershipOfferById, saveMembershipOffer } from "../MembershipOfferAction";
import { validate } from "../MembershipOfferModel";

const MemberPromotionForm = ({ readOnly, descriptionInitial, descriptionEditor, levels, initialValues }) => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const unsubscribe = () => {
      dispatch({ type: GET_MEMBERSHIP_OFFER_BY_ID, membershipOffer: null });
    };
    unsubscribe();
    dispatch(findAllLevels());
    if (params.id) dispatch(getMembershipOfferById(params.id));
    return unsubscribe;
  }, []);

  return (
    <ContentWrapper>
      <Form<any>
        initialValues={initialValues}
        keepDirtyOnReinitialize
        validate={validate}
        onSubmit={(values) =>
          dispatch(
            saveMembershipOffer(
              {
                ...values,
                description: descriptionEditor,
              },
              params.id,
              "membershipOffer-save"
            )
          )
        }
        render={({ handleSubmit, submitting, form, values }) => (
          <>
            <ContentHeading parentText="Loyalty" headerText="Member Offers" subHeaderText="Member Promotions" subHeaderHref="/loyalty/members-offers/member-promotions/" subText={values && values.name ? values.name : "New Member Promotion"} />
            <Row>
              <Col lg={8} sm={12}>
                <Card className="card-default">
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <Field id="name" name="name" type="text" disabled={readOnly} component={requiredField as any} label="Name" className="form-group" />

                      {/* 3rd party offer checkbox */}
                      <div className="checkbox c-checkbox">
                        <label className="mb-2" htmlFor="thirdPartyOffer">
                          <Field id="thirdPartyOffer" name="thirdPartyOffer" component="input" type="checkbox" />
                          <span className="fa fa-check" />
                          Third Party Offer
                        </label>
                      </div>
                      {/* if third party */}
                      {values.thirdPartyOffer && <Field id="thirdPartyOfferType" name="thirdPartyOfferType" type="text" disabled={readOnly} component={renderField as any} label="Third Party" className="form-group" />}

                      {/* Rich text editors */}
                      <div className="form-group">
                        <label htmlFor="wysiwig-editor">Description</label>
                        <SunEditor
                          setContents={descriptionInitial}
                          setOptions={{
                            height: 200,
                            buttonList: [...buttonList.formatting, ["font", "fontSize", "align"]],
                          }}
                          onChange={(editorState) =>
                            dispatch({
                              type: GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE,
                              editorState,
                            })
                          }
                        />
                      </div>
                      <Field name="buttonText" id="buttonText" component={renderField as any} label="Button Text" className="form-group" />
                      <Field name="buttonLink" id="buttonLink" component={renderField as any} label="Button Link" className="form-group" />
                      {/* CHOOSE MULTIPLE LEVELS */}
                      <label className="mb-2">Membership Levels</label>
                      {levels.map(({ accountName: memberType, _key }) => (
                        <div
                          className="checkbox c-checkbox"
                          key={_key}
                          onClick={() => {
                            // switch
                            const existing = values.membershipLevels || [];
                            const newValue = existing.filter((x) => x !== _key);
                            if (newValue.length === existing.length) {
                              form.change("membershipLevels", [...newValue, _key]);
                            } else {
                              form.change("membershipLevels", newValue);
                            }
                          }}
                        >
                          <label className="mb-2" htmlFor={memberType}>
                            <input type="checkbox" name={`memberType[${memberType}]`} checked={values.membershipLevels && values.membershipLevels.includes(_key)} />
                            <span className="fa fa-check" />
                            {memberType}
                          </label>
                        </div>
                      ))}
                      <div className="form-group">
                        <label htmlFor="start-date">Expiry</label>
                        <Field
                          id="expiry"
                          name="expiry"
                          dateFormat="DD MMMM YYYY"
                          component={renderDate}
                          onChangeDate={(date) => {
                            let realDate = null;
                            try {
                              realDate = date.toDate();
                              // eslint-disable-next-line no-empty
                            } catch (e) {}
                            return form.change("expiry", realDate);
                          }}
                        />
                      </div>
                      <br />
                      <div className="checkbox c-checkbox">
                        <label className="mb-2" htmlFor="enabled">
                          <Field id="enabled" name="enabled" component="input" type="checkbox" />
                          <span className="fa fa-check" />
                          Enabled
                        </label>
                      </div>
                    </CardBody>

                    <CardFooter>
                      <Button id="membershipOffer-save" type="submit" color="success" className="float-right" disabled={submitting}>
                        Save {submitting && <Spinner size="sm" />}
                      </Button>

                      <div className="clearfix" />
                    </CardFooter>
                  </form>
                </Card>
              </Col>
            </Row>
          </>
        )}
      />
    </ContentWrapper>
  );
};

export default flow([
  /*
       step 1
       the most inner level. (provides description)
      */
  connect((state: any) => ({
    descriptionInitial: state.membershipOffers.membershipOffer && state.membershipOffers.membershipOffer.description ? state.membershipOffers.membershipOffer.description : "",
    descriptionEditor: state.membershipOffers.descriptionEditor,
    levels: state.levels.byId,
  })),

  /*
       step 3 (outer level)
      provide initial values from store
      */
  connect((state: any) => {
    return {
      initialValues: {
        ...state.membershipOffers.membershipOffer,
      },
    };
  }),
])(MemberPromotionForm);
