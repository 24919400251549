import { map } from "lodash";
import { useMemo } from "react";

import { Areas, TableClasses } from "resbutler-utils/types/Box";
import { Meals } from "resbutler-utils/types/Meals";
import { Menu, Packages } from "resbutler-utils/types/Menu";
import { bookingTypeFilters } from "./utils";

export const useFilterTreeData = (meals: Meals, areas: Areas, tableClasses: TableClasses, menus: Menu[], foodPackages: Packages, beveragePackages: Packages, foodBeveragePackages: Packages, bookingTypeFilterValue: string) => {
  const treeData = useMemo(() => {
    let data = [
      {
        title: "All Meal Periods",
        value: "all-meal-periods",
        children: map(meals, (meal, key) => ({
          title: meal.name,
          value: key,
        })),
      },
    ];

    if (bookingTypeFilterValue !== bookingTypeFilters["all-booking"].value) {
      data = [
        ...data,
        {
          title: "All Areas",
          value: "all-areas",
          children: map(areas, (area, key) => ({
            title: area.name,
            value: key,
          })),
        },
        {
          title: "All Table Classes",
          value: "all-table-classes",
          children: map(tableClasses, (tableClass, key) => ({
            title: tableClass.name,
            value: key,
          })),
        },
      ];
    }

    if (bookingTypeFilterValue === bookingTypeFilters["standard-booking"].value) {
      data = [
        ...data,
        {
          title: "Menus",
          value: "all-menus",
          children: map(menus, (menu) => ({
            title: menu.name,
            value: menu.id,
          })),
        },
      ];
    }

    if (bookingTypeFilterValue === bookingTypeFilters["function-booking"].value) {
      data = [
        ...data,
        {
          title: "All Food Packages",
          value: "all-food-packages",
          children: map(foodPackages, (item, key) => ({
            title: item.name,
            value: key,
          })),
        },

        {
          title: "All Beverage Packages",
          value: "all-beverage-packages",
          children: map(beveragePackages, (item, key) => ({
            title: item.name,
            value: key,
          })),
        },

        {
          title: "All Food & Beverage Packages",
          value: "all-food-beverage-packages",
          children: map(foodBeveragePackages, (item, key) => ({
            title: item.name,
            value: key,
          })),
        },
      ];
    }

    return data;
  }, [meals, areas, tableClasses]);

  return treeData;
};

export default useFilterTreeData;
