import axios from "axios";
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useAppSelector } from "components/Hooks/hooks";
import useAreas from "components/Hooks/useAreas";
import useMeals from "components/Hooks/useMeals";
import usePackages from "components/Hooks/usePackages";
import useTableClasses from "components/Hooks/useTableClasses";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import ManagementPeriodSelector from "components/explorer/ManagementPeriodSelector";
import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import _ from "lodash";
import moment from "moment/moment";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Field, Form } from "react-final-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Select from "react-select";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import { Menu } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import { calendarTypes, managementCalendarModes } from "../utils";
import useFilterTreeData from "./useFilterData";
import { IReportByCoverPayload, bookingTypeFilters, filterResults, functionTypeFilters } from "./utils";

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend);

interface ResultsLineDataset {
  label: string;
  data: number[];
  fill: boolean;
  backgroundColor?: string;
  borderColor: string;
  animation: boolean;
}

interface ResultsLineData {
  labels: string[];
  datasets: ResultsLineDataset[];
}

const CoverSummary = () => {
  const { client, resbutlerApis } = getConfig();
  const restaurantId = useAppSelector((state) => state.root.restaurantId);

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [resultsLineData, setResultsLineData] = useState<ResultsLineData>({
    labels: [],
    datasets: [],
  });

  const [loading, setLoading] = useState(false);

  const menusRaw = useAppSelector((state) => state.root.menus);
  const menus = _.chain(menusRaw)
    .filter((menu: Menu) => menu.enabled && menu.restaurantId === restaurantId)
    .value();

  const [, areas] = useAreas(restaurantId);
  const [, meals] = useMeals(restaurantId);
  const [, tableClasses] = useTableClasses(restaurantId);
  const [, foodPackages] = usePackages(restaurantId, "food");
  const [, beveragePackages] = usePackages(restaurantId, "beverage");
  const [, foodBeveragePackages] = usePackages(restaurantId, "foodbeverage");
  const [calendar, setCalendar] = useState({});

  const initialValues = React.useMemo(
    () => ({
      restaurants: [],
      calendar: calendarTypes.dateRange.value,
    }),
    []
  );

  const [bookingTypeFilterValue, setBookingTypeFilterValue] = useState(bookingTypeFilters["all-booking"].value);
  const [functionTypeFilterValue, setFunctionTypeFilterValue] = useState(functionTypeFilters["all-function"].value);

  const filterTreeData = useFilterTreeData(meals, areas, tableClasses, menus, foodPackages, beveragePackages, foodBeveragePackages, bookingTypeFilterValue);
  const defaultFilterTreeData = [];

  // normalize filter tree default data
  filterTreeData.forEach((item) => {
    defaultFilterTreeData.push(item.value);

    if (item.children) {
      item.children.forEach((item1) => {
        defaultFilterTreeData.push(item1.value);
      });
    }
  });

  const [filterTreeDataValue, setFilterTreeDataValue] = useState(defaultFilterTreeData);

  React.useEffect(() => {
    setFilterTreeDataValue(defaultFilterTreeData);
  }, [bookingTypeFilterValue]);

  React.useEffect(() => {
    const getCalendarData = async () => {
      try {
        const calendarSnap = await firebase.firestore().collection(`${client}/calendar/calendar`).get();
        setCalendar(buildDataFromDocs(calendarSnap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getCalendarData();
  }, []);

  const loadReports = async (values) => {
    try {
      setLoading(true);

      const params = {
        restaurantId,
        clientId: client,
        bookingType: bookingTypeFilterValue,
        calendar: values.calendar,
      } as IReportByCoverPayload;

      if (values.calendar === calendarTypes.management.value) {
        params.mode = values.mode;
        params.period = values.period;
      } else {
        params.startDate = values.startDate;
        params.endDate = values.endDate;
      }

      const API_URL = `${resbutlerApis}/bq/revenue-by-cover-summary`;
      const response = await axios.get(API_URL, { params });
      const results = response.data;
      const filteredResults = filterResults(results, filterTreeData, filterTreeDataValue, bookingTypeFilterValue, functionTypeFilterValue);
      setResults(results);
      setFilteredResults(filteredResults);
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  };

  const validate = (values: IReportByCoverPayload) => {
    const errors = {} as IReportByCoverPayload;

    if (values) {
      if (values.calendar === calendarTypes.management.value) {
        if (!values.mode) {
          errors.mode = "This is required.";
        }

        if (!values.period) {
          errors.period = "Please input time period values.";
        } else {
          if (!values.period.year) {
            errors.period = "Please input time period values.";
          }

          if (values.mode === managementCalendarModes.week.value && !values.period.week) {
            errors.period = "Please input time period values.";
          }

          if (values.mode === managementCalendarModes.month.value && !values.period.month) {
            errors.period = "Please input time period values.";
          }
        }
      } else {
        if (!values.startDate || !values.endDate) {
          errors.startDate = "Please input time period date range.";
        }
      }
    }

    return errors;
  };

  React.useEffect(() => {
    const filteredResults = filterResults(results, filterTreeData, filterTreeDataValue, bookingTypeFilterValue, functionTypeFilterValue);
    setFilteredResults(filteredResults);
  }, [filterTreeDataValue, bookingTypeFilterValue, functionTypeFilterValue]);

  React.useEffect(() => {
    if (_.isArray(filteredResults)) {
      const lineData = {} as ResultsLineData;

      const results = _.chain(filteredResults)
        .sortBy((r) => r.bookingDate.value)
        .groupBy((r) => r.bookingDate.value)
        .value();

      lineData.labels = _.map(results, (_, key) => moment(key).format("DD/MM/YYYY"));

      lineData.datasets = [
        {
          label: "Reservations",
          data: _.map(results, (r) => _.sumBy(r, (i) => i?.reservation || 0)),
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          animation: false,
        },
        {
          label: "Covers",
          data: _.map(results, (r) => _.sumBy(r, (i) => i?.cover || 0)),
          fill: false,
          borderColor: "#742774",
          animation: false,
        },
      ];

      setResultsLineData(lineData);
    }
  }, [filteredResults]);

  return (
    <ContentWrapper>
      <ContentHeading headerText="Cover Report" showRestaurants />

      <Row>
        <Col sm={12}>
          <Card className="card-default py-3 px-4">
            <Row className="align-items-baseline">
              <Col sm={12} lg={4} className="py-2">
                <Row className="mb-4">
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                    <label className="mb-0">
                      <b>Booking Type</b>
                    </label>
                  </Col>
                  <Col sm={12} lg={8}>
                    <Select
                      closeMenuOnSelect
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      blurInputOnSelect={false}
                      options={_.map(bookingTypeFilters, (item) => item)}
                      value={_.find(bookingTypeFilters, (item) => item.value === bookingTypeFilterValue)}
                      onChange={(e) => {
                        setResults([]);
                        setBookingTypeFilterValue(e.value);
                      }}
                    />
                  </Col>
                </Row>

                {bookingTypeFilterValue === bookingTypeFilters["function-booking"].value ? (
                  <Row className="mb-4">
                    <Col sm={12} lg={4} className="d-flex align-items-center">
                      <label className="mb-0">Function Type</label>
                    </Col>

                    <Col sm={12} lg={8}>
                      <Select
                        closeMenuOnSelect
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        blurInputOnSelect={false}
                        options={_.map(functionTypeFilters, (item) => item)}
                        value={_.find(functionTypeFilters, (item) => item.value === functionTypeFilterValue)}
                        onChange={(e) => {
                          setResults([]);
                          setFunctionTypeFilterValue(e.value);
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col sm={12} lg={4} className="d-flex align-items-center">
                    <label className="mb-0">Filter</label>
                  </Col>

                  <Col sm={12} lg={8}>
                    <TreeSelect
                      className="form-control tree-select"
                      style={{ width: "100%" }}
                      choiceTransitionName="rc-tree-select-selection__choice-zoom"
                      dropdownStyle={{ height: 250, overflow: "auto" }}
                      dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                      onDropdownVisibleChange={() => true}
                      treeLine
                      placeholder={<i>None</i>}
                      multiple
                      showIcon={false}
                      maxTagTextLength={25}
                      autoClearSearchValue
                      treeNodeFilterProp="title"
                      treeData={filterTreeData}
                      treeCheckable
                      treeDefaultExpandAll
                      value={filterTreeDataValue}
                      showCheckedStrategy={SHOW_PARENT}
                      maxTagCount={20}
                      onChange={(ids) => setFilterTreeDataValue(ids)}
                      maxTagPlaceholder={(valueList) => {
                        return `+${valueList.length}`;
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Form
                initialValues={initialValues}
                onSubmit={loadReports}
                validate={validate}
                keepDirtyOnReinitialize
                render={({ handleSubmit, submitting, values, form }) => {
                  return (
                    <>
                      <Col sm={12} lg={8} className="total-cells divider-top-cols">
                        <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center h-100 gap-sm">
                          <div className="mb-3 flex align-items-center gap-md">
                            <label className="m-0">
                              <b>Calendar</b>
                            </label>

                            <div className="d-flex gap-xl flex-wrap">
                              <Field name="calendar">
                                {({ input: { value, onChange } }) => (
                                  <div className="form-group gap-md d-flex m-0">
                                    {Object.values(calendarTypes).map((option, index) => (
                                      <label key={`render-units-${index}`} className="radio c-radio m-0 d-flex align-items-center">
                                        <input type="radio" value={option.value} checked={option.value === value} onChange={() => onChange(option.value)} />
                                        <span className="fa fa-circle" />
                                        {option.label}
                                      </label>
                                    ))}
                                  </div>
                                )}
                              </Field>

                              {values.calendar == calendarTypes.management.value ? (
                                <div className="d-flex align-items-center gap-md">
                                  <label className="mb-0">Mode</label>

                                  <div style={{ width: 160 }}>
                                    <Field name="mode">
                                      {({ input: { value, onChange }, meta }) => (
                                        <>
                                          <Select
                                            isSearchable={false}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            blurInputOnSelect={false}
                                            options={_.map(managementCalendarModes, (item) => item)}
                                            value={managementCalendarModes?.[value]}
                                            onChange={(e) => {
                                              onChange(e.value);

                                              form.change("period", undefined);
                                            }}
                                            placeholder="Select mode"
                                          />

                                          {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                        </>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="d-flex align-items-end gap-lg justify-content-between flex-wrap">
                            <div className="d-flex flex-grow-1 align-items-end gap-md">
                              {values.calendar == calendarTypes.dateRange.value ? (
                                <div className="d-flex align-items-start gap-md flex-grow-1">
                                  <div className="form-group m-0 d-flex align-items-baseline gap-sm flex-grow-1">
                                    <label htmlFor="startDate" className="mb-0 text-nowrap">
                                      Time Period
                                    </label>

                                    <Field name="startDate">
                                      {({ meta }) => (
                                        <div className="w-100">
                                          <DatePicker
                                            style={{ width: "auto" }}
                                            id="startDate"
                                            format="DD/MM/YYYY"
                                            calendarPosition="bottom"
                                            range
                                            value={[moment(values.startDate, "YYYY-MM-DD").toDate(), moment(values.endDate, "YYYY-MM-DD").toDate()]}
                                            onChange={(e: DateObject[]) => {
                                              if (e.length === 2) {
                                                form.change("startDate", e[0].format("YYYY-MM-DD"));
                                                form.change("endDate", e[1].format("YYYY-MM-DD"));
                                              }
                                            }}
                                          />

                                          {meta.touched && meta.error && <p className="text-danger mb-0">{meta.error}</p>}
                                        </div>
                                      )}
                                    </Field>
                                  </div>

                                  <div className="flex-grow-1" />
                                </div>
                              ) : null}

                              {values.calendar == calendarTypes.management.value ? (
                                <div className="flex-grow-1">
                                  <div className="form-group flex-grow-1 mb-0">
                                    <Field name="period">
                                      {({ input: { value, onChange }, meta }) => (
                                        <Row className="w-100 no-gutters">
                                          <Col sm={12} lg={2} className="d-flex align-items-center">
                                            <label htmlFor="startDate" className="mb-0">
                                              Time Period
                                            </label>
                                          </Col>

                                          <Col sm={12} lg={10}>
                                            <ManagementPeriodSelector
                                              onChangeYear={(e) =>
                                                onChange({
                                                  ...value,
                                                  year: e,
                                                })
                                              }
                                              onChangeMonth={(e) => {
                                                onChange({
                                                  ...value,
                                                  month: e,
                                                });
                                              }}
                                              onChangeWeek={(e) => {
                                                onChange({
                                                  ...value,
                                                  week: e,
                                                });
                                              }}
                                              calendar={calendar}
                                              mode={values.mode}
                                              value={value}
                                            />

                                            {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                          </Col>
                                        </Row>
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              ) : null}

                              <Button id="category-save" type="submit" className="fixed-width-btn d-flex justify-content-center align-items-center gap-sm" color="primary" disabled={submitting}>
                                {submitting ? <Spinner size="sm" /> : null} Generate Report
                              </Button>
                            </div>
                          </div>
                        </form>
                      </Col>
                    </>
                  );
                }}
              />
            </Row>
          </Card>

          {_.isEmpty(results) === false || loading ? (
            <Card className="card-default">
              {loading ? (
                <div className="fa-3x" style={{ textAlign: "center" }}>
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                  <Line
                    data={resultsLineData}
                    type="Line"
                    options={{
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: "Time Period",
                          },
                        },
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Quantity",
                          },
                        },
                      },
                    }}
                  />
              )}
            </Card>
          ) : null}
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default CoverSummary;
