import classNames from "classnames";
import { trimEnd } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Keyboard from "react-simple-keyboard";
import { Button, Spinner } from "reactstrap";

export enum TillKeyboardType {
  Alphanumeric,
  Numeric,
  NumericWithDash,
}

function removeLastChar(input) {
  if (!input) return input;
  const strInput = String(input);
  const result = trimEnd(strInput, strInput.charAt(strInput.length - 1));
  return result;
}

export const getValidatedNumberKeyboardInput = (keyboardRef, value) => {
  if ((value.match(/\./g) || []).length > 1) {
    const slicedValue = value.slice(0, -1);
    keyboardRef.current.setInput(slicedValue);
    return slicedValue;
  }
  const lastCharacter = value.charAt(value.length - 1);
  if (lastCharacter === ".") {
    value = value.concat(0);
  }
  return value;
};

export const TillKeyboard = ({ keyboardRef, onChange, keyboardType = TillKeyboardType.Numeric, layout = null, onEnter = null, value = null }) => {
  const [layoutName, setLayoutName] = useState("default");
  let defaultLayout = layout;
  let shiftLayout = layout;
  if (!defaultLayout) {
    if (keyboardType === TillKeyboardType.Numeric) {
      defaultLayout = ["1 2 3", "4 5 6", "7 8 9", "0 . {customBackspace}"];
    } else if (keyboardType === TillKeyboardType.NumericWithDash) {
      defaultLayout = ["1 2 3", "4 5 6", "7 8 9", "0 - {customBackspace}"];
    } else {
      defaultLayout = ["` 1 2 3 4 5 6 7 8 9 0 - = {customBackspace}", "{tab} q w e r t y u i o p [ ] \\", "{lock} a s d f g h j k l ; ' {enter}", "{shift} z x c v b n m , . / {shift}", ".com @ {space}"];
      shiftLayout = ["~ ! @ # $ % ^ &amp; * ( ) _ + {customBackspace}", "{tab} Q W E R T Y U I O P { } |", '{lock} A S D F G H J K L : " {enter}', "{shift} Z X C V B N M &lt; &gt; ? {shift}", ".com @ {space}"];
    }
  }
  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      onInit={(keyboard) => {
        if (value) keyboard.setInput(value);
      }}
      onChange={onChange}
      onKeyPress={(e) => {
        if ((e === "{search}" || e === "{enter}") && onEnter) onEnter();
        else if (e === "{shift}" || e === "{lock}") {
          setLayoutName(layoutName === "default" ? "shift" : "default");
        } else if (e === "{customBackspace}") {
          const newValue = removeLastChar(value);
          keyboardRef.current.setInput(newValue);
          onChange(newValue);
        }
      }}
      layout={{
        default: defaultLayout,
        shift: shiftLayout,
      }}
      layoutName={layoutName}
      display={{
        "{customBackspace}": "Backspace",
        "{search}": "🔎",
        "{shift}": "Shift",
        "{tab}": "Tab",
        "{lock}": "Caps Lock",
        "{enter}": "Enter",
        "{space}": "Space",
      }}
      theme="hg-theme-default hg-layout-numeric numeric-theme"
    />
  );
};

export const TillInputField = ({ input, onScreenKeyboardEnabled = false, inputStyle = {}, inputClassNames = "", textArea = false, phoneInput = false, keyboardType = null, onChangeKeyboard = null, disabled = false, loading = false, label = null, meta = null, searchButton = null, applyButton = null, onEnter = null, showInputOnKeyboard = false, keyboardInputLabel = "", requiredLabel = false }) => {
  const [openKeyboard, setOpenKeyboard] = useState(false);
  const keyboardRef: any = React.useRef();
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!openKeyboard) setOpenKeyboard(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const inputProps = {
    className: phoneInput ? "" : inputClassNames || "form-control",
    ...input,
    onChange: (e) => {
      input.onChange(phoneInput ? e : e.target.value);
      if (keyboardRef.current) keyboardRef.current.setInput(phoneInput ? e : e.target.value);
    },
    autoComplete: "off",
    disabled: disabled || loading,
    style: { ...inputStyle },
    onClick: () => !disabled && !loading && setOpenKeyboard(true),
  };
  // const onScreenKeyboardEnabled = window.localStorage.getItem("onScreenKeyboard") === "true";
  return (
    <>
      {label && (
        <div>
          {label} {requiredLabel && <span className="text-danger">*</span>}
        </div>
      )}

      <div className="input-group mb-2">
        {textArea && <textarea {...inputProps} />}
        {phoneInput && <PhoneInput {...inputProps} country={"au"} value={input.value} onBlur={input.onBlur} onlyCountries={["au", "nz", "us", "ca", "uk", "cn", "jp", "kr", "sg", "ph"]} masks={{ au: "... ... ..." }} />}
        {!textArea && !phoneInput && <input {...inputProps} />}

        <div className="input-group-append">
          {applyButton && (
            <button type="button" disabled={applyButton.disabled} className={applyButton.className} onClick={applyButton.onClick}>
              {applyButton.applying ? <Spinner size="sm" /> : applyButton.label}
            </button>
          )}
          {searchButton && (
            <Button type="submit" color="primary" disabled={searchButton.disabled} onClick={searchButton.onSearch}>
              {searchButton.searching ? <Spinner size="sm" /> : <i className="fa fa-search" aria-hidden="true" />}
            </Button>
          )}
        </div>
      </div>
      {meta && meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
      {onScreenKeyboardEnabled && (
        <div ref={wrapperRef} className={classNames("fixed-bottom", { "display-none": !openKeyboard })}>
          {showInputOnKeyboard && (
            <div>
              <input className="form-select text-center border-2 border-dark fw-bold" value={`${keyboardInputLabel} :  ${input.value}`}></input>
            </div>
          )}
          <TillKeyboard
            keyboardRef={keyboardRef}
            keyboardType={keyboardType ? keyboardType : input.type === "number" ? TillKeyboardType.Numeric : TillKeyboardType.Alphanumeric}
            onEnter={() => {
              onEnter ? onEnter : setOpenKeyboard(false);
            }}
            value={input.value}
            onChange={(e) => {
              if (onChangeKeyboard) {
                onChangeKeyboard(e, keyboardRef);
              } else {
                let value = e;
                if (input.type === "number") value = getValidatedNumberKeyboardInput(keyboardRef, value);
                input.onChange(value);
              }
            }}
          />
        </div>
      )}
    </>
  );
};
