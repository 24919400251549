import { getConfig } from "core/utils";
import omit from "lodash/omit";
import { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import ImageUploader from "resbutler-ui/components/Uploader/ImageUploader";
import { deleteGroupGalleryImages, deleteGroupLogo, getImages, removeImage, saveGroupGalleryImages, saveGroupLogo, saveImage } from "../GiftCertificateAction";
import GiftCertificateFormSettings from "./GiftCertificateFormSettings";
import GiftCertificateGalleryRun, { unmountGallery } from "./GiftCertificatesGallery.run";
import GroupLogoRun, { unmountGroupLogo } from "./GroupLogo.run";

const galleryImages = (images) => {
  const results = [];
  images.map((item) => {
    if (item.url !== undefined) results.push({ url: item.url, fileName: item.name });
  });
  return results;
};
class GiftCertificatesSettings extends Component {
  componentDidMount() {
    this.props.dispatch(getImages());
    GroupLogoRun(this.props.dispatch, this.props.settings, { saveGroupLogo, deleteGroupLogo }, "group-logo", 250);
  }

  componentDidUpdate() {
    const images = this.props.images;
    for (let i = 0; i < images.length; i++) {
      GiftCertificateGalleryRun(this.props.dispatch, this.props.images[i], { saveImage, removeImage }, this.props.images[i].id, 250);
    }
  }

  componentWillUnmount() {
    unmountGallery();
    unmountGroupLogo();
  }

  render() {
    const { dispatch, images, saving } = this.props;
    const { client } = getConfig();

    return (
      <div>
        <Row>
          <Col lg={6}>
            <Card className="card-default">
              <CardBody>
                <div className="form-group" style={{ display: "none" }}>
                  <label htmlFor="group-logo">Group Logo</label>
                  <div id="group-logo" action="#" method="post" className="well dropzone" style={{ opacity: "1", minHeight: "213px", maxWidth: 500, marginBottom: 0 }}>
                    <div className="dropzone-previews" />
                    <div className="ball-beat" style={{ display: saving["group-logo"] ? "block" : "none" }}>
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                </div>

                <ImageUploader
                  client={client}
                  sectionName="Group Logo"
                  onDelete={() => dispatch(deleteGroupLogo())}
                  currentImages={[
                    {
                      url: this.props.settings.url,
                      fileName: this.props.settings.fileName,
                    },
                  ]}
                  setImage={(output) => {
                    dispatch(saveGroupLogo(output, "group-logo"));
                  }}
                  path="giftcertificates"
                  disabled={false}
                />

                {images.length == 0 && (
                  <ImageUploader
                    client={client}
                    sectionName="Gift Certificate Gallery"
                    onDelete={(url) => {
                      dispatch(deleteGroupGalleryImages(url));
                    }}
                    currentImages={[]}
                    setImage={(outputs) => {
                      for (const output of outputs) dispatch(saveGroupGalleryImages(output));
                    }}
                    isMultiple={true}
                    path="giftcertificatesGallery"
                    disabled={false}
                  />
                )}

                {images.length > 0 && (
                  <ImageUploader
                    client={client}
                    sectionName="Gift Certificate Gallery"
                    onDelete={(url) => {
                      dispatch(deleteGroupGalleryImages(url));
                    }}
                    currentImages={galleryImages(images)}
                    setImage={(outputs) => {
                      for (const output of outputs) dispatch(saveGroupGalleryImages(output));
                    }}
                    isMultiple={true}
                    path="giftcertificatesGallery"
                    disabled={false}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <GiftCertificateFormSettings settings={omit(this.props.settings, ["gallery"])} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.giftCertificates.images,
    saving: state.giftCertificates.saving,
  };
};

export default connect(mapStateToProps)(GiftCertificatesSettings);
