import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares';
import { updateTheme } from './middlewares/themes.middleware.js';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// DEV redux dev tools
const composeEnhancer = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

  const store = createStore(
    reducers,
    //  persistedState, // second argument overrides the initial state
    composeEnhancer(applyMiddleware(
      ...middlewares,
      ...(process.env.NODE_ENV !== 'development' ? [thunk] : [thunk]),
    ))
  );

export default function configureStore() {
 
  // add a listener that will be invoked on any state change
  // store.subscribe(() => {
  //     saveState({
  //         ...store.getState(),
  //         form: {}
  //     });
  // });

  // Update the initial theme
  updateTheme(store.getState())
  return store;

}
    // eslint-disable-next-line no-undef
export type ReduxState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, AnyAction>;
