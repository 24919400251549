import { combineReducers } from "redux";

import { GET_ALL_CAMPAIGNS, GET_BUILDER_SHORT_LINK, GET_CAMPAIGN, GET_CAMPAIGNS } from "../ActionTypes";

const initialState = {
  campaigns: {},
  allCampaigns: {},
  campaign: null,
  shortLink: "",
};

function campaigns(state = initialState.campaigns, action) {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return action.campaigns;
    default:
      return state;
  }
}

function allCampaigns(state = initialState.allCampaigns, action) {
  switch (action.type) {
    case GET_ALL_CAMPAIGNS:
      return action.allCampaigns;
    default:
      return state;
  }
}

function campaign(state = initialState.campaign, action) {
  switch (action.type) {
    case GET_CAMPAIGN:
      return action.campaign;
    default:
      return state;
  }
}

function shortLink(state = initialState.shortLink, action) {
  switch (action.type) {
    case GET_BUILDER_SHORT_LINK:
      return action.shortLink;
    default:
      return state;
  }
}

export default combineReducers({
  campaigns,
  campaign,
  shortLink,
  allCampaigns,
});
