import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import FlexSearch from "flexsearch";
import _ from "lodash";
import moment from "moment";
import { hideLoading, showLoading } from "react-redux-loading-bar";

import * as types from "./ActionTypes";

import { BUTTON_ERROR, BUTTON_SAVED, BUTTON_SAVING } from "components/Buttons/ActionTypes";
import core, { catchExceptionCallback, getConfig } from "core/utilities";

export const statusFilterOptions = {
  all: {
    value: "all",
    label: "All",
  },
  active: {
    value: "active",
    label: "Active only",
  },
  expired: {
    value: "expired",
    label: "Expired only",
  },
};

export function saveImage(id) {
  return (dispatch) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_SAVING, id });
      dispatch({ type: types.GIFT_CERTIFICATE_SAVED, id });
      dispatch(getImages());
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function saveRestaurantImage(file, id, restaurantId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVING, id });
      const ext = file.name.split(".").pop();
      const images = getState().giftCertificates.imagesRestaurant;
      const index = _.findIndex(images, (img) => img.id === id);
      const newId = images[index].name !== undefined ? `g-${core.guid()}` : id;
      const data = getConfig();
      await Promise.all([
        firebase.storage().ref().child(`${data.client}/images/giftcertificatesrestaurants/${restaurantId}/${newId}.${ext}`).put(file, { contentType: file.type }),
        firebase.storage().ref().child(`${data.client}/images/giftcertificatesrestaurants/${restaurantId}/thumbs/${newId}.${ext}`).putString(file.thumbnail, "data_url"),
      ]);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVED, id });
      dispatch(getRestaurantImages(restaurantId));
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function removeImage(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_SAVED, id });
      dispatch({ type: types.REMOVE_EMPTY_GIFT_CERTIFICATE_IMAGE, id });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function removeRestaurantImage(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVING, id });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVED, id });
      dispatch({ type: types.REMOVE_EMPTY_GIFT_CERTIFICATE_RESTAURANT_IMAGE, id });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function getImages() {
  return async (dispatch) => {
    try {
      const { tenantId } = getConfig();
      const imagesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificateSettings/gallery`).once("value");
      let galleryImages = [];
      imagesSnapshot.forEach((snap) => {
        if (snap.exists()) galleryImages.push({ ...snap.val() });
      });
      dispatch({ type: types.GIFT_CERTIFICATE_IMAGES, images: galleryImages });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function getRestaurantImages(restaurantId) {
  return async (dispatch) => {
    try {
      const { tenantId } = getConfig();
      const imagesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}/gallery`).once("value");
      let galleryImages = [];
      imagesSnapshot.forEach((snap) => {
        if (snap.exists()) galleryImages.push({ ...snap.val(), restaurantId: restaurantId });
      });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_IMAGES, imagesRestaurant: galleryImages });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function searchGiftCertificates(giftCertificates, text, enableFilter = null, showByStatus = null, restaurants, buttonId) {
  return (dispatch) => {
    let filteredGiftCertificates = giftCertificates;
    switch (enableFilter) {
      case null:
        filteredGiftCertificates = giftCertificates;
        break;
      default:
        filteredGiftCertificates = _.filter(filteredGiftCertificates, (gc) => {
          return gc.enabled == enableFilter;
        });
        break;
    }

    // filter by restaurants
    filteredGiftCertificates = _.filter(filteredGiftCertificates, (gc) => restaurants.includes(gc.restaurantId));

    if (showByStatus && showByStatus !== statusFilterOptions.all.value) {
      filteredGiftCertificates = _.filter(filteredGiftCertificates, (gc) => {
        return showByStatus === statusFilterOptions.expired.value ? gc.expiryDate < moment(new Date()).format("YYYYMMDD") : gc.expiryDate > moment(new Date()).format("YYYYMMDD");
      });
    }

    if (text) {
      dispatch({ type: BUTTON_SAVING, id: buttonId });

      const index = new FlexSearch({
        tokenize: "full",
        depth: 3,
        doc: {
          id: "_key",
          field: ["voucherNumber", "lastName", "firstName", "recipientFirstName", "recipientLastName"],
        },
      });

      index.add(filteredGiftCertificates);
      index.search(text, (results) => {
        dispatch({ type: types.GET_GIFT_CERTIFICATES_SEARCH_RESULTS, giftCertificates: results });
        dispatch({ type: BUTTON_SAVED, id: buttonId });
      });
    } else {
      dispatch({ type: types.GET_GIFT_CERTIFICATES_SEARCH_RESULTS, giftCertificates: filteredGiftCertificates });
    }
  };
}

/* export function getGCCustomerById(customerId) {
  return async (dispatch) => {
    const { client } = getConfig();
    const doc = await firebase.firestore().doc(`${client}/crm/customers/${customerId}`).get();
    const customer = doc.data();
    dispatch({ type: types.GET_GIFT_CERTIFICATE_CUSTOMERS, customer: { ...customer, UID_Search: customerId } });
  };
} */

export function getGiftCertficates() {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const { tenantId } = getConfig();
      const giftCertificatesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificates`).once("value");
      let giftCertificates = [];
      giftCertificatesSnapshot.forEach((snap) => {
        giftCertificates.push({ ...snap.val(), _key: snap.key });
      });
      giftCertificates = giftCertificates.sort((a, b) => b.createdAt - a.createdAt);
      dispatch({ type: types.GET_GIFT_CERTIFICATES, giftCertificates });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch(hideLoading());
    }
  };
}

export function getGiftCertficatesByCustomer(customerId) {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const { tenantId } = getConfig();
      const giftCertificatesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificates`).orderByChild("recipientCustomerId").equalTo(customerId).once("value");
      let giftCertificates = [];
      giftCertificatesSnapshot.forEach((snap) => {
        if (snap.exists()) giftCertificates.push({ ...snap.val(), _key: snap.key });
      });
      dispatch({ type: types.GET_GIFT_CERTIFICATE_CUSTOMERS, giftCertificates });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch(hideLoading());
    }
  };
}

export function getGiftCertficatesRestaurants() {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const { tenantId } = getConfig();
      const giftCertificatesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificatesRestaurants`).once("value");
      const giftCertificates = [];
      giftCertificatesSnapshot.forEach((snap) => {
        giftCertificates.push({ ...snap.val(), _key: snap.key });
      });
      dispatch({ type: types.GET_GIFT_CERTIFICATES, giftCertificates });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch(hideLoading());
    }
  };
}

export function getGiftCertficateSettings() {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const { tenantId } = getConfig();
      const settings = await firebase.database().ref(`${tenantId}/giftCertificateSettings`).once("value");
      dispatch({ type: types.GIFT_CERTIFICATE_SETTINGS, settings: settings.val() || {} });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch(hideLoading());
    }
  };
}

export function getGiftCertficateRestaurantSettings(restaurantId) {
  return async (dispatch) => {
    try {
      dispatch(showLoading());
      const { tenantId } = getConfig();
      const settings = await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}`).once("value");
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SETTINGS, restaurantSettings: settings.val() || {} });
      dispatch({ type: types.LOAD_RESTAURANT_GALLERY, loadRestaurantGallery: true });
      dispatch(hideLoading());
    } catch (error) {
      catchExceptionCallback(error);
      dispatch(hideLoading());
    }
  };
}

export function saveGroupLogo(file) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_SAVING, id: "group-logo" });
      const settings = getState().giftCertificates.settings;
      const name = file.fileName;
      const url = file.url;
      const thumbUrl = file.thumbUrl;
      const newSettings = {
        ...settings,
        url,
        thumbUrl,
        name,
        sizeFile: file.sizeFile,
        fileName: file.fileName,
      };
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificateSettings`).set(newSettings);
      dispatch({ type: types.GIFT_CERTIFICATE_SETTINGS, settings: newSettings });
      dispatch({ type: types.GIFT_CERTIFICATE_SAVED, id: "group-logo" });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function saveGroupGalleryImages(file) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVING, id: "group-gallery" });
      const name = file.fileName;
      const url = file.url;
      const thumbUrl = file.thumbUrl;
      const { tenantId } = getConfig();
      var newData = {
        url,
        thumbUrl,
        name,
        sizeFile: file.sizeFile,
        fileName: file.fileName,
      };
      await firebase.database().ref(`${tenantId}/giftCertificateSettings/gallery`).push(newData);
      const imagesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificateSettings/gallery`).once("value");
      let galleryImages = [];
      imagesSnapshot.forEach((snap) => {
        if (snap.exists()) galleryImages.push({ ...snap.val() });
      });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVED, id: "group-gallery" });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_IMAGES, imagesRestaurant: galleryImages });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function deleteGroupGalleryImages(url) {
  return async (dispatch) => {
    try {
      const { tenantId } = getConfig();
      const imagesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificateSettings/gallery`).once("value");
      let galleryImages = [];
      imagesSnapshot.forEach((snap) => {
        if (snap.exists()) galleryImages.push({ ...snap.val() });
      });
      const filteredGalleryImages = url ? galleryImages.filter((item) => item.url !== url) : [];
      await firebase.database().ref(`${tenantId}/giftCertificateSettings/gallery`).set(filteredGalleryImages);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_IMAGES, imagesRestaurant: filteredGalleryImages });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function saveRestaurantLogo(file, restaurantId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVING, id: "restaurant-logo" });
      const settings = getState().giftCertificates.restaurantSettings;
      const name = file.fileName;
      const url = file.url;
      const thumbUrl = file.thumbUrl;
      const newSettings = {
        ...settings,
        url,
        thumbUrl,
        name,
        sizeFile: file.sizeFile,
        fileName: file.fileName,
      };
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}`).set(newSettings);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SETTINGS, restaurantSettings: newSettings });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVED, id: "restaurant-logo" });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function saveRestaurantGalleryImages(file, restaurantId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVING, id: "restaurant-gallery" });
      const settings = getState().giftCertificates.restaurantSettings;
      const name = file.fileName;
      const url = file.url;
      const thumbUrl = file.thumbUrl;
      const { tenantId } = getConfig();
      var newData = {
        url,
        thumbUrl,
        name,
        sizeFile: file.sizeFile,
        fileName: file.fileName,
      };
      await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}/gallery`).push(newData);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SETTINGS, restaurantSettings: settings });
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SAVED, id: "restaurant-logo" });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function deleteRestaurantGalleryImages(url, restaurantId) {
  return async (dispatch) => {
    try {
      const { tenantId } = getConfig();
      const imagesSnapshot = await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}/gallery`).once("value");
      let galleryImages = [];
      imagesSnapshot.forEach((snap) => {
        if (snap.exists()) galleryImages.push({ ...snap.val(), restaurantId: restaurantId });
      });
      const filteredGalleryImages = galleryImages.filter((item) => item.url !== url);
      await firebase.database().ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}/gallery`).set(filteredGalleryImages);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_IMAGES, imagesRestaurant: filteredGalleryImages });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function deleteGroupLogo() {
  return async (dispatch, getState) => {
    try {
      const settings = getState().giftCertificates.settings;
      const { tenantId } = getConfig();
      await firebase
        .database()
        .ref(`${tenantId}/giftCertificateSettings`)
        .set({ ...settings, sizeFile: null, thumbUrl: null, url: null, name: null, fileName: null });
      dispatch({ type: types.GIFT_CERTIFICATE_SETTINGS, settings: { ...settings, url: "", name: "" } });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function deleteRestaurantLogo(restaurantId) {
  return async (dispatch, getState) => {
    try {
      const settings = getState().giftCertificates.restaurantSettings;
      const { tenantId } = getConfig();
      await Promise.all([
        firebase
          .database()
          .ref(`${tenantId}/giftCertificateRestaurantSettings/${restaurantId}`)
          .set({ ...settings, fileName: null, sizeFile: null, thumbUrl: null, url: null, name: null }),
      ]);
      dispatch({ type: types.GIFT_CERTIFICATE_RESTAURANT_SETTINGS, restaurantSettings: { ...settings, url: "", name: "" } });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function saveGiftCertificate(giftCertificate, giftCertificateId, buttonId) {
  return async (dispatch) => {
    try {
      dispatch({ type: BUTTON_SAVING, id: buttonId });
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificates/${giftCertificateId}`).update(giftCertificate);
      dispatch({ type: types.UPDATE_GIFT_CERTIFICATE, giftCertificate });
      dispatch({ type: BUTTON_SAVED, id: buttonId });
    } catch (error) {
      catchExceptionCallback(error);
      dispatch({ type: BUTTON_ERROR, id: buttonId });
    }
  };
}

export function saveGiftCertificateRestaurant(giftCertificateRestaurant, giftCertificateRestaurantId, restaurantId, buttonId) {
  return async (dispatch) => {
    try {
      dispatch({ type: BUTTON_SAVING, id: buttonId });
      const { tenantId } = getConfig();
      await firebase.database().ref(`${tenantId}/giftCertificatesRestaurants/${restaurantId}/${giftCertificateRestaurantId}`).update(giftCertificateRestaurant);
      dispatch({ type: types.UPDATE_GIFT_CERTIFICATE_RESTAURANT, giftCertificateRestaurant });
      dispatch({ type: BUTTON_SAVED, id: buttonId });
    } catch (error) {
      catchExceptionCallback(error);
      dispatch({ type: BUTTON_ERROR, id: buttonId });
    }
  };
}
