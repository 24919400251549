import { combineReducers } from 'redux';
import _ from 'lodash';

import { GET_WIDGET_BY_ID, GET_WIDGETS, GET_BUILDER_WIDGET_LINK, GET_ALL_WIDGETS } from '../ActionTypes';

const initialState = {
  widget: null,
  allWidgets: null,
  byId: {},
  widgetLink: '',
  codeBlock: '',
  buttonLink: '',
  jQueryCode: '',
  javaScriptCode: '',
  htmlCode: '',
};

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case GET_WIDGETS:
      return action.widgets;
    default:
      return state;
  }
}

function allWidgets(state = initialState.allWidgets, action) {
  switch (action.type) {
    case GET_ALL_WIDGETS:
      return action.allWidgets;
    default:
      return state;
  }
}

function widget(state = initialState.widget, action) {
  switch (action.type) {
    case GET_WIDGET_BY_ID:
      return action.widget;
    default:
      return state;
  }
}

function widgetLink(state = initialState.widgetLink, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.widgetLink;
    default:
      return state;
  }
}

function buttonLink(state = initialState.buttonLink, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.buttonLink;
    default:
      return state;
  }
}

function codeBlock(state = initialState.codeBlock, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.codeBlock;
    default:
      return state;
  }
}

function jQueryCode(state = initialState.jQueryCode, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.jQueryCode;
    default:
      return state;
  }
}

function javaScriptCode(state = initialState.javaScriptCode, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.javaScriptCode;
    default:
      return state;
  }
}

function htmlCode(state = initialState.htmlCode, action) {
  switch (action.type) {
    case GET_BUILDER_WIDGET_LINK:
      return action.htmlCode;
    default:
      return state;
  }
}

export function getVisibleWidgets(widgets) {
  return _.pickBy(widgets, (widget) => widget.enabled);
}

export default combineReducers({
  byId,
  widget,
  widgetLink,
  codeBlock,
  buttonLink,
  jQueryCode,
  javaScriptCode,
  htmlCode,
  allWidgets,
});
