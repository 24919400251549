import { findIndex } from "lodash";
import { combineReducers } from "redux";
import { GET_ALL_LEVELS, GET_LEVELS, GET_LEVEL_BY_ID, GET_LEVEL_DESCRIPTION_EDITOR_STATE, UPDATE_LEVEL } from "./LevelAction";

const initialState = {
  byId: [],
  allLevels: [],
  level: null,
  editRow: {
    name: "",
    membershipType: "", // [personal, corporate]
    allocationPriority: "", // whole number
    resbutlerMembershipTypeId: "", // cannot be changed?
    thirdPartyMembershipTypeId: "",
    // description: '', // rich text => delegated to descriptionEditor
    cardBackgroundColor: "", // #ffffff
    enabled: "", // boolean
  },
  descriptionEditor: "",
};

const byId = (state = initialState.byId, action) => {
  switch (action.type) {
    case GET_LEVELS:
      return action.levels;
    case UPDATE_LEVEL: {
      const index = findIndex(state, (level) => level._key === action.key);
      if (index !== -1) {
        return [...state.slice(0, index), action.level, ...state.slice(index + 1)];
      }
      return state;
    }
    default:
      return state;
  }
};

const allLevels = (state = initialState.allLevels, action) => {
  switch (action.type) {
    case GET_ALL_LEVELS:
      return action.allLevels;
    default:
      return state;
  }
};

const level = (state = initialState.level, action) => {
  switch (action.type) {
    case GET_LEVEL_BY_ID:
      return action.level;
    default:
      return state;
  }
};

const editRow = (state = initialState.editRow, action) => {
  switch (action.type) {
    case UPDATE_LEVEL:
      return {
        key: "",
        data: {},
      };
    default:
      return state;
  }
};

const descriptionEditor = (state = initialState.descriptionEditor, action) => {
  switch (action.type) {
    case GET_LEVEL_DESCRIPTION_EDITOR_STATE:
      return action.editorState;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  level,
  editRow,
  descriptionEditor,
  allLevels,
});
