import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { Product } from "resbutler-utils/types/product";
import { buildArrayFromDocs } from "resbutler-utils/utils";

const useProducts = (): [boolean, Product[]] => {
  const { client } = getConfig();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);

  const fetch = async () => {
    setLoading(true);

    const unsubscribe = firebase
      .firestore()
      .collection(`${client}/products/products`)
      .onSnapshot(
        (snapProducts) => {
          setProducts(buildArrayFromDocs<Product>(snapProducts.docs));
          setLoading(false);
        },
        (error) => {
          catchExceptionCallback(error);
        },
        () => {
          setLoading(false);
        }
      );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  };

  useEffect(() => {
    fetch();
  }, []);

  return [loading, products];
};

export default useProducts;
