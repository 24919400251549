import React from "react";
import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { getConfig } from "core/utilities";
import { getRestaurants } from "system/RestaurantReducer";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";

const changeRestaurantHandler = (dispatch, restaurantId) => dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId });

const CRMMembershipAppLinkBuilder = () => {
  const restaurants = useSelector((state: any) => getRestaurants(state.root.restaurants, state.root.user));
  const dispatch = useDispatch();

  const [isGroup, setIsGroup] = useState(true);
  const [restaurantId, setRestaurantId] = useState(null);
  const [marketingLink, setLinkType] = useState(false);
  const [crmAppLink, setCrmAppLink] = useState("");

  const { tenantId, crmPath } = getConfig();

  const submit = () => {
    let appLink = isGroup ? `${crmPath}/${tenantId}` : `${crmPath}/${tenantId}/restaurants/${restaurantId}`;
    if (marketingLink) appLink += "/quick-register";
    setCrmAppLink(appLink);
  };

  const crmButton = `< button href = "${crmAppLink}" target = "_blank" > CRM App</button >
    `;

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" headerText="CRM Link Builder" restaurants={restaurants} restaurantId={restaurantId} onRestaurantChange={(e) => changeRestaurantHandler(dispatch, e)} />
      <Row>
        <Col className="mb-lg" lg={8} sm={12}>
          <>
            <form onSubmit={submit}>
              <Card className="card-default">
                <Row>
                  <Col className="mx-1 mt-1">
                    <div className="form-group"></div>
                  </Col>
                </Row>

                <CardBody>
                  <div className="ml-2">
                    {[
                      { value: true, displayName: "Group" },
                      { value: false, displayName: "Restaurant" },
                    ].map((p) => (
                      <FormGroup key={`widget-type-${p.value}`} check>
                        <Label check>
                          <Input type="radio" name="gcType" onChange={() => setIsGroup(p.value)} />
                          {p.displayName}
                        </Label>
                      </FormGroup>
                    ))}
                    <br />

                    {!isGroup && (
                      <div className="form-group">
                        <label> <b>Restaurant</b></label>
                        <select className="form-control" onChange={(e) => setRestaurantId(e.target.value)} disabled={isGroup}>
                          <option value="" >Select</option>
                          {Object.entries(restaurants).map(([id, r]) => (
                            <option value={id} key={id}> {r.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <br />

                  <div className="ml-2">
                    <b>Link Type</b>
                    {[
                      { value: false, displayName: "Membership App" },
                      { value: true, displayName: "Marketing Sign Up Form" },
                    ].map((p) => (
                      <FormGroup key={`widget-type-${p.value}`} check>
                        <Input type="radio" name="linkType" onChange={() => setLinkType(p.value)} />
                        {p.displayName}
                      </FormGroup>
                    ))}
                  </div>

                  <div className="form-group">
                    <Button onClick={submit} color="primary" className="float-right">
                      Generate Widget Link
                    </Button>
                  </div>

                </CardBody>
              </Card>
              {crmAppLink && (
                <Card className="card-default">
                  <CardBody>
                    <div className="form-group">
                      <label htmlFor="widgetPopup1">
                        <b>Result</b>
                        <br />
                        Add a CRM link to your webpage
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="fw-bold w-100">URL link to CRM App</label>
                      <input readOnly className="form-control" value={crmAppLink} />
                    </div>
                    <div className="form-group">
                      <label className="fw-bold w-100">HTML Button</label>
                      <textarea rows={6} cols={4} readOnly className="form-control" value={crmButton} />
                    </div>
                  </CardBody>
                </Card>
              )}
            </form>
          </>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default CRMMembershipAppLinkBuilder;
