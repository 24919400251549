import _ from "lodash";
import { combineReducers } from "redux";
import { GET_CUSTOMERS, CUSTOMER_GET_TAB, GET_CUSTOMERS_BY_ID, UPDATE_CUSTOMER, GET_CUSTOMERS_SEARCH_RESULTS } from "./ActionTypes";

const initialState = {
  byId: [],
  totalCustomers: 0,
  searchParam: "",
  customer: null,
  tab: 1,
  searchResults: [],
  editRow: { name: "", membershipNumber: "", email: "", level: 0, _key: "", dateJoined: "", deleted: false, firstName: "", lastName: "", membershipClass: "", mobile: "", phone: "", titleId: "" },
};

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case GET_CUSTOMERS:
      return action.customers;
    case UPDATE_CUSTOMER: {
      const index = _.findIndex(state, (customer) => customer._key === action.key);
      if (index !== -1) {
        return [...state.slice(0, index), action.customer, ...state.slice(index + 1)];
      }
      return state;
    }
    default:
      return state;
  }
}

function tab(state = initialState.tab, action) {
  switch (action.type) {
    case CUSTOMER_GET_TAB:
      return action.tab;
    default:
      return state;
  }
}

function customer(state = initialState.customer, action) {
  switch (action.type) {
    case GET_CUSTOMERS_BY_ID:
      return action.customer;
    default:
      return state;
  }
}

function editRow(state = initialState.editRow, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER:
      return {
        key: "",
        data: {},
      };
    default:
      return state;
  }
}

function searchResults(state = initialState.searchResults, action) {
  switch (action.type) {
    case GET_CUSTOMERS_SEARCH_RESULTS:
    case GET_CUSTOMERS:
      return action.customers;
    default:
      return state;
  }
}

export function getCustomer(customers, customerId) {
  const visibleCustomers = _.filter(customers, (c) => !c.deleted);
  return _.find(visibleCustomers, (c) => c._key === customerId);
}

export default combineReducers({
  byId,
  customer,
  editRow,
  tab,
  searchResults,
});
