import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "_utils/format";
import { getReactSelectValue } from "_utils/react-select";
import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import { useAppSelector } from "components/Hooks/hooks";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import showModalTransaction from "components/Modal/showModalTransaction";

import { catchExceptionCallback, getConfig, selectCustomStyles } from "core/utilities";
import { renderRestaurants } from "crm/customers/CustomerModel";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { chain, filter, find, forEach, isEmpty, isNil, keys, map } from "lodash";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { voucherTypes } from "resbutler-ui/components/VoucherForm/VoucherBasicForm";
import VoucherIssuedForm from "resbutler-ui/components/VoucherIssuedForm";
import { Restaurants } from "resbutler-utils/types/Restaurant";
import { ReasonTypes, Voucher } from "resbutler-utils/types/Voucher";
import { Customers } from "resbutler-utils/types/customer";
import { buildDataFromDocs } from "resbutler-utils/utils";

// render a cell of Products
export const Product = ({ productId, sizes }) => {
  const { products, allSizes } = useAppSelector((state) => {
    return {
      products: state.root.products,
      allSizes: state.root.productSizes,
    };
  });
  const product = find(products, (p) => p.id === productId);
  const productSizes = keys(sizes).sort();
  return (
    <>
      {productSizes.map((sizeId) => (
        <span key={sizeId}>
          {product?.name} ({allSizes[sizeId]?.name})|&nbsp;
        </span>
      ))}
    </>
  );
};

// render a cell of Customers
export const Customer = ({ customerId }) => {
  const { client } = getConfig();
  const [customer, setCustomer] = useState({}); // loaded customer
  const { firstName, lastName } = customer || ({ firstName: "", lastName: "" } as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const doc = await firebase.firestore().doc(`${client}/crm/customers/${customerId}`).get();
        setCustomer(doc.data());
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return !loading ? (
    <Link to={`/crm/customers/${customerId}`}>
      {firstName} {lastName}
    </Link>
  ) : null;
};

const getVoucherType = (row) => voucherTypes.find((type) => type.value == row.voucherType)?.label || "";
// const getReasonType = (row) =>;

export const voucherColumns = (allRestaurants: Restaurants, customers: Customers, reasonTypes: ReasonTypes, setOpenVoucherModalById: (v: string) => void) => [
  {
    text: "Restaurants",
    dataField: "restaurant",
    formatter: (cell, row: Voucher) => {
      return (row.restaurants || []).map((id) => allRestaurants[id].name).join(" | ");
    },
    disableSortBy: true,
  },
  {
    text: "Voucher Number",
    dataField: "voucherNumber",
    formatter: (cell, row: Voucher) => {
      return (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            setOpenVoucherModalById(row.id);
          }}
        >
          {row.voucherNumber}
        </Link>
      );
    },
  },
  {
    text: "Voucher Type",
    dataField: "voucherType",
    formatter: (cell, row: Voucher) => {
      return getVoucherType(row);
    },
  },
  {
    text: "Reason Type",
    dataField: "reasonType",
    formatter: (cell, row: Voucher) => {
      return reasonTypes[row.reasonType]?.name;
    },
  },
  {
    text: "Products",
    dataField: "products",
    formatter: (cell, row: Voucher) => {
      if (row.allProducts) return "Any";
      return map(row.products, ({ productId, sizes }, i) => <Product productId={productId} key={i} sizes={sizes} />);
    },
    disableSortBy: true,
  },
  {
    text: "Discount Amount",
    dataField: "discount",
    formatter: (cell, row: Voucher) => {
      if (row.discountType == "$") return priceFormatter.format(row.discountAmount);
      return row.discountType ? row.discountAmount + row.discountType : "";
    },
    disableSortBy: true,
  },
  {
    text: "Issued Date",
    dataField: "issuedDate",
    formatter: (cell, row: Voucher) => {
      return moment(row.issuedDate.seconds * 1000).format("DD/MM/YYYY");
    },
    filterValue: (cell, row: Voucher) => {
      return moment(row.issuedDate.seconds * 1000).format("DD/MM/YYYY");
    },
  },
  {
    text: "Expiry Date",
    dataField: "expiryDate",
    formatter: (cell, row: Voucher) => moment(row.expiryDate.seconds * 1000).format("DD/MM/YYYY"),
    filterValue: (cell, row: Voucher) => {
      return moment(row.expiryDate.seconds * 1000).format("DD/MM/YYYY");
    },
  },

  {
    text: "Redeemed Date",
    dataField: "isRedeemed",
    formatter: (cell, row: Voucher) => {
      const dates = [];
      forEach(row.redeemedInfo, (info) => {
        const date = moment(info.redeemedDate).format("DD/MM/YYYY");
        if (!dates.includes(date)) dates.push(date, " ");
      });
      return dates;
    },
    filterValue: (cell, row: Voucher) => {
      const dates = [];
      forEach(row.redeemedInfo, (info) => {
        const date = moment(info.redeemedDate).format("DD/MM/YYYY");
        if (!dates.includes(date)) dates.push(date, " ");
      });
      return dates;
    },
    disableSortBy: true,
  },
  {
    text: "Transaction",
    dataField: "transaction",
    formatter: (cell, row: Voucher) => {
      const transactions = [];
      forEach(row.redeemedInfo, (info) => {
        transactions.push(
          <div
            key={info.transactionId}
            style={{ color: "blue" }}
            onClick={() =>
              showModalTransaction({
                id: info.transactionId,
              })
            }
          >
            {info.transactionId}
          </div>
        );
      });
      return transactions;
    },
    disableSortBy: true,
  },

  {
    text: "Customer",
    // hidden: excludedColumns.includes("customerId"),
    dataField: "customerId",
    formatter: (cell, row: Voucher) => {
      if (row.customerId) {
        const name = `${customers[row.customerId]?.firstName} ${customers[row.customerId]?.lastName}`;
        return <Link to={`/crm/customers/${row.customerId}`}>{name}</Link>;
      }
    },
    disableSortBy: true,
    filterValue: (cell, row: Voucher) => {
      if (row.customerId) {
        const name = `${customers[row.customerId]?.firstName} ${customers[row.customerId]?.lastName}`;
        return name;
      }
    },
  },
  {
    text: "Enabled",
    id: "isEnabled",
    dataField: "isEnabled",
    formatter: (cell, row: Voucher) => <FontAwesomeIcon icon={row.isEnabled ? faCheck : (faTimes as any)} />,
    disableSortBy: true,
  },
];

const statusTypes = [
  { value: "", label: "All" },
  { value: 1, label: "Active Only" },
  { value: 0, label: "Expired Only" },
];

const VoucherIssuedList = () => {
  const { client, functions } = getConfig();

  const { allRestaurants, restaurantId, products, productSizes } = useAppSelector((state) => ({
    allRestaurants: state.root.restaurants,
    restaurantId: state.root.restaurantId,
    products: state.root.products,
    productSizes: state.root.productSizes,
  }));
  const [vouchers, setVouchers] = useState([]);
  const [customers, setCustomers] = useState<Customers>({});
  const [restaurants, setRestaurants] = useState([restaurantId]);
  const [voucherType, setVoucherType] = useState("");
  const [reasonType, setReasonType] = useState("");
  const [isActive, setIsActive] = useState();
  const [filterEnabled, setFilterEnabled] = useState(true);
  const [reasonTypes, setReasonTypes] = useState<ReasonTypes>({});
  const [openVoucherModalById, setOpenVoucherModalById] = useState("");

  useEffect(() => {
    const load = async () => {
      try {
        const [snap1, snap2] = await Promise.all([firebase.firestore().collection(`${client}/crm/vouchers`).orderBy("issuedDate", "desc").get(), firebase.firestore().collection(`${client}/crm/reasonTypes`).get()]);
        const result = [];
        snap1.docs.forEach((doc) => result.push({ ...doc.data(), id: doc.id }));
        const promisesCustomers = map(
          filter(result, (b) => !isNil(b.customerId)),
          (item) => firebase.firestore().doc(`${client}/crm/customers/${item.customerId}`).get()
        );
        const resultsCustomers = await Promise.all(promisesCustomers);
        const customers1 = buildDataFromDocs(resultsCustomers) as Customers;
        setCustomers(customers1);
        setVouchers(result);
        setReasonTypes(buildDataFromDocs(snap2.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    load();
  }, []);

  const columns = voucherColumns(allRestaurants, customers, reasonTypes, setOpenVoucherModalById);
  const { SearchBar } = Search;

  const voucherList = chain(vouchers)
    .filter((item) => (filterEnabled === null ? true : item.isEnabled === filterEnabled))
    .filter((item) => (isEmpty(isActive) ? true : isActive == 1 ? moment(item.expiryDate.seconds * 1000) >= moment() : moment(item.expiryDate.seconds * 1000) < moment()))
    .filter((item) => (isEmpty(reasonType) ? true : item.reasonType == reasonType))
    .filter((item) => (isEmpty(voucherType) ? true : item.voucherType == voucherType))
    .filter((item) => {
      let isSelected = false;
      restaurants.map((restaurantId) => {
        isSelected = item.restaurants.includes(restaurantId);
      });
      return isSelected;
    })
    .value();

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" headerText="Vouchers Issued" subHeaderText="Voucher Issued" showRestaurants={false} />
      <Row className="mb-2">
        <Col lg={4} sm={4}>
          <FormGroup>
            <Label>Restaurants</Label>
            <ReactSelect
              isMulti
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              options={Object.entries(renderRestaurants(allRestaurants)).map(([v, { name }]: any) => ({ value: v, label: name }))}
              value={getReactSelectValue(
                Object.entries(renderRestaurants(allRestaurants)).map(([v, { name }]: any) => ({ value: v, label: name })),
                restaurants
              )}
              onChange={(restaurants) => setRestaurants(map(restaurants, (r) => r.value))}
              styles={selectCustomStyles}
              isDisabled={false}
            />
          </FormGroup>
        </Col>
        <Col lg={8} sm={8}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Voucher Type</Label>
                <select className="form-control" onChange={(e) => setVoucherType(e.target.value)} value={voucherType}>
                  <option value="">All</option>
                  {voucherTypes.map((voucherType, index) => (
                    <option key={`option-voucher-${index}`} value={voucherType.value}>
                      {voucherType.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Reason Type</Label>
                <select className="form-control" onChange={(e) => setReasonType(e.target.value)} value={reasonType}>
                  <option value="">All</option>
                  {keys(reasonTypes).map((reasonTypeId, index) => (
                    <option key={`option-reason-${index}`} value={reasonTypeId}>
                      {reasonTypes[reasonTypeId].name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Status</Label>
                <select className="form-control" onChange={(e) => setIsActive(e.target.value as any)} value={isActive}>
                  {statusTypes.map((item, index) => (
                    <option key={`option-status-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col>
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="pt-1 mt-4 form-group" getName={getFilterName} onSelect={(e: any) => setFilterEnabled(e)} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Card className="card-default">
        <CardBody>
          <div className="clearfix" />
          <ToolkitProvider keyField="voucherNumber" data={voucherList} columns={columns} search>
            {(props) => (
              <div>
                <div style={{ height: 800, overflow: "auto" }} className="form-group col-lg-12 no-click">
                  <SearchBar {...props.searchProps} style={{ width: "400px" }} placeholder="Search By Voucher Number/ Customer / Dates" />
                  <BootstrapTable classes="table-striped table-sm" {...props.baseProps} />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>

      {openVoucherModalById ? (
        <Modal isOpen size="xl" zIndex={9999} toggle={() => setOpenVoucherModalById("")} scrollable>
          <ModalHeader toggle={() => setOpenVoucherModalById("")} tag="h4">
            Voucher Details
          </ModalHeader>
          <ModalBody className="voucher-modal-body">
            <VoucherIssuedForm functions={functions} productSizes={productSizes} voucherID={openVoucherModalById} clientId={client} restaurants={allRestaurants} products={products} readOnly={false} />
          </ModalBody>
        </Modal>
      ) : null}
    </ContentWrapper>
  );
};
export default VoucherIssuedList;
