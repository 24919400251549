import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import { useAppSelector } from "components/Hooks/hooks";
import useMenuHeadings from "components/Hooks/useMenuHeadings";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { getConfig } from "core/utilities";
import { catchExceptionCallback } from "core/utils";
import firebase from "firebase/compat/app";
import { chain, isNil, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { AdditionalItemsCategories, BeverageMenuPackages } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import BeverageMenuModal from "./BeverageMenuModal";

let unsubscribe = null;

export default function BeverageMenusList() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(true);
  const [loading, setLoading] = useState(true);
  const [beverageMenus, setBeverageMenus] = useState<BeverageMenuPackages>({});
  const { client } = getConfig();
  const [selectedPackage, setSelectedPackage] = useState("");

  const { restaurantId, restaurants, productSizes } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
      restaurants: state.root.restaurants,
      productSizes: state.root.productSizes,
    };
  });

  const [loadingMenuHeadings, menuHeadings] = useMenuHeadings(restaurantId);

  const packageId = firebase.firestore().collection(`${client}/menus/beverageMenus`).doc().id;

  const [additionalItemsCategories, setAdditionalItemsCategories] = useState<AdditionalItemsCategories>({});

  useEffect(() => {
    async function fetch() {
      try {
        const snap = await firebase.firestore().collection(`${client}/menus/additionalItemsCategories`).get();
        setAdditionalItemsCategories(pickBy(buildDataFromDocs(snap.docs) as AdditionalItemsCategories, (c) => c.enabled));
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    const fetchAllPackages = async () => {
      setLoading(true);
      unsubscribe = firebase
        .firestore()
        .collection(`${client}/menus/beverageMenus`)
        .where("restaurantId", "==", restaurantId)
        .onSnapshot((snap) => {
          setBeverageMenus(pickBy(buildDataFromDocs(snap.docs) as BeverageMenuPackages, (c) => c.enabled));
          setLoading(false);
        });
    };
    if (restaurantId) fetchAllPackages();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [restaurantId]);

  return (
    <ContentWrapper>
      <ContentHeading
        parentText="Large Group and Function Menus"
        headerText="Large Group and Function Menus"
        subHeaderText={`Beverage Menus`}
        showRestaurants={true}
        restaurants={restaurants}
        restaurantId={restaurantId}
        onRestaurantChange={(restaurantId) => dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId: restaurantId })}
      />
      <Row>
        <Col lg={8} sm={12}>
          <div className="justify-content-between row mb-2">
            <div className="col-12 col-sm-4">
              <Button color="primary text-capitalize text-nowrap" onClick={() => setSelectedPackage(packageId)}>
                New Beverage Menus
              </Button>
            </div>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group m-0" getName={getFilterName} onSelect={(e) => setFilter(e)} />
            </div>
          </div>
          {!loading ? (
            <Card className="card-default">
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Menu Description</th>
                      <th>Enabled</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {chain(beverageMenus)
                      .pickBy((bm) => filter === null || bm.enabled === filter)
                      .keys()
                      .value()
                      .map((beverageMenuId) => {
                        return (
                          <tr key={beverageMenuId}>
                            <td>{beverageMenus[beverageMenuId].name}</td>
                            <td>{beverageMenus[beverageMenuId].description}</td>
                            <td>{beverageMenus[beverageMenuId].enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                            <td>
                              <Link to="#" onClick={() => setSelectedPackage(beverageMenuId)}>
                                Edit
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <Spinner size="sm" />
          )}
        </Col>
        {!loading && !loadingMenuHeadings && selectedPackage ? (
          <BeverageMenuModal
            isOpen={!isNil(selectedPackage)}
            toggle={() => setSelectedPackage("")}
            packageId={selectedPackage}
            selectedPackage={beverageMenus[selectedPackage]}
            productSizes={productSizes}
            menuHeadings={menuHeadings}
            isNew={beverageMenus[selectedPackage] == undefined}
            additionalItemsCategories={additionalItemsCategories}
          />
        ) : null}
      </Row>
    </ContentWrapper>
  );
}
