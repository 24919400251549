import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { ProductGroups } from "resbutler-utils/types/product";
import { buildDataFromDocs } from "resbutler-utils/utils";

const useProductGroups = (): [boolean, ProductGroups] => {
  const [loading, setLoading] = useState(true);
  const [productGroups, setProductGroups] = useState<ProductGroups>({});
  const { client } = getConfig();

  const fetch = async () => {
    setLoading(true);

    const unsubscribe = firebase
      .firestore()
      .collection(`${client}/products/productGroups`)
      .onSnapshot(
        (snap) => {
          setProductGroups(buildDataFromDocs(snap.docs) as ProductGroups);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          catchExceptionCallback(error);
        }
      );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  };

  useEffect(() => {
    fetch();
  }, []);

  return [loading, productGroups];
};

export default useProductGroups;
