import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ContentWrapper from "components/Layout/ContentWrapper";
import ContentHeading from "components/Layout/ContentHeading";
import { getRestaurants } from "system/RestaurantReducer";

import GiftCertificates from "./BootstrapTableComponent";

import { getGiftCertficates } from "../GiftCertificateAction";
import { useSelector } from "react-redux";
// import { catchExceptionCallback } from "resbutler-ui/utilities";
// import axios from "axios";
import { getConfig } from "core/utilities";
import { useNavigate } from "react-router";
// import { Client } from "resbutler-utils/types/client";

const Issued = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [client, setClient] = useState<Client>(null);
  const { client: clientId } = getConfig();
  // const [loadingClient, setLoadingClient] = useState(true);

  const { restaurants } = useSelector((state: any) => {
    return {
      restaurants: getRestaurants(state.root.restaurants, state.root.user),
      restaurantId: state.root.restaurantId,
      giftCertificates: state.giftCertificates.byId,
    };
  });

  // useEffect(() => {
  //   async function load() {
  //     try {
  //       setLoadingClient(true);
  //       const response = await axios.get(`${process.env.REACT_APP_ADMIN_REALTIME}/clients/${clientId}.json`);
  //       setClient(response.data);
  //     } catch (error) {
  //       catchExceptionCallback(error);
  //     } finally {
  //       setLoadingClient(false);
  //     }
  //   }
  //   load();
  // }, [clientId]);

  useEffect(() => {
    dispatch(getGiftCertficates());
  }, []);

  // if (loadingClient) return null;

  return (
    <ContentWrapper>
      <ContentHeading parentText="Gift Certificates " headerText="Gift Certificate Issued" showRestaurants={false} />
      <GiftCertificates navigate={navigate} restaurants={restaurants} clientId={clientId} />
    </ContentWrapper>
  );
};

export default Issued;
