export const WINDOW_GET_SIZE = "WINDOW_GET_SIZE";
export const GET_TIME = "GET_TIME";
export const GET_SERVER_TIME = "GET_SERVER_TIME";
export const GET_COMPONENT = "GET_COMPONENT";
export const CLEAR_COMPONENT = "CLEAR_COMPONENT";
export const CHANGE_RESTAURANT_ID = "CHANGE_RESTAURANT_ID";
export const GET_DROPDOWN_ACTION = "GET_DROPDOWN_ACTION";
export const GET_RESTAURANTS = "GET_RESTAURANTS";

export const GET_ROOT_UID = "GET_ROOT_UID";
export const GET_ROOT_USER = "GET_ROOT_USER";
export const GET_ROOT_MENUS = "GET_ROOT_MENUS";
export const GET_ROOT_MENUS_LOADING = "GET_ROOT_MENUS_LOADING";
export const GET_ROOT_PRODUCT_SIZES = "GET_ROOT_PRODUCT_SIZES";
export const UPDATE_FORM_POSITION = "UPDATE_FORM_POSITION";

export const GET_MAESTRO_DATA_LABELS = "GET_MAESTRO_DATA_LABELS";

export const CLOSE_BOOKING_LIST = "CLOSE_BOOKING_LIST";
export const CLOSE_GANTT_COMPONENT = "CLOSE_GANTT_COMPONENT";
export const GET_FILTER_DISABLED = "GET_FILTER_DISABLED";

export const SHOW_FORM_LOADING = "SHOW_FORM_LOADING";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const TOGGLE_OPEN = "TOGGLE_OPEN";

export const SHOW_PRODUCTION = "SHOW_PRODUCTION";
export const PRODUCTION_LABEL_CHANGE = "PRODUCTION_LABEL_CHANGE";
export const CLOSE_MESSAGES_COMPONENT = "CLOSE_MESSAGES_COMPONENT";
export const CLOSE_STANDBY_COMPONENT = "CLOSE_STANDBY_COMPONENT";
export const GET_GROUP_AREA_TYPES = "GET_GROUP_AREA_TYPES";

/* ADVERTISING PROMOTIONS */

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_SOURCES = "GET_SOURCES";
export const GET_ALL_SOURCES = "GET_ALL_SOURCES";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_SOURCE = "GET_SOURCE";
export const GET_BUILDER_SHORT_LINK = "GET_BUILDER_SHORT_LINK";

/* END ADVERTISING PROMOTIONS */

/* RESTAURANTS */
export const GET_TIME_ZONES = "GET_TIME_ZONES";
export const GET_RESTAURANTS_BY = "GET_RESTAURANTS_BY";
export const GET_RESTAURANTS_TABS = "GET_RESTAURANTS_TABS";

/* END RESTAURANTS */

export const GET_BUTLER_SERVICE_CATEGORIES = "GET_BUTLER_SERVICE_CATEGORIES";
export const GET_BUTLER_SERVICE_GROUPS = "GET_BUTLER_SERVICE_GROUPS";
export const GET_BUILDER_WIDGET_LINK = "GET_BUILDER_WIDGET_LINK";
export const GET_ALL_WIDGETS = "GET_ALL_WIDGETS";
export const GET_WIDGETS = "GET_WIDGETS";
export const GET_WIDGET_BY_ID = "GET_WIDGET_BY_ID";
export const GET_WIDGET_CONFIGURATIONS = "GET_WIDGET_CONFIGURATIONS";
export const GET_WIDGET_FIELDS = "GET_WIDGET_FIELDS";
export const GET_WIDGET_ORDER = "GET_WIDGET_ORDER";

/* PRODUCTS AND PRODUCT GROUPS */
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";

export const GET_MODIFIER_GROUPS = "GET_MODIFIER_GROUPS";
export const GET_ADDITION_GROUPS = "GET_ADDITION_GROUPS";
export const GET_UPSELL_GROUPS = "GET_UPSELL_GROUPS";
export const GET_PREPARATION_GROUPS = "GET_PREPARATION_GROUPS";
