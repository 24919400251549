import { numberFormatter, priceFormatter } from "_utils/format";
import { chain, forEach, isUndefined, map, set, sumBy } from "lodash";
import { ProductGroups } from "resbutler-utils/types/product";
import { ProductCategoryId } from "resbutler-utils/types/ProductCategoryId";

const calendarTypes = {
  dateRange: { value: "date-range", label: "Date Range" },
  management: { value: "management", label: "Management Calendar" },
};

const managementCalendarModes = {
  week: {
    value: "week",
    label: "Week",
  },
  month: {
    value: "month",
    label: "Month",
  },
};

interface IHierarchyData {
  [key: string]: {
    name: string;
  };
}

const productCategoryOrder = {
  [ProductCategoryId.PrepaidMenus]: 0,
  [ProductCategoryId.SaleProducts]: 1,
  [ProductCategoryId.OtherRevenue]: 2,
  [ProductCategoryId.FeesAndCharges]: 3,
};

// recursively search and collect product group hierarchy
const collectProductGroupHierarchyFromProductGroupId = (key, hierarchyData: IHierarchyData = {}, productGroups: ProductGroups) => {
  let hierarchy = hierarchyData;
  const productGroup = productGroups?.[key];

  if (productGroup) {
    hierarchy = {
      [key]: {
        name: productGroup.name,
      },
      ...hierarchy,
    };
  }

  if (productGroup?.parentGroup === "-1") {
    return hierarchy;
  } else if (productGroup?.parentGroup) {
    return collectProductGroupHierarchyFromProductGroupId(productGroup?.parentGroup, hierarchy, productGroups);
  }
};

// remove duplicates hierarchy
function removeRedundantHierarchies(hierarchyDataList: IHierarchyData[]) {
  const hierarchy = {};

  hierarchyDataList.forEach((obj) => {
    let temp = hierarchy;

    Object.keys(obj).forEach((key) => {
      if (!temp[key]) {
        temp[key] = {};
      }
      temp = temp[key];
    });
  });

  return hierarchy;
}

const getProductGroupHierarchyAndCategoryFromProductGroupIds = (productGroupIds: string[] = [], productGroups: ProductGroups) => {
  const results = [];

  /*
    STEP 1: recursively collect product group hierarchy
    STEP 2: build hierarchy by removing redundant hierarchy
  */
  if (productGroupIds?.length && productGroups) {
    productGroupIds.forEach((key) => {
      const data = collectProductGroupHierarchyFromProductGroupId(key, {}, productGroups);

      if (data) {
        results.push(data);
      }
    });
  }

  const filteredHierarchies = removeRedundantHierarchies(results);

  const groupByCategoriesHierarchies = chain(Object.keys(filteredHierarchies))
    .orderBy((productGroupId) => {
      const productGroup = productGroups[productGroupId] as any;
      const productGroupOrder = productGroup?.order;
      return productGroupOrder || productGroupId;
    })
    .filter((productGroupId) => {
      const productGroup = productGroups[productGroupId];
      const productGroupCategory = productGroup?.productCategoryId;
      return isUndefined(productCategoryOrder[productGroupCategory]) === false;
    })
    .groupBy((productGroupId) => {
      const productGroup = productGroups[productGroupId];
      const productGroupCategory = productGroup?.productCategoryId;
      return productCategoryOrder[productGroupCategory];
    })
    .value();

  const data = {};

  forEach(groupByCategoriesHierarchies, (groups, category) => {
    forEach(groups, (groupId) => {
      set(data, `${category}.${groupId}`, filteredHierarchies[groupId]);
    });
  });

  return data;
};

const getProductGroupHierarchyFromProductGroupIds = (productGroupIds: string[] = [], productGroups: ProductGroups) => {
  const results = [];

  /*
    STEP 1: recursively collect product group hierarchy
    STEP 2: build hierarchy by removing redundant hierarchy
  */
  if (productGroupIds?.length && productGroups) {
    productGroupIds.forEach((key) => {
      const data = collectProductGroupHierarchyFromProductGroupId(key, {}, productGroups);

      if (data) {
        results.push(data);
      }
    });
  }

  return removeRedundantHierarchies(results);
};

const displayAmount = (amount) => {
  return amount ? priceFormatter.format(amount) : null;
};

const displayUnit = (unit) => {
  return unit ? numberFormatter.format(unit) : null;
};

const getTotal = (lines, key: string) => {
  return sumBy(lines, (l) => l[key]);
};

const getTotalPercent = (lines, key: string) => {
  let totalItems = 0;

  let totalPercentage = sumBy(lines, (l) => {
    if (l?.[key]) {
      totalItems += 1;
    }

    return l?.[key];
  });

  totalPercentage = totalPercentage ? totalPercentage / totalItems : 0;

  return totalPercentage;
};

// subtotal
const getSubTotal = (linesCollection, key) => {
  return sumBy(map(linesCollection), (lines) => {
    return getTotal(lines, key);
  });
};

const getSubTotalPercent = (linesCollection, key) => {
  let totalItems = 0;

  let totalPercentage = sumBy(map(linesCollection), (lines) => {
    const total = getTotalPercent(lines, key);

    if (total) {
      totalItems += 1;
    }

    return total;
  });

  totalPercentage = totalPercentage ? totalPercentage / totalItems : 0;

  return totalPercentage;
};

const normalizeProductTreeCollectionForTotalSum = (collections, rawCollections) => {
  const list = [];

  const collectLinesCollection = (collections) => {
    forEach(collections, (items, productGroupId) => {
      const item = rawCollections?.[productGroupId];
      const linesByProductId = item?.lines;

      if (linesByProductId) {
        list.push(linesByProductId);
      }

      if (Object.keys(items).length) {
        collectLinesCollection(items);
      }
    });
  };

  collectLinesCollection(collections);

  return list;
};

const normalizeMenuHeadingsCollectionForTotalSum = (collections, rawCollections) => {
  const list = [];

  forEach(collections, (menuByMenuHeadingId) => {
    forEach(menuByMenuHeadingId, (menuId) => {
      const reports = rawCollections?.[menuId];

      if (reports) {
        list.push(reports.lines);
      }
    });
  });

  return list;
};

const getGrandTotalForMenuRevenue = (collections, rawCollections, key) => {
  const list = normalizeMenuHeadingsCollectionForTotalSum(collections, rawCollections);

  const total = sumBy(list, (item) => {
    return getSubTotal(item, key);
  });

  return total;
};

const getGrandTotalPercentForMenuRevenue = (collections, rawCollections, key) => {
  const list = normalizeMenuHeadingsCollectionForTotalSum(collections, rawCollections);
  let totalItems = 0;

  let totalPercentage = sumBy(list, (lines) => {
    const total = getSubTotalPercent(lines, key);

    if (total) {
      totalItems += 1;
    }

    return total;
  });

  totalPercentage = totalPercentage ? totalPercentage / totalItems : 0;

  return totalPercentage;
};

const getGrandTotalForProductRevenue = (collections, rawCollections, key) => {
  const list = normalizeProductTreeCollectionForTotalSum(collections, rawCollections);

  const total = sumBy(list, (item) => {
    return getSubTotal(item, key);
  });

  return total;
};

const getGrandTotalPercentForProductRevenue = (collections, rawCollections, key) => {
  const list = normalizeProductTreeCollectionForTotalSum(collections, rawCollections);
  let totalItems = 0;

  let totalPercentage = sumBy(list, (lines) => {
    const total = getSubTotalPercent(lines, key);

    if (total) {
      totalItems += 1;
    }

    return total;
  });

  totalPercentage = totalPercentage ? totalPercentage / totalItems : 0;

  return totalPercentage;
};

export {
  calendarTypes,
  displayAmount,
  displayUnit,
  getGrandTotalForMenuRevenue,
  getGrandTotalForProductRevenue,
  getGrandTotalPercentForMenuRevenue,
  getGrandTotalPercentForProductRevenue,
  getProductGroupHierarchyAndCategoryFromProductGroupIds,
  getProductGroupHierarchyFromProductGroupIds,
  getSubTotal,
  getSubTotalPercent,
  getTotal,
  getTotalPercent,
  managementCalendarModes,
  normalizeProductTreeCollectionForTotalSum,
  productCategoryOrder
};

