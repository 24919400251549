import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import { useAppSelector } from "components/Hooks/hooks";
import useMenuHeadings from "components/Hooks/useMenuHeadings";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { getListItem } from "core/react-utils";
import { capitalizeFirstLetter, catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { chain, isBoolean, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import { Packages } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import PackageModalForm from "./PackageModalForm";

export const changeRestaurantHandler = (dispatch, restaurantId) => {
  dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId });
};

let unsubscribe = null;

const PackageList = (props) => {
  const [filter, setFilter] = useState({ enabled: true });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState<Packages>({});
  const { restaurantId, restaurants, productSizes } = useAppSelector((state) => ({
    restaurantId: state.root.restaurantId,
    restaurants: state.root.restaurants,
    productSizes: state.root.productSizes,
  }));
  const { client } = getConfig();
  const [, menuHeadings] = useMenuHeadings(restaurantId);
  const packageName = props.packageName == "additionalItem" ? "item" : props.packageName;
  const firebasePackageName = props.packageName;
  const packageId = firebase.firestore().collection(`${client}/functionAdditionals/${firebasePackageName}/`).doc().id;

  useEffect(() => {
    const fetchAllPackages = async () => {
      setLoading(false);
      unsubscribe = firebase
        .firestore()
        .collection(`${client}/functionAdditionals/${firebasePackageName}Packages`)
        .where("restaurantId", "==", restaurantId)
        .onSnapshot((snap) => {
          const packages1 = buildDataFromDocs(snap.docs);
          setPackages(packages1 as Packages);
          setLoading(false);
        }, catchExceptionCallback);
    };
    if (restaurantId) fetchAllPackages();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [restaurantId, firebasePackageName]);

  const packages1 = isBoolean(filter.enabled) ? pickBy(packages, (item) => item.enabled === filter.enabled) : packages;

  const updateRank = async (packageId1: string, packageId2: string, index1: number, index2: number) => {
    try {
      await Promise.all([
        firebase.firestore().doc(`${client}/functionAdditionals/${firebasePackageName}Packages/${packageId1}`).set({ order: index2 }, { merge: true }),
        firebase.firestore().doc(`${client}/functionAdditionals/${firebasePackageName}Packages/${packageId2}`).set({ order: index1 }, { merge: true }),
      ]);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const sortedPackages = chain(packages1)
    .keys()
    .orderBy((key) => packages1[key]?.order)
    .value();

  return (
    <ContentWrapper>
      <ContentHeading
        parentText="Function Additionals"
        headerText={`Additional ${capitalizeFirstLetter(packageName)}`}
        subHeaderText={`Additional ${capitalizeFirstLetter(packageName)}`}
        showRestaurants
        restaurants={restaurants}
        restaurantId={restaurantId}
        onRestaurantChange={(e) => changeRestaurantHandler(dispatch, e)}
      />
      <Row>
        <Col lg={12} sm={12}>
          <div className="justify-content-between row">
            <div className="col-12 col-sm-4">
              <Button color="primary text-capitalize" onClick={() => setSelectedPackage(packageId)}>
                {`New Additional ${capitalizeFirstLetter(packageName)} Group`}
              </Button>
            </div>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group m-0" getName={getFilterName} onSelect={(e) => setFilter({ enabled: e })} />
            </div>
          </div>
        </Col>

        {loading ? (
          <Spinner size="sm" />
        ) : (
          <Col className="mt-3">
            <Card className="card-default">
              <CardBody>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Description</td>
                      <td>Enabled</td> <th>Order</th>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPackages.map((pkgId, index) => {
                      const pkg = packages1[pkgId];
                      return (
                        <tr key={pkgId}>
                          <td>{pkg.name}</td>
                          <td>{pkg.description}</td>
                          <td>{pkg.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                          <td>{getListItem(undefined, sortedPackages, index, updateRank)}</td>
                          <td>
                            <Link to="#" onClick={() => setSelectedPackage(pkgId)}>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        )}

        {!loading && selectedPackage ? (
          <PackageModalForm isOpen toggle={() => setSelectedPackage("")} packageId={selectedPackage} firebasePackageName={firebasePackageName} selectedPackage={packages[selectedPackage]} productSizes={productSizes} menuHeadings={menuHeadings} isNew={packages[selectedPackage] == undefined} />
        ) : null}
      </Row>
    </ContentWrapper>
  );
};

export default PackageList;
