import axios from "axios";
// import { useAppSelector } from "components/Hooks/hooks";
// import { ADMIN_URL } from "core/auth";
// import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import moment from "moment";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, Spinner } from "reactstrap";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { VoucherSetup } from "../../../resbutler-utils/types/Voucher";
import { Product, ProductSizes } from "../../../resbutler-utils/types/product";
import { catchExceptionCallback } from "../../utilities";
import VoucherBasicForm from "./VoucherBasicForm";
import VoucherCustomerFiltersForm from "./VoucherCustomerFiltersForm";
import VoucherTemplate from "./VoucherTemplate";

const VoucherFormCard = ({
  voucher,
  voucherId,
  restaurantId,
  restaurants,
  clientId,
  tenantId,
  publicStorageBucket,
  disabled,
  templates,
  productSizes,
  products,
  ADMIN_URL,
  resbutlerApis,
  customerGroups,
  readOnlyFieldNames = [],
  customerId = "",
  maxAllowedDiscountAmount,
  hideReadonlyFormElements,
}: {
  voucher: VoucherSetup;
  voucherId: string;
  restaurantId: string;
  restaurants: Restaurants;
  clientId: string;
  tenantId: string;
  publicStorageBucket: string;
  disabled: boolean;
  templates: any;
  productSizes: ProductSizes;
  products: Product[];
  ADMIN_URL: string;
  resbutlerApis: string;
  customerGroups: any;
  readOnlyFieldNames?: string[];
  customerId?: string;
  maxAllowedDiscountAmount?: number;
  hideReadonlyFormElements?: boolean;
}) => {
  const validate = (values: VoucherSetup) => {
    const errors: any = { template: {} };
    if (!values.name) {
      errors.name = "Please enter a name";
    }
    if (!values.description) {
      errors.description = "Please enter a description";
    }
    if (!values.restaurants?.length) {
      errors.restaurants = "Please select a restaurant";
    }
    if (!values.voucherType) {
      errors.voucherType = "Please select a voucher type";
    }
    if (!values.discountType) {
      errors.discountType = "Please select a discount type";
    }
    if (!values.discountAmount) {
      errors.discountAmount = "Please select a discount Amount";
    }
    if (values.discountAmount && values.discountAmount > maxAllowedDiscountAmount) {
      errors.discountAmount = `Discount Amount cannot exceed ${maxAllowedDiscountAmount}`;
    }
    if (voucher.type == "oneOff" && !values.reasonType) {
      errors.reasonType = "Please enter a reason type";
    }
    if (!values.redeemQuantity) {
      errors.redeemQuantity = "Please enter a redeem quantity";
    }
    if (!values.voucherExpiryType) {
      errors.voucherExpiryType = "Please select an expiry type";
    }
    if (!values.voucherExpiryType) {
      errors.voucherExpiryType = "Please select an expiry type";
    }
    if (!values.voucherExpiryNumber) {
      errors.voucherExpiryNumber = "Please enter an expiry";
    }
    if (!values.generationType) {
      errors.generateType = "Please select a generation type";
    }
    if (values.generationType) {
      if (!values.voucherGenerateOn) {
        errors.voucherGenerateOn = "Please select a generation on";
      }
      if (values.isExpirable && !values.voucherGenerateExpiry) {
        errors.voucherGenerateOn = "Please select expiry date ";
      }
    }
    if (values.sendEmail) {
      if (!values.template?.templateId) {
        errors.template.templateId = "PLease select a email template";
      }
      if (!values.template?.headerText1) {
        errors.template.headerText1 = "Please enter a header text";
      }
      if (!values.template?.bodyText1) {
        errors.template.bodyText1 = "Please enter a body text";
      }
    }
    return errors;
  };

  const handleSubmit = async (values: VoucherSetup) => {
    try {
      let message = "";
      await firebase.firestore().doc(`${clientId}/crm/voucherSetups/${voucherId}`).set(values);
      if (values.isEnabled) {
        if (values.generationType == "oneOff" && moment(values.voucherGenerateOn?.seconds * 1000).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
          await axios.post(`${ADMIN_URL}/vouchers/processIndividualSetup`, { voucherSetupId: voucherId, clientId });
          message = "Voucher Sent";
        } else {
          message = "Voucher Scheduled";
        }
      } else {
        message = "Voucher Saved";
      }
      toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
    } catch (e) {
      catchExceptionCallback(e);
    }
  };

  return (
    <Card className="card-default">
      <Form<VoucherSetup>
        onSubmit={handleSubmit}
        initialValues={voucher}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <CardBody>
              <VoucherBasicForm tenantId={tenantId} restaurantId={restaurantId} productSizes={productSizes} disabled={disabled} publicStorageBucket={publicStorageBucket} clientId={clientId} allRestaurants={restaurants} products={products} readOnlyFieldNames={readOnlyFieldNames} hideReadonlyFormElements={hideReadonlyFormElements} />
              <VoucherTemplate disabled={disabled} templates={templates} restaurants={restaurants} clientId={clientId} products={products} productSizes={productSizes} />
              <VoucherCustomerFiltersForm disabled={disabled || !!customerId} clientId={clientId} resbutlerApis={resbutlerApis} customerGroups={customerGroups} />
            </CardBody>
            {!disabled && (
              <CardFooter style={{ height: 60 }}>
                <Button color="success" className="float-right" type="submit" disabled={submitting}>
                  Save {submitting ? <Spinner size="sm" /> : null}
                </Button>
              </CardFooter>
            )}
          </form>
        )}
      />
    </Card>
  );
};
export default VoucherFormCard;
