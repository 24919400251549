import algoliasearch from "algoliasearch";
// import { useAppSelector } from "components/Hooks/hooks";
// import ProductRow from "components/Product/ProductRow";
// import { requiredField } from "core/react-utils";
// import { catchExceptionCallback } from "core/utils";
// import { renderRestaurants } from "crm/customers/CustomerModel";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { chain, get, map, pickBy } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Field, useForm, useFormState } from "react-final-form";
import ReactSelect from "react-select";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { catchExceptionCallback } from "resbutler-ui/utilities";
import { Restaurant, Restaurants } from "resbutler-utils/types/Restaurant";
import { ReasonTypes, VoucherSetup, VoucherTypes } from "resbutler-utils/types/Voucher";
import { Product, ProductSizes } from "resbutler-utils/types/product";
import { buildDataFromDocs } from "resbutler-utils/utils";
import { ProductSearchModal } from "../ProductSearch/ProductSearchModal";
import ProductRow from "../Products/ProductRow";
// import { getReactSelectValue } from "../../../_utils/react-select";
// import { getConfig, selectCustomStyles } from "../../../core/utilities";

export const voucherTypes = [
  { label: "Deferred Booking Voucher", value: VoucherTypes.DeferredBookingVoucher },
  { label: "Donation", value: VoucherTypes.Donation },
  { label: "Promotion", value: VoucherTypes.Promotion },
];

const voucherExpiryTypes = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

/**
 * Work-around for non Vite app that uses resbutler-ui submodule
 * because 'env' does not exist on type 'ImportMeta'.
 */
declare global {
  interface ImportMeta {
    readonly env: {
      [key: string]: any;
      BASE_URL: string;
      MODE: string;
      DEV: boolean;
      PROD: boolean;
      SSR: boolean;
    };
  }
}

const getEnvConfig = (name: string) => (typeof process !== "undefined" ? get(process.env, name) : get(import.meta.env, name));

// eslint-disable-next-line no-undef
const searchClient = algoliasearch(getEnvConfig("REACT_APP_ALGOLIA_APPLICATION_ID"), getEnvConfig("REACT_APP_ALGOLIA"));
type VoucherBasicFormProps = {
  clientId: string;
  tenantId: string;
  publicStorageBucket: string;
  restaurantId: string;
  disabled: boolean;
  allRestaurants?: Restaurants;
  productSizes?: ProductSizes;
  products: Product[];
  readOnlyFieldNames?: string[];
  hideReadonlyFormElements?: boolean;
};

const VoucherBasicForm = ({ clientId, tenantId, publicStorageBucket, restaurantId, disabled, allRestaurants, productSizes, products, readOnlyFieldNames, hideReadonlyFormElements }: VoucherBasicFormProps) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [openDiscountProductModal, setOpenDiscountProductModal] = useState(false);
  const [reasonTypes, setReasonTypes] = useState<ReasonTypes>({});
  const [voucherExpiryDate, setVoucherExpiryDate] = useState("");
  function getReactSelectValue(opts, val) {
    const parsedVal = val ? val : [];
    return opts.filter((o) => parsedVal.includes(o.value));
  }

  useEffect(() => {
    async function load() {
      try {
        const snap = await firebase.firestore().collection(`${clientId}/crm/reasonTypes`).get();
        setReasonTypes(buildDataFromDocs(snap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    load();
  }, []);

  const voucher = useFormState<VoucherSetup>().values;
  const form = useForm();

  useEffect(() => {
    const { voucherExpiryNumber, voucherExpiryType, voucherGenerateOn } = voucher;
    const voucherGenerateDate = voucherGenerateOn || firebase.firestore.Timestamp.now().toDate();
    const expiry = moment(voucherGenerateDate)
      .add(voucherExpiryNumber, voucherExpiryType)
      .format("DD/MM/YYY");
    setVoucherExpiryDate(expiry);
  }, [voucher.voucherExpiryNumber, voucher.voucherExpiryType, voucher.voucherGenerateOn]);

  return (
    <Row>
      <Col>
        {/* Search for products and add */}
        <ProductSearchModal
          initialValues={voucher.products}
          clientId={clientId}
          publicStorageBucket={publicStorageBucket}
          open={searchOpen}
          setOpen={setSearchOpen}
          onCloseData={(products) => {
            if (products?.length) form.change("products", products);
          }}
          tenantId={tenantId}
          searchClient={searchClient}
          restaurantId={restaurantId}
          productSizes={productSizes}
        />
        <Field name="products" type="hidden" component="input"></Field>

        <div className="form-group">
          <Field name="name">
            {({ input: { onChange, value, name }, meta }) => {
              return (
                <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
                  <Label>
                    Name <span className="text-danger">*</span>
                  </Label>
                  <input type="text" className="form-control" disabled={disabled} value={value} onChange={onChange} readOnly={readOnlyFieldNames.includes(name)} />
                  <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                </FormGroup>
              );
            }}
          </Field>
        </div>

        <div className="form-group">
          <Field name="description">
            {({ input: { onChange, value, name }, meta }) => {
              return (
                <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
                  <Label>
                    Description <span className="text-danger">*</span>
                  </Label>
                  <input type="text" className="form-control" disabled={disabled} value={value} onChange={onChange} readOnly={readOnlyFieldNames.includes(name)} />
                  <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                </FormGroup>
              );
            }}
          </Field>
        </div>

        <Field name="restaurants">
          {({ input: { onChange, value, name }, meta }) => (
            <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
              <Label>
                Redeemable Restaurants <span className="text-danger">*</span>{" "}
              </Label>
              <ReactSelect
                isMulti
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                options={Object.entries(pickBy(allRestaurants, (r) => r.enabled)).map(([v, { name }]: [string, Restaurant]) => ({
                  value: v,
                  label: name,
                }))}
                value={getReactSelectValue(
                  Object.entries(pickBy(allRestaurants, (r) => r.enabled)).map(([v, { name }]: [string, Restaurant]) => ({
                    value: v,
                    label: name,
                  })),
                  value
                )}
                onChange={(restaurants) => onChange(map(restaurants, (r) => r.value))}
                isDisabled={disabled || readOnlyFieldNames.includes(name)}
              />
              {readOnlyFieldNames.includes(name) && <input className="form-control" value={value} type="hidden" />}
              <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
            </FormGroup>
          )}
        </Field>

        <Field name="voucherType">
          {({ input: { onChange, value, name }, meta }) => (
            <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
              <Label>
                Voucher Type <span className="text-danger">*</span>
              </Label>
              <select className="form-control" onChange={(e) => onChange(e.target.value ? Number(e.target.value) : onChange(null))} value={value} disabled={disabled || readOnlyFieldNames.includes(name)}>
                <option value="">Please Select</option>
                {map(voucherTypes, (voucherType, index) => (
                  <option key={`option-voucher-${index}`} value={voucherType.value}>
                    {voucherType.label}
                  </option>
                ))}
              </select>
              {readOnlyFieldNames.includes(name) && <input className="form-control" value={value} type="hidden" />}
              <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
            </FormGroup>
          )}
        </Field>

        {voucher.type == "oneOff" && (
          <Field name="reasonType">
            {({ input: { onChange, value }, meta }) => (
              <FormGroup>
                <Label>
                  Reason Type <span className="text-danger">*</span>
                </Label>
                <select className="form-control" onChange={onChange} value={value} disabled={disabled}>
                  <option value="">Please Select</option>
                  {chain(reasonTypes)
                    .pickBy((rt) => Number(rt.voucherType) === voucher.voucherType)
                    .keys()
                    .map((reasonTypeId) => (
                      <option key={`option-reason-${reasonTypeId}`} value={reasonTypeId}>
                        {reasonTypes[reasonTypeId].name}
                      </option>
                    ))
                    .value()}
                </select>
                <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
              </FormGroup>
            )}
          </Field>
        )}

        <Field name="discountType">
          {({ input: { onChange, value, name }, meta }) => (
            <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
              <Label>
                Discount Type <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                onChange={(e) => {
                  onChange(e.target.value);
                  if (voucher.discountType !== "product") {
                    form.change("complementaryProducts", undefined);
                  }
                }}
                value={value}
                disabled={disabled || readOnlyFieldNames.includes(name)}
              >
                <option value="">Please Select</option>
                <option key={`discount-type-%`} value={"%"}>
                  %
                </option>
                <option key={`discount-type-$`} value={"$"}>
                  $
                </option>
              </select>
              {readOnlyFieldNames.includes(name) && <input className="form-control" value={value} type="hidden" />}
              <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
            </FormGroup>
          )}
        </Field>

        {voucher.discountType === "$" || voucher.discountType === "%" ? (
          <Field name="discountAmount">
            {({ input: { onChange, value }, meta }) => {
              return (
                <FormGroup>
                  <Label>
                    Discount Amount {voucher.discountType ? `(${voucher.discountType})` : ""} <span className="text-danger">*</span>
                  </Label>
                  <Row>
                    <Col lg={5} sm={12}>
                      <input name="discountAmount" type="number" className="form-control" value={value} disabled={disabled} onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)} />
                    </Col>
                  </Row>
                  <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                </FormGroup>
              );
            }}
          </Field>
        ) : null}

        {voucher.discountType === "product" ? (
          <div className="mb-4">
            <Button color="primary" onClick={() => setOpenDiscountProductModal(true)}>
              Add products
            </Button>
            <ProductSearchModal initialValues={voucher?.complementaryProducts || []} clientId={clientId} publicStorageBucket={publicStorageBucket} open={openDiscountProductModal} setOpen={setOpenDiscountProductModal} onCloseData={(products) => form.change("complementaryProducts", products)} tenantId={tenantId} searchClient={searchClient} restaurantId={restaurantId} productSizes={productSizes} />
          </div>
        ) : null}

        {voucher.complementaryProducts?.length > 0 && voucher.discountType === "product" ? (
          <Table bordered size="sm" className="mb-4">
            <thead>
              <th>Name</th>
              <th>Size</th>
              <th></th>
            </thead>
            <tbody>
              {voucher.complementaryProducts.map((item1) => {
                const product = products?.[item1.productId];
                const sizes = item1.sizes;
                return (
                  <tr key={item1.productId}>
                    <td className="align-middle">{product.name}</td>
                    <td className="align-middle">
                      {map(sizes, (val, key) => {
                        if (val === false) {
                          return null;
                        }
                        const size = productSizes?.[key];
                        return size.name;
                      })
                        .filter(Boolean)
                        .join(", ")}
                    </td>
                    <td className="align-middle">
                      <Button
                        type="button"
                        color="danger"
                        size="sm"
                        onClick={() => {
                          const temp = voucher.complementaryProducts.filter((i) => i.productId !== item1.productId);
                          form.change("complementaryProducts", temp);
                        }}
                        aria-label="Delete"
                        title="Delete"
                        className="ml-2"
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : null}

        <Field name="allProducts">
          {({ input: { onChange, value, name } }) => {
            return (
              <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
                <div className="checkbox c-checkbox">
                  <label className="mb-1">
                    <Input checked={value} type="checkbox" disabled={disabled} onChange={() => onChange(!value)} readOnly={readOnlyFieldNames.includes(name)} />
                    <span className="fa fa-check" />
                    Apply to All Products
                  </label>
                </div>
              </FormGroup>
            );
          }}
        </Field>

        {/* PRODUCTS */}
        {!voucher.allProducts && (
          <div>
            <Button color="primary" onClick={() => setSearchOpen(true)}>
              Add products
            </Button>
            <br />
            <br />
            {voucher.products?.length !== 0 && (
              <Table style={{ backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                    {voucher.restaurants?.map((r) => <th key={r}>Price: {allRestaurants[r].name}</th>)}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {voucher.products?.map(({ productId, sizes }) => (
                    <ProductRow
                      restaurants={voucher.restaurants}
                      productId={productId}
                      sizes={sizes}
                      allSizes={productSizes}
                      products={products}
                      removeProduct={({ productId }) =>
                        form.change(
                          "voucher.products",
                          voucher.products.filter((p) => productId !== p.productId)
                        )
                      }
                      key={productId}
                    />
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}

        <Field name="redeemQuantity">
          {({ input: { onChange, value }, meta }) => {
            return (
              <>
                <Label>
                  Redeem Quantity <span className="text-danger">*</span>
                </Label>
                <Row>
                  <Col lg={5} sm={12}>
                    <input name="redeemQuantity" type="number" className="form-control" value={value} disabled={disabled} onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)} />
                  </Col>
                </Row>
                <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
              </>
            );
          }}
        </Field>

        <FormGroup className="mt-3">
          <Label>
            Voucher Expiry (from date of issue) <span className="text-danger">*</span>
          </Label>
          <Row>
            <Field name="voucherExpiryNumber">
              {({ input: { onChange, value }, meta }) => {
                return (
                  <>
                    <Col lg={2} sm={3}>
                      <input name="voucherExpiryNumber" type="number" className="form-control" value={value} disabled={disabled} onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)} />
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </Col>
                  </>
                );
              }}
            </Field>

            <Field name="voucherExpiryType">
              {({ input: { onChange, value }, meta }) => {
                return (
                  <>
                    <Col lg={3} sm={5}>
                      <select className="form-control" onChange={onChange} value={value} disabled={disabled}>
                        <option value="">Please Select</option>
                        {voucherExpiryTypes.map((voucherType) => (
                          <option key={voucherType.value} value={voucherType.value}>
                            {voucherType.label}
                          </option>
                        ))}
                      </select>
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </Col>
                    <Col lg={3} sm={5} className="pt-2">
                      Expires on {voucherExpiryDate}
                    </Col>
                  </>
                );
              }}
            </Field>
          </Row>
        </FormGroup>

        {voucher.type != "oneOff" && (
          <Field name="generationType">
            {({ input: { onChange, value }, meta }) => {
              return (
                <FormGroup>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Label>
                        Generation Type <span className="text-danger">*</span>
                      </Label>
                      <Row className="ml-2">
                        <Col>
                          <Label check className="ml-2">
                            <Input
                              disabled={disabled}
                              type="radio"
                              id="1"
                              checked={"recurring" === value}
                              onChange={() => {
                                form.change("voucherGenerateOn", "");
                                onChange("recurring");
                              }}
                            />{" "}
                            Recurring
                          </Label>
                        </Col>
                        <Col>
                          <Label check className="ml-2">
                            <Input
                              disabled={disabled}
                              type="radio"
                              id="2"
                              checked={value === "oneOff"}
                              onChange={() => {
                                form.change("voucherGenerateOn", firebase.firestore.Timestamp.now());
                                onChange("oneOff");
                              }}
                            />{" "}
                            One-off
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                </FormGroup>
              );
            }}
          </Field>
        )}

        <div>
          <hr />
          {voucher.generationType === "recurring" ? (
            <div>
              <h5>
                <b>Recurring Generation Settings</b>
              </h5>

              <Field name="voucherGenerateOn">
                {({ input: { onChange, value }, meta }) => (
                  <FormGroup>
                    <Row className="mt-2">
                      <Col lg={4} sm={4}>
                        <Label>Generate Voucher on</Label>
                        <span className="text-danger">*</span>
                        <select className="form-control" onChange={onChange} value={value} disabled={disabled}>
                          <option value="">Please Select</option>
                          <option key={`firstDayOfMonth`} value={"firstDayOfMonth"}>
                            First day of the month
                          </option>
                          <option key={`lastDayOfMonth`} value={"lastDayOfMonth"}>
                            Last day of the month
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                  </FormGroup>
                )}
              </Field>

              <Field name="isExpirable">
                {({ input: { onChange, value }, meta }) => {
                  return (
                    <FormGroup>
                      <Row>
                        <Col>
                          <div className="checkbox c-checkbox">
                            <label className="mt-lg-2">
                              <Input checked={value} type="checkbox" onChange={() => onChange(!value)} disabled={disabled} />
                              <span className="fa fa-check" />
                              Set generation expiry
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field<firebase.firestore.Timestamp> name="voucherGenerateExpiry">
                {({ input: { onChange, value }, meta }) => {
                  return voucher.isExpirable ? (
                    <>
                      <FormGroup>
                        <label>
                          Generation Expiry Date<span className="text-danger">*</span>
                        </label>
                        <Row>
                          <Col lg={6}>
                            <Datetime dateFormat="DD/MM/YYYY" timeFormat={false} closeOnSelect={true} value={moment(value?.seconds * 1000 || value)} onChange={(e: Moment) => onChange(firebase.firestore.Timestamp.fromDate(e.toDate()))} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </>
                  ) : null;
                }}
              </Field>
            </div>
          ) : (
            <div className={hideReadonlyFormElements && readOnlyFieldNames.includes("voucherGenerateOn") ? "d-none" : ""}>
              <h5>
                <b>One-Off Generation Settings</b>
              </h5>
              <Field<firebase.firestore.Timestamp> name="voucherGenerateOn">
                {({ input: { onChange, value }, meta, name }) => (
                  <FormGroup>
                    <Row className="mt-2">
                      <Col lg={4} sm={4}>
                        <Label>
                          Generate Voucher on <span className="text-danger">*</span>{" "}
                        </Label>
                        <Datetime inputProps={{ disabled: disabled }} dateFormat="DD/MM/YYYY" timeFormat={false} closeOnSelect={true} value={moment(value?.seconds * 1000 || value)} onChange={(e: Moment) => onChange(firebase.firestore.Timestamp.fromDate(e.toDate()))} />
                      </Col>
                    </Row>
                    <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                  </FormGroup>
                )}
              </Field>
            </div>
          )}
          <hr />
        </div>

        <Field name="isEnabled">
          {({ input: { onChange, value, name } }) => {
            return (
              <FormGroup className={hideReadonlyFormElements && readOnlyFieldNames.includes(name) ? "d-none" : ""}>
                <div className="checkbox c-checkbox">
                  <label className="mb-1">
                    <input checked={value} type="checkbox" onChange={() => onChange(!value)} readOnly={readOnlyFieldNames.includes(name)} />
                    <span className="fa fa-check" />
                    Enabled
                  </label>
                </div>
              </FormGroup>
            );
          }}
        </Field>
        <hr />
      </Col>
    </Row>
  );
};

export default VoucherBasicForm;
