import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import Menu from "../../Menu";
import { changeSetting } from "../../store/actions/actions";
import Logout from "../Logout";
import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={classNames({ active: isActive })}>
    <Link to={item.path} title={item.name} className={classNames({ "disabled-link": item.disabled })}>
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <div className="mx-3 text-wrap">{item.name}</div>
    </Link>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => {
  return (
    <li className={classNames({ active: isActive })}>
      <div className={classNames({ "disabled-link": item.disabled }, "nav-item")} onClick={handler}>
        <i className={classNames("fa text-muted", { "fa-chevron-down mr-2": isOpen, "fa-chevron-right mr-2": !isOpen })} aria-hidden="true" />

        {item.label && (
          <Badge tag="div" className="float-right" color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        <span>{item.name}</span>
      </div>

      <Collapse isOpen={isOpen}>
        <ul id={item.path} className="sidebar-nav sidebar-subnav">
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => <li className="sidebar-subnav-header">{item.name}</li>;

const Sidebar = () => {
  const [collapse, setCollapse] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state: any) => state.settings);
  const location = useLocation();
  useEffect(() => {
    // pass navigator to access router api
    SidebarRun(navigator, closeSidebar);
    // prepare the flags to handle menu collapsed states
    buildCollapseList();
  }, []);

  const closeSidebar = () => {
    dispatch(changeSetting("asideToggled", false));
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  const buildCollapseList = () => {
    const collapse = {};
    Menu.forEach(({ name, path, submenu }) => {
      collapse[name] = routeActive(submenu ? submenu.map((item) => item?.path) : path);
      if (submenu) {
        submenu.forEach((submenuitem) => {
          if (submenuitem.submenuchild) {
            collapse[submenuitem.name] = routeActive(submenuitem.submenuchild.map(({ path }) => path));
          }
        });
      }
    });
    setCollapse(collapse);
  };

  const navigator = (route) => {
    navigate(route); // remove '#' in case of use HashRouter
  };

  const routeActive = (paths) => {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => location.pathname.indexOf(p) > -1);
  };

  const toggleItemCollapse = (stateName) => {
    // eslint-disable-next-line
    for (let c in collapse) {
      if (collapse[c] === true && c !== stateName)
        setCollapse({
          ...collapse,
          [c]: false,
        });
    }
    setCollapse({
      ...collapse,
      [stateName]: !collapse[stateName],
    });
  };

  const getSubRoutes = (item) => (item.submenu ? item.submenu.map(({ path }) => path) : item.submenuchild.map(({ path }) => path));

  /** map menu config to string to determine which element to render */
  const itemType = (item) => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
    if (item.submenuchild) return "submenu";
  };

  return (
    <aside className="aside-container">
      {/* START Sidebar (left) */}
      <div className="aside-inner">
        <nav data-sidebar-anyclick-close="" className="sidebar">
          {/* START sidebar nav */}
          <ul className="sidebar-nav">
            {/* START user info */}
            <li className="has-user-block">
              <SidebarUserBlock />
            </li>
            {/* END user info */}

            {/* Iterates over all sidebar items */}
            {Menu.map((item, i) => {
              // heading
              if (itemType(item) === "heading") return <SidebarItemHeader item={item} key={i} />;
              else {
                if (itemType(item) === "menu") return <SidebarItem isActive={routeActive(item.path)} item={item} key={i} />;
                if (itemType(item) === "submenu")
                  return [
                    <SidebarSubItem item={item} isOpen={collapse[item.name]} handler={() => toggleItemCollapse(item.name)} isActive={routeActive(getSubRoutes(item))} key={i}>
                      <SidebarSubHeader item={item} key={i} />
                      {item.submenu.map((subitem, i) => {
                        if (subitem.submenuchild) {
                          return [
                            <SidebarSubItem item={subitem} isOpen={collapse[subitem.name]} handler={() => toggleItemCollapse(subitem.name)} isActive={routeActive(getSubRoutes(subitem))} key={i}>
                              <SidebarSubHeader item={subitem} key={i} />
                              {subitem.submenuchild.map((subchilditem, i) => (
                                <SidebarItem key={i} item={subchilditem} isActive={routeActive(subchilditem.path)} />
                              ))}
                            </SidebarSubItem>,
                          ];
                        } else {
                          return <SidebarItem key={i} item={subitem} isActive={routeActive(subitem.path)} />;
                        }
                      })}
                    </SidebarSubItem>,
                  ];
              }
              return null; // unrecognized item
            })}
          </ul>
          {/* END sidebar nav */}
          <div className="sideBarLogout">
            <Logout />
          </div>
        </nav>
      </div>

      {/* END Sidebar (left) */}
    </aside>
  );
};

export default Sidebar;
