import { useAppSelector } from "components/Hooks/hooks";
import { renderField, requiredField } from "core/react-utils";
import "firebase/compat/database";
import { Field } from "react-final-form";
import { Col, Row } from "reactstrap";
import { getRestaurantsSelector } from "selectors/selectors";

const BeverageMenusForm = () => {
  const { restaurants } = useAppSelector((state) => {
    return {
      restaurants: getRestaurantsSelector(state),
    };
  });

  return (
    <Row>
      <Col>
        <Field id="name" name="name" type="text" component={requiredField as any} label="Name" className="form-group" />
        <Field id="description" name="description" type="text" component={renderField as any} label="Description" className="form-group" />
        <Row className="form-group mb-3">
          <Col md="6">
            <Field name={`beverageOnConsumption`} type="checkbox">
              {({ input, meta }) => (
                <div className="checkbox c-checkbox">
                  <label className="m-0">
                    <input type="checkbox" className="form-check-input" {...input} />
                    <span className="fa fa-check"></span>Beverage On Consumption
                  </label>
                  {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row className="form-group mb-3">
          <Col md="6">
            <Field name={`barTab`} type="checkbox">
              {({ input, meta }) => (
                <div className="checkbox c-checkbox">
                  <label className="m-0">
                    <input type="checkbox" className="form-check-input" {...input} />
                    <span className="fa fa-check"></span>Bar Tab
                  </label>
                  {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Field id="minpax" name="minPax" type="number" min="0" step="1" parse={(value) => Number(value)} component={requiredField as any} label="Min Pax" className="form-group" />
          </Col>
          <Col lg={6}>
            <Field id="maxpax" name="maxPax" type="number" min="0" step="1" parse={(value) => Number(value)} component={requiredField as any} label="Max Pax" className="form-group" />
          </Col>
        </Row>
        <Row className="form-group">
          <Col>
            <Field name="restaurantId">
              {({ input, meta }) => (
                <>
                  <label>Restaurant</label>
                  <select {...input} disabled={input.value} className={"form-control"}>
                    {Object.keys(restaurants).map((key) => (
                      <option key={key} value={key}>
                        {restaurants[key].name}
                      </option>
                    ))}
                  </select>
                  {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                </>
              )}
            </Field>
          </Col>
        </Row>
        <Row className="form-group mb-3">
          <Col md="6">
            <Field name={`enabled`} type="checkbox">
              {({ input, meta }) => (
                <div className="checkbox c-checkbox">
                  <label className="m-0">
                    <input type="checkbox" className="form-check-input" {...input} />
                    <span className="fa fa-check"></span>Enabled
                  </label>
                  {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                </div>
              )}
            </Field>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default BeverageMenusForm;
