interface TableCellData {
  value: string;
  isNested: boolean;
  colSpan: number;
}

const getCellData = (cell: HTMLTableCellElement, isHeaderCell: boolean): TableCellData => {
  const nestedElements = cell.querySelectorAll("*");
  if (nestedElements.length > 0) {
    return { value: cell.textContent?.trim().replace(/(\r\n|\n|\r|\s\s)/gm, " "), isNested: true, colSpan: isHeaderCell ? cell.colSpan : 1 };
  } else {
    return { value: cell.dataset.val ? cell.dataset.val.replace(/(\r\n|\n|\r|\s\s)/gm, "") : cell.textContent?.trim().replace(/(\r\n|\n|\r|\s\s)/gm, " "), isNested: false, colSpan: isHeaderCell ? cell.colSpan : 1 };
  }
};

export default (tableElement, separator = ",", filename = "table") => {
  return new Promise((resolve, reject) => {
    if (!tableElement) {
      reject("Table not found");
    }

    const csvRows: string[] = [];

    Array.from(tableElement.rows).forEach((row: HTMLTableRowElement) => {
      const csvLine: string[] = [];
      let columnIndex = 0;

      const isHeaderRow = row.parentElement?.tagName.toLowerCase() === "thead";

      Array.from(row.cells).forEach((cell: HTMLTableCellElement) => {
        const cellData = getCellData(cell, isHeaderRow);

        for (let i = 0; i < cellData.colSpan; i++) {
          csvLine[columnIndex++] = `"${cellData.value.replace(/"/g, '""')}"`;
        }
      });

      csvRows.push(csvLine.join(separator));
    });

    if (!csvRows) {
      reject("Invalid Data");
    }

    const csvContent = csvRows.join("\n");
    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const csvUrl = URL.createObjectURL(csvBlob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = csvUrl;
    a.download = `${filename}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(csvUrl);

    resolve(csvContent);
  });
};
