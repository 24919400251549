import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { MenuHeadings } from "resbutler-utils/types/Menu";

const useMenuHeadings = (restaurantId: string): [boolean, MenuHeadings] => {
  const [loading, setLoading] = useState(true);
  const [menuHeadings, setMenuHeadings] = useState<MenuHeadings>({});

  useEffect(() => {
    async function fetch() {
      try {
        const { tenantId } = getConfig();
        setLoading(true);
        const snap = await firebase.database().ref(`${tenantId}/menuHeadings`).orderByChild("restaurantId").equalTo(restaurantId).once("value");
        setMenuHeadings((snap.val() || {}) as MenuHeadings);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    if (restaurantId) fetch();
  }, [restaurantId]);

  return [loading, menuHeadings];
};

export default useMenuHeadings;
