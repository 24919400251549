/* eslint-disable no-undef */
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { renderField, renderSketchControl, requiredDropdown, requiredField } from "core/react-utils";
import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { chunk, flow, range } from "lodash";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { GET_LEVEL_BY_ID, findAllLevels, findOneLevel, saveLevel } from "./LevelAction";
import { MembershipLevelTypes, validate } from "./LevelModel";

export interface LevelFormProps {
  membershipType: string;
  accountName: string;
  allocationPriority: number;
  resbutlerMembershipTypeId: string;
  cardBackgroundColor: string;
}

const MembershipLevelsForm = ({ readOnly, initialValues }) => {
  const { client } = getConfig();
  const params = useParams();
  const dispatch = useDispatch();

  const { levels, descriptionEditor, descriptionInitial } = useSelector((state: any) => ({
    levels: state.levels.byId,
    descriptionEditor: state.levels.descriptionEditor,
    descriptionInitial: state.levels.level && state.levels.level.description ? state.levels.level.description : "",
  }));

  const [modalOpen, setModalOpen] = useState(false);
  const [transferring, setTransferring] = useState(false);
  const [toGroupId, setToGroupId] = useState(undefined);

  useEffect(() => {
    const unsubscribe = () => {
      dispatch({ type: GET_LEVEL_BY_ID, level: null });
    };
    unsubscribe();
    dispatch(findAllLevels());
    if (params.id) dispatch(findOneLevel(params.id));
    return unsubscribe;
  }, []);

  const handleCloseModal = () => {
    if (!transferring) setModalOpen(false);
  };

  const save = (values) => {
    dispatch(
      saveLevel(
        {
          ...values,
          description: descriptionEditor,
        },
        params.id,
        "level-save"
      )
    );
  };

  const handleSubmitInner = async (values) => {
    // check for enabled-ness
    if (!values.enabled) {
      // const currentCustomerGroup = await firestore()
      //     .doc(`${client}/crm/customerGroups/${params.id}`).get()
      // const count = currentCustomerGroup.data().customerCount
      // if (count) {
      setModalOpen(true);
      // }
    } else {
      save(values);
    }
  };

  const handleTransfer = async (values) => {
    try {
      setTransferring(true);

      const [fromCustomerGroupRef, toCustomerGroupRef] = [firebase.firestore().doc(`${client}/crm/customerGroups/${params.id}`), firebase.firestore().doc(`${client}/crm/customerGroups/${toGroupId}`)];
      const [fromCustomerGroup] = await Promise.all([fromCustomerGroupRef.get(), toCustomerGroupRef.get()]);
      const fromCustomerGroupCount = fromCustomerGroup.data().customerCount || 0;
      const toCustomerGroupCount = fromCustomerGroup.data().customerCount || 0;
      // qs
      const qs = await firebase.firestore().collection(`${client}/crm/customers`).where("customerGroupId", "==", params.id).get();

      // https://stackoverflow.com/questions/52165352/how-can-i-update-more-than-500-docs-in-firestore-using-batch
      const batches = chunk(qs.docs, 500).map((docs) => {
        const batch = firebase.firestore().batch();
        docs.forEach((doc) => {
          batch.set(doc.ref, { customerGroupId: toGroupId }, { merge: true });
        });
        return batch.commit();
      });
      await Promise.all(batches);

      // update count
      await Promise.all([
        fromCustomerGroupRef.update({
          ...values,
          description: descriptionEditor,
          customerCount: 0,
        }),
        toCustomerGroupRef.update({
          customerCount: toCustomerGroupCount + fromCustomerGroupCount,
        }),
      ]);

      // save after transfer is completed.
      // save()
    } catch (e) {
      catchExceptionCallback(e);
    }
    setTransferring(false);
    setModalOpen(false);
  };

  return (
    <ContentWrapper>
      <Form<LevelFormProps>
        initialValues={initialValues}
        keepDirtyOnReinitialize
        validate={(values) =>
          validate(
            values,
            levels.filter((level) => level?._key && level._key !== params.id)
          )
        }
        onSubmit={handleSubmitInner}
        render={({ handleSubmit, submitting, form, values }) => (
          <>
            <Modal centered isOpen={modalOpen} toggle={() => handleCloseModal()} className={`reactstrap-confirm`}>
              <ModalHeader toggle={() => handleCloseModal()}>
                <strong>Disable Membership Level</strong>
              </ModalHeader>
              <ModalBody>
                Please choose an enabled membership level to transfer all members from {values.accountName}
                <Input type="select" value={toGroupId} onChange={(e) => setToGroupId(e.target.value)}>
                  <option value={undefined}>Please select...</option>
                  {levels
                    .filter((l) => !!l.enabled && l._key !== params.id)
                    .map((l) => (
                      <option key={l._key} value={l._key}>
                        {l.accountName}
                      </option>
                    ))}
                </Input>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setModalOpen(false)} disabled={transferring}>
                  {transferring ? "Please wait..." : "Close"}
                </Button>
                <Button color="danger" onClick={() => handleTransfer(values)} disabled={transferring || !toGroupId}>
                  {transferring ? "Please wait..." : "Transfer & Close"}
                </Button>
              </ModalFooter>
            </Modal>
            <ContentHeading parentText="Loyalty" headerText="Membership Levels" headerHref="/loyalty/membership-levels" subText={values && values.accountName ? values.accountName : "New Membership Level"} />
            <Row>
              <Col lg={8} sm={12}>
                <Card className="card-default">
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <Field
                        id="membershipType"
                        name="membershipType"
                        component={requiredDropdown as any}
                        className="form-group"
                        label="Membership type"
                        types={MembershipLevelTypes}
                        getName={(types, id) => {
                          if (types[id]) {
                            return types[id].name;
                          }
                          return "Please choose";
                        }}
                        onClick={(e) => form.change("membershipType", e)}
                      />

                      <Field id="accountName" name="accountName" type="text" disabled={readOnly} component={requiredField as any} label="Name" className="form-group" />

                      <Field id="allocationPriority" name={`allocationPriority`}>
                        {({ input: { value, onChange } }) => (
                          <FormGroup>
                            <Label>Allocation Priority</Label>
                            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value ? Number(e.target.value) : null)}>
                              <option value="">Please Select</option>
                              {range(1, 11).map((x) => (
                                <option key={x} value={x}>
                                  {x}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        )}
                      </Field>
                      <Field name="resbutlerMembershipTypeId" id="resbutlerMembershipTypeId" component={renderField as any} label="ResButler Membership Type ID" className="form-group" />

                      {/* Rich text editors */}
                      <div className="form-group">
                        <label htmlFor="wysiwig-editor1">Description</label>
                        <SunEditor
                          setContents={descriptionInitial}
                          setOptions={{
                            height: 200,
                            buttonList: [...buttonList.formatting, ["font", "fontSize", "align"]],
                          }}
                          onChange={(content) => form.change("membershipType", content)}
                        />

                        <div className="form-group">
                          <label htmlFor="colorText1">Card Background Color</label>
                          <Field name="cardBackgroundColor" component={renderSketchControl} onChangeColor={(color) => form.change("cardBackgroundColor", color.rgb)} />
                        </div>

                        <Field name="default">
                          {({ input: { onChange, value }, meta: { error } }) => {
                            return (
                              <div className="mb-3">
                                <div className="checkbox c-checkbox">
                                  <label htmlFor="default">
                                    <input id="default" type="checkbox" checked={value === true} onChange={(e) => onChange(e.target.checked)} />
                                    <span className="fa fa-check" />
                                    Default
                                  </label>
                                </div>

                                {error && <span className="validate-form">{error}</span>}
                              </div>
                            );
                          }}
                        </Field>

                        {/* ENABLED checkbox */}
                        <div className="checkbox c-checkbox">
                          <label className="mb-2" htmlFor="enabled1">
                            <Field id="enabled1" name="enabled" component="input" type="checkbox" />
                            <span className="fa fa-check" />
                            Enabled
                          </label>
                        </div>
                      </div>
                    </CardBody>

                    <CardFooter>
                      <Button id="level-save" type="submit" color="success" className="float-right" disabled={submitting}>
                        Save {submitting && <Spinner size="sm" />}
                      </Button>

                      <div className="clearfix" />
                    </CardFooter>
                  </form>
                </Card>
              </Col>
            </Row>
          </>
        )}
      />
    </ContentWrapper>
  );
};

export default flow([
  /*
     step 3 (outer level)
    provide initial values from store
    */
  connect((state: any) => ({
    initialValues: {
      allocationPriority: 1,
      membershipType: "corporate",
      enabled: true,
      ...state.levels.level,
    },
  })),
])(MembershipLevelsForm);
