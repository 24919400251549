import { CHANGE_RESTAURANT_ID } from "ActionTypes";
import { getUserRestaurants } from "RootReducer";
import { useAppSelector } from "components/Hooks/hooks";
import { keys } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Splitter = () => (
  <>
    &nbsp;
    <i className="fa fa-chevron-right icon-smaller" aria-hidden="true" />
    &nbsp;
  </>
);

function ContentHeading(props) {
  const { headerText, headerHref, subHeaderText, subHeaderHref, parentText, parentHref, subText, showRestaurants, onRestaurantChange } = props;

  const { restaurantId, restaurants } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
      restaurants: getUserRestaurants(state.root.restaurants, state.root.user),
    };
  });

  const dispatch = useDispatch();

  return (
    <div className="content-heading d-flex justify-content-between">
      {/* END Language list */}
      <div>
        {subHeaderText || headerText}
        <small data-localize="maestro.WELCOME">
          {parentHref ? <Link to={`${parentHref}`}>{parentText}</Link> : parentText ? <>{parentText} </> : null}
          {headerHref && headerText ? (
            <>
              <Splitter />
              <Link to={headerHref}>{headerText}</Link>
            </>
          ) : null}
          {subHeaderHref ? (
            <>
              <Splitter />
              <Link to={subHeaderHref}>{subHeaderText}</Link>
            </>
          ) : subHeaderText ? (
            <>
              <Splitter />
              {subHeaderText}
            </>
          ) : null}
          {subText ? (
            <React.Fragment>
              <Splitter />
              {subText}
            </React.Fragment>
          ) : null}
        </small>
      </div>
      {showRestaurants === true ? (
        <select
          id="restaurant"
          className="form-control w-25"
          value={restaurantId}
          onChange={(e) => {
            if (onRestaurantChange) onRestaurantChange(e.target.value);
            else dispatch({ type: CHANGE_RESTAURANT_ID, restaurantId: e.target.value });
          }}
        >
          {keys(restaurants).map((key) => (
            <option key={key} value={key}>
              {restaurants[key].name}
            </option>
          ))}
        </select>
      ) : null}
    </div>
  );
}

export default ContentHeading;
