import _, { pickBy } from "lodash";
import { combineReducers } from "redux";

import { roles } from "resbutler-utils";
import { AreaGroup } from "resbutler-utils/types/Box";
import { Labels } from "resbutler-utils/types/Labels";
import { Menu } from "resbutler-utils/types/Menu";
import { Restaurants } from "resbutler-utils/types/Restaurant";
import { AdditionGroups, ModifierGroups, PreparationGroups, Product, ProductGroups, ProductSizes, UpsellGroups } from "resbutler-utils/types/product";
import {
  CHANGE_RESTAURANT_ID,
  GET_ADDITION_GROUPS,
  GET_DROPDOWN_ACTION,
  GET_FILTER_DISABLED,
  GET_GROUP_AREA_TYPES,
  GET_MAESTRO_DATA_LABELS,
  GET_MODIFIER_GROUPS,
  GET_PREPARATION_GROUPS,
  GET_PRODUCTS,
  GET_PRODUCT_GROUPS,
  GET_RESTAURANTS,
  GET_ROOT_MENUS,
  GET_ROOT_MENUS_LOADING,
  GET_ROOT_PRODUCT_SIZES,
  GET_ROOT_USER,
  GET_TIME,
  GET_UPSELL_GROUPS,
  SHOW_FORM_LOADING,
  SHOW_PRODUCTION,
  TOGGLE_OPEN,
  UPDATE_FORM_POSITION,
  WINDOW_GET_SIZE,
} from "./ActionTypes";

interface InitialState {
  windowWidth: number;
  windowHeight: number;
  time: string;
  user: any;
  restaurantId: string;
  restaurants: Restaurants;
  labels: Labels;
  menus: Menu[];
  menusLoading: boolean;
  productSizes: ProductSizes;
  loading: boolean;
  showFormLoading: any;
  toggle: boolean;
  dropdown: any;
  showProduction: boolean;
  filterDisabled: boolean;
  areaGroups: AreaGroup[];
  products: Product[];
  productGroups: ProductGroups;
  modifierGroups: ModifierGroups;
  additionGroups: AdditionGroups;
  preparationGroups: PreparationGroups;
  upsellGroups: UpsellGroups;
}

const initialState: InitialState = {
  windowWidth: 0,
  windowHeight: 0,
  time: "",
  user: null,
  restaurantId: "",
  restaurants: {},
  labels: {},
  menus: [],
  menusLoading: false,
  productSizes: {},
  loading: false,
  showFormLoading: {},
  toggle: false,
  dropdown: {},
  showProduction: false,
  filterDisabled: true,
  areaGroups: [],
  products: [],
  productGroups: {},
  modifierGroups: {},
  additionGroups: {},
  preparationGroups: {},
  upsellGroups: {},
};

function time(state = initialState.time, action) {
  switch (action.type) {
    case GET_TIME:
      return action.time;
    default:
      return state;
  }
}

function labels(state = initialState.labels, action) {
  switch (action.type) {
    case GET_MAESTRO_DATA_LABELS:
      return action.labels;
    default:
      return state;
  }
}

function menus(state = initialState.menus, action): Menu[] {
  switch (action.type) {
    case GET_ROOT_MENUS:
      return action.menus;
    default:
      return state;
  }
}

function menusLoading(state = initialState.menusLoading, action): boolean {
  switch (action.type) {
    case GET_ROOT_MENUS_LOADING:
      return action.loading;
    default:
      return state;
  }
}

function productSizes(state = initialState.productSizes, action): ProductSizes {
  switch (action.type) {
    case GET_ROOT_PRODUCT_SIZES:
      return action.productSizes;
    default:
      return state;
  }
}

function areaGroups(state = initialState.areaGroups, action): AreaGroup[] {
  switch (action.type) {
    case GET_GROUP_AREA_TYPES:
      return _.toArray(action.types);
    default:
      return state;
  }
}

function products(state = initialState.products, action): Product[] {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.products;
    default:
      return state;
  }
}

function productGroups(state = initialState.productGroups, action): ProductGroups {
  switch (action.type) {
    case GET_PRODUCT_GROUPS:
      return action.productGroups;
    default:
      return state;
  }
}

function filterDisabled(state = initialState.filterDisabled, action) {
  switch (action.type) {
    case GET_FILTER_DISABLED:
      return action.value;
    default:
      return state;
  }
}

function showProduction(state = initialState.showProduction, action) {
  switch (action.type) {
    case SHOW_PRODUCTION:
      return action.showProduction;
    default:
      return state;
  }
}

function dropdown(state = initialState.dropdown, action) {
  switch (action.type) {
    case GET_DROPDOWN_ACTION:
      return { id: action.id, labelId: action.labelId, actionType: action.actionType };
    default:
      return state;
  }
}

function toggle(state = initialState.toggle, action) {
  switch (action.type) {
    case TOGGLE_OPEN:
      return { buttonId: action.buttonId, open: action.open };
    default:
      return state;
  }
}

function windowWidth(state = initialState.windowWidth, action) {
  switch (action.type) {
    case WINDOW_GET_SIZE:
      return action.width;
    default:
      return state;
  }
}

function windowHeight(state = initialState.windowHeight, action) {
  switch (action.type) {
    case WINDOW_GET_SIZE:
      return action.height;
    default:
      return state;
  }
}

function user(state = initialState.user, action) {
  switch (action.type) {
    case GET_ROOT_USER:
      return { x: 0, y: 0, ...action.user };
    case UPDATE_FORM_POSITION:
      return { ...state, x: action.x, y: action.y };
    default:
      return state;
  }
}

function restaurants(state = initialState.restaurants, action): Restaurants {
  switch (action.type) {
    case GET_RESTAURANTS:
      return action.restaurants;
    default:
      return state;
  }
}

function restaurantId(state = initialState.restaurantId, action): string {
  switch (action.type) {
    case CHANGE_RESTAURANT_ID:
      return action.restaurantId;
    case GET_ROOT_USER:
      if (action.user && action.user.defaultRestaurant) {
        return action.user.defaultRestaurant;
      }
      return state;
    default:
      return state;
  }
}

function modifierGroups(state = initialState.modifierGroups, action): ModifierGroups {
  switch (action.type) {
    case GET_MODIFIER_GROUPS:
      return action.payload;
    default:
      return state;
  }
}

function additionGroups(state = initialState.additionGroups, action): AdditionGroups {
  switch (action.type) {
    case GET_ADDITION_GROUPS:
      return action.payload;
    default:
      return state;
  }
}

function upsellGroups(state = initialState.upsellGroups, action): UpsellGroups {
  switch (action.type) {
    case GET_UPSELL_GROUPS:
      return action.payload;
    default:
      return state;
  }
}

function preparationGroups(state = initialState.preparationGroups, action): PreparationGroups {
  switch (action.type) {
    case GET_PREPARATION_GROUPS:
      return action.payload;
    default:
      return state;
  }
}

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case GET_ROOT_USER:
      return action.loading;
    default:
      return state;
  }
}

function showFormLoading(state = initialState.showFormLoading, action) {
  switch (action.type) {
    case SHOW_FORM_LOADING:
      return {
        ...state,
        [action.buttonId]: action.show,
      };
    default:
      return state;
  }
}

export function getDropdown(dropdown, id) {
  if (dropdown.id === id) {
    return dropdown;
  }
  return {};
}

export function getLabelsByRestaurantId(localLabels, restaurantId, showProduction = false) {
  if (showProduction) {
    return _.pickBy(localLabels, (label) => label.restaurantId === restaurantId);
  }
  return _.pickBy(localLabels, (label) => label.enabled === true);
}

export function getUserRestaurants(restaurants: Restaurants, user) {
  if (roles.hasRole(user.customClaims.roles, roles.roles.RESTAURANT_MANAGER)) {
    return _.pickBy(restaurants, (r, restaurantId) => r.enabled === true && user.restaurants && user.restaurants[restaurantId] === true);
  }
  return _.pickBy(restaurants, (r) => r.enabled === true);
}

export function getEnabledProductGroups(productGroups: ProductGroups) {
  return pickBy(productGroups, (pg) => pg.enabled);
}

export default combineReducers({
  windowWidth,
  windowHeight,
  time,
  user,
  restaurantId,
  restaurants,
  loading,
  showFormLoading,
  toggle,
  dropdown,
  labels,
  menus,
  menusLoading,
  productSizes,
  showProduction,
  filterDisabled,
  areaGroups,
  products,
  productGroups,
  modifierGroups,
  additionGroups,
  upsellGroups,
  preparationGroups,
});
