import { getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import { VoucherSetup } from "resbutler-utils/types/Voucher";

const getVocherGenerationOn = (voucher: VoucherSetup) => {
  if (voucher.generationType === "oneOff") {
    return moment(voucher.voucherGenerateOn.seconds * 1000).format("YYYY/MM/DD");
  } else {
    if (voucher.voucherGenerateOn === "firstDayOfMonth") {
      return "First day of the month";
    } else if (voucher.voucherGenerateOn === "lastDayOfMonth") {
      return "Last day of the month";
    }
  }
};

const filterVouchers = (vouchers, filterEnabled) =>
  vouchers.filter((item) => {
    if (filterEnabled === null) return true;
    return item.isEnabled === filterEnabled;
  });

export const getId = () => {
  const { client } = getConfig();
  return firebase.firestore().collection(`${client}/crm/loyaltyVouchers`).doc().id;
};

const VoucherTableCard = ({ vouchers, viewLink, filterEnabled }) => {
  return (
    <Card className="card-default">
      <CardBody>
        <Table bordered striped>
          <thead>
            <tr>
              <td>Name</td>
              <td>Generation Type</td>
              <td>Generate on</td>
              <td>Generation Expiry</td>
              <td>Enabled</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {filterVouchers(vouchers, filterEnabled).map((voucher) => (
              <tr key={voucher._key}>
                <td>{voucher.name}</td>
                <td>{voucher.generationType == "oneOff" ? "One-Off" : "Recurring"}</td>
                <td>{getVocherGenerationOn(voucher)}</td>
                <td>{voucher.voucherGenerateExpiry ? moment(voucher.voucherGenerateExpiry.seconds * 1000).format("ll") : "Never"}</td>
                <td>{voucher.isEnabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                <td>
                  <Link to={`${viewLink}/${voucher._key}/view`}>Edit</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default VoucherTableCard;
