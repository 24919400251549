import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import _ from "lodash";
import { hideLoading, showLoading } from "react-redux-loading-bar";

import { catchExceptionCallback, getConfig } from "@/core/utilities";

import { BUTTON_ERROR, BUTTON_SAVED, BUTTON_SAVING } from "@/components/Buttons/ActionTypes";
import { ADMIN_URL } from "core/auth";
import * as types from "./ActionTypes";

export function getSubscriptionDetails() {
  return (dispatch) => {
    try {
      const callBack = (snapshot) => {
        const subscriptionDetails = Object.assign({}, _.merge({ contact: {}, modules: { groupModules: 0, restaurantModules: {} } }, snapshot.val() || {}));
        dispatch({ type: types.GET_SUBSCRIPTION_DETAILS, subscriptionDetails });
      };
      const { tenantId } = getConfig();
      firebase
        .database()
        .ref(`${tenantId}/subscriptionDetails`)
        .once("value")
        .then((snapshot) => callBack(snapshot))
        .catch((error) => {
          catchExceptionCallback(error);
        });
    } catch (error) {
      catchExceptionCallback(error);
    }
  };
}

export function save(subscriptionDetail, buttonId) {
  return (dispatch) => {
    try {
      dispatch({ type: BUTTON_SAVING, id: buttonId });
      const { tenantId } = getConfig();
      firebase
        .database()
        .ref(`${tenantId}/subscriptionDetails`)
        .update(subscriptionDetail, (error) => {
          if (error) {
            catchExceptionCallback(error.code);
            dispatch({ type: BUTTON_ERROR, id: buttonId });
          } else {
            dispatch({ type: BUTTON_SAVED, id: buttonId });
          }
        });
    } catch (error) {
      dispatch({ type: BUTTON_ERROR, id: buttonId });
      catchExceptionCallback(error);
    }
  };
}

export function getSystemProducts() {
  return (dispatch) => {
    try {
      //const data = getConfig();
      dispatch(showLoading());
      // eslint-disable-next-line no-undef
      axios
        .get(`${ADMIN_URL}/products/getAllProducts`, {
          /* headers: { 'client-id': data.client } */
        })
        .then((response) => {
          dispatch({ type: types.GET_SYSTEM_PRODUCTS, systemProducts: response.data });
          dispatch(hideLoading());
        });
    } catch (error) {
      dispatch(hideLoading());
      catchExceptionCallback(error);
    }
  };
}
