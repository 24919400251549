import { combineReducers } from "redux";
import { CustomerTag, CustomerTagCategory } from "resbutler-utils/types/customer";
import { GET_CUSTOMER_PRODUCT_TAGS, GET_CUSTOMER_TAGS, GET_CUSTOMER_TAG_CATEGORIES, GET_CUSTOMER_TAG_CATEGORIES_BY_ID, SAVE_CUSTOMER_TAGS, SAVE_CUSTOMER_TAG_CATEGORIES, UPDATE_CUSTOMER_TAGS, UPDATE_CUSTOMER_TAG_CATEGORIES } from "./ActionTypes";

const initialState = {
  customerTagCategories: [],
  customerTagsById: [],
  customerTags: [],
  allergiesProductTags:[],
  dietaryProductTags:[],
} as CustomerTagState;

export interface CustomerTagState {
  customerTagCategories: CustomerTagCategory[];
  customerTagsById: CustomerTag[];
  customerTags: CustomerTag[];
  allergiesProductTags:string[],
  dietaryProductTags:string[],
}

function customerTagCategories(state = initialState.customerTagCategories, action): CustomerTagCategory[] {
  switch (action.type) {
    case GET_CUSTOMER_TAG_CATEGORIES:
      return action.payload;
    case SAVE_CUSTOMER_TAG_CATEGORIES:
      return [...state, action.payload];
    case UPDATE_CUSTOMER_TAG_CATEGORIES:
      return state.map((customerTagCategory) => {
        if (customerTagCategory.id === action.payload.id) {
          return action.payload;
        }
        return customerTagCategory;
      });

    default:
      return state;
  }
}

function customerTagsById(state = initialState.customerTagsById, action): CustomerTag[] {
  switch (action.type) {
    case GET_CUSTOMER_TAG_CATEGORIES_BY_ID:
      return action.payload;
    case SAVE_CUSTOMER_TAGS:
      return [...state, action.payload];
    case UPDATE_CUSTOMER_TAGS:
      return state.map((customerTag) => {
        if (customerTag.id === action.payload.id) {
          return action.payload;
        }
        return customerTag;
      });
    default:
      return state;
  }
}

function customerTags(state = initialState.customerTags, action): CustomerTag[] {
  switch (action.type) {
    case GET_CUSTOMER_TAGS:
      return action.payload;
    default:
      return state;
  }
}

function allergiesProductTags(state= initialState.allergiesProductTags, action) {
  switch (action.type) {
    case GET_CUSTOMER_PRODUCT_TAGS:
      return action.allergiesProductTags;
    default:
      return state;
  }
}

function dietaryProductTags(state= initialState.dietaryProductTags, action) {
  switch (action.type) {
    case GET_CUSTOMER_PRODUCT_TAGS:
      return action.dietaryProductTags;
    default:
      return state;
  }
}

export default combineReducers({
  customerTagCategories,
  customerTagsById,
  customerTags,
  allergiesProductTags,
  dietaryProductTags
});
