import SketchControlV2 from "components/Form/SketchControlV2";
import { getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, FormGroup, Input, Label, Spinner } from "reactstrap";
//import SunEditor from "suneditor-react";
import { hexToRGB, rgbToHex } from "_utils/colorConverter";
import ImageUploader from "resbutler-ui/components/Uploader/ImageUploader";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const defaultColor = "#000000";
const defaultData = {
  backgroundColor: defaultColor,
  themeColor: defaultColor,
  iconOld192: null,
  icon512URL: null,
  icon512: null,
  fullName: "",
  shortName: "",
  sameAsGroup: true,
  description: "",
};
const PwaAppForm = ({ restaurantId }) => {
  const { client, crmPath, tenantId } = getConfig();

  const [fullName, setFullName] = useState("");
  const [shortName, setShortName] = useState("");
  const [description, setDescription] = useState("");
  // presentational states
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // loaded icon data
  const [iconOld192, setIconOld192] = useState(null);

  // loaded icon data
  const [iconOld512, setIconOld512] = useState(null);

  // form data
  const [backgroundColor, setbackgroundColor] = useState(defaultColor);
  const [themeColor, setThemeColor] = useState(defaultColor);
  const [icon192, setIcon192] = useState(null);
  const [icon512, setIcon512] = useState(null);

  const [sameAsGroup, setSameAsGroup] = useState(defaultData.sameAsGroup);

  // location for firebase data (group / restaurant)
  const firebaseRef = restaurantId ? `${client}/crm/settings/${restaurantId}` : `${client}/crm`;
  //const icon192StoreLocation = restaurantId ? `${client}/images/restaurants/${restaurantId}/icon192.png` : `${client}/images/icon192.png`;
  // const icon512StoreLocation = restaurantId ? `${client}/images/restaurants/${restaurantId}/icon512.png` : `${client}/images/icon512.png`;

  useEffect(() => {
    setIcon192(null);
    setIcon512(null);
    const _ = async () => {
      setLoading(true);
      const ref = await firebase.firestore().doc(firebaseRef).get();
      const { description = "", backgroundColor = defaultColor, themeColor = defaultColor, icon192URL, icon512URL, fullName, shortName, sameAsGroup } = ref.data() ? (ref.data().manifest ? ref.data().manifest : defaultData) : defaultData;

      // load existing data
      setbackgroundColor(hexToRGB(backgroundColor) as any);
      setThemeColor(hexToRGB(themeColor) as any);

      // old (original) data for icon
      setIconOld192(icon192URL);
      setIconOld512(icon512URL);
      setFullName(fullName);
      setShortName(shortName);
      setDescription(description);
      setSameAsGroup(sameAsGroup);
      setLoading(false);
    };
    _().then();
  }, [restaurantId]);

  const submit = async () => {
    setSaving(true);
    if (sameAsGroup && restaurantId) {
      await firebase
        .firestore()
        .doc(firebaseRef)
        .set({ manifest: { sameAsGroup } }, { merge: true })
        .then(() => {
          return toast.success("Successfully saved", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      setSaving(false);
      return;
    }

    let error = false;
    // Error checking
    if (!icon192 || !icon512) error = true;
    if (!fullName || !shortName) error = true;
    if (error) {
      setSaving(false);
      return toast.warn("Must complete all fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    // icon do not need to be updated if no new icon is passed
    const toUpdate = {
      backgroundColor: rgbToHex(backgroundColor as any),
      themeColor: rgbToHex(themeColor as any),
      fullName,
      shortName,
      startUrl: restaurantId ? `${crmPath}/${tenantId}/restaurants/${restaurantId}` : `${crmPath}/${tenantId}`,
      display: "standalone",
      description,
      sameAsGroup,
      icon192URL: null,
      icon512URL: null,
    };

    if (icon192) {
      toUpdate.icon192URL = icon192.url;
    }

    if (icon512) {
      toUpdate.icon512URL = icon512.url;
    }
    // otherwise the icon will not be updated

    await firebase
      .firestore()
      .doc(firebaseRef)
      .set({ manifest: toUpdate }, { merge: true })
      .then(() => {
        return toast.success("Successfully saved", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    setSaving(false);
    return;
  };

  if (loading) return <Spinner />;
  return (
    <>
      {(!sameAsGroup || !restaurantId) && (
        <Card className="card-default">
          <CardBody>
            <div>
              <FormGroup>
                <Label>Full Name</Label>
                <Input
                  type="text"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label>Short Name</Label>
                <Input
                  type="text"
                  value={shortName}
                  onChange={(e) => {
                    setShortName(e.target.value);
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </FormGroup>

              {/* original logo preview */}

              <ImageUploader
                client={client}
                sectionName="Current Icon (192 X 192)"
                onDelete={() => {
                  setIcon192(null);
                }}
                currentImages={[
                  {
                    url: iconOld192,
                    fileName: iconOld192 ? "Current Icon (192 X 192)" : "",
                  },
                ]}
                setImage={(output) => {
                  setIcon192(output);
                }}
                path="membership"
                disabled={false}
                isMultiple={false}
              />

              <ImageUploader
                client={client}
                sectionName="Current Icon (512 X 512)"
                onDelete={() => {
                  setIcon512(null);
                }}
                currentImages={[
                  {
                    url: iconOld512,
                    fileName: iconOld512 ? "Current Icon (512 X 512)" : "",
                  },
                ]}
                setImage={(output) => {
                  setIcon512(output);
                }}
                path="membership"
                disabled={false}
                isMultiple={false}
              />

              <FormGroup>
                <Label>Background Color</Label>
                <SketchControlV2 color={backgroundColor} onChange={setbackgroundColor} />
              </FormGroup>
              <FormGroup>
                <Label>Theme Color</Label>
                <SketchControlV2 color={themeColor} onChange={setThemeColor} />
              </FormGroup>
            </div>
          </CardBody>

          <CardFooter>
            <Button color="success" className="float-right" disabledtext="Saving" onClick={submit} disabled={saving}>
              {saving ? <Spinner size="sm" /> : "Save"}
            </Button>
          </CardFooter>
        </Card>
      )}

      {restaurantId && (
        <Card className="card-default">
          <CardBody>
            <div>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={sameAsGroup}
                    onChange={() => {
                      setSameAsGroup(!sameAsGroup);
                    }}
                  />{" "}
                  Same as group
                </Label>
              </FormGroup>
            </div>
          </CardBody>

          <CardFooter>
            <Button color="success" className="float-right" disabledtext="Saving" onClick={submit} disabled={saving}>
              {saving ? <Spinner size="sm" /> : "Save"}
            </Button>
          </CardFooter>
        </Card>
      )}
    </>
  );
};

export default PwaAppForm;
