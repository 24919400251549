import classnames from "classnames";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import { ReasonTypes } from "../../../resbutler-utils/types/Voucher";
import { buildDataFromDocs } from "../../../resbutler-utils/utils";
import { catchExceptionCallback } from "../../utilities";
import VoucherIssuedFormDetails from "./VoucherIssuedFormDetails";
import VoucherIssuedFormRestaurants from "./VoucherIssuedFormRestaurants";

const VoucherIssuedForm = ({ productSizes, voucherID, clientId, restaurants, products, readOnly, functions }) => {
  const firebaseRef = `${clientId}/crm/vouchers/${voucherID}`;

  // states
  const [tab, setTab] = useState("Details");
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [input, setInput] = useState({
    voucherNumber: "",
    restaurants: [],
    voucherSetup: {},
    allProducts: true,
    products: [],
    discountType: "",
    discountAmount: "",
    issuedDate: "",
    expiryDate: "",
    isEnabled: false,
    staffNote: "",
    isRedeemed: false,
    customer: {}
  });

  const [reasonTypes, setReasonTypes] = useState<ReasonTypes>({});

  useEffect(() => {
    const loadVoucher = async () => {
      setLoading(true);
      try {
        const snapshot = await firebase.firestore().doc(firebaseRef).get();

        if (snapshot.exists) {
          const data = snapshot.data();

          const response = await Promise.all([firebase.firestore().doc(`${clientId}/crm/voucherSetups/${data.voucherSetupId}`).get(), firebase.firestore().doc(`${clientId}/crm/customers/${data.customerId}`).get()]);

          setInput({
            ...data,
            voucherSetup: response[0].data() || {},
            customer: response[1].data(),
            restaurants: data.restaurants ? data.restaurants : [],
            products: data.products ? data.products : []
          } as any);
        }
      } catch (e) {
        catchExceptionCallback(e);
      }
      setLoading(false);
    };
    loadVoucher();
  }, [voucherID]);

  useEffect(() => {
    async function load() {
      try {
        const snap = await firebase.firestore().collection(`${clientId}/crm/reasonTypes`).get();
        setReasonTypes(buildDataFromDocs(snap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    load();
  }, []);

  const handleSubmit = async () => {
    if (!readOnly) {
      setSaveLoading(true);
      const v = cloneDeep(input);

      try {
        delete v.issuedDate;
        delete v.expiryDate;

        await firebase.firestore().doc(firebaseRef).set(v, { merge: true });
      } catch (e) {
        console.error(e);
      }
      setSaveLoading(false);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="content-wrapper">
      {readOnly ? null : (
        <div className="content-heading">
          <Row>
            <Col lg={9} className="mt-3 text-capitalize">
              Loyalty &nbsp;
              <i className="fa fa-chevron-right icon-smaller" aria-hidden="true" />
              &nbsp;
              <Link to="/loyalty/members-voucher/issued-voucher">{input.voucherNumber}</Link>
            </Col>
          </Row>
        </div>
      )}

      <Row>
        <Col sm={12}>
          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: tab === "Details" })} onClick={() => setTab("Details")}>
                <span className="px-1">Details</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: tab === "Restaurants" })} onClick={() => setTab("Restaurants")}>
                <span className="px-1">Restaurants</span>
              </NavLink>
            </NavItem>
          </Nav>
          <Container fluid>
            <TabContent activeTab={tab} className={tab !== "Details" ? "hide-tab" : ""}>
              <Card className="card-default px-2">
                <CardBody>
                  <TabPane tabId={"Details"}>
                    {tab === "Details" ? (
                      <VoucherIssuedFormDetails functions={functions} reasonTypes={reasonTypes} clientId={clientId} readOnly={readOnly} restaurants={restaurants} products={products} input={input} setInput={setInput} productSizes={productSizes} />
                    ) : null}
                  </TabPane>
                </CardBody>
              </Card>
            </TabContent>
            <TabContent activeTab={tab} className={tab !== "Restaurants" ? "hide-tab" : ""}>
              <Card className="card-default px-2">
                <CardBody>
                  <TabPane tabId={"Restaurants"}>{tab === "Restaurants" ? <VoucherIssuedFormRestaurants readOnly={readOnly} restaurants={restaurants} input={input} setInput={setInput} /> : null}</TabPane>
                </CardBody>
              </Card>
            </TabContent>

            {readOnly ? null : (
              <Button className="float-end mt-3" color="success" onClick={handleSubmit} disabled={saveLoading}>
                {saveLoading ? <Spinner size="sm" /> : "Save"}
              </Button>
            )}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default VoucherIssuedForm;
