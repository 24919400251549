import { useAppSelector } from "components/Hooks/hooks";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import _, { findIndex, keys } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from "reactstrap";
import { bindActionCreators } from "redux";
import { ProductEventActionType, ProductLog } from "resbutler-utils/types/EventType";
import { BeverageMenuPackages, Menu, Packages } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import { getAllProductsFromMenu } from "resbutler-utils/utils/menuUtils";
import { toggleSetting } from "store/actions/actions";
import ToggleFullscreen from "../Common/ToggleFullscreen";
import Notifications, { INotifications } from "./Notifications";

interface ProductLogList {
  [key: string]: ProductLog;
}

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const checkProductGroupOnMenu = (menuTypeHeadings, productGroupId) => {
  if (_.isUndefined(menuTypeHeadings) === false) {
    return _.forEach(menuTypeHeadings, (heading) => {
      const groupHeadings = heading?.groupHeadings;

      if (_.isEmpty(groupHeadings) === false) {
        // check for productGroup exist on this menu or not
        return _.some(groupHeadings, (heading1) => heading1.productGroupId === productGroupId);
      }
    });
  }
};

const getAssociatedMenusByProductIdOrProductGroupId = (menus: Menu[], notification: ProductLog = {} as any) => {
  const associatedMenus = [];
  if (ProductEventActionType.AddProductInMenuHeading == notification.action || ProductEventActionType.DeleteProductInMenuHeading == notification?.action) {
    if (keys(notification.metadata?.associatedData?.menus || {}).length) {
      keys(notification.metadata.associatedData.menus).map((menuId) => {
        associatedMenus.push(findIndex(menus, (item) => item.id == menuId));
      });
    }
  } else {
    _.forEach(menus, (menu, menuId) => {
      if (!notification.productId && notification.productGroupId) {
        const food = menu.food?.groupHeadings;
        const beverage = menu.beverage?.groupHeadings;
        const foodInclusion = menu?.foodInclusions;
        const drinkInclusions = menu?.drinkInclusions;

        const hasProductGroupIdInFood = checkProductGroupOnMenu(food, notification.productGroupId);
        const hasProductGroupIdInBeverage = checkProductGroupOnMenu(beverage, notification.productGroupId);
        const hasProductGroupIdInFoodInclusion = checkProductGroupOnMenu(foodInclusion, notification.productGroupId);
        const hasProductGroupIdInDrinkInclusion = checkProductGroupOnMenu(drinkInclusions, notification.productGroupId);

        if (hasProductGroupIdInFood || hasProductGroupIdInBeverage || hasProductGroupIdInFoodInclusion || hasProductGroupIdInDrinkInclusion) {
          associatedMenus.push(menuId);
        }
      } else {
        const allProducts = getAllProductsFromMenu(menu);
        if (_.has(allProducts, notification.productId)) {
          associatedMenus.push(menuId);
        }
      }
    });
  }

  return associatedMenus;
};

const getAssociatedPackages = (packages: Packages | BeverageMenuPackages, notification: ProductLog = {} as any, type: "foodPackages" | "beveragePackages" | "foodAndBeveragePackages" | "beverageMenusPackages" | "foodAdditionals" | "beverageAdditionals") => {
  const associatedPackages = [];
  if (ProductEventActionType.AddProductInMenuHeading == notification.action || ProductEventActionType.DeleteProductInMenuHeading == notification?.action) {
    if (keys(notification.metadata?.associatedData?.menus || {}).length) {
      keys(notification.metadata.associatedData?.[type]).map((_key) => {
        associatedPackages.push({ ...packages[_key], _key });
      });
    }
  }
  return associatedPackages;
};

const Header = (props) => {
  const menus = useAppSelector((state) => state.root.menus);
  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const location = useLocation();
  const [, setNavItem] = useState("");
  const { client } = getConfig();
  const [notifications, setNotifications] = useState<INotifications>({});
  const notificationCount = Object.keys(notifications).length;

  useEffect(() => {
    const path = location.pathname.split("/")?.[1];
    setNavItem(path);
  }, [location]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(`${client}/events/products`)
      .where("read", "==", false)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const promises = [
            firebase.firestore().collection(`${client}/menus/foodPackages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
            firebase.firestore().collection(`${client}/menus/beveragePackages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
            firebase.firestore().collection(`${client}/menus/foodbeveragePackages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
            firebase.firestore().collection(`${client}/menus/beverageMenus`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
            firebase.firestore().collection(`${client}/functionAdditionals/foodPackages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
            firebase.firestore().collection(`${client}/functionAdditionals/beveragePackages`).where("enabled", "==", true).where("restaurantId", "==", restaurantId).get(),
          ];
          Promise.all(promises)
            .then((snaps) => {
              const foodPackages1: Packages = buildDataFromDocs(snaps[0].docs);
              const beveragePackages1: Packages = buildDataFromDocs(snaps[1].docs);
              const foodBeveragePackages1: Packages = buildDataFromDocs(snaps[2].docs);
              const beverageMenusPackages1: BeverageMenuPackages = buildDataFromDocs(snaps[3].docs);
              const foodAdditionals1: Packages = buildDataFromDocs(snaps[4].docs);
              const beverageAdditionals1: Packages = buildDataFromDocs(snaps[5].docs);
              let data = {} as INotifications;
              const results = buildDataFromDocs(snapshot.docs) as ProductLogList;
              _(results)
                .keys()
                .sortBy((key) => results[key].timestamp)
                .reverse()
                .forEach((notificationId) => {
                  const notification = results[notificationId];
                  const affectedMenus = getAssociatedMenusByProductIdOrProductGroupId(menus, notification);
                  const affectedFoodPackages = getAssociatedPackages(foodPackages1, notification, "foodPackages");
                  const affectedBeveragePackages = getAssociatedPackages(beveragePackages1, notification, "beveragePackages");
                  const affectedFoodAndBeveragePackages = getAssociatedPackages(foodBeveragePackages1, notification, "foodAndBeveragePackages");
                  const affectedBeverageMenusPackages = getAssociatedPackages(beverageMenusPackages1, notification, "beverageMenusPackages");
                  const affectedFoodAdditionals = getAssociatedPackages(foodAdditionals1, notification, "foodAdditionals");
                  const affectedBeverageAdditionals = getAssociatedPackages(beverageAdditionals1, notification, "beverageAdditionals");
                  const affectedPackages = [...affectedFoodPackages, ...affectedBeveragePackages, ...affectedFoodAndBeveragePackages, ...affectedBeverageMenusPackages, ...affectedFoodAdditionals, ...affectedBeverageAdditionals];
                  data = {
                    ...data,
                    [notificationId]: {
                      ...notification,
                      affectedMenus,
                      affectedPackages,
                    },
                  };
                });
              setNotifications(data);
            })
            .catch((error) => catchExceptionCallback(error));
        }
      });
  }, [menus]);

  const toggleOffsidebar = (e) => {
    e.preventDefault();
    props.actions.toggleSetting("offsidebarOpen");
  };

  const toggleCollapsed = (e) => {
    e.preventDefault();
    props.actions.toggleSetting("isCollapsed");
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    props.actions.toggleSetting("asideToggled");
  };

  const resize = () => {
    const evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  };

  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      <nav className="navbar topnavbar navbar-expand-md navbar-light">
        <ul className="navbar-nav flex-row">
          <li className="nav-item">
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={toggleCollapsed}>
              <em className="fas fa-bars"></em>
            </a>
            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={toggleAside}>
              <em className="fas fa-bars"></em>
            </a>
          </li>

          <li className="nav-item">
            <div className="navbar-header">
              <a className="navbar-brand" href="#/">
                <div className="brand-logo">
                  <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                </div>
              </a>
            </div>
          </li>
        </ul>
        {/* START Left navbar */}
        {/* END navbar header */} {/* START Nav wrapper */}
        <Nav navbar className="mr-auto flex-column flex-lg-row"></Nav>
        <Nav className="flex-row" navbar>
          <UncontrolledDropdown disabled={notificationCount === 0} nav inNavbar>
            <DropdownToggle nav className="dropdown-toggle-nocaret notification-dropdown-toggle">
              <div className="css-13lxiba">
                <em className="notification-toggle">M</em>
                {notificationCount > 0 ? <span className="bg-danger rounded-pill badge">{notificationCount}</span> : null}
              </div>
            </DropdownToggle>

            <DropdownMenu right className="dropdown-menu-right animated flipInX notification-main">
              <Notifications notifications={notifications} />
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* Fullscreen (only desktops) */}
          <li className="nav-item">
            <ToggleFullscreen className="nav-link" />
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#" onClick={toggleOffsidebar}>
              <em className="fa fa-th"></em>
            </NavLink>
          </li>
        </Nav>
        {/* END Nav wrapper */}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ toggleSetting }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Header);
