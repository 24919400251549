export const thirdParty = {
  "Sydney Dance Party": { name: "Sydney Dance Party" },
  "Sydney Dance Party 1": { name: "Sydney Dance Party 1" },
};

export const validate = ({ name }) => {
  const errors = {};
  if (!name) errors.name = "Please enter a name";
  return errors;
};
