export const GET_BOOKING_SERVICE = "GET_BOOKING_SERVICE";
export const SORT_BOOKINGS = "SORT_BOOKINGS";
export const TOGGLE_TIME_FORM = "TOGGLE_TIME_FORM";
export const EXCEEDED_PREVIEW = "EXCEEDED_PREVIEW";
export const STAND_BY_PREVIEW = "STAND_BY_PREVIEW";
export const STAND_BY_CONFIRMED = "STAND_BY_CONFIRMED";
export const CONFLICTED_PREVIEW = "CONFLICTED_PREVIEW";
export const FORCE_REALLOCATION = "FORCE_REALLOCATION";
export const BOOKING_FORM_NOTIFICATION = "BOOKING_FORM_NOTIFICATION";
export const BOOKING_FORM_CONFLICTED_SEATED = "BOOKING_FORM_CONFLICTED_SEATED";
export const BOOKING_FORM_CASUAL = "BOOKING_FORM_CASUAL";
export const GET_BOOKING_FORM_CUSTOMERS = "GET_BOOKING_FORM_CUSTOMERS";
export const CLOSE_EDIT_BOOKING = "CLOSE_EDIT_BOOKING";
export const BOOKING_MOBILE_VALID = "BOOKING_MOBILE_VALID";
export const GET_TIME_UNITS = "GET_TIME_UNITS";
export const SHOW_SUMMARY_PAYMENT = "SHOW_SUMMARY_PAYMENT";
export const GET_PAYMENT_SUMMARY = "GET_PAYMENT_SUMMARY";
export const BOOKING_FORM_MANUAL_PAYMENT_AND_REFUNDS = "BOOKING_FORM_MANUAL_PAYMENT_AND_REFUNDS";
export const BOOKING_ANIMATE = "BOOKING_ANIMATE";
export const GET_BOOKING_FORM_GUESTS = "GET_BOOKING_FORM_GUESTS";
export const GET_BOOKING_FORM_MENUS = "GET_BOOKING_FORM_MENUS";
