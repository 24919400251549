import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { roles } from "resbutler-utils";

const PrivateRoute = ({ path, element, permissions, ...rest }) => {
  const { user } = useSelector((state: any) => {
    return {
      user: state.root.user,
    };
  });
  if (!user || !user.customClaims) {
    return <Navigate to="/login" />;
  }
  if ((roles.hasRole(user.customClaims.roles, roles.roles.SYSTEM | permissions) || !permissions) && (rest.moduleAccess === undefined || rest.moduleAccess)) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

function mapStateToProps(state) {
  return {
    user: state.root.user,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
