import { chain, filter, forEach, groupBy, head, set, some, split } from "lodash";
import { Menu } from "resbutler-utils/types/Menu";

export interface IReportByMenuPayload {
  calendar: string;
  restaurantId: string;
  clientId: string;
  firstPeriod?: any;
  secondPeriod?: any;
  mode?: string;
  firstDateFrom?: string;
  firstDateTo?: string;
  secondDateFrom?: string;
  secondDateTo?: string;
}

export const getMenuGroupHierarchy = (menuIds: string[] = [], menus: Menu[]) => {
  const menuByMenuHeadings = menuIds.reduce((results, menuId) => {
    const menu = menus.find((item) => item.id === menuId);

    if (menu) {
      const menuCategoryId = menu.menuCategoryId;

      if (results?.[menuCategoryId]) {
        results = {
          ...results,
          [menuCategoryId]: [...results[menuCategoryId], menuId],
        };
      } else {
        results = {
          ...results,
          [menuCategoryId]: [menuId],
        };
      }
    }

    return results;
  }, {});

  return menuByMenuHeadings;
};

const normalizeResults = (results, menu: Menu, filterTreeData, filterTreeValue) => {
  if (!menu || !results) {
    return {};
  }

  const fixedProductIds = filter(Object.keys(menu.menuPayments?.fixed?.productIds), (v) => menu.menuPayments?.fixed?.productIds[v]);

  let lines = [];

  const filteredResults = filterResults(results, filterTreeData, filterTreeValue)

  filteredResults.forEach((i) => {
    const tempLines = i.lines.filter((l) => l.lineItemTypeId === "3");
    lines = [...lines, ...tempLines];
  });

  const data = chain(lines)
    .groupBy(({ fixProductPrice }) => head(split(fixProductPrice, '",')))
    .reduce((result, item, fixedProductId, collection) => {
      const linesGroupedByMenuHeadingId = groupBy(item, "menuHeadingId");

      // null keys are additional product
      if (fixedProductId) {
        forEach(linesGroupedByMenuHeadingId, (lines, menuHeadingId) => {
          const linesGroupedByProductId = groupBy(lines, "productId");

          forEach(linesGroupedByProductId, (lines, productId) => {
            // only add inclusive items
            const tempLines = lines.filter((l) => l.isInclusive);

            if (tempLines.length) {
              set(result, `${fixedProductId}.${lines?.[0]?.preparationType}.${menuHeadingId}.${productId}`, tempLines);
            }
          });
        });
      } else {
        forEach(linesGroupedByMenuHeadingId, (lines, menuHeadingId) => {
          const linesGroupedByProductId = groupBy(lines, "productId");

          forEach(linesGroupedByProductId, (lines, productId) => {
            const isInclusiveProduct = some(fixedProductIds, (fixedProductId) => {
              const fixedProductLines = collection?.[fixedProductId];

              if (fixedProductLines) {
                const linesGroupedByProductId = groupBy(fixedProductLines, "productId");
                return !!linesGroupedByProductId?.[productId];
              }

              return false;
            });

            if (isInclusiveProduct === false) {
              set(result, `['additional-items'].${lines?.[0]?.preparationType}.${menuHeadingId}.${productId}`, lines);
            }
          });
        });
      }

      return result;
    }, {})
    .value();

  return data;
};

const filterResults = (results, filterTreeData, filterValue) => {
  return filter(results, (item) => {
    let shouldShowByMealPeriod = false,
    shouldShowByArea = false,
    shouldShowByTableClass = false;

    // filter out by meal period
    if (filterValue.includes(filterTreeData[0].value)) {
      shouldShowByMealPeriod = true;
    } else {
      shouldShowByMealPeriod = filterValue.includes(item.mealId);
    }

    // filter out by area
    if (filterValue.includes(filterTreeData[1].value)) {
      shouldShowByArea = true;
    } else {
      shouldShowByMealPeriod = filterValue.includes(item.areaId);
    }

    // filter out by area
    if (filterValue.includes(filterTreeData[2].value)) {
      shouldShowByTableClass = true;
    } else {
      shouldShowByTableClass = filterValue.includes(item.tableClassId);
    }

    return shouldShowByMealPeriod && shouldShowByArea && shouldShowByTableClass;
  });
};

export { filterResults, normalizeResults };
