import { toast } from "react-toastify";

export const catchExceptionCallback = (error) => {
  let error1 = error;
  const className = "error";
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      error1 = error.response.data.message;
    } else {
      error1 = error.response.data;
    }
    // if (error.stack && error.response.status === 500) {
    //     stacktrace = error.stack;
    // }
  } else if (error.message) {
    error1 = error.message;
    // if (error.stack) {
    //     stacktrace = error.stack;
    // }
  }
  toast[className](error1, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 6000,
  });
  return error;
};

export const transactionNumberPad = (transactionNumber) => String(transactionNumber).padStart(4, "0");
