import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import core from 'core/utilities';

class SketchControl extends React.Component {

  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
  };

  render() {

    const styles = reactCSS({
      'default': {
        wrap: {
          position: 'relative'
        },
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: core.rgb2hex(this.props.color.r, this.props.color.g, this.props.color.b),
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          top: '-300px'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div style={styles.wrap}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <SketchPicker color={this.props.color} onChange={this.props.onChange} />
        </div> : null }

      </div>
    );
  }
}

export default SketchControl;
