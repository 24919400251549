import { faUpload, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { catchExceptionCallback } from "../../utilities";

interface currentImageSetting {
  url: string;
  fileName: string;
  fileSize?: string;
  isDeleted?: boolean;
}

const ImageUploader = ({ path, sectionName, disabled, currentImages, setImage, onDelete, isMultiple, client }: { path: string; sectionName: string; disabled: boolean; currentImages: currentImageSetting[]; setImage: any; onDelete: any; client: string; isMultiple: boolean }) => {
  const [showModal, setShowModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [currentSettingValue, setCurrentSettingValue] = useState<currentImageSetting>();
  const [currentMultipleSetting, setCurrentMultipleSetting] = useState([]);

  const [imagesPool, setImagesPool] = useState([]);
  const [imagesAllPool, setImagesAllPool] = useState([]); // for overall search array
  const [selectedImagesPool, setSelectedImagesPool] = useState([]);

  async function fetchSetting(current) {
    try {
      if (current !== undefined && current.length === 1) {
        let name = current[0].fileName;
        let size = "NA";

        if (current[0]?.isDeleted !== true && current[0].url) {
          try {
            const httpsReference = firebase.storage().refFromURL(current[0].url);
            const metadata = await httpsReference.getMetadata();
            name = metadata.name;
            size = (metadata.size / 1024).toFixed(2);
          } catch (err) {
            if (err.code !== "storage/object-not-found" && err.code !== "storage/invalid-argument") catchExceptionCallback(err);
          }
        }

        // clear multiple images options
        setCurrentMultipleSetting([]);

        setCurrentSettingValue({
          url: current[0].url,
          fileName: name,
          fileSize: size
        });
      } else if (current !== undefined && current.length > 1) {
        const tempArry = [];

        for (let item = 0; item < current.length; item++) {
          let name = current[item].fileName;
          let size = "NA";
          if (current[item]?.isDeleted !== true && current[item].url) {
            try {
              const httpsReference = firebase.storage().refFromURL(current[item].url);
              const metadata = await httpsReference.getMetadata();
              name = metadata.name;
              size = (metadata.size / 1024).toFixed(2);
              tempArry.push({
                url: current[item].url,
                fileName: name,
                fileSize: size
              });
            } catch (error) {
              if (error.code !== "storage/object-not-found" && error.code !== "storage/invalid-argument") catchExceptionCallback(error);
            }
          }
        }
        setCurrentMultipleSetting(tempArry);
      }
    } catch (error) {
      if (error.code !== "storage/object-not-found" && error.code !== "storage/invalid-argument") catchExceptionCallback(error);
    } finally {
      setImageLoaded(true);
    }
  }

  useEffect(() => {
    fetchSetting(currentImages);
  }, [currentImages]);

  const resizeFile = (file: any) => new Promise((resolve) => Resizer.imageFileResizer(file, 300, 300, "JEPG", 80, 0, (uri) => resolve(uri), "blob", 200, 300));

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: async (file) => {
      setLoaded(false);
      const metadata = { contentType: file[0].type };
      const storageRef = firebase.storage().ref().child(`${client}/images/${path}/${file[0].name}`);
      const uploadTask = storageRef.put(file[0], metadata);
      uploadTask.on("state_changed", {
        complete: async () => {
          try {
            const imageThumb = (await resizeFile(file[0])) as Blob;
            const storageThumbRef = firebase.storage().ref().child(`${client}/images/${path}/thumbs/${file[0].name}`);
            const uploadThumbTask = storageThumbRef.put(imageThumb, metadata);
            uploadThumbTask.on("state_changed", {
              complete: () => pullImages(true)
            });
          } catch (error) {
            catchExceptionCallback(error);
          }
        }
      });
    }
  });

  // return file settings for saving
  const saveFile = () => {
    if (isMultiple) {
      const newSettings = [];
      const tempArr = currentMultipleSetting;
      selectedImagesPool.map((item) => {
        newSettings.push({
          name: item.name,
          fileName: item.name,
          sizeFile: parseFloat(item.size) * 1024,
          thumbUrl: item.thumb,
          type: item.type,
          url: item.url
        });
        tempArr.push({ fileName: item.name, url: item.url });
      });
      setImage(newSettings);
      setCurrentMultipleSetting(tempArr);
    } else {
      const newSetting = {};
      newSetting["name"] = selectedImagesPool[0].name;
      newSetting["fileName"] = selectedImagesPool[0].name;
      newSetting["sizeFile"] = parseFloat(selectedImagesPool[0].size) * 1024;
      newSetting["thumbUrl"] = selectedImagesPool[0].thumb;
      newSetting["type"] = selectedImagesPool[0].type;
      newSetting["url"] = selectedImagesPool[0].url;
      setImage(newSetting);
      fetchSetting([{ fileName: selectedImagesPool[0].name, url: selectedImagesPool[0].url }]);
    }
    setShowModal(false);
  };

  // pull images from firebase hosting
  const pullImages = (isAdded: boolean) => {
    setLoaded(false);
    const store = firebase.storage().ref().child(`${client}/images/${path}`);
    const images = [];
    const imagesPoolCount = imagesPool.length;

    store
      .listAll()
      .then(async (res) => {
        await Promise.all(
          res.items.map((itemRef) => {
            const image = {
              name: itemRef?.name,
              fullPath: itemRef.fullPath,
              checked: false
            };
            return itemRef
              .getDownloadURL()
              .then((url) => {
                image["url"] = url;
                image["thumb"] = url;
              })
              .then(async () => {
                const metadata = await itemRef.getMetadata();
                image["size"] = (metadata.size / 1024).toFixed(2);
                image["type"] = metadata.contentType;
              })
              .then(() => {
                images.push(image);
              })
              .catch((error) => {
                if (error.code !== "storage/object-not-found") catchExceptionCallback(error);
              });
          })
        );
      })
      .then(() => {
        setImagesPool(images);
        setImagesAllPool(images);
        isAdded ? (images.length >= imagesPoolCount ? setLoaded(true) : setLoaded(false)) : setLoaded(true);
      })
      .catch((error) => {
        catchExceptionCallback(error);
      });
  };

  // add to selected pool
  const addToSelectedImagesPool = (src) => {
    const found = selectedImagesPool.find((element) => element.name == src.name);
    const imagePoolItem = imagesPool.find((element) => element.name == src.name);
    if (selectedImagesPool.indexOf(found) > -1) {
      selectedImagesPool.splice(found, 1);

      const items = [...imagesPool];
      const item = { ...items[imagesPool.indexOf(imagePoolItem)] };
      item.checked = false;
      items[imagesPool.indexOf(imagePoolItem)] = item;
      setImagesPool(items);
    } else {
      setSelectedImagesPool([...selectedImagesPool, src]);
      imagePoolItem.checked = true;
    }
  };

  // on checkbox change
  const onChangeHandler = (event) => {
    setSearchValue(event.target.value);
    const others = imagesAllPool.filter((element) => {
      return element.name.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setImagesPool(others);
  };

  const imagesList = imagesPool.map((src, index) => {
    return (
      <div key={index} className="image-item">
        <div className="image-wrapper">
          <img className="inner-image-item mw-100" src={src.url} />
          <input className="checkbox" type="checkbox" id={src.url} defaultChecked={src.checked} onClick={() => addToSelectedImagesPool(src)} />
        </div>
        <div className="image-meta">
          <p style={{ fontWeight: "bold" }}>{src.name.length > 20 ? src.name.substring(0, 20) + `...` : src.name}</p>
          <p> {src.size} KB</p>
        </div>
      </div>
    );
  });

  // render images list
  const renderImages = currentMultipleSetting.map((currentSettingValue, index) => {
    return (
      currentSettingValue?.isDeleted !== true &&
      currentSettingValue.url !== "" && (
        <Col key={index} lg={3}>
          <div className="image-holder">
            <div className="image-cross">
              <FontAwesomeIcon title="Remove Image" onClick={() => removeCurrentImage(currentSettingValue.url)} icon={faWindowClose as any} />
            </div>

            <img src={currentSettingValue.url} style={{ width: "100%", height: "100%" }} />
            <p>{currentSettingValue.fileName}</p>
            <p>{currentSettingValue.fileSize !== "NA" && currentSettingValue.fileSize} KB</p>
          </div>
        </Col>
      )
    );
  });

  // delete firebase items
  const removeItems = () => {
    try {
      setLoaded(false);
      selectedImagesPool.map((item) => {
        const desertRef = firebase.storage().ref().child(item.fullPath);
        desertRef.delete().then(() => {
          if (currentImages && currentImages[0].url == item.url) fetchSetting([]);
        });
      });
      setSelectedImagesPool([]);
      pullImages(false);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const removeCurrent = (deletedUrl: string) => {
    fetchSetting([{ fileName: "", url: "", isDeleted: true }]);
    onDelete(deletedUrl);
  };

  const removeCurrentImage = (deletedUrl: string) => {
    setImageLoaded(false);
    const newImages = currentMultipleSetting.filter((item) => item.url !== deletedUrl);
    fetchSetting(newImages);
    onDelete(deletedUrl);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <div className="mb-3 mt-3">
            <p>{sectionName}</p>

            {!imageLoaded ? (
              <div className="fa-4x" style={{ textAlign: "center", paddingTop: "10%" }}>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            ) : currentMultipleSetting.length > 0 ? (
              <Row> {renderImages} </Row>
            ) : (
              currentSettingValue?.isDeleted !== true && (
                <Row>
                  <Col lg={3}>
                    {currentSettingValue?.url && currentSettingValue?.url !== "" && (
                      <div className="image-holder">
                        23
                        <div className="image-cross">
                          <FontAwesomeIcon title="Remove Image" onClick={() => removeCurrent(currentSettingValue?.url)} icon={faWindowClose as any} />
                        </div>
                        <img src={currentSettingValue.url} style={{ width: "100%", height: "100%" }} />
                        <p>{currentSettingValue.fileName}</p>
                        <p> {currentSettingValue.fileSize !== "NA" && currentSettingValue.fileSize} KB</p>
                      </div>
                    )}
                  </Col>
                </Row>
              )
            )}
          </div>
        </Col>
      </Row>

      <div style={{ paddingBottom: "15px" }}>
        <Button
          style={{ position: "relative", whiteSpace: "nowrap" }}
          disabled={disabled}
          color={"primary"}
          onClick={() => {
            setImagesPool([]);
            setSelectedImagesPool([]); // clear selected pool
            setSearchValue("");
            pullImages(false);
            setShowModal(true);
          }}
        >
          Image Uploader
        </Button>

        {showModal && (
          <Modal centered isOpen={showModal} onClosed={() => setShowModal(false)} toggle={() => setShowModal(false)} style={{ maxWidth: "70vw" }} className="widget-blocks image-uploader">
            <ModalHeader toggle={() => setShowModal(false)}>
              <div style={{ display: "inline" }}>
                <span style={{ fontSize: "22px", fontWeight: "bold", paddingRight: "15px" }}>Image Uploader</span> | <span style={{ paddingLeft: "15px" }}>Images/{path}</span>
              </div>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={8} className="container-leftside">
                  <div className="search-tab">
                    <Input placeholder="Search" type="text" value={searchValue} onChange={onChangeHandler} className="search-bar"></Input>
                    <Button
                      className="mt-3"
                      style={{ position: "relative", whiteSpace: "nowrap" }}
                      color={"danger"}
                      onClick={() => {
                        removeItems();
                      }}
                      disabled={selectedImagesPool.length < 1}
                    >
                      Delete
                    </Button>
                  </div>

                  <div className="image-list">
                    {!loaded ? (
                      <div className="fa-4x" style={{ textAlign: "center", paddingTop: "10%" }}>
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <div className="image-items">{imagesList}</div>
                    )}
                  </div>
                </Col>
                <Col lg={4} style={{ fontWeight: "bold", fontSize: "1rem", padding: " 44px 15px 0px 0px" }}>
                  <div className="drop-zone" style={{ textAlign: "center", borderRadius: "10px", height: "100%" }}>
                    <div {...getRootProps({ className: "dropzone well" })} style={{ opacity: "1", height: "100%", borderRadius: "10px" }}>
                      <input {...getInputProps()} />
                      <div className="drop-info">
                        <p>
                          <FontAwesomeIcon icon={faUpload as any} />
                        </p>
                        <p>Drag and drop image here to upload</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div>
                <span style={{ color: "red" }}>{isMultiple ? "* Multiple Selection" : "* Max Selection 1"}</span>
              </div>
              <Button
                style={{ position: "relative", backgroundColor: "#27c24c", color: "#fff", whiteSpace: "nowrap" }}
                onClick={() => {
                  saveFile();
                }}
                disabled={(isMultiple && selectedImagesPool.length < 1) || (!isMultiple && selectedImagesPool.length !== 1)}
              >
                Select
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ImageUploader;
