import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import * as types from './ActionTypes';
class SaveButton extends Component {

  static setComplete(dispatch, id, delayMilliseconds) {
    setTimeout(() => dispatch({ type: types.BUTTON_COMPLETE, id }), delayMilliseconds);
  }

  render() {
    const { saving, id, text, type, style, color, className, disabled, disabledText, onClick, delayMilliseconds, hidden } = this.props;

    if (hidden) {
      return null;
    }

    if (saving[id] && saving[id].saving === true) {
      return (
        <Button type={type} className={className} color={color} disabled onClick={onClick} style={style}>
          {text} <Spinner size="sm" />
        </Button>
      );
    } else if (saving[id] && saving[id].error === true) {
      SaveButton.setComplete(this.props.dispatch, id, delayMilliseconds);
      return (
        <Button type={type} className={className} color={color} disabled onClick={onClick} style={style}>
          {disabledText}
          <i className="fa fa-times" />
        </Button>
      );
    } else if (saving[id] && saving[id].saved === true) {
      SaveButton.setComplete(this.props.dispatch, id, delayMilliseconds);
      return (
        <Button type={type} className={className} color={color} disabled onClick={onClick} style={style}>
          <i className="fa fa-check" />
        </Button>
      );
    }
    return (
      <Button type={type} className={className} color={color} disabled={disabled} onClick={onClick} style={style}>
        {text}
      </Button>
    );
  }
}

SaveButton.defaultProps = {
  onClick: null,
  disabled: false,
  hidden: false,
  showInProgressText: false,
  delayMilliseconds: 2000,
}

function mapStateToProps(state) {
  return {
    saving: state.buttons.saving,
  };
}

export default connect(mapStateToProps)(SaveButton);
