import { filter } from "lodash";

export interface IReportByCoverPayload {
  restaurantId: string;
  clientId: string;

  bookingType: string;

  startDate?: string;
  endDate?: string;

  period?: any;
  mode?: string;

  calendar: string;
}

const bookingTypeFilters = {
  "standard-booking": {
    value: "Standard",
    title: "Standard Bookings",
    label: "Standard",
  },
  "function-booking": {
    value: "Function",
    title: "Functions",
    label: "Functions",
  },
  "all-booking": {
    value: "All",
    title: "All Booking Types",
    label: "All Booking Types",
  },
};

const functionTypeFilters = {
  "non-exclusive-function": {
    value: "non-exclusive-function",
    title: "Non-Exclusive Functions",
    label: "Non-Exclusive Functions",
  },
  "exclusive-function": {
    value: "exclusive-function",
    title: "Exclusive Functions",
    label: "Exclusive Functions",
  },
  "all-function": {
    value: "all-function",
    title: "All Functions",
    label: "All Functions",
  },
};

const filterResults = (results, filterTreeData, filterValue, bookingTypeFilterValue, functionTypeFilterValue) => {
  const showingStandardBooking = bookingTypeFilterValue === bookingTypeFilters["standard-booking"].value;
  const showingFunctionBooking = bookingTypeFilterValue === bookingTypeFilters["function-booking"].value;

  return filter(results, (item) => {
    let shouldShowByMealPeriod = false,
      shouldShowByArea = false,
      shouldShowByTableClass = false,
      shouldShowByMenu = false,
      shouldShowByFoodPackage = false,
      shouldShowByBeveragePackage = false,
      shouldShowByFoodAndBeverage = false,
      shouldShowByExclusiveNonExclusive = false;

    // filter out by meal period
    if (filterValue.includes(filterTreeData[0].value)) {
      shouldShowByMealPeriod = true;
    } else {
      shouldShowByMealPeriod = filterValue.includes(item.mealId);
    }

    if (showingStandardBooking || showingFunctionBooking) {
      // filter out by area
      if (filterValue.includes(filterTreeData[1].value)) {
        shouldShowByArea = true;
      } else {
        shouldShowByMealPeriod = filterValue.includes(item.areaId);
      }

      // filter out by table classes
      if (filterValue.includes(filterTreeData[2].value)) {
        shouldShowByTableClass = true;
      } else {
        shouldShowByTableClass = filterValue.includes(item?.tableClassId);
      }
    }

    if (showingStandardBooking) {
      // filter out by menu
      if (filterValue.includes(filterTreeData[3].value)) {
        shouldShowByMenu = true;
      } else {
        shouldShowByMenu = filterValue.includes(item?.menuId);
      }
    }

    if (showingFunctionBooking) {
      // filter out by food package
      if (filterValue.includes(filterTreeData[3].value)) {
        shouldShowByFoodPackage = true;
      } else {
        shouldShowByFoodPackage = filterValue.includes(item?.foodPackageId);
      }

      // filter out by beverage package
      if (filterValue.includes(filterTreeData[4].value)) {
        shouldShowByBeveragePackage = true;
      } else {
        shouldShowByBeveragePackage = filterValue.includes(item?.beveragePackageId);
      }

      // filter out by food & beverage package
      if (filterValue.includes(filterTreeData[5].value)) {
        shouldShowByFoodAndBeverage = true;
      } else {
        shouldShowByFoodAndBeverage = filterValue.includes(item?.foodAndBeveragePackageId);
      }

      // filter out by exclusive or non-exclusive
      if (functionTypeFilterValue === functionTypeFilters["exclusive-function"].value) {
        shouldShowByExclusiveNonExclusive = item?.nonExclusive ? JSON.parse(item?.nonExclusive) === false : true;
      } else if (functionTypeFilterValue === functionTypeFilters["non-exclusive-function"].value) {
        shouldShowByExclusiveNonExclusive = item?.nonExclusive ? JSON.parse(item?.nonExclusive) === true : true;
      } else {
        shouldShowByExclusiveNonExclusive = true;
      }
    }

    if (showingStandardBooking) {
      return shouldShowByMealPeriod && shouldShowByArea && shouldShowByTableClass && shouldShowByMenu;
    }

    if (showingFunctionBooking) {
      return shouldShowByMealPeriod && shouldShowByArea && shouldShowByTableClass && shouldShowByFoodPackage && shouldShowByBeveragePackage && shouldShowByFoodAndBeverage && shouldShowByExclusiveNonExclusive;
    }

    return shouldShowByMealPeriod;
  });
};

export { bookingTypeFilters, filterResults, functionTypeFilters };
