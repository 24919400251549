import { find, keys } from "lodash";
import { Button } from "reactstrap";
import { Product, ProductSizes } from "resbutler-utils/types/product";

const ProductRow = ({ productId, sizes, allSizes, products, removeProduct = null, restaurants }: { productId: string; sizes: { [sizeId: string]: boolean }; allSizes: ProductSizes; products: Product[]; removeProduct: any; restaurants: string[] }) => {
  const product = find(products, (p) => p.id === productId);
  const { name } = product;
  const productSizes = keys(sizes).sort();
  return (
    <tr>
      <td>{name}</td>
      <td>
        {productSizes.map((sizeId) => (
          <div key={sizeId}>{allSizes[sizeId]?.name}</div>
        ))}
      </td>
      {restaurants.map((r) => (
        <td key={r}>
          {productSizes.map((size) => (
            <div key={size}>{(product.restaurants || {})[r]?.price[size] || <i className="fa fa-times" />}</div>
          ))}
        </td>
      ))}
      {removeProduct && (
        <td>
          <Button color="danger" onClick={() => removeProduct({ productId })}>
            <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        </td>
      )}
    </tr>
  );
};

export default ProductRow;
