// customerTagCategories
export const GET_CUSTOMER_TAG_CATEGORIES = "GET_CUSTOMER_TAG_CATEGORIES";
export const SAVE_CUSTOMER_TAG_CATEGORIES = "SAVE_CUSTOMER_TAG_CATEGORIES";
export const UPDATE_CUSTOMER_TAG_CATEGORIES = "UPDATE_CUSTOMER_TAG_CATEGORIES";

export const GET_CUSTOMER_TAGS = "GET_CUSTOMER_TAGS";
export const GET_CUSTOMER_TAG_CATEGORIES_BY_ID = "GET_CUSTOMER_TAG_CATEGORIES_BY_ID";
export const SAVE_CUSTOMER_TAGS = "SAVE_CUSTOMER_TAGS";
export const UPDATE_CUSTOMER_TAGS = "UPDATE_CUSTOMER_TAGS";

export const GET_CUSTOMER_PRODUCT_TAGS = 'GET_CUSTOMER_PRODUCT_TAGS';