import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "_utils/format";
import classNames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import "firebase/compat/firestore";
import _, { find, keys } from "lodash";
import React, { useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { Button, Col, Row, Table } from "reactstrap";
import { GroupHeadingProducts, Menu } from "resbutler-utils/types/Menu";
import { ProductSizes } from "resbutler-utils/types/product";
import { isProductLandingPageEnabled, updateProductPropertyInMenu } from "resbutler-utils/utils/menuUtils";

const shouldDisableLandingPage = (menu: Menu, productId: string, isLandingPageChecked: boolean) => {
  if (isLandingPageChecked === true) {
    return false;
  }

  return isProductLandingPageEnabled(menu, productId);
};

const RenderProduct = ({ productId, restaurantId, collapseProductId, setCollapseProductId, sizes, onChange, value, updateProductOrder, isInclusion, pkg = false }) => {
  const products = useAppSelector((state) => state.root.products);
  const product = find(products, (p) => p.id === productId);
  const form = useForm();
  const values = useFormState().values as Menu;

  const isLandingPageCheckboxChecked = value?.[productId]?.landingPage;
  const shouldDisableLandingPageCheckbox = shouldDisableLandingPage(values, productId, isLandingPageCheckboxChecked);

  const handleChangeLandingPage = (e) => {
    const landingPage = e.target.checked;

    form.batch(() => {
      form.change(`landing.products.${productId}`, landingPage ? { order: keys(values.landing.products).length } : undefined);
      onChange({ ...value, [productId]: { ...value[productId], landingPage } });
    });
  };

  const handleChangeLimitless = (e) => {
    const limitless = e.target.checked;

    /*
     * update all same product inside menus to have the same limitless status
     * ref.: DT-5991
     */
    const updatedValues = _.cloneDeep(values);
    updateProductPropertyInMenu(updatedValues, productId, "limitless", limitless);
    form.reset(updatedValues);
  };

  return (
    <React.Fragment>
      <tr style={{ backgroundColor: collapseProductId[productId] ? "#f6f6f6" : "#fff" }}>
        <td>
          <Button type="button" className="mr-2" onClick={() => setCollapseProductId((state) => ({ ...state, [productId]: state[productId] !== undefined ? !state[productId] : true }))}>
            <i className={classNames("fa", { "fa-chevron-right": !collapseProductId[productId], "fa-chevron-down": collapseProductId[productId] })} aria-hidden="true" />
          </Button>
          {product.name}
        </td>
        {!isInclusion ? (
          <td>
            <div className="checkbox c-checkbox">
              <label className="m-0" htmlFor={`${productId}-landing-page`}>
                <input id={`${productId}-landing-page`} type="checkbox" className="form-check-input" onChange={handleChangeLandingPage} checked={isLandingPageCheckboxChecked} disabled={shouldDisableLandingPageCheckbox} />
                <span className="fa fa-check"></span>
              </label>
            </div>
          </td>
        ) : null}
        <td>
          <div className="checkbox c-checkbox">
            <label className="m-0" htmlFor={`${productId}-limitless`}>
              <input id={`${productId}-limitless`} type="checkbox" className="form-check-input" onChange={handleChangeLimitless} checked={value?.[productId]?.limitless} />
              <span className="fa fa-check"></span>
            </label>
          </div>
        </td>
        <td>
          <input type="text" className="form-control" maxLength={15} value={value?.[productId]?.note} onChange={(e) => onChange({ ...value, [productId]: { ...value[productId], note: e.target.value } })} />
        </td>
        <td>
          <Row className="justify-content-end">
            <Col className="col-auto p-0">
              <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => updateProductOrder("up", productId)}>
                <i className="fa fa-arrow-up"></i>
              </button>
            </Col>
            <Col className="col-auto ps-1">
              <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => updateProductOrder("down", productId)}>
                <i className="fa fa-arrow-down"></i>
              </button>
            </Col>
          </Row>
        </td>
      </tr>
      {collapseProductId[productId] && _.keys(value[productId]?.productSizeIds).length > 0 && (
        <tr>
          <td colSpan={3}>
            <Table bordered className="m-0">
              <thead>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faEye as any} />
                  </th>
                  {pkg ? <th>Always Included</th> : null}
                  <th>Reduce From Menu</th>
                  <th>Product Size</th>
                  <th>Standard Pricing</th>
                  <th>Adjusted Price</th>
                </tr>
              </thead>
              <tbody>
                {_.keys(value[productId].productSizeIds).map((sizeId) => (
                  <tr key={`${productId}-${sizeId}`}>
                    <td>
                      <div className="checkbox c-checkbox">
                        <label className="m-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={value[productId].productSizeIds[sizeId].enabled === true}
                            onChange={(e) => onChange({ ...value, [productId]: { ...value[productId], productSizeIds: { ...value[productId].productSizeIds, [sizeId]: { ...value[productId].productSizeIds?.[sizeId], enabled: e.target.checked } } } })}
                          />
                          <span className="fa fa-check"></span>
                        </label>
                      </div>
                    </td>
                    {pkg ? (
                      <td>
                        <div className="checkbox c-checkbox">
                          <label className="m-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={value[productId].productSizeIds[sizeId].isAlwaysIncluded === true}
                              onChange={(e) => onChange({ ...value, [productId]: { ...value[productId], productSizeIds: { ...value[productId].productSizeIds, [sizeId]: { ...value[productId].productSizeIds?.[sizeId], isAlwaysIncluded: e.target.checked } } } })}
                            />
                            <span className="fa fa-check"></span>
                          </label>
                        </div>
                      </td>
                    ) : null}
                    <td>
                      <div className="checkbox c-checkbox">
                        <label className="m-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={value[productId].productSizeIds[sizeId].notReducedMenu}
                            onChange={(e) => onChange({ ...value, [productId]: { ...value[productId], productSizeIds: { ...value[productId].productSizeIds, [sizeId]: { ...value[productId].productSizeIds?.[sizeId], notReducedMenu: e.target.checked } } } })}
                          />
                          <span className="fa fa-check"></span>
                        </label>
                      </div>
                    </td>
                    <td>{sizes?.[sizeId]?.name}</td>
                    <td>{product.restaurants?.[restaurantId]?.price?.[sizeId] ? priceFormatter.format(product.restaurants[restaurantId]?.price?.[sizeId]) : ""}</td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={value[productId].productSizeIds?.[sizeId]?.upgradePrice}
                        onChange={(e) => onChange({ ...value, [productId]: { ...value[productId], productSizeIds: { ...value[productId].productSizeIds, [sizeId]: { ...value[productId].productSizeIds?.[sizeId], upgradePrice: e.target.value ? Number(e.target.value) : null } } } })}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default function ProductList({ sizes, onChange, value, isInclusion, pkg = false }: { sizes: ProductSizes; onChange: any; value: GroupHeadingProducts; isInclusion: boolean; pkg?: boolean }) {
  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const sortedProducts = _.chain(value)
    .map((v, id) => ({ ...v, id }))
    .sortBy("order")
    .value();
  const [collapseProductId, setCollapseProductId] = useState({});

  const updateProductOrder = (action: "up" | "down", productId: string) => {
    const productIndex = sortedProducts.findIndex((product) => product.id === productId);
    const requiredIndex = action === "up" ? productIndex - 1 : productIndex + 1;
    if (requiredIndex >= 0 && requiredIndex < sortedProducts.length) {
      const swapProduct = sortedProducts[requiredIndex];
      onChange({
        ...value,
        [productId]: { ...value[productId], order: requiredIndex + 1 },
        [swapProduct.id]: { ...value[swapProduct.id], order: productIndex + 1 },
      });
    }
  };

  return (
    <Row>
      <Col md="12">
        <Table bordered className="mt-3">
          <thead className="parent-header">
            <tr>
              <th>Product name</th>
              {!isInclusion ? <th>Landing Page</th> : null}
              <th>Limitless</th>
              <th>Menu Note</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sortedProducts.map((current) => {
              return (
                <RenderProduct
                  key={current.id}
                  productId={current.id}
                  restaurantId={restaurantId}
                  collapseProductId={collapseProductId}
                  setCollapseProductId={setCollapseProductId}
                  sizes={sizes}
                  onChange={onChange}
                  value={value}
                  updateProductOrder={updateProductOrder}
                  isInclusion={isInclusion}
                  pkg={pkg}
                />
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
