/**
 * @param val: Number
 * @param isCents: Boolean
 */
export const toCurrency = (val, isCents = false) =>
  new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(val / (isCents ? 100 : 1));

export const priceFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  minimumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat("en-AU");

export const redemptionCodeFormat = (code) => {
  return code?.substr(2);
};
