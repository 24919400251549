import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";
import { Meals } from "resbutler-utils/types/Meals";

const useMeals = (restaurantId): [boolean, Meals] => {
  const [loading, setLoading] = useState(true);
  const [meals, setMeals] = useState<Meals>({});
  const { tenantId } = getConfig();

  async function fetchData() {
    try {
      setLoading(true);
      const snap = restaurantId ? await firebase.database().ref(`${tenantId}/meals`).orderByChild("restaurantId").equalTo(restaurantId).once("value") : await firebase.database().ref(`${tenantId}/meals`).once("value");
      setMeals(pickBy(snap.val() as Meals, (m) => m.enabled));
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (restaurantId) fetchData();
  }, [restaurantId]);

  return [loading, meals];
};

export default useMeals;
