// import axios from "axios";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import { find } from "lodash";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import Select from "react-select";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { catchExceptionCallback } from "../../utilities";

const required = (value) => (value ? undefined : "Required");

const handleRedeem = async ({ values, setLoading }: { values: any; setLoading: (t: boolean) => void }) => {
  try {
    setLoading(true);
    console.info("manual redeem", values);
  } catch (error) {
    catchExceptionCallback(error);
  } finally {
    setLoading(false);
  }
};

const RedeemModal = ({ isOpen, setModal, voucher, restaurants }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Container fluid>
      <Form
        onSubmit={async (values) => {
          await handleRedeem({ values, setLoading });
          setModal(false);
        }}
        render={({ handleSubmit, submitting }) => (
          <Modal isOpen={isOpen} toggle={() => setModal(false)} loading={true} zIndex={9999}>
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader toggle={() => setModal(false)}>Redeem Voucher</ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={12}>
                    <Field name="restaurantId" validate={required}>
                      {({ input: { onChange, value }, meta }) => {
                        const valueRestaurant = find(restaurants, (_, id) => id === value);
                        return (
                          <div className="form-group">
                            <label htmlFor="restaurant">
                              Restaurant <span className="validate-form">*</span>
                            </label>

                            <Select
                              id="restaurant"
                              blurInputOnSelect={false}
                              closeMenuOnSelect={true}
                              options={voucher.restaurants?.map((id) => {
                                const restaurant = restaurants?.[id];

                                return {
                                  label: restaurant?.name,
                                  value: id
                                };
                              })}
                              value={
                                value
                                  ? {
                                      value: value,
                                      label: valueRestaurant.name
                                    }
                                  : undefined
                              }
                              onChange={(e) => onChange(e.value)}
                            />
                            {meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
                          </div>
                        );
                      }}
                    </Field>
                  </Col>

                  <Col sm={12}>
                    <Field name="redeemAmount" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-group">
                          <label>
                            Amount Redeemed <span className="validate-form">*</span>
                          </label>
                          <input {...input} type="number" className="form-control" min={0} />
                          {meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col lg={12}>
                    <Button type="submit" color="success">
                      Save {(submitting || loading) && <Spinner size="sm" />}
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          </Modal>
        )}
      />
    </Container>
  );
};

export default RedeemModal;
