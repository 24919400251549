import { combineReducers } from "redux";
import { filter } from "lodash";

import {
  GIFT_CERTIFICATE_IMAGES,
  REMOVE_EMPTY_GIFT_CERTIFICATE_IMAGE,
  GIFT_CERTIFICATE_SAVED,
  GIFT_CERTIFICATE_SAVING,
  GIFT_CERTIFICATE_SETTINGS,
  GET_CERTIFICATE_EDITOR_STATE,
  GET_GIFT_CERTIFICATES,
  GET_GIFT_CERTIFICATES_SEARCH_RESULTS,
  GET_GIFT_CERTIFICATE_BY_ID,
  UPDATE_GIFT_CERTIFICATE,
  GIFT_CERTIFICATE_FORM_TAB,
  GET_ISSUED_GIFT_CERTIFICATE_TAB,
  GIFT_CERTIFICATE_RESTAURANT_SETTINGS,
  GIFT_CERTIFICATE_RESTAURANT_IMAGES,
  REMOVE_EMPTY_GIFT_CERTIFICATE_RESTAURANT_IMAGE,
  GIFT_CERTIFICATE_RESTAURANT_SAVING,
  GIFT_CERTIFICATE_RESTAURANT_SAVED,
  GET_CERTIFICATE_RESTAURANT_EDITOR_STATE,
  LOAD_RESTAURANT_GALLERY,
  DISPLAY_PROGRESS_BAR_RESTAURANT_IMAGES,
  GET_GIFT_CERTIFICATE_CUSTOMERS,
  //GET_GIFT_CERTIFICATE_CUSTOMERS,
} from "./ActionTypes";

const initialState = {
  images: [],
  saving: {},
  imagesRestaurant: [],
  savingRestaurant: {},
  settings: null,
  restaurantSettings: {},
  editorState: null,
  restaurantEditorState: null,
  byId: [],
  giftCertificateByCustomer: [],
  searchResults: [],
  giftCertificate: null,
  customer: null,
  recipient: null,
  formTab: 1,
  issuedGiftCertificateTab: 1,
  loadRestaurantGallery: true,
  progressBarRestaurantImages: false,
  giftCertificateCustomers: [],
};

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATES:
      return action.giftCertificates;
    default:
      return state;
  }
}

function giftCertificatesbyCustomerId(state = initialState.giftCertificateByCustomer, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATE_CUSTOMERS:
      return action.giftCertificates;
    default:
      return state;
  }
}

function searchResults(state = initialState.searchResults, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATES_SEARCH_RESULTS:
    case GET_GIFT_CERTIFICATES:
      return action.giftCertificates;
    default:
      return state;
  }
}

function images(state = initialState.images, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_IMAGES:
      return action.images;
    case REMOVE_EMPTY_GIFT_CERTIFICATE_IMAGE:
      return filter(state, (img) => img.id !== action.id);
    default:
      return state;
  }
}

function imagesRestaurant(state = initialState.imagesRestaurant, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_RESTAURANT_IMAGES:
      return action.imagesRestaurant;
    case REMOVE_EMPTY_GIFT_CERTIFICATE_RESTAURANT_IMAGE:
      return filter(state, (img) => img.id !== action.id);
    default:
      return state;
  }
}

function giftCertificate(state = initialState.giftCertificate, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATE_BY_ID:
    case UPDATE_GIFT_CERTIFICATE:
      return action.giftCertificate;
    default:
      return state;
  }
}

function customer(state = initialState.customer, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATE_BY_ID:
      return action.customer;
    default:
      return state;
  }
}

function recipient(state = initialState.recipient, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATE_BY_ID:
      return action.recipient;
    default:
      return state;
  }
}

/* function giftCertificateCustomers(state = initialState.giftCertificateCustomers, action) {
  switch (action.type) {
    case GET_GIFT_CERTIFICATE_CUSTOMERS: {
      if (!(action && action.customer)) return state;
      const filter = state.filter((customer) => customer.UID_Search === action.customer.UID_Search);
      if (filter.length > 0) return state;
      return [...state, action.customer];
    }
    default:
      return state;
  }
} */

function editorState(state = initialState.editorState, action) {
  switch (action.type) {
    case GET_CERTIFICATE_EDITOR_STATE:
      return action.editorState;
    default:
      return state;
  }
}
function restaurantEditorState(state = initialState.restaurantEditorState, action) {
  switch (action.type) {
    case GET_CERTIFICATE_RESTAURANT_EDITOR_STATE:
      return action.restaurantEditorState;
    default:
      return state;
  }
}
function saving(state = initialState.saving, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_SAVING:
      return {
        ...state,
        [action.id]: true,
      };
    case GIFT_CERTIFICATE_SAVED:
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
}

function savingRestaurant(state = initialState.savingRestaurant, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_RESTAURANT_SAVING:
      return {
        ...state,
        [action.id]: true,
      };
    case GIFT_CERTIFICATE_RESTAURANT_SAVED:
      return {
        ...state,
        [action.id]: false,
      };
    default:
      return state;
  }
}

function settings(state = initialState.settings, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_SETTINGS:
      return action.settings;
    default:
      return state;
  }
}

function restaurantSettings(state = initialState.restaurantSettings, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_RESTAURANT_SETTINGS:
      return action.restaurantSettings;
    default:
      return state;
  }
}

function formTab(state = initialState.formTab, action) {
  switch (action.type) {
    case GIFT_CERTIFICATE_FORM_TAB:
      return action.formTab;
    default:
      return state;
  }
}

function issuedGiftCertificateTab(state = initialState.issuedGiftCertificateTab, action) {
  switch (action.type) {
    case GET_ISSUED_GIFT_CERTIFICATE_TAB:
      return action.issuedGiftCertificateTab;
    default:
      return state;
  }
}

function loadRestaurantGallery(state = initialState.loadRestaurantGallery, action) {
  switch (action.type) {
    case LOAD_RESTAURANT_GALLERY:
      return action.loadRestaurantGallery;
    default:
      return state;
  }
}

function progressBarRestaurantImages(state = initialState.progressBarRestaurantImages, action) {
  switch (action.type) {
    case DISPLAY_PROGRESS_BAR_RESTAURANT_IMAGES:
      return action.loading;
    default:
      return state;
  }
}

export default combineReducers({
  images,
  imagesRestaurant,
  saving,
  savingRestaurant,
  settings,
  editorState,
  byId,
  searchResults,
  giftCertificate,
  customer,
  formTab,
  restaurantSettings,
  restaurantEditorState,
  issuedGiftCertificateTab,
  loadRestaurantGallery,
  progressBarRestaurantImages,
  recipient,
  giftCertificatesbyCustomerId,
  //giftCertificateCustomers,
});
