import React from "react";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

const BasePage = () => (
  <div className="wrapper">
    <Outlet />
    <ToastContainer autoClose={3000} pauseOnFocusLoss={false} style={{ zIndex: 9999 }} />
  </div>
);

export default BasePage;
