import {findIndex} from 'lodash';
import {combineReducers} from 'redux';
import {GET_MEMBERSHIP_OFFER_BY_ID, GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE, GET_MEMBERSHIP_OFFERS, GET_ALL_MEMBERSHIP_OFFERS, UPDATE_MEMBERSHIP_OFFER} from "./MembershipOfferAction";

const initialState = {
    byId: [],
    allMembershipOffers: [],
    membershipOffer: null,
    editRow: {
        name: '',
        thirdPartyOffer: false,
        // description: '', // rich text => delegated to descriptionEditor
        cardBackgroundColor: '', // #ffffff
        enabled: '', // boolean
        expiry: '',
    },
    descriptionEditor: '',
};

const byId = (state = initialState.byId, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_OFFERS:
            return action.membershipOffers;
        case UPDATE_MEMBERSHIP_OFFER: {
            const index = findIndex(state, (membershipOffer) => membershipOffer._key === action.key);
            if (index !== -1) {
                return [
                    ...state.slice(0, index),
                    action.membershipOffer,
                    ...state.slice(index + 1),
                ];
            }
            return state;
        }
        default:
            return state;
    }
};

const allMembershipOffers = (state = initialState.allMembershipOffers, action) => {
    switch (action.type) {
        case GET_ALL_MEMBERSHIP_OFFERS:
            return action.allMembershipOffers;
        default:
            return state;
    }
};

const membershipOffer = (state = initialState.membershipOffer, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_OFFER_BY_ID:
            return action.membershipOffer;
        default:
            return state;
    }
};

const editRow = (state = initialState.editRow, action) => {
    switch (action.type) {
        case UPDATE_MEMBERSHIP_OFFER:
            return {
                key: '',
                data: {},
            };
        default:
            return state;
    }
};

const descriptionEditor = (state = initialState.descriptionEditor, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE:
            return action.editorState;
        default:
            return state;
    }
};

export default combineReducers({
    byId,
    membershipOffer,
    editRow,
    descriptionEditor,
    allMembershipOffers
});
