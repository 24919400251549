const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

export interface IRGB {
  r: number;
  g: number;
  b: number;
}

export const rgbToHex = ({ r, g, b }: IRGB) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRGB = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  const rgba = {
    r,
    g,
    b,
    a: "1",
  };
  return rgba;
};
