import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import VoucherTableCard, { getId } from "./VoucherTableCard";

export const Voucherslist = () => {
  const link = `/vouchers/one-off-vouchers`;
  const [vouchers, setVouchers] = useState([]);
  const [filterEnabled, setFilterEnabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client } = getConfig();

  useEffect(() => {
    const load = async () => {
      dispatch(showLoading());
      try {
        const snapshot = await firebase.firestore().collection(`${client}/crm/voucherSetups`).where("generationType", "==", "oneOff").where("type", "==", "oneOff").get();
        const l = snapshot.docs.map((snap1) => ({ ...snap1.data(), _key: snap1.id, id: snap1.id }));
        setVouchers(l);
      } catch (e) {
        catchExceptionCallback(e);
      } finally {
        dispatch(hideLoading());
      }
    };
    load();
  }, []);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Vouchers" headerText="One-Off Vouchers" subHeaderText="One-Off Vouchers" showRestaurants={false} />
      <Row>
        <Col lg={8} sm={12}>
          <div className="justify-content-between row">
            <Col>
              <Button color="primary" onClick={() => navigate(`${link}/${getId()}`)}>
                New One-Off Voucher
              </Button>
            </Col>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group" getName={getFilterName} onSelect={(e: any) => setFilterEnabled(e)} />
            </div>
          </div>
          <VoucherTableCard vouchers={vouchers} viewLink={link} filterEnabled={filterEnabled} />
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default Voucherslist;
