import { combineReducers } from "redux";

import * as types from "./ActionTypes";
import { CLOSE_EDIT_BOOKING } from "../bookingForm/ActionTypes";
import { CLEAR_COMPONENT } from "../ActionTypes";

const initialState = {
  bookingId: "",
  bookingIdImplicit: "",
  // ↑ temporary storage location if a customer list is desired to be displayed, despite runSheet is not open
  transactionId: "",
  customerId: "",
  modalFormCustomerId: "",
  customerHistory: [],
  customerHistoryMeals: {},
  tab: 1,
  qrCodeWindow: null,
};

function customerHistoryMeals(state = initialState.customerHistoryMeals, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_HISTORY_MEALS:
      return action.customerHistoryMeals;
    case CLEAR_COMPONENT:
      return {};
    default:
      return state;
  }
}

function customerHistory(state = initialState.customerHistory, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_HISTORY:
      return action.customerHistory;
    case CLEAR_COMPONENT:
      return [];
    default:
      return state;
  }
}

const modalFormCustomerId = (state = initialState.modalFormCustomerId, action) => {
  switch (action.type) {
    case types.SHOW_MODAL_CUSTOMER_FORM:
      return action.customerId;
    default:
      return state;
  }
};

function bookingId(state = initialState.bookingId, action) {
  switch (action.type) {
    case types.SHOW_RUN_SHEET:
      return action.bookingId;
    case CLEAR_COMPONENT:
    case CLOSE_EDIT_BOOKING:
    case types.CLOSE_RUN_SHEET:
      return "";
    default:
      return state;
  }
}

function bookingIdImplicit(state = initialState.bookingIdImplicit, action) {
  switch (action.type) {
    case types.SHOW_RUN_SHEET_IMPLICIT:
      return action.bookingId;
    case types.CLOSE_RUN_SHEET:
    case types.CLOSE_RUN_SHEET_CUSTOMER:
      return initialState.bookingIdImplicit;
    default:
      return state;
  }
}

function transactionId(state = initialState.bookingId, action) {
  switch (action.type) {
    case types.SHOW_RUN_SHEET_TRANSACTION:
      return action.transactionId;
    case CLEAR_COMPONENT:
    case CLOSE_EDIT_BOOKING:
    case types.CLOSE_RUN_SHEET:
    case types.CLOSE_RUN_SHEET_TRANSACTION:
      return "";
    default:
      return state;
  }
}

function customerId(state = initialState.bookingId, action) {
  switch (action.type) {
    case types.SHOW_RUN_SHEET_CUSTOMER:
      return action.customerId;
    case CLEAR_COMPONENT:
    case CLOSE_EDIT_BOOKING:
    case types.CLOSE_RUN_SHEET:
    case types.CLOSE_RUN_SHEET_CUSTOMER:
      return "";
    default:
      return state;
  }
}

function tab(state = initialState.tab, action) {
  switch (action.type) {
    case types.RUN_SHEET_TAB:
      return action.tab;
    case types.CLOSE_RUN_SHEET:
      return 0;
    default:
      return state;
  }
}

function qrCodeWindow(state = initialState.qrCodeWindow, action) {
  switch (action.type) {
    case types.SHOW_QRCODE_PAGE:
      return action.qrCodeWindow;
    default:
      return state;
  }
}

export default combineReducers({
  customerHistory,
  customerHistoryMeals,
  bookingId,
  bookingIdImplicit,
  transactionId,
  customerId,
  modalFormCustomerId,
  tab,
  qrCodeWindow,
});
