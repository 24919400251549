import { toast } from "react-toastify";

export function getConfig() {
  const dataUnserialized = window.localStorage.getItem("butler:data");
  return dataUnserialized ? JSON.parse(dataUnserialized) : null;
}

export function getHeadersFromConfig(extras) {
  const data = getConfig();

  return {
    headers: {
      "client-id": data.client,
      ...extras,
    },
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const catchExceptionCallback = (error) => {
  let error1 = error;
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      error1 = error.response.data.message;
    } else {
      error1 = error.response.data;
    }
    // if (error.stack && error.response.status === 500) {
    //     stacktrace = error.stack;
    // }
  } else if (error.message) {
    error1 = error.message;
    // if (error.stack) {
    //     stacktrace = error.stack;
    // }
  }
  toast["error"](error1, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 6000,
  });
  return error;
};

export const selectCustomStyles = {
  control: (base, state) => {
    const val = state.getValue();
    return {
      ...base,
      height: val.length > 1 ? null : 35,
      minHeight: 35,
      minWidth: 100,
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  valueContainer: (provided, state) => {
    const val = state.getValue();
    return {
      ...provided,
      height: val.length > 1 ? null : 35,
      minHeight: 35,
    };
  },
};

export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
