
import { combineReducers } from 'redux';
import { GET_CLIENT_BUILDER_WIDGET_LINK, } from './ActionTypes';

const initialState = {
    clientWidgetLink: '',
    clientCodeBlock: '',
    clientButtonLink: '',
    clientjQueryCode: '',
    clientJavaScriptCode: '',
    clientHtmlCode: ''
};

function clientButtonLink(state = initialState.clientButtonLink, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientButtonLink;
        default:
            return state;
    }
}

function clientCodeBlock(state = initialState.clientCodeBlock, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientCodeBlock;
        default:
            return state;
    }
}

function clientWidgetLink(state = initialState.clientWidgetLink, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientWidgetLink;
        default:
            return state;
    }
}

function clientjQueryCode(state = initialState.clientjQueryCode, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientjQueryCode;
        default:
            return state;
    }
}

function clientJavaScriptCode(state = initialState.clientJavaScriptCode, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientJavaScriptCode;
        default:
            return state;
    }
}

function clientHtmlCode(state = initialState.clientHtmlCode, action) {
    switch (action.type) {
        case GET_CLIENT_BUILDER_WIDGET_LINK:
            return action.clientHtmlCode;
        default:
            return state;
    }
}

export default combineReducers({
    clientWidgetLink,
    clientCodeBlock,
    clientButtonLink,
    clientjQueryCode,
    clientJavaScriptCode,
    clientHtmlCode
});
