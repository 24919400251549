import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { find, isEmpty, isUndefined, map, round } from "lodash";
import moment from "moment";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Select from "react-select";
import { Button, Card, Col, Row, Spinner, Table } from "reactstrap";

import { useAppSelector } from "components/Hooks/hooks";
import useAreas from "components/Hooks/useAreas";
import useMeals from "components/Hooks/useMeals";
import useTableClasses from "components/Hooks/useTableClasses";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import ManagementPeriodSelector from "components/explorer/ManagementPeriodSelector";
import ToggleWrapper from "components/explorer/ToggleWrapper";

import { catchExceptionCallback, getConfig } from "core/utils";
import { buildDataFromDocs } from "resbutler-utils/utils";

import tableToCSV from "explorer/tableToCSV";
import tableToPDF from "explorer/tableToPDF";

import { calendarTypes, displayAmount, displayUnit, getGrandTotalForProductRevenue, getGrandTotalPercentForProductRevenue, getProductGroupHierarchyFromProductGroupIds, getSubTotal, getSubTotalPercent, getTotal, getTotalPercent, managementCalendarModes } from "../utils";
import useFilterTreeData from "./useFilterData";
import { IReportByBookingTypePayload, bookingTypeFilters, normalizeResults } from "./utils";

const RevenueReport = () => {
  const [results, setResults] = useState([]);

  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const products = useAppSelector((state) => state.root.products);
  const productGroups = useAppSelector((state) => state.root.productGroups);

  const [, areas] = useAreas(restaurantId);
  const [, meals] = useMeals(restaurantId);
  const [, tableClasses] = useTableClasses(restaurantId);

  const filterTreeData = useFilterTreeData(meals, areas, tableClasses);
  const defaultFilterTreeData = [];

  filterTreeData.forEach((item) => {
    defaultFilterTreeData.push(item.value);

    if (item.children) {
      item.children.forEach((item1) => {
        defaultFilterTreeData.push(item1.value);
      });
    }
  });

  const [bookingTypeFilterValue, setBookingTypeFilterValue] = useState(bookingTypeFilters["all-booking"].value);
  const [filterTreeDataValue, setFilterTreeDataValue] = useState(defaultFilterTreeData);
  const dateStore = useRef(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const [calendar, setCalendar] = useState({});
  const { client, resbutlerApis } = getConfig();

  const initialValues = useMemo(
    () => ({
      restaurants: [],
      calendar: calendarTypes.dateRange.value,
      filterTree: defaultFilterTreeData,
    }),
    []
  );

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        const calendarSnap = await firebase.firestore().collection(`${client}/calendar/calendar`).get();
        setCalendar(buildDataFromDocs(calendarSnap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getCalendarData();
  }, []);

  const loadReports = async (values) => {
    try {
      const params = {
        calendar: values.calendar,
        restaurantId,
        clientId: client,
      } as IReportByBookingTypePayload;

      if (values.calendar === calendarTypes.management.value) {
        params.firstPeriod = values?.firstPeriod;
        params.secondPeriod = values?.secondPeriod;
        params.mode = values?.mode;

        dateStore.current = {
          firstPeriod: values?.firstPeriod,
          secondPeriod: values.secondPeriod,
        };
      } else {
        params.firstDateFrom = values.firstDateFrom;
        params.firstDateTo = values.firstDateTo;
        params.secondDateFrom = values.secondDateFrom;
        params.secondDateTo = values.secondDateTo;

        dateStore.current = {
          firstDateFrom: values.firstDateFrom,
          firstDateTo: values.firstDateTo,
          secondDateFrom: values.secondDateFrom,
          secondDateTo: values.secondDateTo,
        };
      }

      setResults([]);
      const API_URL = `${resbutlerApis}/bq/${bookingTypeFilterValue}`;
      const response = await axios.get(API_URL, { params });
      setResults(response.data);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToCSV = async (e) => {
    try {
      e.preventDefault();
      await tableToCSV(tableRef.current, ",", "revenue-report-by-booking-type");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToPDF = async (e) => {
    try {
      e.preventDefault();
      await tableToPDF(tableRef.current, "Revenue Report by Booking Type", "revenue-report-by-booking-type");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const validate = (values: IReportByBookingTypePayload) => {
    const errors = {} as IReportByBookingTypePayload;

    if (values) {
      if (values.calendar === calendarTypes.management.value) {
        if (!values.mode) {
          errors.mode = "This is required.";
        }

        if (!values.firstPeriod) {
          errors.firstPeriod = "Please input first period values.";
        } else {
          if (!values.firstPeriod.year) {
            errors.firstPeriod = "Please input first period values.";
          }

          if (values.mode === managementCalendarModes.week.value && !values.firstPeriod.week) {
            errors.firstPeriod = "Please input first period values.";
          }

          if (values.mode === managementCalendarModes.month.value && !values.firstPeriod.month) {
            errors.firstPeriod = "Please input first period values.";
          }
        }
      } else {
        if (!values.firstDateFrom || !values.firstDateTo) {
          errors.firstDateFrom = "Please input first period date range.";
        }
      }
    }

    return errors;
  };

  const filteredResults = normalizeResults(results, filterTreeData, filterTreeDataValue);
  const productGroupHierarchy = getProductGroupHierarchyFromProductGroupIds(Object.keys(filteredResults), productGroups);

  const renderProductTreeReports = (productGroupKeys, depth = 0) => {
    return map(productGroupKeys, (items, productGroupId) => {
      const productGroup = productGroups[productGroupId];

      const item = filteredResults?.[productGroupId];
      const linesByProductId = item?.lines;

      const subTotalVariancePercentage = getSubTotalPercent(linesByProductId, "varianceAmountPercent");
      const subTotalVarianceUnitPercentage = getSubTotalPercent(linesByProductId, "varianceUnitPercent");

      return (
        <ToggleWrapper key={productGroupId} defaultOpen={true}>
          {(open, toggle) => {
            return (
              <>
                <tr>
                  <td colSpan={2} className="divider-left">
                    <button className="toggle-btn flex align-items-center" onClick={toggle}>
                      {[...Array(depth)].map((p) => (
                        <Fragment key={`depth-${p}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                      ))}

                      <i className={`fa fa-chevron-${open ? "down" : "up"} mr-2`} aria-hidden="true"></i>
                      <b>{productGroup.name}</b>
                    </button>
                  </td>

                  <td className="divider-right--light divider-left text-right" />
                  <td className="divider-right divider-left text-right" />

                  <td className="divider-right--light divider-left text-right" />
                  <td className="divider-right divider-left text-right" />

                  <td className="divider-right--light divider-left text-right" />
                  <td className="divider-right divider-left text-right" />

                  <td className="divider-right--light divider-left text-right" />
                  <td className="divider-right divider-left text-right" />
                </tr>

                {open ? (
                  <>
                    {isUndefined(item) === false ? (
                      <>
                        {map(linesByProductId, (lines, productId) => {
                          const product = products?.[productId];

                          const totalVariancePercentage = getTotalPercent(lines, "varianceAmountPercent");
                          const totalVarianceUnitPercentage = getTotalPercent(lines, "varianceUnitPercent");

                          return (
                            <tr key={`${productId}-${productGroupId}`} className="small">
                              <td className="pl-6 divider-left"></td>
                              <td className="pl-0 pb-0">{product?.name || lines[0]?.productName}</td>

                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "firstPeriodUnit"))}</td>
                              <td className="divider-right divider-left text-right">{displayAmount(getTotal(lines, "firstPeriodAmount"))}</td>

                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "secondPeriodUnit"))}</td>
                              <td className="divider-right divider-left text-right">{displayAmount(getTotal(lines, "secondPeriodAmount"))}</td>

                              <td className="divider-right--light divider-left text-right">{displayUnit(getTotal(lines, "varianceUnit"))}</td>
                              <td className="divider-right divider-left text-right">{totalVarianceUnitPercentage ? round(totalVarianceUnitPercentage, 2) + "%" : null}</td>

                              <td className="divider-right--light divider-left text-right">{displayAmount(getTotal(lines, "varianceAmount"))}</td>
                              <td className="divider-right divider-left text-right">{totalVariancePercentage ? round(totalVariancePercentage, 2) + "%" : null}</td>
                            </tr>
                          );
                        })}

                        <tr className="small">
                          <td className="pl-6 divider-left"></td>
                          <td className="pl-0 total-cells divider-top">
                            <i>Sub-total</i>
                          </td>

                          <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesByProductId, "firstPeriodUnit"))}</td>
                          <td className="total-cells divider-top text-right">{displayAmount(getSubTotal(linesByProductId, "firstPeriodAmount"))}</td>

                          <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesByProductId, "secondPeriodUnit"))}</td>
                          <td className="total-cells divider-top divider-right divider-left text-right">{displayAmount(getSubTotal(linesByProductId, "secondPeriodAmount"))}</td>

                          <td className="total-cells divider-top divider-right--light divider-left text-right">{displayUnit(getSubTotal(linesByProductId, "varianceUnit"))}</td>
                          <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVarianceUnitPercentage ? round(subTotalVarianceUnitPercentage, 2) + "%" : null}</td>

                          <td className="total-cells divider-top divider-right--light divider-left text-right">{displayAmount(getSubTotal(linesByProductId, "varianceAmount"))}</td>
                          <td className="total-cells divider-top divider-right divider-left text-right">{subTotalVariancePercentage ? round(subTotalVariancePercentage, 2) + "%" : null}</td>
                        </tr>
                      </>
                    ) : null}

                    {Object.keys(items).length ? renderProductTreeReports(items, depth + 1) : null}
                  </>
                ) : null}
              </>
            );
          }}
        </ToggleWrapper>
      );
    });
  };

  const grandTotalVariancePercentage = getGrandTotalPercentForProductRevenue(productGroupHierarchy, filteredResults, "varianceAmountPercent");
  const grandTotalVarianceUnitPercentage = getGrandTotalPercentForProductRevenue(productGroupHierarchy, filteredResults, "varianceUnitPercent");

  return (
    <ContentWrapper>
      <ContentHeading headerText="Revenue Report by Booking Type" showRestaurants />

      <Card className="card-default py-3 px-4">
        <Row>
          <Col sm={12} lg={4} className="py-2">
            <Row className="mb-4">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">
                  <b>Booking Type</b>
                </label>
              </Col>
              <Col sm={12} lg={8}>
                <Select
                  closeMenuOnSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  blurInputOnSelect={false}
                  options={map(bookingTypeFilters, (item) => item)}
                  value={find(bookingTypeFilters, (item) => item.value === bookingTypeFilterValue)}
                  onChange={(e) => {
                    setResults([]);
                    setBookingTypeFilterValue(e.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">Filter</label>
              </Col>

              <Col sm={12} lg={8}>
                <TreeSelect
                  className="form-control tree-select"
                  style={{ width: "100%" }}
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ height: 250, overflow: "auto" }}
                  dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                  onDropdownVisibleChange={() => true}
                  treeLine
                  placeholder={<i>None</i>}
                  multiple
                  showIcon={false}
                  maxTagTextLength={25}
                  autoClearSearchValue
                  treeNodeFilterProp="title"
                  treeData={filterTreeData}
                  treeCheckable
                  treeDefaultExpandAll
                  value={filterTreeDataValue}
                  showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={20}
                  onChange={(ids) => setFilterTreeDataValue(ids)}
                  maxTagPlaceholder={(valueList) => {
                    return `+${valueList.length}`;
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Form
            initialValues={initialValues}
            onSubmit={loadReports}
            validate={validate}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, values, form }) => {
              return (
                <>
                  <Col sm={12} lg={8} className="divider-cols">
                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center h-100 gap-sm">
                      <div className="mb-3 flex align-items-center gap-md">
                        <label className="m-0">
                          <b>Calendar</b>
                        </label>

                        <div className="d-flex gap-xl flex-wrap">
                          <Field name="calendar">
                            {({ input: { value, onChange } }) => (
                              <div className="form-group gap-md d-flex m-0">
                                {Object.values(calendarTypes).map((option, index) => (
                                  <label key={`render-units-${index}`} className="radio c-radio m-0 d-flex align-items-center">
                                    <input type="radio" value={option.value} checked={option.value === value} onChange={() => onChange(option.value)} />
                                    <span className="fa fa-circle" />
                                    {option.label}
                                  </label>
                                ))}
                              </div>
                            )}
                          </Field>

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="d-flex align-items-center gap-md">
                              <label className="mb-0">Mode</label>

                              <div style={{ width: 160 }}>
                                <Field name="mode">
                                  {({ input: { value, onChange }, meta }) => (
                                    <>
                                      <Select
                                        isSearchable={false}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        blurInputOnSelect={false}
                                        options={map(managementCalendarModes, (item) => item)}
                                        value={managementCalendarModes?.[value]}
                                        onChange={(e) => {
                                          onChange(e.value);

                                          form.change("firstPeriod", undefined);
                                          form.change("secondPeriod", undefined);
                                        }}
                                        placeholder="Select mode"
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex align-items-end gap-lg justify-content-between flex-wrap">
                        <div className="d-flex flex-grow-1 align-items-end gap-md">
                          {values.calendar == calendarTypes.dateRange.value ? (
                            <div className="d-flex align-items-start gap-md flex-grow-1">
                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="startDate" className="mb-0 text-nowrap">
                                  First Period
                                </label>

                                <Field name="firstDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="startDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.firstDateFrom, "YYYY-MM-DD").toDate(), moment(values.firstDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("firstDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("firstDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="endDate" className="mb-0 text-nowrap">
                                  Second Period
                                </label>

                                <Field name="secondDateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="endDate"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.secondDateFrom, "YYYY-MM-DD").toDate(), moment(values.secondDateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("secondDateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("secondDateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="flex-grow-1">
                              <div className="form-group mb-2 flex-grow-1">
                                <Field name="firstPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="startDate" className="mb-0">
                                          First Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group m-0 flex-grow-1">
                                <Field name="secondPeriod">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="endDate" className="mb-0">
                                          Second Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          <Button id="category-save" type="submit" className="d-flex justify-content-center align-items-center gap-sm" color="primary" disabled={submitting}>
                            {submitting ? <Spinner size="sm" /> : null} Generate Report
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Col>
                </>
              );
            }}
          />
        </Row>
      </Card>

      {isEmpty(filteredResults) ? null : (
        <>
          <div className="flex align-items-end gap-md justify-content-between mb-2">
            <h4 className="mb-0">{find(bookingTypeFilters, (i) => i.value === bookingTypeFilterValue).title}</h4>

            <div className="d-flex align-items-center gap-sm justify-content-end">
              <Button onClick={handleExportToCSV} type="button" color="primary">
                Download CSV
              </Button>
              <Button onClick={handleExportToPDF} type="button" color="primary">
                Download PDF
              </Button>
            </div>
          </div>

          <Card className="card-default py-2 px-4">
            <Table borderless className="explorer-table" innerRef={tableRef}>
              <thead>
                <tr>
                  <td colSpan={2} width="260px" className="divider-top divider-left text-center"></td>

                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    {dateStore.current?.firstDateTo ? (
                      <>
                        {moment(dateStore.current.firstDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.firstDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.firstPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.firstPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.firstPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.firstPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.firstPeriod?.year ? `${calendar[dateStore.current.firstPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    {dateStore.current?.secondDateTo ? (
                      <>
                        {moment(dateStore.current.secondDateFrom).format("DD/MM/YYYY")} ~ {moment(dateStore.current.secondDateTo).format("DD/MM/YYYY")}
                      </>
                    ) : (
                      <>
                        {dateStore.current?.secondPeriod?.month ? (
                          <>
                            {`Month: ${dateStore.current.secondPeriod.month}`}
                            <br />
                          </>
                        ) : null}
                        {dateStore.current?.secondPeriod?.week ? (
                          <>
                            {`Week: ${dateStore.current.secondPeriod.week}`}
                            <br />
                          </>
                        ) : null}
                        &nbsp;
                        {dateStore.current?.secondPeriod?.year ? `${calendar[dateStore.current.secondPeriod.year]?.name}` : null}
                      </>
                    )}
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    Quantity
                  </td>
                  <td colSpan={2} className="divider-full text-center text-decoration-underline">
                    Amount
                  </td>
                </tr>
                <tr className="small">
                  <td colSpan={2} className="divider-left"></td>

                  <td className="divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Quantity</td>
                  <td className="divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Amount ($)</td>

                  <td className="divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Quantity</td>
                  <td className="divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Amount ($)</td>

                  <td className="divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Variance (Qty)</td>
                  <td className="divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Variance (%)</td>

                  <td className="divider-top divider-left divider-right--light text-center font-weight-bold text-decoration-underline">Variance ($)</td>
                  <td className="divider-top divider-left divider-right text-center font-weight-bold text-decoration-underline">Variance (%)</td>
                </tr>
              </thead>

              <tbody>
                {renderProductTreeReports(productGroupHierarchy)}

                <tr>
                  <td colSpan={2} className="total-cells divider-full">
                    <i>Total Sales</i>
                  </td>

                  <td className="total-cells divider-full divider-right--light text-right">{displayUnit(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodUnit"))}</td>
                  <td className="total-cells divider-full text-right">{displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "firstPeriodAmount"))}</td>

                  <td className="total-cells divider-full divider-right--light text-right">{displayUnit(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodUnit"))}</td>
                  <td className="total-cells divider-full text-right">{displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "secondPeriodAmount"))}</td>

                  <td className="total-cells divider-full divider-right--light text-right">{displayUnit(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "varianceUnit"))}</td>
                  <td className="total-cells divider-full text-right">{grandTotalVarianceUnitPercentage ? round(grandTotalVarianceUnitPercentage, 2) + "%" : null}</td>

                  <td className="total-cells divider-full divider-right--light text-right">{displayAmount(getGrandTotalForProductRevenue(productGroupHierarchy, filteredResults, "varianceAmount"))}</td>
                  <td className="total-cells divider-full text-right">{grandTotalVariancePercentage ? round(grandTotalVariancePercentage, 2) + "%" : null}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueReport;
