import SketchControl from "components/Editor/SketchControl";
import _ from "lodash";
import React from "react";
import Datetime from "react-datetime";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const renderField = ({ input: { type, ...rest }, label, placeholder, disabled, className, after }) => (
  <React.Fragment>
    <div className={className}>
      {label ? <label>{label}</label> : null}
      <input {...rest} placeholder={placeholder} type={type} className="form-control" disabled={disabled} />
      {after}
    </div>
  </React.Fragment>
);

export const requiredField = ({ input: { type, ...rest }, label, placeholder, disabled, className, after, helpText, hidden = false, meta: { touched, error, warning } }) => (
  <React.Fragment>
    <div className={className}>
      {label ? (
        <label>
          {label} <span className="text-danger">*</span>
        </label>
      ) : null}
      <input {...rest} placeholder={placeholder} type={type} className="form-control" disabled={disabled} style={{ display: hidden ? "none" : "block" }} />
      {after}
      {helpText ? <div className="help-text">{helpText}</div> : null}
      {touched && ((error && <span className="validate-form">{error}</span>) || (warning && <span className="validate-warning">{warning}</span>))}
    </div>
  </React.Fragment>
);

export const renderSketchControl = ({ input }) => <SketchControl color={input.value} onChange={(e) => input.onChange(e.rgb)} />;

export const requiredDropdown = ({ input: { onChange, value }, id, types, disabled, className, label, helpText, optionalAsterisk = true, meta: { touched, error, warning } }) => (
  <div className={className}>
    {label ? (
      <label>
        {label} {optionalAsterisk ? <span className="text-danger">*</span> : ""}
      </label>
    ) : null}
    <select className="form-control" disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">Please Select</option>
      {_.keys(types).map((typeId) => (
        <option key={typeId} value={typeId}>
          {types[typeId].name}
        </option>
      ))}
    </select>
    {helpText ? <div className="help-text">{helpText}</div> : null}
    {touched &&
      ((error && (
        <span key={`${id}2`} className="validate-form">
          {error}
        </span>
      )) ||
        (warning && <span key={`${id}2`}>{warning}</span>))}
  </div>
);

export const renderDate = ({ input, id, dateFormat, onChangeDate, meta: { touched, error, warning }, disabled = false }) => (
  <React.Fragment>
    <Datetime
      id={id}
      {...input}
      dateFormat={dateFormat}
      timeFormat={false}
      //defaultValue={moment().format('h:mmA')}
      // value={getTime(input.value)}
      onChange={onChangeDate}
      onBlur={() => {}}
      inputProps={{ disabled }}
    />
    {touched && ((error && <span className="validate-form">{error}</span>) || (warning && <span>{warning}</span>))}
  </React.Fragment>
);

export const requiredTelInput2 = ({ input: { value, onChange, onBlur }, meta: { error, touched } }) => (
  <>
    <PhoneInput inputClass="form-control" country={"au"} value={String(value)} onlyCountries={["au", "nz", "us", "ca", "uk", "cn", "jp", "kr", "sg", "ph"]} onPhoneNumberBlur={onBlur} onChange={onChange} masks={{ au: "... ... ..." }} separateDialCode />
    {touched && error && <div className="validate-form">{error}</div>}
  </>
);

export const renderDropdown = ({ input, onSelect, types, disabled, className, label, helpText }) => (
  <div className={className}>
    {label ? <label>{label}</label> : null}
    <select className="form-control" {...input} disabled={disabled} onClick={(e) => onSelect(e.target.value)}>
      <option value="">Please Select</option>
      {Object.keys(types).map((typeId) => (
        <option key={typeId} value={typeId}>
          {types[typeId].name}
        </option>
      ))}
    </select>
    {helpText ? <div className="help-text">{helpText}</div> : null}
  </div>
);

export const getListItem = (dispatch, items, index, updatePosition) => {
  const result = [];
  const getClickFunc = (item1, item2, index1, index2) => {
    if (updatePosition) {
      if (dispatch) dispatch(updatePosition(item1, item2, index1, index2));
      updatePosition(item1, item2, index1, index2);
    }
  };
  if (items.length > 1) {
    if (index === 0) {
      result.push(<em key="group-area-down" className={`fa fa-arrow-down fa-fw ${updatePosition ? "" : "invisible"}`} onClick={() => getClickFunc(items[index], items[index + 1], index, index + 1)} />);
      result.push(<em key="group-area-up" className="fa fa-arrow-up fa-fw invisible" />);
    } else if (index === items.length - 1) {
      result.push(<em key="group-area-down" className="fa fa-arrow-down fa-fw invisible" />);
      result.push(<em key="group-area-up" className={`fa fa-arrow-up fa-fw ${updatePosition ? "" : "invisible"}`} onClick={() => getClickFunc(items[index], items[index - 1], index, index - 1)} />);
    } else {
      result.push(<em key="group-area-down" className={`fa fa-arrow-down fa-fw ${updatePosition ? "" : "invisible"}`} onClick={() => getClickFunc(items[index], items[index + 1], index, index + 1)} />);
      result.push(<em key="group-area-up" className={`fa fa-arrow-up fa-fw ${updatePosition ? "" : "invisible"}`} onClick={() => getClickFunc(items[index], items[index - 1], index, index - 1)} />);
    }
  }
  return result;
};
