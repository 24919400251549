import { combineReducers } from "redux";

import { GET_TABLE_INFO, GET_PIE_CHART_INFO, GET_PIE_CHART_LOADING, GET_TABLE_LOADING } from "./actionTypes";

const initialState = {
  tableInfo: [],
  pieChartInfo: [],
  tableLoading: false,
  pieChartLoading: false,
};

function tableInfo(state = initialState.tableInfo, action) {
  switch (action.type) {
    case GET_TABLE_INFO:
      return action.data;
    default:
      return state;
  }
}

function tableLoading(state = initialState.tableLoading, action) {
  switch (action.type) {
    case GET_TABLE_LOADING:
      return action.data;
    default:
      return state;
  }
}

function pieChartLoading(state = initialState.pieChartLoading, action) {
  switch (action.type) {
    case GET_PIE_CHART_LOADING:
      return action.data;
    default:
      return state;
  }
}

function pieChartInfo(state = initialState.pieChartInfo, action) {
    switch (action.type) {
      case GET_PIE_CHART_INFO:
        return action.data;
      default:
        return state;
    }
  }

export default combineReducers({
  pieChartInfo,
  tableInfo,
  tableLoading,
  pieChartLoading
});
