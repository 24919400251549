import React, { useEffect } from "react";
import ContentWrapper from "components/Layout/ContentWrapper";
import ContentHeading from "components/Layout/ContentHeading";
import { useDispatch, useSelector } from "react-redux";
import { getMembershipOfferId, getMembershipOffers } from "../MembershipOfferAction";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { GET_FILTER_DISABLED } from "ActionTypes";
import { getFilterName, filterList, DropdownListFilter } from "components/CustomFilter";
import moment from "moment";

const renderOfferList = (membershipOffers, filterDisabled) => {
  const offerList = filterList(membershipOffers, filterDisabled);
  const results = [];
  for (let i = 0; i < offerList.length; i++) {
    const membershipOffer = offerList[i];
    results.push(
      <tr key={membershipOffer._key}>
        <td>{membershipOffer.name}</td>
        <td>{membershipOffer.expiry && moment.unix(membershipOffer.expiry.seconds).format("DD/MM/YYYY")}</td>
        <td>{membershipOffer.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
        <td>
          <Link to={`/loyalty/members-offers/member-promotions/${membershipOffer._key}`}>Edit</Link>
        </td>
      </tr>
    );
  }
  return results;
};

const MemberPromotion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { membershipOffers, filterDisabled } = useSelector((state: any) => ({
    membershipOffers: state.membershipOffers.byId,
    filterDisabled: state.root.filterDisabled,
  }));

  useEffect(() => {
    dispatch(getMembershipOffers());
  }, []);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" headerText="Member Offers" subHeaderText="Member Promotions" showRestaurants={false} />
      <Row>
        <Col lg={8} sm={12}>
          <div className="justify-content-between row">
            <div className="col-12 col-sm-4 pb-2">
              <Button color="primary" onClick={() => navigate(`/loyalty/members-offers/member-promotions/${getMembershipOfferId()}`)}>
                New Membership Offer
              </Button>
            </div>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group" getName={getFilterName} onSelect={(e) => dispatch({ type: GET_FILTER_DISABLED, value: e })} />
            </div>
          </div>
          <Card className="card-default">
            <CardBody>
              <Table bordered striped>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Expiry</td>
                    <td>Enabled</td>
                    <td />
                  </tr>
                </thead>
                <tbody>{renderOfferList(membershipOffers, filterDisabled)}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default MemberPromotion;
