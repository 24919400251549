/** MENUS **/
export const GET_MENU_HEADINGS = "GET_MENU_HEADINGS";
export const GET_ALL_MENU_HEADINGS = "GET_ALL_MENU_HEADINGS";
export const GET_MENU_HEADINGS_BY_ID = "GET_MENU_HEADINGS_BY_ID";
export const GET_MENU_HEADING_SUBCATEGORY_NAME = "GET_MENU_HEADING_SUBCATEGORY_NAME";





export const MENU_FILE = "MENU_FILE";
export const MENU_FILE_SAVE_COMPLETE = "MENU_FILE_SAVE_COMPLETE";
export const MENU_SAVING_COMPLETE = "MENU_SAVING_COMPLETE";

export const SAVE_MENU_ITEM = "SAVE_MENU_ITEM";
export const GET_MENU_ITEMS = "GET_MENU_ITEMS";

export const GET_MENU_COURSES = "GET_MENU_COURSES";
export const GET_MENU_COURSE = "GET_MENU_COURSE";

export const ADD_COURSE_DURATION = "ADD_COURSE_DURATION";
export const UPDATE_COURSE_DURATION = "UPDATE_COURSE_DURATION";
export const GET_MENU_PAYMENTS = "GET_MENU_PAYMENTS";

export const GET_MENUS_ADD_NEW_TIME = "GET_MENUS_ADD_NEW_TIME";
export const GET_MENUS_CLEAR_NEW_TIME = "GET_MENUS_CLEAR_NEW_TIME";
export const MENU_EDITOR_STATE = "MENU_EDITOR_STATE";
export const GET_MENU_TERMS = "GET_MENU_TERMS";
export const ADD_MENU_TERMS = "ADD_MENU_TERMS";

/** END MENUS */
