import { IRGB, rgbToHex } from "_utils/colorConverter";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getRestaurants } from "system/RestaurantReducer";

interface IProps {
  open: boolean;
  toggle: () => void;
  settings: ISettings;
}

interface ISettings {
  emailBackgroundColor: IRGB;
  logo: string;
  gallery: string;
  restaurantId: string;
}

const GiftCertificateRestaurantPreview = ({ open, toggle, settings }: IProps) => {
  const { restaurants } = useSelector((state: any) => ({
    restaurants: getRestaurants(state.root.restaurants, state.root.user),
  }));

  const restaurant = restaurants?.[settings.restaurantId];

  return (
    <Modal scrollable isOpen={open} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Preview Gift Certificate</ModalHeader>

      <ModalBody>
        <div className="gift-certificate-preview-container" style={{ backgroundColor: settings?.emailBackgroundColor ? rgbToHex(settings.emailBackgroundColor) : "#000" }}>
          <div className="gift-certificate-preview-paper">
            <div className="gift-certificate-preview-inner-container">
              {settings?.logo ? <img src={settings.logo} alt="restaurant-logo" className="gift-certificate-preview-logo" /> : null}
              <h1 className="gift-certificate-preview-title">Gift Certificate</h1>
              {settings?.gallery ? <img src={settings.gallery} alt="restaurant-thumbnail" className="gift-certificate-preview-gallery" /> : null}
              <h2 className="gift-certificate-preview-heading">Heading</h2>
              <i className="gift-certificate-preview-body-text">Body Text</i>
              <div className="gift-certificate-preview-price">$XXX.XX</div>
              <p>Gift Certificate Number: XXXXXXXXX</p>
              <p>Expiry: DD/MM/YYYY</p>
            </div>

            {restaurant ? (
              <div className="gift-certificate-preview-restaurant-detail-container">
                <p>
                  <b>Contact the Venue</b>
                  <p>{restaurant.name}</p>
                  <p>{restaurant.address}</p>
                  <p>
                    {restaurant.reservationEmail}, {restaurant.reservationPhoneNumber}
                  </p>
                </p>
              </div>
            ) : null}

            <br />

            <p className="gift-certificate-preview-toc">Terms and Conditions</p>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default GiftCertificateRestaurantPreview;
