import { GET_ADDITION_GROUPS, GET_MODIFIER_GROUPS, GET_PREPARATION_GROUPS, GET_UPSELL_GROUPS } from "ActionTypes";
import { catchExceptionCallback, getConfig } from "core/utilities";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { buildDataFromDocs } from "resbutler-utils/utils";

const useProductAmmendments = (): [boolean] => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        const { client } = getConfig();
        const modifierGroupsQuery = query(collection(getFirestore(), `${client}/products/modifierGroups`), where("enabled", "==", true));
        const additionGroupsQuery = query(collection(getFirestore(), `${client}/products/additionGroups`), where("enabled", "==", true));
        const preparationGroupsQuery = query(collection(getFirestore(), `${client}/products/preparationGroups`), where("enabled", "==", true));
        const upsellGroupsQuery = query(collection(getFirestore(), `${client}/products/upsellGroups`), where("enabled", "==", true));
        const [snap1, snap2, snap3, snap4] = await Promise.all([getDocs(modifierGroupsQuery), getDocs(additionGroupsQuery), getDocs(preparationGroupsQuery), getDocs(upsellGroupsQuery)]);
        dispatch({ type: GET_MODIFIER_GROUPS, payload: buildDataFromDocs(snap1.docs) });
        dispatch({ type: GET_ADDITION_GROUPS, payload: buildDataFromDocs(snap2.docs) });
        dispatch({ type: GET_PREPARATION_GROUPS, payload: buildDataFromDocs(snap3.docs) });
        dispatch({ type: GET_UPSELL_GROUPS, payload: buildDataFromDocs(snap4.docs) });
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, []);

  return [loading];
};
export default useProductAmmendments;
