import { toast } from "react-toastify";

export function getConfig() {
  const dataUnserialized = window.localStorage.getItem("butler:data");
  return dataUnserialized ? JSON.parse(dataUnserialized) : null;
}

export const catchExceptionCallback = (error) => {
  let error1 = error,
    className = "error";
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      error1 = error.response.data.message;
    } else {
      error1 = error.response.data;
    }
    // if (error.stack && error.response.status === 500) {
    //     stacktrace = error.stack;
    // }
  } else if (error.message) {
    error1 = error.message;
    // if (error.stack) {
    //     stacktrace = error.stack;
    // }
  }
  toast[className](error1, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 6000,
  });
  return error;
};

export function getTicks(date) {
  if (date && date?.hours && date?.minutes) {
    return 1000 * (3600 * date.hours() + 60 * date.minutes());
  }
  return null;
}

export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function capitalizeSpaceWords(string) {
  return string.replace(/([A-Z])/g, " $1").trim();
}

export default {
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  },

  //Function to convert hex format to a rgb color
  rgb2hex(r, g, b) {
    return "#" + ("0" + parseInt(r, 10).toString(16)).slice(-2) + ("0" + parseInt(g, 10).toString(16)).slice(-2) + ("0" + parseInt(b, 10).toString(16)).slice(-2);
  },
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
};

export const selectCustomStyles = {
  control: (base, state) => {
    const val = state.getValue();
    return {
      ...base,
      height: val.length > 1 ? null : 35,
      minHeight: 35,
      minWidth: 100,
    };
  },
  valueContainer: (provided, state) => {
    const val = state.getValue();
    return {
      ...provided,
      height: val.length > 1 ? null : 35,
      minHeight: 35,
    };
  },
};
