import "firebase/compat/firestore";
import { find, map } from "lodash";
import moment from "moment-timezone";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import { PermissionType } from "../../../resbutler-utils/types/Operator";
import { VoucherTypes } from "../../../resbutler-utils/types/Voucher";
import { priceFormatter } from "../../utils/transactionDetail";
import { useOperatorLockScreen } from "../OperatorLockScreen";
import RedeemVoucherModal from "./RedeemVoucherModal";
import TransactionModal from "./TransactionModal";

export const voucherTypes = [
  { label: "Deferred Booking Voucher", value: VoucherTypes.DeferredBookingVoucher },
  { label: "Donation", value: VoucherTypes.Donation },
  { label: "Promotion", value: VoucherTypes.Promotion },
];

const VoucherIssuedFormDetails = ({ input, setInput, productSizes, products, readOnly, clientId, functions, restaurants, reasonTypes }) => {
  const [openTransactionModalById, setOpenTransactionModalById] = useState("");
  const [openManualRedeemModal, setOpenManualRedeemModal] = useState(false);

  const {
    Component: OperatorLockScreen,
    handleOpenLock,
    handleCloseLock,
  } = useOperatorLockScreen({
    config: {
      client: clientId,
      functions,
    },
  });

  const handleOpenManualRedeemModal = () => {
    return handleOpenLock({
      serverSidePermissionCheck: false,
      permission: PermissionType.manualTransaction,
      callback: async () => {
        setOpenManualRedeemModal(true);
        handleCloseLock();
      },
    });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="form-group">
            <label className="fw-bold mb-0">Voucher Number</label>
            <div className="fw-bold">{input.voucherNumber}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group">
            <label className="fw-bold mb-0">Voucher Description</label>
            <div className="fw-bold">{input.voucherSetup.name}</div>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fw-bold mb-0">Customer</label>
            <Link className="d-block" to={`/crm/customers/${input.customerId}`}>{`${input.customer?.lastName}, ${input.customer?.firstName}`}</Link>
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="form-group">
            <h4 className="fw-bold mb-2">Summary</h4>

            <div className="overflow-auto">
              <Table bordered striped>
                <thead>
                  <th>Voucher Type</th>
                  <th>Reason Type</th>
                  <th>Discount Type</th>
                  <th>Discount Amount</th>
                  <th>Products</th>
                  <th>Issued Date</th>
                  <th>Expiry Date</th>
                  <th>Redeem Quantity</th>
                </thead>

                <tbody>
                  <tr>
                    <td>{find(voucherTypes, (i) => i.value === input.voucherType)?.label}</td>
                    <td>{reasonTypes?.[input.voucherSetup.reasonType]?.name}</td>
                    <td className="text-center">{input.voucherSetup.discountType}</td>
                    <td>{input.voucherSetup.discountType === "$" ? priceFormatter.format(input.voucherSetup?.discountAmount || 0) : input.voucherSetup?.discountAmount}</td>
                    <td>
                      {input.allProducts ? (
                        "Any"
                      ) : (
                        <>
                          {map(input.products, ({ productId, sizes }, index: number) => {
                            const product = products?.[productId];
                            const isLastIndex = index === Object.keys(input.products).length - 1;

                            return (
                              <Fragment key={productId}>
                                {product?.name || ""} (
                                {map(sizes, (val, key) => {
                                  if (val === false) {
                                    return null;
                                  }

                                  const size = productSizes?.[key];

                                  return size.name;
                                })
                                  .filter(Boolean)
                                  .join(", ")}
                                ){isLastIndex ? "" : ", "}
                              </Fragment>
                            );
                          })}
                        </>
                      )}
                    </td>
                    <td>{moment(input.issuedDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                    <td>{moment(input.expiryDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                    <td>{input.redeemedInfo ? input.redeemQuantityLeft + input.redeemedInfo.length : input.redeemQuantityLeft}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="form-group">
            {input.redeemedInfo?.length > 0 ? (
              <>
                <h4 className="fw-bold mb-2">Redeems</h4>

                <Table bordered striped>
                  <thead>
                    <tr>
                      <th>Redeemed Date</th>
                      <th>Transaction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(input.redeemedInfo || []).map((info, i) => {
                      return (
                        <tr key={i}>
                          <td>{moment(info.redeemedDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                          <td>
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTransactionModalById(info.transactionId);
                              }}
                            >
                              {info.transactionId}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : null}

            <Button color="primary" disabled={input?.redeemQuantityLeft === 0} className="d-none align gap-sm align-items-center" onClick={handleOpenManualRedeemModal}>
              <em className="fa fa-lock"></em>Manual Redeem
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="form-group">
            <label className="fw-bold mb-0">Staff Note</label>
            <textarea
              rows={5}
              className="form-control"
              disabled={readOnly}
              value={input?.staffNote}
              onChange={(e) =>
                setInput({
                  ...input,
                  staffNote: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="checkbox c-checkbox">
            <label className="mb-1">
              <input type="checkbox" disabled={readOnly} checked={input.isEnabled} onChange={() => setInput({ ...input, isEnabled: !input.isEnabled })} />
              <span className="fa fa-check" />
              Enabled
            </label>
          </div>
        </Col>
      </Row>

      {openTransactionModalById ? <TransactionModal clientId={clientId} id={openTransactionModalById} restaurants={restaurants} onClose={() => setOpenTransactionModalById("")} /> : null}

      <OperatorLockScreen />

      <RedeemVoucherModal isOpen={openManualRedeemModal} restaurants={restaurants} setModal={setOpenManualRedeemModal} voucher={input} />
    </>
  );
};

export default VoucherIssuedFormDetails;
