import { getEnabledProductGroups } from "RootReducer";
import classnames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import useMenuHeadings from "components/Hooks/useMenuHeadings";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useParams } from "react-router";
import { Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import { AdditionalItemsCategories, BeverageMenuPackage } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import MenuItem, { createDynamicGroupHeadings, createStaticGroupHeadings } from "../../menus-and-pricing/menu-heading/MenuItem";
import BeverageMenusForm from "./BeverageMenusForm";

const BeverageMenus = () => {
  const { id: packageId } = useParams();
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const { client } = getConfig();

  const { restaurantId } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
    };
  });

  const [pkg, setPackage] = useState<BeverageMenuPackage>(null);
  const [initialValues, setInitialValues] = useState<BeverageMenuPackage>(null);

  const pkgClone = JSON.parse(JSON.stringify(pkg));

  const [loadingMenuHeadings, menuHeadings] = useMenuHeadings(pkg?.restaurantId);
  const [removedProducts, setRemovedProducts] = useState({});
  const { products, productGroups, productSizes } = useAppSelector((state) => {
    return {
      products: state.root.products,
      productGroups: getEnabledProductGroups(state.root.productGroups),
      productSizes: state.root.productSizes,
    };
  });

  const [additionalItemsCategories, setAdditionalItemsCategories] = useState<AdditionalItemsCategories>({});

  useEffect(() => {
    async function fetch() {
      try {
        const snap = await firebase.firestore().collection(`${client}/menus/additionalItemsCategories`).get();
        setAdditionalItemsCategories(pickBy(buildDataFromDocs(snap.docs) as AdditionalItemsCategories, (c) => c.enabled));
      } catch (error) {
        catchExceptionCallback(error);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snap = await firebase.firestore().doc(`${client}/menus/beverageMenus/${packageId}`).get();
        const pkg = {
          beverageMenus: {
            groupHeadings: {},
          },
          restaurantId,
          ...snap.data(),
        } as BeverageMenuPackage;
        setPackage(pkg);
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    if (packageId) fetchData();
  }, [packageId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        for (const groupHeadingId in pkg.beverageMenus.groupHeadings) {
          const gh = pkg.beverageMenus.groupHeadings[groupHeadingId];
          if (menuHeadings[gh.menuHeadingId].type === "static") {
            createStaticGroupHeadings(menuHeadings[gh.menuHeadingId], gh, gh.groupHeadingType, gh.menuHeadingId);
          } else {
            setRemovedProducts(createDynamicGroupHeadings(menuHeadings[gh.menuHeadingId], gh, groupHeadingId, productGroups, products, productSizes, gh.groupHeadingType, gh.menuHeadingId));
          }
        }
        setInitialValues(pkg);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (!loadingMenuHeadings) fetchData();
  }, [loadingMenuHeadings]);

  const validate = (values) => {
    const errors = {} as any;
    if (!values.minPax) {
      errors.minPax = "Min pax should not be empty";
    }
    if (!values.maxPax) {
      errors.maxPax = "Max pax should not be empty";
    }
    if (values.minPax > values.maxPax) {
      errors.minPax = "Min pax cannot exceed max pax value";
    }
    if (!values.name) {
      errors.name = "Package Name should not be empty";
    }
    if (!values.beverageOnConsumption && !values.barTab) {
      errors.barTab = "Bar tab or beverage on consumption must be selected";
    }
    return errors;
  };

  const onSave = async (values: BeverageMenuPackage) => {
    try {
      const { client } = getConfig();
      await firebase.firestore().doc(`${client}/menus/beverageMenus/${packageId}`).set(values);
      setPackage(values);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <ContentWrapper>
      <ContentHeading parentText={`Large Group and Function Menus`} headerText={"Beverage Menus"} subHeaderHref={`/functions-menus/beverage-menus`} subHeaderText={"Beverage Menus"} showRestaurants={false} />
      {!loading ? (
        <Form<BeverageMenuPackage>
          initialValues={initialValues}
          onSubmit={onSave}
          validate={validate}
          keepDirtyOnReinitialize
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-md-10">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: tab === 1 })} onClick={() => setTab(1)}>
                      Create/Edit
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: tab === 2 })} onClick={() => setTab(2)}>
                      Menu Items
                    </NavLink>
                  </NavItem>
                </Nav>
                {!loading ? (
                  <TabContent className="p-4" id="menu-create-edit" activeTab={tab}>
                    <TabPane tabId={1}>
                      <BeverageMenusForm />
                    </TabPane>
                    <TabPane tabId={2}>
                      <MenuItem groupHeadings={values.beverageMenus?.groupHeadings} menuOrPackage={pkgClone} type="beverageMenus" path="beverageMenus" productSizes={productSizes} pkg menuHeadings={menuHeadings} removedProducts={removedProducts} />
                    </TabPane>
                  </TabContent>
                ) : null}
              </div>
            </form>
          )}
        ></Form>
      ) : (
        <Spinner size="sm" />
      )}
    </ContentWrapper>
  );
};

export default BeverageMenus;
