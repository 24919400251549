import _ from "lodash";
import { combineReducers } from "redux";

import * as types from "./ActionTypes";

const initialState = {
  headings: {},
  allHeadings: {},
  heading: null,
  subcategoryName: "",
  files: {},
};

function headings(state = initialState.headings, action) {
  switch (action.type) {
    case types.GET_MENU_HEADINGS:
      return action.headings;
    default:
      return state;
  }
}

function allHeadings(state = initialState.allHeadings, action) {
  switch (action.type) {
    case types.GET_ALL_MENU_HEADINGS:
      return action.allHeadings;
    default:
      return state;
  }
}

function heading(state = initialState.heading, action) {
  switch (action.type) {
    case types.GET_MENU_HEADINGS_BY_ID:
      return action.heading;
    default:
      return state;
  }
}

function subcategoryName(state = initialState.subcategoryName, action) {
  switch (action.type) {
    case types.GET_MENU_HEADING_SUBCATEGORY_NAME:
      return action.value;
    default:
      return state;
  }
}

function files(state = initialState.files, action) {
  switch (action.type) {
    case types.MENU_FILE:
      return { [action.menuId]: action.file };
    case types.MENU_FILE_SAVE_COMPLETE:
      return { [action.menuId]: null };
    default:
      return state;
  }
}

export function getVisiblePricing(menuPricing) {
  return _.pickBy(menuPricing, (pricing) => pricing.enabled === true);
}

export function getVisibleCategories(menuCategories) {
  return _.pickBy(menuCategories, (category) => category.enabled === true);
}

export function getVisibleHeadings(menuHeadings) {
  return _.pickBy(menuHeadings, (heading) => heading.enabled === true);
}

export function getVisibleMenus(menus) {
  return _.pickBy(menus, (menu) => menu.enabled === true);
}

export function getPricing(pricing) {
  return {
    standard: {},
    ...pricing,
  };
}

export default combineReducers({
  headings,
  allHeadings,
  heading,
  subcategoryName,
  files,
});
