import { DropdownListFilter, getFilterName } from "components/CustomFilter";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { requiredField } from "core/react-utils";
import { getConfig } from "core/utilities";
import { catchExceptionCallback } from "core/utils";
import firebase from "firebase/compat/app";
import { keys, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap";
import { AdditionalItemsCategories, AdditionalItemsCategory } from "resbutler-utils/types/Menu";
import { buildDataFromDocs } from "resbutler-utils/utils";
import "./list.scss";

let unsubscribe = null;

export default function () {
  const [filter, setFilter] = useState({ enabled: true });
  const [loading, setLoading] = useState(true);
  const [additionalItemsCategories, setAdditionalItemsCategories] = useState<AdditionalItemsCategories>({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const { client } = getConfig();

  const categoryId = firebase.firestore().collection(`${client}/menus/additionalItemsCategories`).doc().id;

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      unsubscribe = firebase
        .firestore()
        .collection(`${client}/menus/additionalItemsCategories`)
        .onSnapshot((snap) => {
          setAdditionalItemsCategories(buildDataFromDocs(snap.docs) as AdditionalItemsCategories);
          setLoading(false);
        });
    };
    fetch();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Additional Items Categories" headerText="Additional Items Categories" />
      <Row>
        <Col lg={6} sm={6}>
          <div className="justify-content-between d-flex mb-2">
            <div>
              <Button color="primary text-capitalize" onClick={() => setSelectedCategory(categoryId)}>
                New Additional Items Category
              </Button>
            </div>
            <div>
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group m-0" getName={getFilterName} onSelect={(e) => setFilter({ enabled: e })} />
            </div>
          </div>
          {!loading ? (
            <Card className="card-default">
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Enabled</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {keys(pickBy(additionalItemsCategories, (c) => filter.enabled === null || (filter.enabled && c.enabled) || (!filter.enabled && !c.enabled))).map((categoryId) => {
                      return (
                        <tr key={categoryId}>
                          <td>{additionalItemsCategories[categoryId].name}</td>
                          <td>{additionalItemsCategories[categoryId].enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                          <td>
                            <Link to="#" onClick={() => setSelectedCategory(categoryId)}>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <Spinner size="sm" />
          )}
        </Col>
        {!loading && selectedCategory ? <AdditionalItemsCategoryModal isOpen toggle={() => setSelectedCategory("")} categoryId={selectedCategory} selectedCategory={additionalItemsCategories[selectedCategory]} isNew={additionalItemsCategories[selectedCategory] == undefined} /> : null}
      </Row>
    </ContentWrapper>
  );
}

function AdditionalItemsCategoryModal(props: { isOpen: boolean; toggle: () => void; categoryId: string; selectedCategory: AdditionalItemsCategory; isNew: boolean }) {
  const { toggle, isOpen, isNew, selectedCategory, categoryId } = props;

  function validate(values) {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please enter a name";
    }
    return errors;
  }

  const onSave = async (values: AdditionalItemsCategory) => {
    try {
      const { client } = getConfig();
      await firebase.firestore().doc(`${client}/menus/additionalItemsCategories/${categoryId}`).set(values);
      toggle();
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <Modal isOpen={isOpen === true} size="xl" toggle={toggle} scrollable>
      <Form<AdditionalItemsCategory>
        initialValues={{ enabled: true, ...selectedCategory }}
        onSubmit={onSave}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader toggle={toggle}>{isNew ? "Create New Additional Item Category" : `Edit ${selectedCategory?.name}`}</ModalHeader>
              <ModalBody>
                <Field id="name1" name="name" type="text" component={requiredField as any} label="Name" className="form-group" />
                <div className="checkbox c-checkbox">
                  <label className="mb-2" htmlFor="enabled1">
                    <Field id="enabled1" name="enabled" component="input" type="checkbox" />
                    <span className="fa fa-check" />
                    Enabled
                  </label>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={submitting} color="success" className="float-right">
                  Save {submitting && <Spinner size="sm" />}
                </Button>
                <div className="clearfix" />
              </ModalFooter>
            </form>
          );
        }}
      ></Form>
    </Modal>
  );
}
