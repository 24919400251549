import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getConfig } from "core/utilities";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
//import _ from 'lodash';
import { priceFormatter } from "_utils/format";
import { useAppSelector } from "components/Hooks/hooks";
import showModalTransaction from "components/Modal/showModalTransaction";
import { catchExceptionCallback } from "core/utils";
import { find, forEach, keys, map } from "lodash";
import { Button } from "reactstrap";
import { Restaurants } from "resbutler-utils/types/Restaurant";
import { Voucher } from "resbutler-utils/types/Voucher";
import { Customer } from "resbutler-utils/types/customer";

// render a cell of Products
export const Product = ({ productId, sizes }) => {
  const { products, allSizes } = useAppSelector((state) => {
    return {
      products: state.root.products,
      allSizes: state.root.productSizes,
    };
  });
  const product = find(products, (p) => p.id === productId);

  const productSizes = keys(sizes).sort();
  return (
    <>
      {productSizes.map((sizeId) => (
        <span key={sizeId}>
          {product?.name} ({allSizes[sizeId]?.name})|&nbsp;
        </span>
      ))}
    </>
  );
};

// render a cell of Customers
export const VoucherCustomer = ({ customerId }) => {
  const { client } = getConfig();
  const [customer, setCustomer] = useState<Customer>(null); // loaded customer
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const snap = await firebase.firestore().doc(`${client}/crm/customers/${customerId}`).get();
        setCustomer(snap.data() as Customer);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (customerId) fetchData();
  }, [customerId]);

  return !loading ? (
    <Link to={`/crm/customers/${customerId}`}>
      {customer?.firstName} {customer?.lastName}
    </Link>
  ) : null;
};

// table
export const voucherColumns = (allRestaurants: Restaurants, excludedColumns = []) => [
  {
    text: "Restaurants",
    dataField: "restaurant",
    formatter: (cell, row: Voucher) => {
      let restaurantNames = "";
      forEach(row.restaurants, (id) => (restaurantNames = restaurantNames.concat(allRestaurants[id].name, " | ")));
      return restaurantNames;
    },
    filterValue: (cell, row: Voucher) => {
      let restaurantNames = "";
      (row.restaurants || []).forEach((id) => (restaurantNames = restaurantNames.concat(allRestaurants[id].name)));
      return restaurantNames;
    },
  },
  {
    text: "Voucher Number",
    dataField: "voucherNumber",
    sort: true,
    formatter: (cell, row: Voucher) => {
      return <Link to={`/crm/vouchers/issued/${row.id}`}>{row.voucherNumber}</Link>;
    },
  },
  {
    text: "Products",
    dataField: "products",
    formatter: (cell, row: Voucher) => {
      if (row.allProducts) return "Any";
      return map(row.products, ({ productId, sizes }) => <Product key={productId} productId={productId} sizes={sizes} />);
    },
  },
  {
    text: "Discount",
    dataField: "discount",
    formatter: (cell, row: Voucher) => {
      if (row.discountType === "$") return priceFormatter.format(row.voucherAmount);
      return row.discountType ? row.discountAmount + row.discountType : "";
    },
  },
  {
    text: "Issued Date",
    dataField: "issuedDate",
    formatter: (issuedDate) => moment(issuedDate.seconds * 1000).format("DD/MM/YYYY"),
    sort: true,
    filterValue: (cell, row: Voucher) => {
      return moment(row.issuedDate.seconds * 1000).format("DD/MM/YYYY");
    },
  },
  {
    text: "Expiry Date",
    dataField: "expiryDate",
    formatter: (expiryDate) => moment(expiryDate.seconds * 1000).format("DD/MM/YYYY"),
    sort: true,
    filterValue: (cell, row: Voucher) => {
      return moment(row.expiryDate.seconds * 1000).format("DD/MM/YYYY");
    },
  },

  {
    text: "Redeemed Date",
    dataField: "isRedeemed",
    formatter: (cell, row: Voucher) => {
      const dates = [];
      (row.redeemedInfo || []).forEach((info) => {
        const date = moment(info.redeemedDate).format("DD/MM/YYYY");
        if (!dates.includes(date)) dates.push(date, " ");
      });
      return dates;
    },
  },
  {
    text: "Transaction",
    dataField: "transaction",
    formatter: (cell, row: Voucher) => {
      const transactions = [];
      (row.redeemedInfo || []).forEach((info) => {
        transactions.push(
          <div
            key={info.transactionId}
            style={{ color: "blue" }}
            onClick={() =>
              showModalTransaction({
                id: info.transactionId,
              })
            }
          >
            {info.transactionId}
          </div>
        );
      });
      return transactions;
    },
  },

  {
    text: "Customer",
    hidden: excludedColumns.includes("customerId"),
    dataField: "customerId",
    formatter: (customerId) => <VoucherCustomer customerId={customerId} />,
  },
  {
    text: "Enabled",
    dataField: "isEnabled",
    formatter: (isEnabled) => <FontAwesomeIcon icon={isEnabled ? faCheck : faTimes} />,
  },
];

const IssuedVoucherList = () => {
  const allRestaurants = useAppSelector((state) => state.root.restaurants);
  const [vouchers, setVouchers] = useState([]);

  useEffect(() => {
    const _ = async () => {
      const { client } = getConfig();
      const snap = await firebase.firestore().collection(`${client}/crm/vouchers`).get();
      const result = [];
      snap.docs.forEach((doc) =>
        result.push({
          ...doc.data(),
          id: doc.id,
        })
      );
      setVouchers(result);
    };
    _().then();
  }, []);

  const columns = voucherColumns(allRestaurants);
  const SearchBox = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (
      <Row className="mb-2">
        <Col lg={5}>
          <div className="input-group">
            <input className="form-control" ref={(n) => (input = n)} type="text" placeholder="Search By Voucher Number/ Restaurants/ Dates" />
            <div className="input-group-append">
              <Button color="primary" onClick={handleClick}>
                <i className="fa fa-search" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const options = {
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
    ],
  };

  return (
    <ContentWrapper>
      <ContentHeading parentText="CRM" headerText="Vouchers Issued" subHeaderText="Voucher Issued" showRestaurants={false} />
      <Row className="mb-2">
        <Col className="float-right">
          {/*<form onSubmit={handleSubmit(() => null)}>*/}
          {/*  <div className="input-group">*/}
          {/*    <Input type="select" className="form-control"*/}
          {/*           onChange={e => setFilter({...filter,searchField: e.target.value })}>*/}
          {/*      <option value={""}>Select filter...</option>*/}
          {/*      <option value="lastName_low">Last Name</option>*/}
          {/*      <option value="mobile">Mobile</option>*/}
          {/*      <option value="email">Email</option>*/}
          {/*    </Input>*/}
          {/*    <Field type="text" name="search" component="input"*/}
          {/*           className="form-control"*/}
          {/*           onChange={e => setFilter({...filter,search: e.target.value })} />*/}
          {/*    <div className="input-group-append">*/}
          {/*      <Button type="submit" color="primary"*/}
          {/*              onClick={handleSearch}*/}
          {/*              disabled={!filter.searchField}*/}
          {/*      >*/}
          {/*        <i className="fa fa-search" aria-hidden="true" />*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</form>*/}
        </Col>
      </Row>
      {/* =========== TABLE CARD ============= */}
      <Card className="card-default">
        <CardBody>
          <div className="clearfix" />
          <ToolkitProvider keyField="_key" data={vouchers} columns={columns} search>
            {(props) => (
              <div>
                <div style={{ height: 800, overflow: "auto" }} className="form-group col-lg-12 no-click">
                  <SearchBox {...props.searchProps} />
                  <BootstrapTable classes="table-striped table-responsive" pagination={paginationFactory(options)} {...props.baseProps} keyField="voucherNumber" />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </ContentWrapper>
  );
};
export default IssuedVoucherList;
