import { groupBy, map } from "lodash";
import { useMemo } from "react";

import { Areas, TableClasses } from "resbutler-utils/types/Box";
import { Meals } from "resbutler-utils/types/Meals";
import { Menu, MenuCategories } from "resbutler-utils/types/Menu";

export const useFilterTreeData = (meals: Meals, areas: Areas, tableClasses: TableClasses) => {
  const treeData = useMemo(() => {
    return [
      {
        title: "All Meal Periods",
        value: "all-meal-periods",
        children: map(meals, (meal, key) => ({
          title: meal.name,
          value: key,
        })),
      },
      {
        title: "All Areas",
        value: "all-areas",
        children: map(areas, (area, key) => ({
          title: area.name,
          value: key,
        })),
      },
      {
        title: "All Table Classes",
        value: "all-table-classes",
        children: map(tableClasses, (tableClass, key) => ({
          title: tableClass.name,
          value: key,
        })),
      },
    ];
  }, [meals, areas, tableClasses]);

  return treeData;
};

export const useMenuTreeData = (menus: Menu[], menuCategories: MenuCategories) => {
  const menusByCategory = groupBy(menus, "menuCategoryId");

  const treeData = useMemo(
    () =>
      menuCategories
        ? map(menusByCategory, (menus, categoryId) => {
            const menuCategory = menuCategories[categoryId];

            return {
              title: menuCategory?.name,
              value: categoryId,
              children: map(menus, (menu: Menu) => ({
                title: menu?.name,
                value: `${menu.id} ${categoryId}`,
              })),
            };
          })
        : [],
    [menusByCategory]
  );

  return treeData;
};

export default useFilterTreeData;
