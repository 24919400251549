import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "_utils/format";
import classNames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import { chain, difference, find, findIndex, findKey, get, keys, map, orderBy } from "lodash";
import React, { ReactElement, useRef, useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Badge, Button, Col, Row, Table } from "reactstrap";
import { BeverageMenuPackage, GroupHeading, GroupHeadingProducts, GroupHeadings, Menu, MenuHeading, MenuOrderingAllocationType, Package } from "resbutler-utils/types/Menu";
import "./menu-item.scss";

export function BookingQuantityLimits({ fields, groupHeading, inclusionType }: { fields: any; groupHeading: GroupHeading; inclusionType: "" | "limitless" | "fixed" }) {
  const [minPax, setMinPax] = useState("");
  const [maxPax, setMaxPax] = useState("");
  const [limitQty, setLimitQty] = useState("");
  const [minutes, setMinutes] = useState("");
  return (
    <Row>
      <Col lg={12}>
        <Table bordered striped>
          <thead>
            <tr>
              <th>Min Pax</th>
              <th>Max Pax</th>
              <th>Ordering Quantity Limit</th>
              {inclusionType === "limitless" ? <th>Minutes</th> : null}
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <input type="number" min={0} step={1} readOnly className="form-control" value={fields.value[index].minPax} onChange={(e) => (fields.value[index].minPax = e.target.value)} />
                    </td>
                    <td>
                      <input type="number" min={0} step={1} readOnly className="form-control" value={fields.value[index].maxPax} onChange={(e) => (fields.value[index].maxPax = e.target.value)} />
                    </td>
                    <td>
                      <input type="number" min={0} step={1} readOnly className="form-control" value={fields.value[index].quantityLimit} onChange={(e) => (fields.value[index].quantityLimit = e.target.value)} />
                    </td>
                    {inclusionType === "limitless" ? (
                      <td>
                        <input type="number" min={0} step={1} readOnly className="form-control" value={fields.value[index].minutes} onChange={(e) => (fields.value[index].minutes = e.target.value)} />
                      </td>
                    ) : null}
                    <td>
                      <Button type="button" color="danger" onClick={() => fields.remove(index)}>
                        <i className="fa fa-trash" aria-hidden="true" />
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <input type="number" min={0} step={1} className="form-control" value={minPax} onChange={(e) => setMinPax(e.target.value || "")} />
              </td>
              <td>
                <input type="number" min={0} step={1} className="form-control" value={maxPax} onChange={(e) => setMaxPax(e.target.value || "")} />
              </td>
              <td>
                <input type="number" min={0} step={1} className="form-control" value={limitQty} onChange={(e) => setLimitQty(e.target.value || "")} />
              </td>
              {inclusionType === "limitless" ? (
                <td>
                  <input type="number" min={0} step={1} className="form-control" value={minutes} onChange={(e) => setMinutes(e.target.value || "")} />
                </td>
              ) : null}
              <td>
                <Button
                  type="button"
                  color="success"
                  onClick={() => {
                    fields.push({
                      minPax: Number(minPax),
                      maxPax: Number(maxPax),
                      quantityLimit: Number(limitQty),
                      minutes: Number(minutes),
                    });
                    setMinPax("");
                    setMaxPax("");
                    setLimitQty("");
                    setMinutes("");
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                </Button>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Col>
    </Row>
  );
}

export function InclusionOptions({
  pathToGroupHeadings,
  groupHeadingId,
  menuOrPackage,
  inclusionSubheadingType,
  isInclusionFoodOrDrink,
}: {
  pathToGroupHeadings: string;
  groupHeadingId: string;
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  inclusionSubheadingType: "" | "limitless" | "fixed";
  isInclusionFoodOrDrink?: boolean;
}) {
  const form = useForm();
  const groupHeadings = get(menuOrPackage, `${pathToGroupHeadings}.groupHeadings`) as GroupHeadings;
  const inclusionType = groupHeadings[groupHeadingId].inclusionType ? groupHeadings[groupHeadingId].inclusionType : inclusionSubheadingType;
  const handleAllocationTypeChange = (e) => {
    const value = e.target.value;
    const targetpath = `${pathToGroupHeadings}.groupHeadings.${groupHeadingId}`;
    // reset previous values

    if (value === MenuOrderingAllocationType.PerBooking) {
      form.change(`${targetpath}.inclusionQuantityLimit`, null);
      form.change(`${targetpath}.inclusionMinutesLimit`, null);
    } else if (value === "") {
      form.change(`${targetpath}.inclusionQuantityLimit`, null);
      form.change(`${targetpath}.inclusionMinutesLimit`, null);
      form.change(`${targetpath}.perBookingQuantityLimits`, null);
    } else {
      form.change(`${targetpath}.perBookingQuantityLimits`, null);
    }
    form.change(`${targetpath}.fixedQuantityType`, value);
  };

  return (
    <>
      <Row className="form-group mb-0">
        <Col>
          <Field type="radio" name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.inclusionType`} defaultValue={inclusionType}>
            {({ input: { onChange } }) => {
              const options = [
                { value: "fixed", label: "Fixed Quantity" },
                { value: "limitless", label: "Limitless" },
              ];
              return (
                <div className="mt-2">
                  <label>Menu Inclusions Type</label>
                  <Row className="form-group">
                    {options.map((option) => {
                      return (
                        <div className="col-md-3" key={`${groupHeadingId}-${option.value}`}>
                          <label className="radio c-radio" htmlFor={`inclusion-type-${groupHeadingId}-${option.value}`}>
                            <input
                              id={`inclusion-type-${groupHeadingId}-${option.value}`}
                              type="radio"
                              disabled={inclusionSubheadingType ? option.value !== inclusionSubheadingType : false}
                              value={option.value}
                              checked={option.value === inclusionType}
                              onChange={(e) => {
                                form.change(`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.fixedQuantityType`, null);
                                form.change(`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.perBookingQuantityLimits`, null);
                                form.change(`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.inclusionQuantityLimit`, null);
                                form.change(`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.inclusionMinutesLimit`, null);
                                onChange(e.target.value);
                              }}
                            />
                            <span className="fa fa-circle" />
                            {option.label}
                          </label>
                        </div>
                      );
                    })}
                  </Row>
                </div>
              );
            }}
          </Field>
        </Col>
      </Row>
      {inclusionType && (isInclusionFoodOrDrink || (!isInclusionFoodOrDrink && inclusionType === "fixed")) && (
        <Row className="form-group mb-0">
          <Col lg="12">
            <Row>
              <Col lg="6" className="form-group">
                <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.fixedQuantityType`} parse={(x) => x}>
                  {({ input: { onChange, value } }) => {
                    const options = [
                      { value: "perPerson", label: "Per Person" },
                      { value: "perBooking", label: "Per Booking" },
                    ];
                    return (
                      <div>
                        <div className="mb-2">Ordering Contraints</div>
                        <label>Allocation Type</label>
                        <select className="form-control" value={value} onChange={handleAllocationTypeChange}>
                          <option value="">Choose Option</option>
                          {options &&
                            Object.keys(options).map((option) => (
                              <option key={options[option].value} value={options[option].value}>
                                {options[option].label}
                              </option>
                            ))}
                        </select>
                      </div>
                    );
                  }}
                </Field>
              </Col>
              <Col className="align-self-end pb-3">
                <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.onlyHostCanOrder`} type="checkbox">
                  {({ input: { onChange, checked } }) => (
                    <div className="checkbox c-checkbox mb-2">
                      <label className="m-0" htmlFor={`enable-only-host-can-order-${pathToGroupHeadings}-${groupHeadingId}`}>
                        <input id={`enable-only-host-can-order-${pathToGroupHeadings}-${groupHeadingId}`} type="checkbox" className="form-check-input" onChange={(e) => onChange(e.target.checked)} checked={checked} />
                        <span className="fa fa-check"></span> Only host can order
                      </label>
                    </div>
                  )}
                </Field>
              </Col>
            </Row>
            {groupHeadings[groupHeadingId]?.fixedQuantityType === "perPerson" ? (
              <Row>
                <Col lg="6" className="form-group">
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.inclusionQuantityLimit`} type="number">
                      {({ input: { onChange, value }, meta }) => (
                        <div>
                          <input value={value} onChange={(e) => onChange(e.target.value || "")} min="0" step="1" className="form-control" />
                          {meta.error && <span className="validate-form">{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <div className="ml-1 mr-1 text-nowrap">item/s {inclusionType === "limitless" ? " per" : ""}</div>
                    {inclusionType === "limitless" ? (
                      <>
                        <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.inclusionMinutesLimit`} type="number">
                          {({ input: { onChange, value }, meta }) => (
                            <div>
                              <input value={value} onChange={(e) => onChange(e.target.value || "")} min="0" step="1" className="form-control" />
                              {meta.error && <span className="validate-form">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <div className="ml-1">minutes</div>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
            ) : null}
            {groupHeadings[groupHeadingId]?.fixedQuantityType === "perBooking" && (
              <FieldArray id="fixedQuantityPerBooking" name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.perBookingQuantityLimits`} groupHeading={groupHeadings[groupHeadingId]} component={BookingQuantityLimits as any} inclusionType={inclusionType} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

export function getRelativeParentGroups(menuOrPackage: Menu | Package | BeverageMenuPackage, pathToGroupHeadings: string, groups: string[] = []) {
  let path = pathToGroupHeadings;
  let groupHeading = null;
  const length = pathToGroupHeadings
    .split(".")
    .slice(1)
    .filter((s) => s === "groupHeadings").length;
  for (let i = 0; i < length; i++) {
    groupHeading = get(menuOrPackage, path) as GroupHeading;
    if (groupHeading?.displayName) {
      groups.push(groupHeading.displayName);
      path = pathToGroupHeadings.split(".").slice(0, -2).join(".");
      // groupHeading = get(menuOrPackage, newPath) as GroupHeading;
      // groups.push(groupHeading.displayName);
    }
  }
  groups.reverse();
  // const groupHeading = groupHeadings[groupHeadingId];
  // if (groupHeading) {
  //   groups.push(groupHeading.displayName);
  //   if (keys(groupHeading.groupHeadings).length > 0) {
  //     for (const groupHeadingId1 in groupHeading.groupHeadings) {
  //       getRelativeParentGroups(groupHeading.groupHeadings, pathToGroupHeadings, groupHeadingId1, groups);
  //     }
  //   }
  // }
}

function RenderProduct({
  pathToGroupHeadings,
  currentGroupHeadings,
  currentProductGroupId,
  menuOrPackage,
  isInclusion,
  path,
  removedProducts,
  pkg = false,
}: {
  pathToGroupHeadings: string;
  currentGroupHeadings: GroupHeadings;
  currentProductGroupId: string;
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  isInclusion: boolean;
  path: string;
  removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } };
  pkg?: boolean;
}) {
  const inputRef = useRef([]);

  const changeProductsOrder = (action: "up" | "down", pathToGroupHeadings: string, currentGroupHeadings: GroupHeadings, productGroupId1: string, productId: string) => {
    if (keys(currentGroupHeadings[productGroupId1].products).length > 0) {
      const result = orderBy(
        map(currentGroupHeadings[productGroupId1].products, (p, k) => {
          return { order: p.order, productId: k };
        }),
        (p) => p.order,
        "asc"
      ); //.order
      let index = findIndex(result, (r) => r.productId === productId);
      if (action === "up" && index > 0) {
        index -= 1;
      } else if (action === "down" && index < result.length - 1) {
        index += 1;
      }
      if (findIndex(result, (r) => r.productId === productId) !== index) {
        form.change(`${pathToGroupHeadings}.groupHeadings.${productGroupId1}.products.${result[index].productId}.order`, currentGroupHeadings[productGroupId1].products[productId].order);
        form.change(`${pathToGroupHeadings}.groupHeadings.${productGroupId1}.products.${productId}.order`, currentGroupHeadings[productGroupId1].products[result[index].productId].order);
      }
      inputRef.current[productId].focus();
    }
  };

  const originalMenuProducts = get(menuOrPackage, pathToGroupHeadings)?.groupHeadings?.[currentProductGroupId]?.products as GroupHeadingProducts;
  const currentProducts = { ...currentGroupHeadings[currentProductGroupId]?.products, ...originalMenuProducts };
  const [collapseProductId, setCollapseProductId] = useState({});
  const currentProductsSorted = chain(currentProducts)
    .keys()
    .map((productId) => ({ ...currentProducts[productId], _key: productId }))
    .orderBy((v) => v.order, "asc")
    .value();
  const { products, productSizes, restaurantId } = useAppSelector((state) => {
    return {
      products: state.root.products,
      productSizes: state.root.productSizes,
      restaurantId: state.root.restaurantId,
    };
  });
  const form = useForm();

  const addedProducts = difference(keys(currentGroupHeadings[currentProductGroupId]?.products), keys(originalMenuProducts));
  return (
    <Row>
      <Col lg={12}>
        <Table bordered className="mt-3 table-sm">
          <thead>
            <tr>
              <th>Product name</th>
              {!isInclusion ? <th>Landing Page</th> : null}
              <th>Limitless</th>
              <th>Menu Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentProductsSorted.map((groupHeadingProduct) => {
              const productId = groupHeadingProduct._key;
              const product = find(products, (p) => p.id === productId);
              return (
                <React.Fragment key={productId}>
                  <tr>
                    <td>
                      <Button
                        type="button"
                        className="mr-2"
                        onClick={() =>
                          setCollapseProductId((state) => {
                            return { ...state, [productId]: state[productId] !== undefined ? !state[productId] : true };
                          })
                        }
                      >
                        <i className={classNames("fa", { "fa-chevron-right": !collapseProductId[productId], "fa-chevron-down": collapseProductId[productId] })} aria-hidden="true" />
                      </Button>
                      <span style={{ textDecorationLine: !product?.enabled ? "line-through" : "none" }}>{product?.name}</span>
                      {product?.enabled && product?.sizes && !findKey(currentGroupHeadings?.[currentProductGroupId]?.products?.[productId]?.productSizeIds, (p) => p.enabled) ? <FontAwesomeIcon icon={faEyeSlash as any} className="ml-1" /> : null}
                      {findIndex(addedProducts, (p) => p === productId) > -1 ? (
                        <Badge pill className="new-badge ml-1">
                          New
                        </Badge>
                      ) : null}
                      {removedProducts[currentProductGroupId]?.[productId] ? (
                        <Badge pill className="removed-badge ml-1">
                          Removed
                        </Badge>
                      ) : null}
                    </td>
                    {!isInclusion ? (
                      <td>
                        <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.landingPage`} type="checkbox">
                          {({ input: { onChange, checked } }) => (
                            <div className="checkbox c-checkbox">
                              <label className="m-0" htmlFor={`${pathToGroupHeadings}-${currentProductGroupId}-${productId}-landing-page`}>
                                <input
                                  id={`${pathToGroupHeadings}-${currentProductGroupId}-${productId}-landing-page`}
                                  type="checkbox"
                                  className="form-check-input"
                                  onChange={(e) => {
                                    onChange(e.target.checked);
                                    if (!e.target.checked) {
                                      form.change(`landing.products.${productId}`, undefined);
                                    } else {
                                      form.change(`landing.products.${productId}`, { order: keys((menuOrPackage as Menu).landing.products).length });
                                    }
                                  }}
                                  checked={checked}
                                />
                                <span className="fa fa-check"></span>
                              </label>
                            </div>
                          )}
                        </Field>
                      </td>
                    ) : null}
                    <td>
                      <div className="checkbox c-checkbox">
                        <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.limitless`} className="form-check-input" type="checkbox">
                          {({ input: { onChange, checked } }) => (
                            <div className="checkbox c-checkbox">
                              <label className="m-0" htmlFor={`${pathToGroupHeadings}-${currentProductGroupId}-${productId}-limitless`}>
                                <input id={`${pathToGroupHeadings}-${currentProductGroupId}-${productId}-limitless`} type="checkbox" className="form-check-input" onChange={(e) => onChange(e.target.checked)} checked={checked} />
                                <span className="fa fa-check"></span>
                              </label>
                            </div>
                          )}
                        </Field>
                      </div>
                    </td>
                    <td>
                      <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.note`}>
                        {({ input, meta }) => (
                          <>
                            <input {...input} maxLength={15} className="form-control" ref={(el) => (inputRef.current[productId] = el)} />
                            {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                          </>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Row className="justify-content-end">
                        <Col className="col-auto p-0">
                          <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => changeProductsOrder("up", pathToGroupHeadings, currentGroupHeadings, currentProductGroupId, productId)}>
                            <i className="fa fa-arrow-up"></i>
                          </button>
                        </Col>
                        <Col className="col-auto ps-1">
                          <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => changeProductsOrder("down", pathToGroupHeadings, currentGroupHeadings, currentProductGroupId, productId)}>
                            <i className="fa fa-arrow-down"></i>
                          </button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  {collapseProductId[productId] && keys(product.sizes).length > 0 && (
                    <tr>
                      <td colSpan={3}>
                        <Table bordered className="mt-3 table-sm">
                          <thead>
                            <tr>
                              <th>
                                <FontAwesomeIcon icon={faEye as any} />
                              </th>
                              {pkg ? <th>Always Included</th> : null}
                              <th>Reduce From Menu</th>
                              <th>Product Size</th>
                              <th>Standard Pricing</th>
                              <th>Adjusted Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(product.sizes).map(([sizeId, enabled]) => {
                              if (enabled) {
                                return (
                                  <tr key={`${path}-${productId}-${sizeId}`}>
                                    <td>
                                      <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.productSizeIds.${sizeId}.enabled`} type="checkbox">
                                        {({ input: { onChange, checked } }) => (
                                          <div className="checkbox c-checkbox">
                                            <label className="m-0" htmlFor={`enabled-${pathToGroupHeadings}-${productId}-${sizeId}`}>
                                              <input id={`enabled-${pathToGroupHeadings}-${productId}-${sizeId}`} type="checkbox" className="form-check-input" onChange={(e) => onChange(e.target.checked)} checked={checked} />
                                              <span className="fa fa-check"></span>
                                            </label>
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                    {pkg ? (
                                      <td>
                                        <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.productSizeIds.${sizeId}.isAlwaysIncluded`} type="checkbox">
                                          {({ input: { onChange, checked } }) => (
                                            <div className="checkbox c-checkbox">
                                              <label className="m-0" htmlFor={`isIncluded1-${pathToGroupHeadings}-${productId}-${sizeId}`}>
                                                <input id={`isIncluded1-${pathToGroupHeadings}-${productId}-${sizeId}`} className="form-check-input" type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
                                                <span className="fa fa-check"></span>
                                              </label>
                                            </div>
                                          )}
                                        </Field>
                                      </td>
                                    ) : null}
                                    <td>
                                      <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.productSizeIds.${sizeId}.notReducedMenu`} type="checkbox">
                                        {({ input: { onChange, checked } }) => (
                                          <div className="checkbox c-checkbox">
                                            <label className="m-0" htmlFor={`notReducedMenu1-${pathToGroupHeadings}-${productId}-${sizeId}`}>
                                              <input id={`notReducedMenu1-${pathToGroupHeadings}-${productId}-${sizeId}`} className="form-check-input" type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
                                              <span className="fa fa-check"></span>
                                            </label>
                                          </div>
                                        )}
                                      </Field>
                                    </td>
                                    <td>{productSizes[sizeId]?.name}</td>
                                    <td>{product.restaurants?.[restaurantId]?.price?.[sizeId] ? priceFormatter.format(product.restaurants[restaurantId]?.price[sizeId]) : ""}</td>
                                    <td>
                                      <Field name={`${pathToGroupHeadings}.groupHeadings.${currentProductGroupId}.products.${productId}.productSizeIds.${sizeId}.upgradePrice`}>
                                        {({ input }) => (
                                          <>
                                            <input {...input} className="form-control" />
                                          </>
                                        )}
                                      </Field>
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

function RenderRecursiveProductsList({
  pathToGroupHeadings,
  currentGroupHeadings,
  currentGroupHeadingId,
  level,
  menuOrPackage,
  isInclusion,
  menuHeading,
  path,
  removedProducts,
  pkg = false,
  isInclusionFoodOrDrink,
}: {
  pathToGroupHeadings: string;
  currentGroupHeadings: GroupHeadings;
  currentGroupHeadingId: string;
  level: number;
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  isInclusion: boolean;
  menuHeading: MenuHeading;
  path: string;
  removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } };
  pkg?: boolean;
  isInclusionFoodOrDrink?: boolean;
}): ReactElement<any, any> {
  const values = useFormState<Menu>().values;
  const [collapseSubGroupHeadingId, setCollapseSubGroupHeadingId] = useState("");
  const breadCrumbs = [];
  getRelativeParentGroups(menuOrPackage, pathToGroupHeadings, breadCrumbs);

  if (keys(currentGroupHeadings).length > 0) {
    return (
      <>
        {keys(currentGroupHeadings).map((groupHeadingId1) => {
          const breadCrumbs1 = [...breadCrumbs, currentGroupHeadings[groupHeadingId1].displayName];
          return (
            <React.Fragment key={`pg-${groupHeadingId1}`}>
              <span className="separator"></span>
              <div style={{ paddingLeft: level * 20 }}>
                <Row>
                  <Col>
                    <Row>
                      <Col lg={12} className="d-flex align-items-center">
                        <Button type="button" className="mr-2" onClick={() => setCollapseSubGroupHeadingId(collapseSubGroupHeadingId === groupHeadingId1 ? "" : groupHeadingId1)}>
                          <i className={classNames("fa", { "fa-chevron-right": collapseSubGroupHeadingId !== groupHeadingId1, "fa-chevron-down": collapseSubGroupHeadingId === groupHeadingId1 })} aria-hidden="true" />
                        </Button>
                        <p className="text-bold mb-0">
                          {currentGroupHeadings[groupHeadingId1].displayName}
                          <small className="d-block">
                            {breadCrumbs1.map((b) => (
                              <React.Fragment key={b}>
                                <i className="fa fa-chevron-right icon-smaller" aria-hidden="true"></i>&nbsp;{b}&nbsp;
                              </React.Fragment>
                            ))}
                          </small>
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <div className="float-right">
                      <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`} type="checkbox">
                        {({ input: { onChange, checked } }) => (
                          <div className="checkbox c-checkbox">
                            <label className="m-0" htmlFor={`enabled-${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`}>
                              <input id={`enabled-${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`} type="checkbox" className="form-check-input" onChange={(e) => onChange(e.target.checked)} checked={checked} />
                              <span className="fa fa-check"></span>
                              Enable Subheading
                            </label>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div />
                  </Col>
                </Row>
                {collapseSubGroupHeadingId === groupHeadingId1 && (
                  <>
                    <Row>
                      <Col lg={6}>
                        <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.displayName`}>
                          {({ input, meta }) => (
                            <>
                              <label>Display Name</label>
                              <input {...input} className="form-control" />
                              {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
                            </>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    {keys(currentGroupHeadings[groupHeadingId1].groupHeadings).length === 0 ? (
                      <Row>
                        <Col lg={6}>
                          <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.numberOptions`} type="number" parse={(value) => Number(value)}>
                            {({ input, meta }) => (
                              <>
                                <label>Number of Options</label>
                                <input {...input} min="0" className="form-control" />
                                {meta.error && <span className="validate-form">{meta.error}</span>}
                              </>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    ) : null}
                    {keys(currentGroupHeadings[groupHeadingId1].groupHeadings).length === 0 && isInclusion && menuHeading.inclusionsAtSubheadingLevel ? (
                      <InclusionOptions groupHeadingId={groupHeadingId1} pathToGroupHeadings={pathToGroupHeadings} menuOrPackage={menuOrPackage} isInclusionFoodOrDrink={isInclusionFoodOrDrink} inclusionSubheadingType={menuHeading.inclusionSubheadingType} />
                    ) : null}
                    {keys(currentGroupHeadings[groupHeadingId1].groupHeadings).length > 0 ? (
                      <RenderRecursiveProductsList
                        pathToGroupHeadings={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}`}
                        currentGroupHeadings={currentGroupHeadings[groupHeadingId1].groupHeadings}
                        currentGroupHeadingId={groupHeadingId1}
                        level={level + 1}
                        menuOrPackage={menuOrPackage}
                        isInclusion={isInclusion}
                        menuHeading={menuHeading}
                        path={path}
                        removedProducts={removedProducts}
                        pkg={pkg}
                        isInclusionFoodOrDrink={isInclusionFoodOrDrink}
                      />
                    ) : (
                      <RenderProduct pathToGroupHeadings={pathToGroupHeadings} currentGroupHeadings={currentGroupHeadings} currentProductGroupId={groupHeadingId1} menuOrPackage={menuOrPackage} isInclusion={isInclusion} path={path} removedProducts={removedProducts} pkg={pkg} />
                    )}
                  </>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  }
  return <RenderProduct pathToGroupHeadings={pathToGroupHeadings} currentGroupHeadings={currentGroupHeadings} currentProductGroupId={currentGroupHeadingId} menuOrPackage={menuOrPackage} isInclusion={isInclusion} path={path} removedProducts={removedProducts} pkg={pkg} />;
}

export default function MenuItemDynamic({
  path,
  menuOrPackage,
  groupHeadingId,
  groupHeadings,
  pkg = false,
  isInclusion,
  menuHeading,
  removedProducts,
  isInclusionFoodOrDrink,
}: {
  path: string;
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  groupHeadingId: string;
  groupHeadings: GroupHeadings;
  pkg?: boolean;
  isInclusion: boolean;
  menuHeading: MenuHeading;
  removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } };
  isInclusionFoodOrDrink?: boolean;
}) {
  return (
    <>
      <Row>
        <Col lg={6}>
          <Field name={`${path}.groupHeadings.${groupHeadingId}.displayName`}>
            {({ input, meta }) => (
              <>
                <label>Display Name</label>
                <input {...input} className="form-control" />
                {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}
              </>
            )}
          </Field>
        </Col>
      </Row>
      {pkg === true ? (
        <Row style={{ paddingTop: 10 }}>
          <Col lg={6}>
            <Field name={`${path}.groupHeadings.${groupHeadingId}.minimumServiceDuration`} type="number" parse={(value) => Number(value)}>
              {({ input, meta }) => (
                <>
                  <label>Minimum Service Duration (min)</label>
                  <input {...input} min="0" className="form-control" />
                  {meta.error && <span className="validate-form">{meta.error}</span>}
                </>
              )}
            </Field>
          </Col>
        </Row>
      ) : null}
      {keys(groupHeadings[groupHeadingId]?.groupHeadings).length === 0 ? (
        <Row>
          <Col lg={6}>
            <Field name={`${path}.groupHeadings.${groupHeadingId}.numberOptions`} type="number" parse={(value) => Number(value)}>
              {({ input, meta }) => (
                <>
                  <label>Number of Options</label>
                  <input {...input} min="0" className="form-control" />
                  {meta.error && <span className="validate-form">{meta.error}</span>}
                </>
              )}
            </Field>
          </Col>
        </Row>
      ) : null}
      {isInclusion ? <InclusionOptions groupHeadingId={groupHeadingId} pathToGroupHeadings={path} menuOrPackage={menuOrPackage} inclusionSubheadingType={menuHeading.inclusionSubheadingType} isInclusionFoodOrDrink={isInclusionFoodOrDrink} /> : null}
      {keys(groupHeadings[groupHeadingId]?.groupHeadings).length > 0 ? (
        <RenderRecursiveProductsList
          pathToGroupHeadings={`${path}.groupHeadings.${groupHeadingId}`}
          currentGroupHeadings={groupHeadings[groupHeadingId].groupHeadings}
          currentGroupHeadingId={groupHeadingId}
          level={1}
          menuOrPackage={menuOrPackage}
          isInclusion={isInclusion}
          menuHeading={menuHeading}
          path={path}
          removedProducts={removedProducts}
          pkg={pkg}
          isInclusionFoodOrDrink={isInclusionFoodOrDrink}
        />
      ) : (
        <RenderProduct pathToGroupHeadings={path} currentGroupHeadings={groupHeadings} currentProductGroupId={groupHeadingId} menuOrPackage={menuOrPackage} isInclusion={isInclusion} path={path} removedProducts={removedProducts} pkg={pkg} />
      )}
    </>
  );
}
