import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { filter, findKey, flatMap, isEmpty, isUndefined, keyBy, keys, map, mapValues, pickBy, sumBy, values } from "lodash";
import moment from "moment";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Select from "react-select";
import { Button, Card, Col, Row, Spinner, Table } from "reactstrap";

import { useAppSelector } from "components/Hooks/hooks";
import useAreas from "components/Hooks/useAreas";
import useMeals from "components/Hooks/useMeals";
import useTableClasses from "components/Hooks/useTableClasses";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import ManagementPeriodSelector from "components/explorer/ManagementPeriodSelector";
import ToggleWrapper from "components/explorer/ToggleWrapper";
import { catchExceptionCallback, getConfig } from "core/utils";

import { ProductCategoryId } from "resbutler-utils/types/ProductCategoryId";
import { buildDataFromDocs } from "resbutler-utils/utils";

import tableToCSV from "explorer/tableToCSV";
import tableToPDF from "explorer/tableToPDF";

import { calendarTypes, displayAmount, getProductGroupHierarchyAndCategoryFromProductGroupIds, managementCalendarModes, normalizeProductTreeCollectionForTotalSum, productCategoryOrder } from "../utils";
import useFilterTreeData from "./useFilterData";
import { IReportByProductTreeAreaPayload, filterResults, normalizeResults, productFilters } from "./utils";

const RevenueReport = () => {
  const [results, setResults] = useState([]);

  const restaurantId = useAppSelector((state) => state.root.restaurantId);
  const products = useAppSelector((state) => state.root.products);
  const productGroups = useAppSelector((state) => state.root.productGroups);

  const [productFilterValue, setProductFilterValue] = useState(productFilters["all-products"].value);
  const tableRef = useRef<HTMLTableElement>(null);

  const [loadingAreas, areas] = useAreas(restaurantId);
  const [, meals] = useMeals(restaurantId);
  const [, tableClasses] = useTableClasses(restaurantId);

  const filterTreeData = useFilterTreeData(meals, areas, tableClasses);
  const defaultFilterTreeData = [];

  // normalize filter tree default data
  filterTreeData.forEach((item) => {
    defaultFilterTreeData.push(item.value);

    if (item.children) {
      item.children.forEach((item1) => {
        defaultFilterTreeData.push(item1.value);
      });
    }
  });

  const [filterTreeDataValue, setFilterTreeDataValue] = useState(defaultFilterTreeData);

  const areasByAreaGroup = useAppSelector((state) => {
    if (loadingAreas) {
      return {};
    }

    const areaGroups = state.root.areaGroups;
    const filteredAreaGroups = filter(areaGroups, { enabled: true, restaurantId: restaurantId });

    const result = mapValues(keyBy(filteredAreaGroups, "abbreviation"), (areaGroup) => ({
      name: areaGroup.name,
      areas: keys(
        pickBy(areas, (area, key) => {
          if (filterTreeDataValue.includes("all-areas")) {
            return area.groupAreaId === areaGroup.abbreviation;
          }

          return area.groupAreaId === areaGroup.abbreviation && filterTreeDataValue.includes(key);
        })
      ),
    }));

    return pickBy(result, (areaGroup) => areaGroup.areas.length > 0);
  });

  const [calendar, setCalendar] = useState({});
  const { client, resbutlerApis } = getConfig();

  const initialValues = useMemo(
    () => ({
      restaurants: [],
      calendar: calendarTypes.dateRange.value,
      filterTree: defaultFilterTreeData,
    }),
    []
  );

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        const calendarSnap = await firebase.firestore().collection(`${client}/calendar/calendar`).get();
        setCalendar(buildDataFromDocs(calendarSnap.docs));
      } catch (error) {
        catchExceptionCallback(error);
      }
    };
    getCalendarData();
  }, []);

  const loadReports = async (values) => {
    try {
      const params = {
        restaurantId,
        clientId: client,
      } as IReportByProductTreeAreaPayload;

      if (values.calendar === calendarTypes.management.value) {
        params.mode = values.mode;
        params.period = values.period;
      } else {
        params.dateFrom = values.dateFrom;
        params.dateTo = values.dateTo;
      }

      setResults([]);
      const API_URL = `${resbutlerApis}/bq/revenue-by-product-tree-area`;
      const response = await axios.get(API_URL, { params });
      setResults(response.data);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToCSV = async (e) => {
    try {
      e.preventDefault();
      await tableToCSV(tableRef.current, ",", "revenue-report-by-product-tree-by-area");
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const handleExportToPDF = async (e) => {
    try {
      e.preventDefault();
      await tableToPDF(tableRef.current, "Revenue by Product Tree by Area", "revenue-report-by-product-tree-by-area", 0.75);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const validate = (values: IReportByProductTreeAreaPayload) => {
    const errors = {} as IReportByProductTreeAreaPayload;

    if (values) {
      if (values.calendar === calendarTypes.management.value) {
        if (!values.mode) {
          errors.mode = "This is required.";
        }

        if (!values.period) {
          errors.period = "Please input time period values.";
        } else {
          if (!values.period.year) {
            errors.period = "Please input time period values.";
          }

          if (values.mode === managementCalendarModes.week.value && !values.period.week) {
            errors.period = "Please input time period values.";
          }

          if (values.mode === managementCalendarModes.month.value && !values.period.month) {
            errors.period = "Please input time period values.";
          }
        }
      } else {
        if (!values.dateFrom || !values.dateTo) {
          errors.dateFrom = "Please input time period date range.";
        }
      }
    }

    return errors;
  };

  if (loadingAreas) {
    return (
      <div className="d-flex justify-content-center m-4">
        <Spinner />
      </div>
    );
  }

  const normalizedResults = normalizeResults(results, filterTreeData, filterTreeDataValue);
  const filteredResults = filterResults(normalizedResults, filterTreeData, filterTreeDataValue);
  const productGroupHierarchy = getProductGroupHierarchyAndCategoryFromProductGroupIds(Object.keys(filteredResults), productGroups);
  const filteredResultListForTotalSum = normalizeProductTreeCollectionForTotalSum(productGroupHierarchy, filteredResults);

  const renderProductTreeByAreaReports = (productGroupKeys, depth = 1) => {
    return map(productGroupKeys, (items, productGroupId) => {
      const productGroup = productGroups[productGroupId];
      const item = filteredResults?.[productGroupId];
      const linesByProductId = item?.lines;
      const flattenedLinesByProductId = flatMap(values(linesByProductId));

      if (flattenedLinesByProductId.length === 0 && Object.keys(items).length === 0) {
        return null;
      }

      return (
        <ToggleWrapper key={productGroupId} defaultOpen={true}>
          {(open, toggle) => {
            return (
              <>
                <tr>
                  <td className="divider-right">
                    <button className="toggle-btn flex align-items-center" onClick={toggle}>
                      {[...Array(depth)].map((p) => (
                        <Fragment key={`depth-${p}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                      ))}

                      <i className={`fa fa-chevron-${open ? "down" : "up"} mr-2`} aria-hidden="true"></i>
                      <b>{productGroup.name}</b>
                    </button>
                  </td>

                  {map(areasByAreaGroup, (item, key) => {
                    return item.areas.map((areaId, index: number) => {
                      const isLastIndex = item.areas.length === index + 1;
                      return <td key={key + areaId + "-1"} className={`${isLastIndex ? "divider-right" : ""}`} />;
                    });
                  })}

                  <td />
                </tr>

                {open ? (
                  <>
                    {isUndefined(item) === false ? (
                      <>
                        {map(linesByProductId, (lines, productId) => {
                          const product = products?.[productId];

                          if (productFilterValue !== productFilters["all-products"].value) {
                            return null;
                          }

                          return (
                            <tr key={`${productId}-${productGroupId}`} className="small">
                              <td className="pl-6 pb-0 divider-right">{product?.name || lines[0]?.productName || lines[0]?.item}</td>

                              {map(areasByAreaGroup, (item, key) => {
                                return item.areas.map((areaId, index: number) => {
                                  const isLastIndex = item.areas.length === index + 1;
                                  return (
                                    <td key={key + areaId + "-2"} className={`${isLastIndex ? "divider-right" : ""} text-center`}>
                                      {displayAmount(sumBy(filter(lines, { areaId }), "periodAmount")) || "*"}
                                    </td>
                                  );
                                });
                              })}

                              <td className="text-center">
                                {displayAmount(
                                  sumBy(
                                    filter(lines, (line) => {
                                      return areas?.[line.areaId];
                                    }),
                                    "periodAmount"
                                  )
                                ) || "*"}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td className="pl-6 pb-0 pr-0 pt-0 divider-right">
                            <div className="divider-top">
                              <i>Sub-total</i>
                            </div>
                          </td>

                          {map(areasByAreaGroup, (item, key) => {
                            return item.areas.map((areaId, index: number) => {
                              const isLastIndex = item.areas.length === index + 1;
                              return (
                                <td key={key + areaId + "-3"} className={`${isLastIndex ? "divider-right" : ""} divider-top text-center`}>
                                  {displayAmount(sumBy(filter(flattenedLinesByProductId, { areaId }), "periodAmount")) || "*"}
                                </td>
                              );
                            });
                          })}

                          <td className="divider-top text-center">
                            {displayAmount(
                              sumBy(
                                filter(flattenedLinesByProductId, (line) => {
                                  return areas?.[line.areaId];
                                }),
                                "periodAmount"
                              )
                            ) || "*"}
                          </td>
                        </tr>
                      </>
                    ) : null}

                    {Object.keys(items).length ? renderProductTreeByAreaReports(items, depth + 1) : null}
                  </>
                ) : null}
              </>
            );
          }}
        </ToggleWrapper>
      );
    });
  };

  return (
    <ContentWrapper>
      <ContentHeading headerText="Revenue by Product Tree by Area" showRestaurants />

      <Card className="card-default py-3 px-4">
        <Row>
          <Col sm={12} lg={4} className="py-2">
            <Row className="mb-4">
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">
                  <b>Products</b>
                </label>
              </Col>
              <Col sm={12} lg={8}>
                <Select
                  closeMenuOnSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  blurInputOnSelect={false}
                  options={map(productFilters, (item) => item)}
                  value={productFilters?.[productFilterValue]}
                  onChange={(e) => setProductFilterValue(e.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={4} className="d-flex align-items-center">
                <label className="mb-0">Filter</label>
              </Col>

              <Col sm={12} lg={8}>
                <TreeSelect
                  className="form-control tree-select"
                  style={{ width: "100%" }}
                  choiceTransitionName="rc-tree-select-selection__choice-zoom"
                  dropdownStyle={{ height: 250, overflow: "auto" }}
                  dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                  onDropdownVisibleChange={() => true}
                  treeLine
                  placeholder={<i>None</i>}
                  multiple
                  showIcon={false}
                  maxTagTextLength={25}
                  autoClearSearchValue
                  treeNodeFilterProp="title"
                  treeData={filterTreeData}
                  treeCheckable
                  treeDefaultExpandAll
                  value={filterTreeDataValue}
                  showCheckedStrategy={SHOW_PARENT}
                  maxTagCount={20}
                  onChange={(ids) => setFilterTreeDataValue(ids)}
                  maxTagPlaceholder={(valueList) => {
                    return `+${valueList.length}`;
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Form
            initialValues={initialValues}
            onSubmit={loadReports}
            validate={validate}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, values, form }) => {
              return (
                <>
                  <Col sm={12} lg={8} className="total-cells divider-top-cols">
                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center h-100 gap-sm">
                      <div className="mb-3 flex align-items-center gap-md">
                        <label className="m-0">
                          <b>Calendar</b>
                        </label>

                        <div className="d-flex gap-xl flex-wrap">
                          <Field name="calendar">
                            {({ input: { value, onChange } }) => (
                              <div className="form-group gap-md d-flex m-0">
                                {Object.values(calendarTypes).map((option, index) => (
                                  <label key={`render-units-${index}`} className="radio c-radio m-0 d-flex align-items-center">
                                    <input type="radio" value={option.value} checked={option.value === value} onChange={() => onChange(option.value)} />
                                    <span className="fa fa-circle" />
                                    {option.label}
                                  </label>
                                ))}
                              </div>
                            )}
                          </Field>

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="d-flex align-items-center gap-md">
                              <label className="mb-0">Mode</label>

                              <div style={{ width: 160 }}>
                                <Field name="mode">
                                  {({ input: { value, onChange }, meta }) => (
                                    <>
                                      <Select
                                        isSearchable={false}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        blurInputOnSelect={false}
                                        options={map(managementCalendarModes, (item) => item)}
                                        value={managementCalendarModes?.[value]}
                                        onChange={(e) => {
                                          onChange(e.value);

                                          form.change("period", undefined);
                                        }}
                                        placeholder="Select mode"
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex align-items-end gap-lg justify-content-between flex-wrap">
                        <div className="d-flex flex-grow-1 align-items-end gap-md">
                          {values.calendar == calendarTypes.dateRange.value ? (
                            <div className="d-flex align-items-start gap-md flex-grow-1">
                              <div className="form-group m-0 d-flex align-items-center gap-sm flex-grow-1">
                                <label htmlFor="dateFrom" className="mb-0 text-nowrap">
                                  Period
                                </label>

                                <Field name="dateFrom">
                                  {({ meta }) => (
                                    <>
                                      <DatePicker
                                        style={{ width: "auto" }}
                                        id="dateFrom"
                                        format="DD/MM/YYYY"
                                        calendarPosition="bottom"
                                        range
                                        value={[moment(values.dateFrom, "YYYY-MM-DD").toDate(), moment(values.dateTo, "YYYY-MM-DD").toDate()]}
                                        onChange={(e: DateObject[]) => {
                                          if (e.length === 2) {
                                            form.change("dateFrom", e[0].format("YYYY-MM-DD"));
                                            form.change("dateTo", e[1].format("YYYY-MM-DD"));
                                          }
                                        }}
                                      />

                                      {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="flex-grow-1" />
                            </div>
                          ) : null}

                          {values.calendar == calendarTypes.management.value ? (
                            <div className="flex-grow-1">
                              <div className="form-group mb-2 flex-grow-1">
                                <Field name="period">
                                  {({ input: { value, onChange }, meta }) => (
                                    <Row className="w-100 no-gutters">
                                      <Col sm={12} lg={3} className="d-flex align-items-center">
                                        <label htmlFor="period" className="mb-0">
                                          Period
                                        </label>
                                      </Col>

                                      <Col sm={12} lg={9}>
                                        <ManagementPeriodSelector
                                          onChangeYear={(e) =>
                                            onChange({
                                              ...value,
                                              year: e,
                                            })
                                          }
                                          onChangeMonth={(e) => {
                                            onChange({
                                              ...value,
                                              month: e,
                                            });
                                          }}
                                          onChangeWeek={(e) => {
                                            onChange({
                                              ...value,
                                              week: e,
                                            });
                                          }}
                                          calendar={calendar}
                                          mode={values.mode}
                                          value={value}
                                        />

                                        {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
                                      </Col>
                                    </Row>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ) : null}

                          <Button id="category-save" type="submit" className="fixed-width-btn d-flex justify-content-center align-items-center gap-sm" color="primary" disabled={submitting}>
                            {submitting ? <Spinner size="sm" /> : null} Generate Report
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Col>
                </>
              );
            }}
          />
        </Row>
      </Card>

      {isEmpty(filteredResults) ? null : (
        <>
          <div className="d-flex items-center gap-sm mb-2 justify-content-end">
            <Button onClick={handleExportToCSV} type="button" color="primary">
              Download CSV
            </Button>
            <Button onClick={handleExportToPDF} type="button" color="primary">
              Download PDF
            </Button>
          </div>

          <Card className="card-default py-2 px-4 overflow-auto">
            <Table borderless className="explorer-table fullWidth" innerRef={tableRef}>
              <thead>
                <tr>
                  <td width="260px" className="divider-bottom text-center"></td>

                  {map(areasByAreaGroup, (item, key) => {
                    return (
                      <td key={key} colSpan={item.areas.length} className="divider-bottom underline text-center">
                        {item.name}
                      </td>
                    );
                  })}

                  <td className="divider-bottom underline text-center">Totals</td>
                </tr>

                <tr>
                  <td className="divider-top divider-right text-center small"></td>

                  {map(areasByAreaGroup, (item, key) => {
                    return item.areas.map((areaId, index: number) => {
                      const area = areas[areaId];
                      const isLastIndex = item.areas.length === index + 1;

                      return (
                        <td key={key + areaId} className={`${isLastIndex ? "divider-right" : ""} text-center small`}>
                          {area.name}
                        </td>
                      );
                    });
                  })}

                  <td className="small text-underline text-center">Total</td>
                </tr>
              </thead>

              <tbody>
                {map(productGroupHierarchy, (productGroupKeys, productCategoryIdOrder) => {
                  const productCategoryId =
                    findKey(productCategoryOrder, (order) => {
                      return order === Number(productCategoryIdOrder);
                    }) || productCategoryIdOrder;

                  return (
                    <>
                      <tr>
                        <td className="divider-right font-weight-bold">
                          <u>{ProductCategoryId?.[productCategoryId] ? ProductCategoryId[productCategoryId].replace(/([a-z])([A-Z])/g, "$1 $2") : null}</u>
                        </td>

                        {map(areasByAreaGroup, (item, key) => {
                          return item.areas.map((areaId, index: number) => {
                            const isLastIndex = item.areas.length === index + 1;
                            return <td key={key + areaId + "-1"} className={`${isLastIndex ? "divider-right" : ""}`} />;
                          });
                        })}

                        <td />
                      </tr>

                      {renderProductTreeByAreaReports(productGroupKeys)}
                    </>
                  );
                })}

                <tr>
                  <td className="total-cells divider-top font-italic">Total Sales</td>

                  {map(areasByAreaGroup, (item, key) => {
                    return item.areas.map((areaId) => {
                      return (
                        <td key={key + areaId + "-4"} className="total-cells divider-top text-center">
                          {displayAmount(
                            sumBy(filteredResultListForTotalSum, (linesByProductId) => {
                              const flattenedLinesByProductId = flatMap(values(linesByProductId));
                              return sumBy(filter(flattenedLinesByProductId, { areaId }), "periodAmount");
                            })
                          ) || "*"}
                        </td>
                      );
                    });
                  })}

                  <td className="total-cells divider-top text-center">
                    {displayAmount(
                      sumBy(filteredResultListForTotalSum, (linesByProductId) => {
                        const flattenedLinesByProductId = flatMap(values(linesByProductId));
                        return sumBy(
                          filter(flattenedLinesByProductId, (line) => {
                            return areas?.[line.areaId];
                          }),
                          "periodAmount"
                        );
                      })
                    ) || "*"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};

export default RevenueReport;
