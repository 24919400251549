/* eslint-disable no-unused-vars */
import axios from "axios";
import "firebase/auth";
import firebase from "firebase/compat/app";
import _ from "lodash";
import { catchExceptionCallback, getConfig } from "./utils";
// import { BUTTON_SAVING, BUTTON_SAVED, BUTTON_ERROR } from "../components/Buttons/ActionTypes";

export function clearUsers() {
  _.forIn(window.localStorage, (_value, objKey) => {
    if (_.startsWith(objKey, "butler:data")) {
      window.localStorage.removeItem(objKey);
    }
  });
}

export async function loginCustomToken(customToken, clientId) {
  try {
    const configByEmailResponse = await axios.get(`${ADMIN_URL}/configByClient?clientId=${clientId}`);
    if (firebase.apps.length) {
      await firebase.app().delete();
    }
    firebase.initializeApp(configByEmailResponse.data.config);
    const userCredential = await firebase.auth().signInWithCustomToken(customToken);
    const functions = window.location.hostname === "localhost" && userCredential.user.displayName === "Paul de Wit" ? "http://localhost:5000/staging-60983/us-central1" : configByEmailResponse.data.functions;
    window.localStorage.setItem("butler:data", JSON.stringify(Object.assign(configByEmailResponse.data, { functions })));
    window.location.href = "/";
  } catch (error) {
    console.error(error);
    const query = new URLSearchParams(window.location.search);
    if (query.get("returnUrl")) {
      window.location.href = `/login?returnUrl=${query.get("returnUrl")}`;
    } else {
      window.location.href = "/login";
    }
  }
}

// eslint-disable-next-line no-undef
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
//export const ADMIN_URL = "https://us-central1-admin-13943.cloudfunctions.net";

export async function login(email, pw) {
  try {
    // eslint-disable-next-line no-undef
    const configByEmailResponse = await axios.post(`${ADMIN_URL}/configByEmail`, { email });
    clearUsers();
    if (firebase.apps.length) {
      await firebase.app().delete();
    }
    firebase.initializeApp(configByEmailResponse.data.config);
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, pw);
    const functions = window.location.hostname === "localhost" && userCredential.user.displayName === "Paul de Wit" ? `http://localhost:5000/${configByEmailResponse.data.config.projectId}/us-central1` : configByEmailResponse.data.functions;
    window.localStorage.setItem("butler:data", JSON.stringify(Object.assign(configByEmailResponse.data, { functions })));
    const userRecord = await firebase.auth().currentUser.getIdTokenResult();
    if (userRecord.claims.roles === 1) {
      const { tenantId } = getConfig();
      const snap = await firebase.database().ref(`${tenantId}/users`).child(userRecord.claims.user_id).once("value");
      const rootUser = snap.val() || {};
      window.location.href = `/mobile-seating?restaurantId=${rootUser.defaultRestaurant}`;
    } else {
      const query = new URLSearchParams(window.location.search);
      if (query.get("returnUrl")) {
        window.location.href = query.get("returnUrl");
      } else {
        window.location.href = "/";
      }
    }
  } catch (error) {
    catchExceptionCallback(error);
  }
}
