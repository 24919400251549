import { catchExceptionCallback, getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { pickBy } from "lodash";
import { useEffect, useState } from "react";
import { MenuCategories } from "resbutler-utils/types/Menu";

export default function useMenuCategories(restaurantId): [boolean, MenuCategories] {
  const [categories, setCategories] = useState<MenuCategories>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { tenantId } = getConfig();
        const snap = await firebase.database().ref(`${tenantId}/menuCategories`).orderByChild("restaurantId").equalTo(restaurantId).once("value");
        setCategories(pickBy(snap.val() as MenuCategories, (category) => category.enabled));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return [loading, categories];
}
