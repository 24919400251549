import { getEnabledProductGroups } from "RootReducer";
import classNames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import useCrudServices from "components/Hooks/useCrudServices";
import { catchExceptionCallback, getConfig } from "core/utils";
import arrayMutators from "final-form-arrays";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import CreateEditForm from "function-menus/CreateEditForm";
import Pricing from "function-menus/Pricing";
import MenuItem, { createDynamicGroupHeadings, createStaticGroupHeadings } from "menus-and-pricing/menu-heading/MenuItem";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import { GroupHeadings, MenuHeadings, Package } from "resbutler-utils/types/Menu";
import { ProductSizes } from "resbutler-utils/types/product";

const PackageModalForm = (props: { packageId?: string; isOpen?: boolean; selectedPackage: Package; toggle?: any; firebasePackageName?: string; isNew?: boolean; productSizes: ProductSizes; menuHeadings: MenuHeadings }) => {
  const [tabs, setTabs] = useState(1);
  const { packageId, isOpen, selectedPackage, toggle, firebasePackageName, isNew, productSizes, menuHeadings } = props;
  const [, entities] = useCrudServices("", "functionServiceStyles");
  const [removedProducts, setRemovedProducts] = useState({});
  const { products, productGroups, restaurantId } = useAppSelector((state) => ({
    products: state.root.products,
    productGroups: getEnabledProductGroups(state.root.productGroups),
    restaurantId: state.root.restaurantId,
  }));
  const [initialValues, setInitialValues] = useState<Package>({ restaurantId, enabled: true, ...selectedPackage });

  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.minPax) {
      errors.minPax = "Min pax should not be empty";
    }
    if (!values.maxPax) {
      errors.maxPax = "Max pax should not be empty";
    }
    if (values.minPax > values.maxPax) {
      errors.minPax = "Min pax cannot exceed max pax value";
    }
    if (!values.name) {
      errors.name = "Package Name should not be empty";
    }
    if (!values.priceType) {
      errors.priceType = "Price Type should not be empty";
    }
    return errors;
  };

  useEffect(() => {
    if (selectedPackage?.[props.firebasePackageName]?.groupHeadings) {
      for (const groupHeadingId in selectedPackage?.[props.firebasePackageName]?.groupHeadings) {
        const gh = selectedPackage?.[props.firebasePackageName]?.groupHeadings[groupHeadingId];
        if (menuHeadings[gh.menuHeadingId].type === "static") {
          createStaticGroupHeadings(menuHeadings[gh.menuHeadingId], gh, gh.groupHeadingType, gh.menuHeadingId);
        } else {
          setRemovedProducts(createDynamicGroupHeadings(menuHeadings[gh.menuHeadingId], gh, groupHeadingId, productGroups, products, productSizes, gh.groupHeadingType, gh.menuHeadingId));
        }
      }
      setInitialValues(selectedPackage);
    }
  }, []);

  const onSave = async (values: Package) => {
    try {
      const { client } = getConfig();
      await firebase.firestore().doc(`${client}/functionAdditionals/${firebasePackageName}Packages/${packageId}`).set(values);
      toggle();
      toast.success(`Saved`, { position: toast.POSITION.BOTTOM_RIGHT });
    } catch (error) {
      console.log(error);
      catchExceptionCallback(error);
    }
  };

  return (
    <Modal isOpen={isOpen === true} size="xl" toggle={toggle} scrollable>
      <Form<Package>
        initialValues={initialValues}
        onSubmit={onSave}
        validate={validate}
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values, valid, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader toggle={toggle}>{isNew ? "Create New Package" : `Edit ${selectedPackage?.name}`}</ModalHeader>
              <ModalBody>
                <div className="col-md-12">
                  <Nav tabs>
                    <NavItem>
                      <NavLink className={classNames({ active: tabs === 1 })} onClick={() => setTabs(1)}>
                        Create/Edit
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink disabled={!valid} className={classNames({ active: tabs === 2 })} onClick={() => setTabs(2)}>
                        Menu Items
                      </NavLink>
                    </NavItem>
                    {values.priceType == "fixedProductPrice" ? (
                      <NavItem>
                        <NavLink disabled={!valid} className={classNames({ active: tabs === 3 })} onClick={() => setTabs(3)}>
                          Pricing
                        </NavLink>
                      </NavItem>
                    ) : null}
                  </Nav>
                  <TabContent className="p-4" id="menu-create-edit" activeTab={tabs}>
                    <TabPane tabId={1}>
                      <CreateEditForm serviceStyles={entities} menuPackage={values} packageName={props.firebasePackageName} functionPackageType="functionAdditionals" />
                    </TabPane>
                    <TabPane tabId={2}>
                      <MenuItem
                        groupHeadings={values[props.firebasePackageName]?.groupHeadings as GroupHeadings}
                        menuOrPackage={values}
                        type={props.firebasePackageName as any}
                        path={props.firebasePackageName}
                        pkg
                        productSizes={productSizes}
                        menuHeadings={menuHeadings}
                        removedProducts={removedProducts}
                        functionPackageType="functionAdditionals"
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <Pricing />
                    </TabPane>
                  </TabContent>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
                <Button type="submit" disabled={submitting} color="success" className="float-right">
                  Save {submitting && <Spinner size="sm" />}
                </Button>
                <div className="clearfix" />
              </ModalFooter>
            </form>
          );
        }}
      ></Form>
    </Modal>
  );
};

export default PackageModalForm;
