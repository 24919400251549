// So we can create dropzones programmatically
//Dropzone.autoDiscover = false;
import Dropzone from 'dropzone';

let dzHandler = {};

export function unmountGroupLogo() {
    dzHandler = {};
}

function removeWatermark() {
    for (let i = 0; i < document.getElementsByClassName('dz-success-mark').length; i++) {
        const el = document.getElementsByClassName('dz-success-mark')[i];
        el.setAttribute('style', 'display: none');
    }
    for (let i = 0; i < document.getElementsByClassName('dz-error-mark').length; i++) {
        const el = document.getElementsByClassName('dz-error-mark')[i];
        el.setAttribute('style', 'display: none');
    }
}

export default (dispatch, settings, { saveGroupLogo, deleteGroupLogo }, id, width) => {
    // Dropzone settings
    if (dzHandler[id] === undefined && Dropzone) {
        dzHandler[id] = {};
        new Dropzone(`div#${id}`, {
            autoProcessQueue: false,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: 1,
            dictDefaultMessage: '<em class="ion-upload text-info icon-2x"></em>Drop files here to upload', // default messages before first drop
            paramName: 'file', // The name that will be used to transfer the file
            maxFilesize: 20, // MB
            thumbnailWidth: width,
            thumbnailHeight: null,
            addRemoveLinks: true,
            accept: function(file, done) {
                done();
            },
            init: function() {
                dzHandler[id] = this;
                dzHandler[id].on('addedfile', () => {
                    if (dzHandler[id].files[1] != null) {
                        dzHandler[id].removeFile(dzHandler[id].files[0]);
                    }
                });
                dzHandler[id].on('removedfile', (file) => {
                    file.previewElement.remove();
                    dispatch(deleteGroupLogo());
                });
                dzHandler[id].on('thumbnail', (file, thumb) => {
                    file.thumbnail = thumb;
                    if (file.upload) {
                        removeWatermark()
                        dispatch(saveGroupLogo(file, id));
                    }
                });
                dzHandler[id].on('error', (file) => {
                    if (file.accepted) {
                        let mypreview = document.getElementsByClassName('dz-error');
                        mypreview = mypreview[mypreview.length - 1];
                        mypreview.classList.toggle('dz-error');
                    }
                });
                const mockFile = {
                    name: settings.name,
                    size: settings.sizeFile,
                };
                // dzHandler.createThumbnailFromUrl(mockFile, thumbnailUrls[i]);
                // And optionally show the thumbnail of the file:
                dzHandler[id].files.push(mockFile);
                dzHandler[id].emit('addedfile', mockFile);
                dzHandler[id].emit('thumbnail', mockFile, settings.thumbUrl);
                dzHandler[id].emit('complete', mockFile);
                for (let i = 0; i < document.getElementsByClassName('dz-success-mark').length; i++) {
                    const el = document.getElementsByClassName('dz-success-mark')[i];
                    el.setAttribute('style', 'display: none');
                }
                for (let i = 0; i < document.getElementsByClassName('dz-error-mark').length; i++) {
                    const el = document.getElementsByClassName('dz-error-mark')[i];
                    el.setAttribute('style', 'display: none');
                }
            },
        });
    }
};

