/**
 * Types
 */
export const MembershipLevelTypes = {
  corporate: { name: "Corporate" },
  personal: { name: "Personal" },
};

export const getMembershipDisplayName = (v) => (MembershipLevelTypes[v] ? MembershipLevelTypes[v].name : "");

/**
 * Validator
 */
export const validate = ({ accountName, membershipType, allocationPriority, ...rest }, levels = []) => {
  const errors = {} as any;
  if (!accountName) errors.accountName = "Please enter a name";
  if (!membershipType) errors.membershipType = "Please enter a membership type";
  if (!allocationPriority) errors.allocationPriority = "Please enter an allocation priority";

  if (rest?.default) {
    const hasAnotherDefaultLevels = levels.some((level) => level?.default === true);

    if (hasAnotherDefaultLevels) {
      errors.default = "A default membership level has already been defined.";
    }
  }

  return errors;
};
