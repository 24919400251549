import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { loadingBarReducer } from "react-redux-loading-bar";

// maestro
import runSheet from "maestro/RunSheetReducer";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import buttonsReducer from "components/Buttons/Reducer";
import loginReducer from "LoginReducer";
import rootReducer from "RootReducer";
import restaurants from "restaurant/RestaurantReducer";
import widgets from "booking-channel/WidgetReducer";
import marketing from "advertising-promotions/AdvertisingReducer";
import insightsReducer from "Insights/insightsReducer";

// crm
import customers from "crm/CustomerReducer";

// loyalty
import levels from "loyalty/membership-levels/LevelReducer";
import subscriptionDetails from "system/subscription-details/SubscriptionDetailsReducer";
import membershipOffers from "../../loyalty/member-offers/MembershipOffersReducer";

import giftCertificates from "gift-certificates/GiftCertificateReducer";
import giftCertificateLinkBuilder from "gift-certificates/widget/GiftCertificateWidgetLinkBuilder/GiftCertificateLinkBuilderReducer";
import menus from "menus-and-pricing/MenuReducer"
import clients from "admin/client/ClientReducer";
import customersTags from "customer-tags/CustomerTagReducer"

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  buttons: buttonsReducer,
  login: loginReducer,
  giftCertificates,
  root: rootReducer,
  levels,
  restaurants,
  widgets,
  marketing,
  loadingBar: loadingBarReducer,
  giftCertificateLinkBuilder,
  clients,
  customers,
  runSheet,
  subscriptionDetails,
  insights: insightsReducer,
  form: formReducer,
  membershipOffers,
  menus,
  customersTags
});
