// So we can create dropzones programmatically
//Dropzone.autoDiscover = false;
import Dropzone from 'dropzone';

let dzHandler = {};

export function unmountGallery() {
    dzHandler = {};
}

function removeWatermark() {
    for (let i = 0; i < document.getElementsByClassName('dz-success-mark').length; i++) {
        const el = document.getElementsByClassName('dz-success-mark')[i];
        el.setAttribute('style', 'display: none');
    }
    for (let i = 0; i < document.getElementsByClassName('dz-error-mark').length; i++) {
        const el = document.getElementsByClassName('dz-error-mark')[i];
        el.setAttribute('style', 'display: none');
    }
}

export default (dispatch, image, { saveImage, removeImage }, id) => {
    // Dropzone settings
    if (dzHandler[id] === undefined && Dropzone && document.getElementById(id)) {
        dzHandler[id] = {};
        new Dropzone(`#${id}`, {
            autoProcessQueue: false,
            uploadMultiple: false,
            parallelUploads: 100,
            maxFiles: 1,
            dictDefaultMessage: '',
            paramName: 'file', // The name that will be used to transfer the file
            maxFilesize: 20, // MB
            thumbnailWidth: null,
            thumbnailHeight: 70,
            addRemoveLinks: true,
            accept: function(file, done) {
                done();
            },
            init: function() {
                dzHandler[id] = this;
                dzHandler[id].on('addedfile', () => {
                    // if (dzHandler[id].files[1] != null) {
                    //    // dispatch(removeImage(id, false));
                    //     dzHandler[id].files[1].previewElement.remove();
                    // }
                });
                dzHandler[id].on('removedfile', () => {
                 //   file.previewElement.remove();
                    dispatch(removeImage(id));
                 //   dispatch(action.removeFile(`email-form-${widgetId}`, field, template));
                });
                dzHandler[id].on('thumbnail', (file, thumb) => {
                    if (dzHandler[id].files.length === 1) {
                        file.thumbnail = thumb;
                        if (file.upload) {
                            removeWatermark()
                            dispatch(saveImage(file, id));
                        }
                    } else {
                        file.previewElement.remove();
                    }
                });
                dzHandler[id].on('error', () => {
                });
                // dzHandler.createThumbnailFromUrl(mockFile, thumbnailUrls[i]);
                // And optionally show the thumbnail of the file:
                if (image.url) {
                    const mockFile = {
                        name: image.thumbName,
                        size: image.meta.size,
                    };
                    // dzHandler.createThumbnailFromUrl(mockFile, thumbnailUrls[i]);
                    // And optionally show the thumbnail of the file:
                    dzHandler[id].files.push(mockFile);
                    dzHandler[id].emit('addedfile', mockFile);
                    dzHandler[id].emit('thumbnail', mockFile, image.url);
                    dzHandler[id].emit('complete', mockFile);
                    removeWatermark();           
                }
            },
        });
    }
};

