import axios from "axios";
import classnames from "classnames";
import firebase from "firebase/compat/app";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import { catchExceptionCallback } from "../../../resbutler-ui/utilities";
import { GiftCertificate as IGiftCertificate } from "../../../resbutler-utils/types/GiftCertificate";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { Transaction } from "../../../resbutler-utils/types/transaction";
import IssuedGiftCertificatesDetails from "./IssuedGiftCertificatesDetails";
import IssuedGiftCertificatesRestaurants from "./IssuedGiftCertificatesRestaurants";

export interface IGiftCertificatesModalProps {
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  giftCertificateId: string;
  giftCertificate?: IGiftCertificate;
  save?: any;
  restaurants: Restaurants;
  clientId: string;
  resbutlerApis: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GiftCertificateModals: React.FC<IGiftCertificatesModalProps> = ({ giftCertificateId, giftCertificate, restaurants, setOpen, setModalOpen, resbutlerApis, clientId }) => {
  const [tabs, setTabs] = useState(1);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [client, setClient] = useState(null);
  const [loadingClient, setLoadingClient] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        setLoadingClient(true);
        const uninterceptedAxiosInstance = axios.create();
        const response = await uninterceptedAxiosInstance.get(`${process.env.REACT_APP_ADMIN_REALTIME}/clients/${clientId}.json`);
        setClient(response.data);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoadingClient(false);
      }
    }
    load();
  }, [clientId]);

  const onDownloadGC = async ({ restaurantId }) => {
    try {
      setDownloading(true);
      const url = `${process.env.REACT_APP_ADMIN_URL}/schedule/giftCertificateEmailVoucher`;
      const uninterceptedAxiosInstance = axios.create();
      const token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
      const response = await uninterceptedAxiosInstance.post(
        url,
        {
          restaurantId,
          giftCertificateId,
        },
        { headers: { "client-id": clientId, authorization: `Bearer ${token}` } }
      );
      const { emailPdf } = response.data;
      const link = document.createElement("a");
      link.innerHTML = "Download PDF file";
      link.download = "gift-certificate.pdf";
      link.href = "data:application/octet-stream;base64," + emailPdf;
      link.click();
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setDownloading(false);
    }
  };

  const saveGiftCertificate = async (values) => {
    const sanitizedValues = _.pickBy(
      {
        ...values,
      },
      (v) => v !== undefined
    );
    try {
      setLoading(true);
      await Promise.all([firebase.database().ref(`${client.tenantId}/giftCertificates/${giftCertificateId}`).update(sanitizedValues), firebase.firestore().doc(`${clientId}/crm/giftCertificates/${giftCertificateId}`).set(sanitizedValues, { merge: true })]);
      toast.success(`Gift certificate saved successfully`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
      setModalOpen(false);
      setOpen(false);
    }
  };

  if (loadingClient) return null;

  return (
    <Form<IGiftCertificate>
      initialValues={{
        ...giftCertificate,
      }}
      keepDirtyOnReinitialize
      onSubmit={(values) => saveGiftCertificate(values)}
      render={({ handleSubmit, form, submitting }) => (
        <Modal isOpen size="xl" toggle={() => setModalOpen(false)} zIndex={9999} scrollable>
          <form onSubmit={handleSubmit} className="modal-content">
            <ModalHeader toggle={() => setModalOpen(false)} tag="h3">
              Gift Certificate Details
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={12}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink className={classnames({ active: tabs === 1 })} onClick={() => setTabs(1)}>
                        <span className="px-1">Details</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: tabs === 2 })} onClick={() => setTabs(2)}>
                        <span className="px-1">Restaurants</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent id="issued-gift-certificate-details" activeTab={tabs} className={tabs !== 1 ? "hide-tab" : ""}>
                    <TabPane tabId={1}>{tabs === 1 ? <IssuedGiftCertificatesDetails giftCertificate={giftCertificate} setModalOpen={setModalOpen} giftCertificateId={giftCertificateId} clientId={clientId} resbutlerApis={resbutlerApis} restaurants={restaurants} /> : null}</TabPane>
                  </TabContent>
                  <TabContent id="issued-gift-certificate-restaurant" activeTab={tabs} className={tabs !== 2 ? "hide-tab" : ""}>
                    <TabPane tabId={2}>{tabs === 2 ? <IssuedGiftCertificatesRestaurants giftCertificateId={giftCertificateId} restaurants={restaurants} giftCertificate={giftCertificate} /> : null}</TabPane>
                  </TabContent>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" onClick={() => onDownloadGC(giftCertificate)}>
                Download Gift Certificate {downloading && <Spinner size="sm" />}
              </Button>{" "}
              <Button
                type="button"
                color="light"
                onClick={() => {
                  setModalOpen(false);
                  setOpen(false);
                }}
              >
                Close
              </Button>
              <Button type="submit" color="success" onClick={() => form.submit()}>
                Save {(submitting || loading) && <Spinner size="sm" />}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    />
  );
};

export interface IGiftCertificateModalProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  style?: any;
  giftCertificate?: IGiftCertificate;
  transaction: Transaction;
  handleSubmit?: any;
  onDownloadGC?: any;
  showModalTransaction?: any;
  deductAmountModal?: boolean;
  setDeductAmountModal?: React.Dispatch<React.SetStateAction<boolean>>;
  downLoading?: boolean;
  customerNavLink?: any;
  recipientNavLink?: any;
  clientId: string;
  resbutlerApis: string;
}

