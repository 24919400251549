import "core-js/es6/array";
import "core-js/es6/map";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es6/set";
import "core-js/es6/string";
import "core-js/es7/array";
import "core-js/es7/object";
import "raf/polyfill";

import { getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "store/store";
import App from "./App";

const data = getConfig();

if (data) {
  firebase.initializeApp(data.config);
} else {
  const history = createBrowserHistory();
  if (window.location?.pathname === "/challenge") {
    history.push(`/challenge${window.location.search}`);
  } else {
    history.push(`/login${window.location.search}`);
  }
}

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
