import React from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { Row, Col, Card, CardBody, FormGroup, Label, Input } from "reactstrap";
import { Component } from "react";
import { getClientWidgetLink } from "./GiftCertificateLinkBuilderAction";
import { requiredDropdown } from "core/react-utils";
import Save from "components/Buttons/Save";
import * as types from "./ActionTypes";

export const widgetTypes = [
  { id: 1, displayName: "Group Widget", key: "group" },
  { id: 2, displayName: "Restaurant Widget", key: "restaurant" },
];

const getRestaurantName = (restaurants, restaurantId) => {
  if (restaurants && restaurantId && restaurants[restaurantId]) {
    return restaurants[restaurantId].name;
  }
  return "Choose Restaurant";
};
export class GiftCertificateLinkBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeWidgetType: "",
    };
  }
  static handleSubmit(dispatch, values) {
    dispatch(getClientWidgetLink(values, "widget-builder-save"));
  }

  validate(values) {
    const errors = {};
    if (!values.restaurantId && values.widgetType === "restaurant") {
      errors.restaurantId = "Please select a restaurant";
    }
    return errors;
  }

  onSelect(form, e) {
    form.change("widgetType", e.target.value);
    this.setState({ changeWidgetType: e.target.value });
    if (e.target.value === "group") {
      form.change("restaurantId", undefined);
    }
    const clientWidgetLink = "",
      clientCodeBlock = "",
      clientButtonLink = "",
      clientjQueryCode = "",
      clientJavaScriptCode = "",
      clientHtmlCode = "";
    this.props.dispatch({ type: types.GET_CLIENT_BUILDER_WIDGET_LINK, clientWidgetLink, clientCodeBlock, clientButtonLink, clientjQueryCode, clientJavaScriptCode, clientHtmlCode });
  }

  render() {
    const { clientWidgetLink, clientCodeBlock, clientjQueryCode, clientJavaScriptCode, clientHtmlCode, restaurants } = this.props;
    let disabled = this.state.changeWidgetType === widgetTypes[0].key ? true : false;
    return (
      <>
        <Form
          initialValues={this.props.initialValues}
          onSubmit={(values) => GiftCertificateLinkBuilder.handleSubmit(this.props.dispatch, values)}
          validate={this.validate}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit}>
              <Card className="card-default">
                <CardBody>
                  <Row>
                    <Col className="mx-1">
                      <div className="ml-2">
                        <label>Create a Group Widget or a Restaurant Widget</label>
                        {widgetTypes.map((p) => (
                          <FormGroup key={`widget-type-${p.key}`} check>
                            <Label check>
                              <Input type="radio" name="gcType" value={p.key} onChange={(e) => this.onSelect(form, e)} /> {/* this.setState({ paymentGateway: p}) */}
                              {p.displayName}
                            </Label>
                          </FormGroup>
                        ))}
                      </div>
                      <Field
                        id="restaurants1"
                        name="restaurantId"
                        disabled={disabled}
                        component={requiredDropdown}
                        helpText="The restaurant applicable to this widget link"
                        optionalAsterisk={false}
                        getName={getRestaurantName}
                        className="form-group"
                        label="Restaurant"
                        types={restaurants || {}}
                        onClick={(e) => form.change("restaurantId", e)}
                      />
                      <div className="form-group">
                        <label htmlFor="widget-builder-save" />
                        <Save id="widget-builder-save" type="submit" color="primary" className="float-right" text="Generate Widget Link" disabledText="Saving" />
                      </div>
                    </Col>
                  </Row>

                    <div className="form-group">
                      <label htmlFor="widgetLink1">
                        <b>Option 1: Gift Certificate Widget Link</b>
                        <br />
                        Link to the gift certificate Widget webpage
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="widgetLink1">Gift Certificate Widget Link</label>
                      <textarea id="widgetLink1" rows={3} cols={4} value={clientWidgetLink} readOnly className="form-control"></textarea>
                      <div className="help-text">Add this link to your booking button to open up the required gift certificate on a webpage</div>
                    </div>
                </CardBody>
              </Card>
                {/*  <Card className="card-default">
                                    <CardBody>
                                    <div className="form-group">
                                        <label htmlFor="widgetPopup1"><b>Option2: Gift Certificate</b><br />Add a gift certificate popup on your webpage</label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="codeBlock1">Code Block</label>
                                        <textarea id="codeBlock1" rows={6} cols={4} value={clientCodeBlock} readOnly className="form-control"></textarea>
                                        <div className="help-text">Add this code block before the end of the body tag on your webpage</div>
                                    </div>
                                    </CardBody>
                                    </Card> */}
                <Card className="card-default">
                  <CardBody>
                    <div className="form-group">
                      <label htmlFor="widgetPopup1">
                        <b>Option 2: Widget Popup</b>
                        <br />
                        Add a booking widget popup on your webpage
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold w-100">Step 1</label>
                      <label className="w-100">Add this code block before the end of the body tag on your webpage</label>
                      <label htmlFor="codeBlock1">Code Block</label>
                      <textarea id="codeBlock1" rows={6} cols={4} value={clientCodeBlock} readOnly className="form-control"></textarea>
                      {/*  <div className="help-text">Add this code block before the end of the body tag on your webpage</div> */}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold w-100">Step 2</label>
                      <label className="w-100">Add one of the following three options on the script file or as a html attribute:</label>
                      <label className="pt-1" htmlFor="buttonLink1">
                        JQuery
                      </label>
                      <textarea id="buttonLink1" rows={4} cols={4} value={clientjQueryCode} readOnly className="form-control"></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="buttonLink1 w-100">JavaScript</label>
                      <textarea id="buttonLink1" rows={4} cols={4} value={clientJavaScriptCode} readOnly className="form-control"></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="buttonLink1">HTML Attribute</label>
                      <textarea id="buttonLink1" rows={3} cols={4} value={clientHtmlCode} readOnly className="form-control"></textarea>
                    </div>
                  </CardBody>
                </Card>
              </form>
            );
          }}
        ></Form>
      </>
    );
  }
}

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(GiftCertificateLinkBuilder);
