import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from "reactstrap";
import { TillInputField } from "../../../resbutler-ui/components/Till/TillInput";

const SendEmailModal = ({ onSend, loading, setSendEmailModal }) => {
  return (
    <Modal size="lg" scrollable isOpen zIndex={9999}>
      <Form
        initialValues={{}}
        validate={(v) => {
          const errors: any = {};
          if (!v.email) errors.email = "Required";
          return errors;
        }}
        onSubmit={(values) => {
          onSend(values.email);
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader toggle={() => setSendEmailModal(false)}>
                <div className="text-center">Send Email</div>
              </ModalHeader>
              <ModalBody className="ps-3 pe-3 pb-1 pt-3">
                <>
                  <Table bordered responsive striped className="mb-0">
                    <tbody>
                      <>
                        <tr>
                          <td>Email</td>
                          <td>
                            <Field id="email" name="email" type="email" disabled={loading} className="form-select" component={TillInputField as any} onScreenKeyboardEnabled={window.localStorage.getItem("onScreenKeyboard") === "true"} />
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </Table>
                </>
              </ModalBody>
              <ModalFooter>
                <Button disabled={loading} type="button" className="col-lg-2 btn-lg text-nowrap" onClick={() => setSendEmailModal(false)}>
                  Close{loading && <Spinner type="grow" size="sm" />}
                </Button>
                <Button disabled={loading} className="col-lg-2 btn-lg text-nowrap" type="submit">
                  Send {loading && <Spinner type="grow" size="sm" />}
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default SendEmailModal;
