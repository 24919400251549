import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from "components/Layout/ContentWrapper";
import ContentHeading from "components/Layout/ContentHeading";
import { getRestaurants } from "system/RestaurantReducer";
import GiftCertificatesSettings from "./GiftCertificatesSettings";
import { getGiftCertficateSettings, getGiftCertficateRestaurantSettings,getRestaurantImages } from "../GiftCertificateAction";
import * as types from "gift-certificates/ActionTypes";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import GiftCertificateRestaurantSettings from "./GiftCertificateRestaurantSettings";

class Default extends Component {
  componentDidMount() {
    this.props.dispatch(getGiftCertficateSettings());
    this.props.dispatch(getGiftCertficateRestaurantSettings(this.props.restaurantId));
    this.props.dispatch(getRestaurantImages(this.props.restaurantId));

  }

  render() {
    const { restaurantSettings, settings, tab, restaurants } = this.props;
    return (
      <ContentWrapper>
        <ContentHeading parentText="Gift Certificates" headerText="Gift Certificate Settings" showRestaurants={false} />
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: tab === 1 }) + " px-2"} onClick={() => this.props.dispatch({ type: types.GIFT_CERTIFICATE_FORM_TAB, formTab: 1 })}>
              Group Gift Certificates
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: tab === 2 }) + " px-2"} onClick={() => this.props.dispatch({ type: types.GIFT_CERTIFICATE_FORM_TAB, formTab: 2 })}>
              Restaurant Gift Certificates
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent id="gift-certificate-create-edit" activeTab={tab}>
          <TabPane tabId={1}>{settings ? <GiftCertificatesSettings settings={settings} /> : null}</TabPane>
          <TabPane tabId={2}>{tab === 2 ? <GiftCertificateRestaurantSettings restaurantSettings={restaurantSettings} restaurants={restaurants} /> : null}</TabPane>
        </TabContent>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurants: getRestaurants(state.root.restaurants, state.root.user),
    restaurantId: state.root.restaurantId,
    settings: state.giftCertificates.settings,
    restaurantSettings: state.giftCertificates.restaurantSettings,
    tab: state.giftCertificates.formTab,
  };
}

export default connect(mapStateToProps)(Default);
