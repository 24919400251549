import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, FormGroup, Input, Label, Spinner } from "reactstrap";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const MembershipAppSettingsFormCore = ({ restaurantId }) => {
  const { client } = getConfig();

  // presentational states
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // form data
  const [termsAndConditions, setTerms] = useState("");
  const [useGroupWebsite, setUseGroupWebsite] = useState(true); // restaurant only field ?

  // location for firebase data (group / restaurant)
  const firebaseRef = restaurantId ? `${client}/crm/settings/${restaurantId}` : `${client}/crm`;
  //const logoStoreLocation = restaurantId ? `${client}/images/restaurants/${restaurantId}/logo.png` : `${client}/images/logo.png`;

  useEffect(() => {
    const _ = async () => {
      setLoading(true);
      const ref = await firebase.firestore().doc(firebaseRef).get();
      const { useGroupWebsite = true, termsAndConditions = "" } = ref.data() || {
        useGroupWebsite: true,
        termsAndConditions: "",
      };

      // load existing data
      setUseGroupWebsite(useGroupWebsite);
      setTerms(termsAndConditions);
      setLoading(false);
    };
    _().then();
  }, [restaurantId]);

  const submit = async () => {
    try {
      setSaving(true);

      const toUpdate = {
        useGroupWebsite,
        termsAndConditions,
      };
      await firebase.firestore().doc(firebaseRef).set(toUpdate, { merge: true });
      setSaving(false);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  return (
    <>
      <Card className="card-default">
        <CardBody>
          {/* Rich text editors */}
          <div className="form-group">
            <label htmlFor="wysiwig-editor">Terms and Conditions</label>
            <SunEditor
              setContents={termsAndConditions}
              setOptions={{
                height: 200,
                buttonList: [...buttonList.formatting, ["font", "fontSize", "align"]],
              }}
              onChange={setTerms}
            />
          </div>

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={useGroupWebsite}
                onChange={() => {
                  setUseGroupWebsite(!useGroupWebsite);
                }}
              />{" "}
              Use Group Website
            </Label>
          </FormGroup>
        </CardBody>

        <CardFooter>
          <Button color="success" className="float-right" disabledtext="Saving" onClick={submit} disabled={saving}>
            {saving ? <Spinner size="sm" /> : "Save"}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default MembershipAppSettingsFormCore;
