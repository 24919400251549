import classNames from "classnames";
import ProductList from "components/Product/ProductList";
import { chain, keys } from "lodash";
import { InclusionOptions, getRelativeParentGroups } from "menus-and-pricing/menu-heading/MenuItemDynamic";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { Button, Col, Row } from "reactstrap";
import { BeverageMenuPackage, GroupHeadingProducts, GroupHeadings, Menu, MenuHeading, Package } from "resbutler-utils/types/Menu";
import { ProductSizes } from "resbutler-utils/types/product";
import "./menu-heading-item.scss";

// export function getRelativeParentGroups(subheadings: SubHeadings, subHeadingId: string, groups: string[] = []) {
//   groups.push(subheadings[subHeadingId].name);
//   if (subheadings[subHeadingId].subheadings) {
//     for (const subHeadingId1 in subheadings[subHeadingId].subheadings) {
//       getRelativeParentGroups(subheadings[subHeadingId].subheadings, subHeadingId1);
//     }
//   }
// }

function RenderMenuHeadings({
  menuOrPackage,
  pathToGroupHeadings,
  currentGroupHeadings,
  isInclusion,
  menuHeading,
  sizes,
  level = 1,
  pkg = false,
  isInclusionFoodOrDrink,
}: {
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  pathToGroupHeadings: string;
  currentGroupHeadings: GroupHeadings;
  isInclusion: boolean;
  menuHeading: MenuHeading;
  sizes: ProductSizes;
  level?: number;
  pkg?: boolean;
  isInclusionFoodOrDrink?: boolean;
}) {
  const [collapseSubGroupHeadingId, setCollapseSubGroupHeadingId] = useState("");
  const breadCrumbs = [];
  getRelativeParentGroups(menuOrPackage, pathToGroupHeadings, breadCrumbs);
  const { inclusionsAtSubheadingLevel, inclusionSubheadingType } = menuHeading;
  if (keys(currentGroupHeadings).length > 0) {
    return (
      <>
        {chain(currentGroupHeadings)
          .map((v, id) => ({ ...v, id }))
          .sortBy("order")
          .value()
          .map((currentGroupHeading) => {
            const groupHeadingId1 = currentGroupHeading.id;
            const breadCrumbs1 = [...breadCrumbs, currentGroupHeadings[groupHeadingId1].displayName];
            return (
              <React.Fragment key={groupHeadingId1}>
                <span className="separator" />
                <div style={{ paddingLeft: 20 * level }}>
                  <Row>
                    <Col>
                      <Row>
                        <Col lg={12} className="d-flex align-items-center">
                          <Button type="button" className="mr-2" onClick={() => setCollapseSubGroupHeadingId(collapseSubGroupHeadingId === groupHeadingId1 ? "" : groupHeadingId1)}>
                            <i className={classNames("fa", { "fa-chevron-right": collapseSubGroupHeadingId !== groupHeadingId1, "fa-chevron-down": collapseSubGroupHeadingId === groupHeadingId1 })} aria-hidden="true" />
                          </Button>
                          <p className="text-bold mb-0">
                            {currentGroupHeadings[groupHeadingId1].displayName}
                            <small className="d-block">
                              {breadCrumbs1.map((b) => (
                                <React.Fragment key={b}>
                                  <i className="fa fa-chevron-right icon-smaller" aria-hidden="true"></i>&nbsp;{b}&nbsp;
                                </React.Fragment>
                              ))}
                            </small>
                          </p>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <div className="float-right">
                        <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`} type="checkbox">
                          {({ input: { onChange, checked } }) => (
                            <div className="checkbox c-checkbox">
                              <label className="m-0" htmlFor={`enabled-${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`}>
                                <input id={`enabled-${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`} type="checkbox" className="form-check-input" onChange={(e) => onChange(e.target.checked)} checked={checked} />
                                <span className="fa fa-check"></span>
                                Enable Subheading
                              </label>
                            </div>
                          )}
                        </Field>
                      </div>
                      <div />
                    </Col>
                  </Row>
                  {/* get(values, `${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.isEnableSubheading`) && */}
                  {collapseSubGroupHeadingId === groupHeadingId1 && (
                    <div className="mt-3">
                      {keys(currentGroupHeadings[groupHeadingId1].groupHeadings).length === 0 ? (
                        <Row>
                          <Col lg={6}>
                            <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.numberOptions`} type="number" parse={(value) => Number(value)}>
                              {({ input, meta }) => (
                                <>
                                  <label>Number of Options</label>
                                  <input {...input} min="0" className="form-control" />
                                  {meta.error && <span className="validate-form">{meta.error}</span>}
                                </>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      ) : null}
                      {level == 1 && isInclusion && inclusionsAtSubheadingLevel ? (
                        <InclusionOptions groupHeadingId={groupHeadingId1} pathToGroupHeadings={pathToGroupHeadings} menuOrPackage={menuOrPackage} inclusionSubheadingType={inclusionSubheadingType} isInclusionFoodOrDrink={isInclusionFoodOrDrink} />
                      ) : null}

                      {keys(currentGroupHeadings[groupHeadingId1]?.groupHeadings).length > 0 ? (
                        <RenderMenuHeadings
                          menuOrPackage={menuOrPackage}
                          pathToGroupHeadings={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}`}
                          currentGroupHeadings={currentGroupHeadings[groupHeadingId1].groupHeadings}
                          sizes={sizes}
                          isInclusion={isInclusion}
                          menuHeading={menuHeading}
                          level={level + 1}
                          pkg={pkg}
                          isInclusionFoodOrDrink={isInclusionFoodOrDrink}
                        />
                      ) : (
                        <>
                          <div className="mt-3">
                            <Field<GroupHeadingProducts> name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId1}.products`}>
                              {({ input: { onChange, value } }) => {
                                return <ProductList onChange={onChange} value={value} sizes={sizes} isInclusion={isInclusion} pkg={pkg} />;
                              }}
                            </Field>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </>
    );
  }
  return null;
}

export default function MenuHeadingStatic({
  menuOrPackage,
  groupHeadings,
  pathToGroupHeadings,
  groupHeadingId,
  sizes,
  isInclusion,
  menuHeading,
  pkg,
  isInclusionFoodOrDrink,
}: {
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  groupHeadings: GroupHeadings;
  pathToGroupHeadings: string;
  groupHeadingId: string;
  sizes: ProductSizes;
  isInclusion: boolean;
  menuHeading: MenuHeading;
  pkg: boolean;
  isInclusionFoodOrDrink?: boolean;
}) {
  const { inclusionsAtSubheadingLevel, inclusionSubheadingType, subheadings } = menuHeading;

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col lg={6}>
              <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.displayName`} parse={(value) => value}>
                {({ input, meta }) => (
                  <>
                    <label>Display Name</label>
                    <input {...input} className="form-control" />
                    {meta.error && <span className="validate-form">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Col>
          </Row>
          {pkg === true ? (
            <Row style={{ paddingTop: 10 }}>
              <Col lg={6}>
                <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.minimumServiceDuration`} type="number" parse={(value) => Number(value)}>
                  {({ input, meta }) => (
                    <>
                      <label>Minimum Service Duration (min)</label>
                      <input {...input} min="0" className="form-control" />
                      {meta.error && <span className="validate-form">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      {keys(groupHeadings[groupHeadingId]?.groupHeadings).length === 0 ? (
        <Row>
          <Col lg={6}>
            <Field name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.numberOptions`} type="number" parse={(value) => Number(value)}>
              {({ input, meta }) => (
                <>
                  <label>Number of Options</label>
                  <input {...input} min="0" className="form-control" />
                  {meta.error && <span className="validate-form">{meta.error}</span>}
                </>
              )}
            </Field>
          </Col>
        </Row>
      ) : null}
      {/* show inclusion settings on top level */}
      {isInclusion && (!inclusionsAtSubheadingLevel || (inclusionSubheadingType && keys(subheadings).length === 0)) ? (
        <InclusionOptions groupHeadingId={groupHeadingId} pathToGroupHeadings={pathToGroupHeadings} menuOrPackage={menuOrPackage} inclusionSubheadingType={inclusionSubheadingType} isInclusionFoodOrDrink={isInclusionFoodOrDrink} />
      ) : null}

      {keys(groupHeadings[groupHeadingId]?.groupHeadings).length > 0 ? (
        <RenderMenuHeadings
          menuOrPackage={menuOrPackage}
          pathToGroupHeadings={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}`}
          currentGroupHeadings={groupHeadings[groupHeadingId].groupHeadings}
          isInclusion={isInclusion}
          menuHeading={menuHeading}
          level={1}
          sizes={sizes}
          pkg={pkg}
          isInclusionFoodOrDrink={isInclusionFoodOrDrink}
        />
      ) : (
        <>
          <div className="mt-3">
            <Field<GroupHeadingProducts> name={`${pathToGroupHeadings}.groupHeadings.${groupHeadingId}.products`}>
              {({ input: { onChange, value } }) => {
                return <ProductList onChange={onChange} value={value} sizes={sizes} isInclusion={isInclusion} pkg={pkg} />;
              }}
            </Field>
          </div>
        </>
      )}
    </>
  );
}
