import { lazy } from "react";
import { roles } from "resbutler-utils";

export const newsLettersMenuPath = "/newsletters";
export const AdvertisingAndPromotionPath = "/advertising-and-promotions";
export const smsMenuPath = "/sms-mms";
export const defaultMenuPath = "/menus-and-pricing/menus";
export const InsightsListPath = "/insights-list";
export const EmailBrandingTemplatePath = "/marketing/email-branding-templates";

import CoverSummary from "explorer/coverSummary";
import MenuProfitLossRevenue from "explorer/menuProfitAndLossRevenue";
import RevenueByBookingType from "explorer/revenueByBookingType";
import RevenueByYield from "explorer/revenueByYield";
import RevenueProductTreeByArea from "explorer/revenueProductTreeByArea";
import SalesReport from "explorer/salesReport";
import WeeklyMenuRevenue from "explorer/weeklyMenuRevenue";
import AdditionalItems from "function-additionals/AdditionalItems";
import AdditionalPackageList from "function-additionals/PackageList";
import AdditionalItemsCategories from "function-menus/AdditionalItemsCategories";
import FunctionListPackage from "function-menus/PackageList";
import BeverageMenus from "function-menus/beverage-menus/BeverageMenus";
import BeverageMenusList from "function-menus/beverage-menus/BeverageMenusList";
import IssuedGiftCertificatesList from "gift-certificates/issued/Issued";
import IssuedGiftCertificates from "gift-certificates/issued/IssuedGiftCertificates";
import GiftCertificatesForm from "gift-certificates/settings/Default";
import GiftCertificateLinkBuilderForm from "gift-certificates/widget/GiftCertificateWidgetLinkBuilder/GiftCertificateLinkBuilderForm";
import VoucherIssuedList from "vouchers/issued-voucher/VoucherIssuedList";
import VoucherForm from "vouchers/one-off-vouchers/VoucherForm";
import Voucherslist from "vouchers/one-off-vouchers/VouchersList";
import CRMMemberAppLinkBuilder from "./loyalty/crm-members-app-link-builder";
import LoyaltyVoucherForm from "./loyalty/loyalty-voucher/LoyaltyVoucherForm";
import LoyaltyVouchers from "./loyalty/loyalty-voucher/LoyaltyVouchers";
import MemberPromotionForm from "./loyalty/member-offers/member-promotions/MemberPromotionForm";
import MemberPromotions from "./loyalty/member-offers/member-promotions/MemberPromotions";
import MembershipAppSettings from "./loyalty/membership-app-settings/MembershipAppSettings";
import MembershipLevels from "./loyalty/membership-levels/MembershipLevels";
import MembershipLevelsForm from "./loyalty/membership-levels/MembershipLevelsForm";

const Menu = [
  {
    name: "Menus & Pricing",
    icon: "",
    permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
    submenu: [
      {
        name: "Menus",
        path: "/menus-and-pricing/menus",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/menus-and-pricing/menus/:menuId?",
            component: lazy(() => import("menus-and-pricing/MenuList")),
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: false,
            },
          },
        ],
      },
      {
        name: "Menu Categories",
        path: "/menus-and-pricing/menu-categories",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/menus-and-pricing/menu-categories/:id",
            component: lazy(() => import("menus-and-pricing/MenuCategoriesForm")),
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: false,
            },
          },
          {
            path: "/menus-and-pricing/menu-categories",
            component: lazy(() => import("menus-and-pricing/MenuCategoriesList")),
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: false,
            },
          },
        ],
      },
      {
        name: "Menu Headings",
        path: "/menus-and-pricing/menu-headings",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/menus-and-pricing/menu-headings/:id",
            component: lazy(() => import("menus-and-pricing/menu-heading/MenuHeadingForm")),
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: false,
            },
          },
          {
            path: "/menus-and-pricing/menu-headings",
            component: lazy(() => import("menus-and-pricing/menu-heading/MenuHeadingList")),
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: false,
            },
          },
        ],
      },
    ],
  },
  {
    name: "Large Group and Function Menus",
    icon: "",
    permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
    submenu: [
      {
        name: "Food Packages",
        path: "/functions-menus/food-packages",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-menus/food-packages",
            component: FunctionListPackage,
            permissions: roles.roles.ADMIN,
            props: {
              key: "food-packages",
              isFunction: true,
              packageName: "food",
            },
          },
        ],
      },
      {
        name: "Beverage Packages",
        path: "/functions-menus/beverage-packages",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-menus/beverage-packages",
            component: FunctionListPackage,
            permissions: roles.roles.ADMIN,
            props: {
              key: "beverage-packages",
              isFunction: true,
              packageName: "beverage",
            },
          },
        ],
      },

      {
        name: "Food & Beverage Packages",
        path: "/functions-menus/food-and-beverage-packages",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-menus/food-and-beverage-packages",
            component: FunctionListPackage,
            permissions: roles.roles.ADMIN,
            props: {
              key: "food-beverage-packages",
              isFunction: true,
              packageName: "food_and_beverage",
            },
          },
        ],
      },
      {
        name: "Beverage Menus",
        path: "/functions-menus/beverage-menus",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-menus/beverage-menus/:id",
            component: BeverageMenus,
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: true,
            },
          },
          {
            path: "/functions-menus/beverage-menus",
            component: BeverageMenusList,
            permissions: roles.roles.ADMIN,
            props: {
              isFunction: true,
            },
          },
        ],
      },
      {
        name: "Additional Items Categories",
        path: "/functions-menus/additional-items-categories",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-menus/additional-items-categories",
            component: AdditionalItemsCategories,
            permissions: roles.roles.ADMIN,
          },
        ],
      },
    ],
  },
  {
    name: "Function Additionals",
    icon: "",
    Permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
    submenu: [
      {
        name: "Additional Foods",
        path: "/function-additionals/additional-foods",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/function-additionals/additional-foods",
            component: AdditionalPackageList,
            permissions: roles.roles.ADMIN,
            props: {
              key: "food-packages",
              isFunction: true,
              packageName: "food",
            },
          },
        ],
      },
      {
        name: "Additional Beverages",
        path: "function-additionals/additional-beverages",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/function-additionals/additional-beverages",
            component: AdditionalPackageList,
            permissions: roles.roles.ADMIN,
            props: {
              key: "beverage-packages",
              isFunction: true,
              packageName: "beverage",
            },
          },
        ],
      },
      {
        name: "Additional Items",
        path: "/functions-additional-items/additional-items",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/functions-additional-items/additional-items",
            component: AdditionalItems,
            permissions: roles.roles.ADMIN,
            props: {
              key: "additional-items-packages",
              isFunction: true,
              packageName: "additionalItem",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Loyalty",
    icon: "",
    submenu: [
      {
        name: "Membership Levels",
        path: "/loyalty/membership-levels",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/loyalty/membership-levels/:id",
            component: MembershipLevelsForm,
          },
          {
            path: "/loyalty/membership-levels",
            component: MembershipLevels,
          },
        ],
      },
      // {
      //   name: "Member Vouchers",
      //   icon: "",
      //   path: "/loyalty/members-voucher",
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   submenuchild: [
      //     {
      //       name: "Voucher Setup",
      //       icon: "",
      //       path: "/loyalty/members-voucher/voucher-setup",
      //       routes: [
      //         {
      //           path: "/loyalty/members-voucher/voucher-setup/:id",
      //           component: LoyaltyVoucherForm, //addEditForm
      //         },
      //         {
      //           path: "/loyalty/members-voucher/voucher-setup",
      //           component: VoucherSetupList,
      //         },
      //       ],
      //     },
      //     {
      //       name: "Issued Vouchers",
      //       icon: "",
      //       path: "/loyalty/members-voucher/issued-voucher",
      //       routes: [
      //         {
      //           path: "/loyalty/members-voucher/issued-voucher/:id",
      //           component: VoucherIssuedForm, //addEditForm
      //         },
      //         {
      //           path: "/loyalty/members-voucher/issued-voucher",
      //           component: VoucherIssuedList,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Member Promotions",
        icon: "",
        path: "/loyalty/members-offers/member-promotions",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/loyalty/members-offers/member-promotions/:id",
            component: MemberPromotionForm,
          },
          {
            path: "/loyalty/members-offers/member-promotions",
            component: MemberPromotions,
          },
        ],
      },
      // {
      //   name: "Non-Member Offers",
      //   icon: "",
      //   path: "/loyalty/non-member-offers",
      //   disabled: true,
      //   routes: [
      //     {
      //       path: "/loyalty/non-member-offers",
      //     },
      //   ],
      // },
      // {
      //   name: "Partner Offers",
      //   icon: "",
      //   path: "/loyalty/partner-offers",
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   submenuchild: [
      //     {
      //       name: "Internal Promotions",
      //       path: "/loyalty/partner-offers/internal-promotions",
      //       disabled: true,
      //       routes: [
      //         {
      //           path: "/loyalty/partner-offers/internal-promotions",
      //         },
      //       ],
      //     },
      //     {
      //       name: "External Promotions",
      //       path: "/loyalty/partner-offers/external-promotions",
      //       disabled: true,
      //       routes: [
      //         {
      //           path: "/loyalty/partner-offers/external-promotions",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "CRM Members App Link Builder",
        icon: "",
        path: "/loyalty/crm-members-app-link-builder",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/loyalty/crm-members-app-link-builder",
            component: CRMMemberAppLinkBuilder,
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
          },
        ],
      },
      {
        name: "Membership App Settings",
        icon: "",
        path: "/loyalty/membership-app-settings",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/loyalty/membership-app-settings",
            component: MembershipAppSettings,
          },
        ],
      },
      {
        name: "Loyalty Vouchers",
        icon: "",
        path: "/loyalty/loyalty-vouchers",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/loyalty/loyalty-vouchers/:id/view",
            component: LoyaltyVoucherForm, //addEditForm
          },
          {
            path: "/loyalty/loyalty-vouchers/:id",
            component: LoyaltyVoucherForm, //addEditForm
          },
          {
            path: "/loyalty/loyalty-vouchers",
            component: LoyaltyVouchers,
          },
        ],
      },
      // {
      //   name: 'Internal Reviews',
      //   icon: "",
      //   path: "/loyalty/internal-reviews",
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   disabled: true,
      //   routes: [
      //     {
      //       path: "/loyalty/internal-reviews/:id",
      //     },
      //     {
      //       path: "/loyalty/internal-reviews",
      //     },
      //   ],
      // },
      // {
      //   name: 'Third Party Reviews',
      //   icon: "",
      //   path: "/loyalty/third-party-reviews",
      //   disabled: true,
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   routes: [
      //     {
      //       path: "/loyalty/third-party-reviews/:id",
      //     },
      //     {
      //       path: "/loyalty/third-party-reviews",
      //       component: ThirdPartyReviews,
      //     },
      //   ],
      // }
    ],
  },
  {
    name: "Vouchers",
    submenu: [
      {
        name: "One-Off Vouchers",
        path: "/vouchers/one-off-vouchers",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/vouchers/one-off-vouchers/:id/view",
            component: VoucherForm, //addEditForm
          },
          {
            path: "/vouchers/one-off-vouchers/:id",
            component: VoucherForm, //addEditForm
          },
          {
            path: "/vouchers/one-off-vouchers",
            component: Voucherslist,
          },
        ],
      },
      {
        name: "Issued Vouchers",
        icon: "",
        path: "/vouchers/issued-voucher",
        routes: [
          {
            path: "/vouchers/issued-voucher",
            component: VoucherIssuedList,
          },
        ],
      },
    ],
  },
  {
    name: "Gift Certificates",
    // icon: "fa fa-angle-right",
    submenu: [
      {
        name: "Gift Certificate Widget",
        // icon: "fa fa-angle-right",
        path: "/gift-certificates/giftcertificate-widget",
        submenuchild: [
          // {
          //   name: "Gift Certificate Widget Configurator",
          //   path: "/gift-certificates/giftcertificate-widget/configurator",
          //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
          //   disabled: true,
          //   key: "GIFT_CERTIFICATE_BUTLER",
          //   routes: [
          //     {
          //       path: "/gift-certificates/giftcertificate-widget/configurator",
          //       component: GiftCertificateConfigurator,
          //       permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          //     },
          //   ],
          // },
          {
            name: "Gift Certificate Widget Link Builder",
            path: "/gift-certificates/giftcertificate-widget/gift-certificate-widget-link-builder",
            key: "GIFT_CERTIFICATE_BUTLER",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/gift-certificates/giftcertificate-widget/gift-certificate-widget-link-builder",
                component: GiftCertificateLinkBuilderForm,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
          // {
          //   name: "Customer Gift Certificate Email Templates",
          //   path: "/gift-certificates/giftcertificate-widget/customer-gift-certificate-email-template",
          //   disabled: true,
          //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
          //   key: "GIFT_CERTIFICATE_BUTLER",
          //   routes: [
          //     {
          //       path: "/gift-certificates/giftcertificate-widget/customer-gift-certificate-email-template",
          //       component: GiftCertificateEmailTemplate,
          //       permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "Gift Certificate Settings",
        path: "/gift-certificates/settings",
        key: "GIFT_CERTIFICATE_BUTLER",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/gift-certificates/settings",
            component: GiftCertificatesForm,
            permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          },
        ],
      },
      {
        name: "Gift Certificates Issued",
        path: "/gift-certificates/issued",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/gift-certificates/issued/:id",
            component: IssuedGiftCertificates,
            permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          },
          {
            path: "/gift-certificates/issued",
            component: IssuedGiftCertificatesList,
            permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          },
        ],
      },
      // {
      //   name: "Gift Certificate Integrations",
      //   path: "/gift-certificates/integrations",
      //   key: "GIFT_CERTIFICATE_BUTLER",
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   disabled: true,
      //   routes: [
      //     {
      //       path: "/gift-certificates/integrations",
      //       component: GiftCertificateIntegrations,
      //       permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
      //     },
      //   ],
      // },
      // {
      //   name: "Gift Certificate Reporting",
      //   path: "/gift-certificates/reporting",
      //   key: "GIFT_CERTIFICATE_BUTLER",
      //   permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
      //   disabled: true,
      //   routes: [
      //     {
      //       path: "/gift-certificates/reporting",
      //       component: GiftCertificateReporting,
      //       permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Marketing",
    submenu: [
      {
        name: "Email Branding Templates",
        path: EmailBrandingTemplatePath,
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        routes: [
          {
            path: EmailBrandingTemplatePath + "/:id",
            component: lazy(() => import("marketing/email-branding-templates/EmailTemplateForm")),
          },
          {
            path: EmailBrandingTemplatePath,
            component: lazy(() => import("marketing/email-branding-templates/EmailTemplateLists")),
          },
        ],
      },
      {
        name: "SMS & MMS Messaging",
        path: smsMenuPath,
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        routes: [
          {
            path: smsMenuPath + "/:id/view",
            component: lazy(() => import("sms-mms/SmsMmsForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: smsMenuPath + "/:id/edit",
            component: lazy(() => import("sms-mms/SmsMmsForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: smsMenuPath + "/:id",
            component: lazy(() => import("sms-mms/SmsMmsForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: smsMenuPath,
            component: lazy(() => import("sms-mms/SmsMmsList")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
        ],
      },
      {
        name: "Newsletters",
        path: newsLettersMenuPath,
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        routes: [
          {
            path: newsLettersMenuPath + "/statistics/:id",
            component: lazy(() => import("newsletters/NewslettersStatistics")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: newsLettersMenuPath + "/:id/view",
            component: lazy(() => import("newsletters/NewslettersForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: newsLettersMenuPath + "/:id/edit",
            component: lazy(() => import("newsletters/NewslettersForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: newsLettersMenuPath + "/:id",
            component: lazy(() => import("newsletters/NewslettersForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: newsLettersMenuPath,
            component: lazy(() => import("newsletters/NewsLettersList")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
        ],
      },
      {
        name: "Advertising & Promotions",
        icon: "",
        path: AdvertisingAndPromotionPath,
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        submenuchild: [
          {
            name: "Campaigns",
            path: "/advertising-and-promotions/campaigns",
            permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
            routes: [
              {
                path: "/advertising-and-promotions/campaigns/:id",
                component: lazy(() => import("advertising-promotions/CampaignForm")),
                permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
              },
              {
                path: "/advertising-and-promotions/campaigns/",
                component: lazy(() => import("advertising-promotions/CampaignList")),
                permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
              },
            ],
          },
          {
            name: "Promo Codes",
            path: "/advertising-and-promotions/promo-codes",
            permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
            routes: [
              {
                path: "/advertising-and-promotions/promo-codes/:id/view",
                component: lazy(() => import("advertising-promotions/promo-codes/PromoCodeView")),
                permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
              },
              {
                path: "/advertising-and-promotions/promo-codes/:id",
                component: lazy(() => import("advertising-promotions/promo-codes/CreateOrUpdate")),
                permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
              },
              {
                path: "/advertising-and-promotions/promo-codes",
                component: lazy(() => import("advertising-promotions/promo-codes/PromoCodeList")),
                permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
              },
            ],
          },
        ],
      },
      {
        name: "Customer Filters",
        path: "customer-filters",
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        routes: [
          {
            path: "customer-filters",
            component: lazy(() => import("customer-filters/List")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
          {
            path: "customer-filters/:id",
            component: lazy(() => import("customer-filters/CreateOrUpdateForm")),
            permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
          },
        ],
      },
      {
        name: "Customer Tags",
        path: "customer-tags",
        permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
        routes: [
          {
            path: "customer-tags",
            component: lazy(() => import("customer-tags/CustomerTags")),
            permissions: [roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR],
          },
        ],
      },
    ],
  },
  {
    name: "Insights List",
    path: InsightsListPath,
    permissions: [roles.roles.ADMIN, roles.roles.WAIT_STAFF, roles.roles.SUPERVISOR, roles.roles.RESTAURANT_MANAGER, roles.roles.SYSTEM],
    routes: [
      {
        path: InsightsListPath + "/:id",
        component: lazy(() => import("Insights/InsightsForm")),
        permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
      },
      {
        path: InsightsListPath,
        component: lazy(() => import("Insights/InsightsList")),
        permissions: roles.roles.ADMIN | roles.roles.WAIT_STAFF | roles.roles.SUPERVISOR | roles.roles.RESTAURANT_MANAGER,
      },
    ],
  },

  {
    name: "WizPower",
    submenu: [
      {
        name: "Revenue Report by Product Tree",
        submenuchild: [
          {
            name: "Revenue by product",
            path: "/wizpower/revenue-product-tree",
            key: "SALES_REPORT",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-product-tree",
                component: SalesReport,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
          {
            name: "Revenue by Product Tree by Area",
            path: "/wizpower/revenue-by-area",
            key: "REVENUE_PRODUCT_TREE_BY_AREA",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-by-area",
                component: RevenueProductTreeByArea,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
          {
            name: "Revenue by Booking Type",
            path: "/wizpower/revenue-booking-type",
            key: "REVENUE_REPORT_BY_BOOKING_TYPE",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-booking-type",
                component: RevenueByBookingType,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
          {
            name: "Revenue Yield Report",
            path: "/wizpower/revenue-yield-report",
            key: "REVENUE_REPORT_BY_YIELD",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-yield-report",
                component: RevenueByYield,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
        ],
      },
      {
        name: "Revenue Report by Menu",
        submenuchild: [
          {
            name: "Total Revenue by Menu",
            path: "/wizpower/revenue-menu",
            key: "REVENUE_REPORT_BY_MENU",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-menu",
                component: WeeklyMenuRevenue,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
          {
            name: "Fixed Price Menus (Gain/Loss)",
            path: "/wizpower/revenue-fixed-price-menus",
            key: "REVENUE_REPORT_BY_FIXED_PRICE_MENUS",
            permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
            routes: [
              {
                path: "/wizpower/revenue-fixed-price-menus",
                component: MenuProfitLossRevenue,
                permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
              },
            ],
          },
        ],
      },
      {
        name: "Cover Report",
        path: "/wizpower/cover-report",
        key: "COVER_SUMMARY",
        permissions: [roles.roles.ADMIN, roles.roles.SYSTEM],
        routes: [
          {
            path: "/wizpower/cover-report",
            component: CoverSummary,
            permissions: roles.roles.ADMIN | roles.roles.SYSTEM,
          },
        ],
      },
    ],
  },
];

export default Menu;
