import { getEnabledProductGroups } from "RootReducer";
import classNames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import { getConfig } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import _, { chain, difference, findIndex, findKey, isBoolean, keys, pickBy } from "lodash";
import MenuHeadingStatic from "menus-and-pricing/menu-heading/MenuHeadingStatic";
import MenuItemDynamic from "menus-and-pricing/menu-heading/MenuItemDynamic";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import { useEffect, useState } from "react";
import { Field, useForm } from "react-final-form";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { BeverageMenuPackage, GroupHeading, GroupHeadingProducts, GroupHeadingType, GroupHeadings, Menu, MenuHeading, MenuHeadingProducts, MenuHeadings, Package, SubHeadings } from "resbutler-utils/types/Menu";
import { Product, ProductGroups, ProductSizes } from "resbutler-utils/types/product";
import SpecialProduct from "./SpecialProduct";

export interface MenuItemsProps {
  type: "food" | "beverage" | "foodbeverage" | "beverageMenus" | "foodInclusions" | "drinkInclusions";
  groupHeadings: GroupHeadings;
  productSizes: ProductSizes;
  menuHeadings: MenuHeadings;
  path: string;
  pkg?: boolean;
  menuOrPackage: Menu | Package | BeverageMenuPackage;
  removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } };
  functionPackageType?: "functionAdditionals" | "functionPackages";
  // additionalItemsCategories?: AdditionalItemsCategories;
}

function getGroupHeadingType(type: string): GroupHeadingType | undefined {
  const typeMapping: { [key: string]: GroupHeadingType } = {
    food: GroupHeadingType.food,
    beverage: GroupHeadingType.beverage,
    foodInclusions: GroupHeadingType.foodInclusion,
    drinkInclusions: GroupHeadingType.drinkInclusion,
    foodbeverage: GroupHeadingType.foodBeverage,
    beverageMenus: GroupHeadingType.beverageMenus,
  };

  return typeMapping[type];
}

const createStaticGroupHeadingProducts = (menuHeadingProducts: MenuHeadingProducts, _groupHeadingProducts: GroupHeadingProducts, enabled?: boolean) => {
  let groupHeadingProducts: GroupHeadingProducts = {};
  if (_groupHeadingProducts == undefined) {
    chain(menuHeadingProducts || {})
      .map((v, id) => ({ ...v, id }))
      .mapValues()
      .sortBy("order")
      .value()
      .map((item: any, index) => {
        const productId = item.id;
        groupHeadingProducts[productId] = { productSizeIds: {} };
        for (const sizeId in item.productSizeIds || {}) {
          groupHeadingProducts[productId].productSizeIds[sizeId] = { enabled: false };
        }
        groupHeadingProducts[productId].order = index + 1;
      });
  } else {
    groupHeadingProducts = { ..._groupHeadingProducts };
  }

  if (enabled) {
    for (const productId in groupHeadingProducts) {
      for (const sizeId in groupHeadingProducts[productId].productSizeIds || {}) {
        groupHeadingProducts[productId].productSizeIds[sizeId].enabled = enabled;
      }
    }
  }
  return groupHeadingProducts;
};

export function createStaticGroupHeadings(menuHeading: MenuHeading, groupHeading: GroupHeading, type: GroupHeadingType, menuHeadingId: string, enabled?: boolean) {
  const groupHeading1 = JSON.parse(JSON.stringify(groupHeading));
  groupHeading1.displayName = groupHeading1.displayName || menuHeading.name;
  groupHeading1.groupHeadings = groupHeading1.groupHeadings || {};
  groupHeading1.products = createStaticGroupHeadingProducts(menuHeading.products, groupHeading1.products, enabled);
  let order = 1;
  if (menuHeading.enableSubHeadings && keys(menuHeading.subheadings).length > 0) {
    for (const subHeadingId in menuHeading.subheadings) {
      createStaticGroupHeadingsRecursive(groupHeading1.groupHeadings, menuHeading.subheadings, type, subHeadingId, order, enabled);
      order += 1;
    }
  }
  return groupHeading1;
}

export function createStaticGroupHeadingsRecursive(groupHeadings: GroupHeadings, subheadings: SubHeadings, type: GroupHeadingType, currentSubHeadingId: string, order: number, enabled?: boolean) {
  const { client } = getConfig();
  const groupHeadingId = findKey(groupHeadings, (g) => g.subHeadingId === currentSubHeadingId) || firebase.firestore().collection(`${client}/menus/menus`).doc().id;
  const isEnableSubheading = isBoolean(groupHeadings[groupHeadingId]?.isEnableSubheading) ? groupHeadings[groupHeadingId]?.isEnableSubheading : true;
  const groupHeadingProducts = createStaticGroupHeadingProducts(subheadings[currentSubHeadingId]?.products, groupHeadings[groupHeadingId]?.products, enabled);
  groupHeadings[groupHeadingId] = {
    ...groupHeadings[groupHeadingId],
    displayName: subheadings[currentSubHeadingId].name,
    groupHeadingType: type,
    subHeadingId: currentSubHeadingId,
    groupHeadings: groupHeadings[groupHeadingId]?.groupHeadings || {},
    products: groupHeadingProducts,
    isEnableSubheading,
    order: subheadings[currentSubHeadingId].order || order,
  };
  if (subheadings[currentSubHeadingId].enableSecondTier && keys(subheadings[currentSubHeadingId].subheadings).length > 0) {
    for (const subHeadingId1 in subheadings[currentSubHeadingId].subheadings) {
      createStaticGroupHeadingsRecursive(groupHeadings[groupHeadingId].groupHeadings, subheadings[currentSubHeadingId].subheadings, type, subHeadingId1, order, enabled);
    }
  }
}

export function createDynamicGroupHeadings(menuHeading: MenuHeading, groupHeading: GroupHeading, groupHeadingId: string, productGroups: ProductGroups, products: Product[], productSizes: ProductSizes, type: GroupHeadingType, menuHeadingId: string, enabled?: boolean) {
  const groupHeading1 = JSON.parse(JSON.stringify(groupHeading));
  groupHeading1.displayName = groupHeading1.displayName || productGroups[menuHeading.productGroupId].name;
  groupHeading1.groupHeadings = groupHeading1.groupHeadings || {};
  groupHeading1.products = groupHeading1.products || {};
  const removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } } = {};
  createDynamicGroupHeadingsRecursive(groupHeading1, groupHeadingId, productGroups, products, productSizes, type, menuHeading.productGroupId, removedProducts, enabled);
  return { removedProducts, groupHeading: groupHeading1 };
}

function createDynamicGroupHeadingsRecursive(
  groupHeading: GroupHeading,
  groupHeadingId: string,
  productGroups: ProductGroups,
  products: Product[],
  productSizes: ProductSizes,
  type: GroupHeadingType,
  currentGroupId: string,
  removedProducts: { [groupHeadingId: string]: { [productId: string]: boolean } },
  enabled?: boolean,
  order = 1
) {
  const { client } = getConfig();
  const childGroups = chain(productGroups)
    .pickBy((pg) => pg.parentGroup === currentGroupId)
    .map((v, _key) => ({ ...v, _key }))
    .sortBy((item) => (!item.order ? 0 : item.order))
    .keyBy("_key")
    .mapValues()
    .value();
  if (keys(childGroups).length > 0) {
    for (const pgId1 in childGroups) {
      const groupHeadingId1 = findKey(groupHeading.groupHeadings, (g) => g.productGroupId === pgId1) || firebase.firestore().collection(`${client}/menus/menus`).doc().id;
      const isEnableSubheading = isBoolean(groupHeading.groupHeadings[groupHeadingId1]?.isEnableSubheading) ? groupHeading.groupHeadings[groupHeadingId1]?.isEnableSubheading : true;
      groupHeading.groupHeadings[groupHeadingId1] = {
        ...groupHeading.groupHeadings[groupHeadingId1],
        displayName: groupHeading.groupHeadings[groupHeadingId1]?.displayName || productGroups[pgId1].name,
        groupHeadingType: type,
        productGroupId: pgId1,
        groupHeadings: groupHeading.groupHeadings[groupHeadingId1]?.groupHeadings || {},
        products: groupHeading.groupHeadings[groupHeadingId1]?.products || {},
        isEnableSubheading,
        order: productGroups[pgId1].order || order,
      };
      order++;
      createDynamicGroupHeadingsRecursive(groupHeading.groupHeadings[groupHeadingId1], groupHeadingId1, productGroups, products, productSizes, type, pgId1, removedProducts, enabled, order);
    }
  } else {
    const productsByGroup = _.chain(products)
      .filter((p) => p.groupId === currentGroupId && p.enabled)
      .sortBy((item) => (!item.order ? 0 : item.order))
      .value();
    const groupHeadingProducts: GroupHeadingProducts = {};
    let order = 1;
    for (let i = 0; i < productsByGroup.length; i++) {
      const product = productsByGroup[i];
      const sizes1 = pickBy(product.sizes, (s, k) => s === true && productSizes[k]?.enabled === true);
      for (const sizeId in sizes1) {
        groupHeadingProducts[product.id] = {
          ...groupHeading.products[product.id],
          ...groupHeadingProducts[product.id],
          productSizeIds: {
            ...groupHeadingProducts[product.id]?.productSizeIds,
            [sizeId]: {
              ...groupHeading.products?.[product.id]?.productSizeIds?.[sizeId],
            },
          },
          order: groupHeading.products?.[product.id]?.order || order,
        };
        if (enabled !== undefined) {
          groupHeadingProducts[product.id].productSizeIds[sizeId].enabled = enabled;
        }
      }
      groupHeading.products[product.id] = groupHeadingProducts[product.id];
      order++;
    }
    const removed = pickBy(groupHeading.products, (p, k) => findIndex(productsByGroup, (p) => p.id === k) === -1);
    removedProducts[groupHeadingId] = removedProducts[groupHeadingId] || {};
    for (const productId in removed) {
      removedProducts[groupHeadingId][productId] = true;
    }
  }
}

const MenuItem = ({ type, productSizes, groupHeadings = {}, menuHeadings, path, pkg = false, menuOrPackage, removedProducts, functionPackageType }: MenuItemsProps) => {
  const [menuHeadingIdSelected, setMenuHeadingIdSelected] = useState<string[]>([]);
  const form = useForm();
  const [collapseGroupHeadingId, setCollapseGroupHeadingId] = useState("");
  const { products, productGroups } = useAppSelector((state) => ({
    products: state.root.products,
    productGroups: getEnabledProductGroups(state.root.productGroups),
  }));

  useEffect(() => {
    if (keys(menuHeadings).length) {
      const groupHeadingIds = keys(groupHeadings);
      const selectedMenuHeadingIds = groupHeadingIds.map((groupHeadingId) => groupHeadings[groupHeadingId].menuHeadingId);
      setMenuHeadingIdSelected(sortMenuHeadingByOrder(selectedMenuHeadingIds));
    }
  }, [menuHeadings]);

  const sortMenuHeadingByOrder = (menuIds) => {
    if (menuIds.length) {
      const sortedMenuIds = menuIds.sort((menuId1, menuId2) => {
        const gruopHeadingId1 = findKey(groupHeadings, (g) => g.menuHeadingId === menuId1);
        const gruopHeadingId2 = findKey(groupHeadings, (g) => g.menuHeadingId === menuId2);
        const menuId1Order = groupHeadings[gruopHeadingId1]?.order;
        const menuId2Order = groupHeadings[gruopHeadingId2]?.order;
        if (typeof menuId1Order === "number" && typeof menuId2Order === "number") {
          return menuId1Order - menuId2Order;
        }
        // keep original order of menuId1 and menuId2
        return 0;
      });
      return sortedMenuIds;
    }
    return menuIds;
  };

  const packageType = type == "beverage" ? "drink" : type == "food" ? "food" : "all";
  const menuHeadingTree = [
    {
      label: "Dynamic",
      title: "Dynamic",
      selectable: false,
      disableCheckbox: true,
      value: "0-0-dynamic",
      key: "0-0-dynamic",
    },
    {
      label: "Static",
      title: "Static",
      selectable: false,
      disableCheckbox: true,
      value: "0-0-static",
      key: "0-0-static",
    },
  ] as any;

  const headingDynamicTree = Object.entries(_.pickBy(menuHeadings, (f) => f.type === "dynamic" && f.enabled && (packageType === "all" ? true : f.groupType === packageType))).map(([menuHeadingId, menuHeading]: [string, MenuHeading]) => {
    return {
      key: menuHeadingId,
      title: menuHeading.name,
      value: menuHeadingId,
      disabled: false,
    };
  });
  const headingStaticTree = Object.entries(_.pickBy(menuHeadings, (f) => f.type === "static" && f.enabled && (packageType === "all" ? true : f.groupType === packageType))).map(([menuHeadingId, menuHeading]: [string, MenuHeading]) => {
    return {
      key: menuHeadingId,
      title: menuHeading.name,
      value: menuHeadingId,
      disabled: false,
    };
  });
  menuHeadingTree[0].children = headingDynamicTree;
  menuHeadingTree[1].children = headingStaticTree;

  const updateMenuHeadingOrder = (action, headingId) => {
    const selectedMenuByOrder = [...menuHeadingIdSelected];
    const menuIdIndex = selectedMenuByOrder.indexOf(headingId);
    const requiredIndex = action === "up" ? menuIdIndex - 1 : menuIdIndex + 1;

    if (requiredIndex >= 0 && requiredIndex < selectedMenuByOrder.length) {
      const menuId = selectedMenuByOrder[menuIdIndex];
      const swapMenuId = selectedMenuByOrder[requiredIndex];

      selectedMenuByOrder[requiredIndex] = menuId;
      selectedMenuByOrder[menuIdIndex] = swapMenuId;

      const groupHeadingId1 = findKey(groupHeadings, (g) => g.menuHeadingId === headingId);
      const groupHeadingId2 = findKey(groupHeadings, (g) => g.menuHeadingId === swapMenuId);

      form.change(`${path}.groupHeadings.${groupHeadingId1}.order`, requiredIndex + 1);
      form.change(`${path}.groupHeadings.${groupHeadingId2}.order`, menuIdIndex + 1);

      setMenuHeadingIdSelected(selectedMenuByOrder);
    }
  };

  // update selected menu Id and maintain field state
  const handleHeadingId = (ids: string[]) => {
    const remainingMenuHeadingIds = [...ids];
    // remove field state if id is unchecked
    if (menuHeadingIdSelected.length && menuHeadingIdSelected.length > ids.length) {
      // each time when unchecked happen it will only remove one item a time
      const excludedMenuId = _.difference(menuHeadingIdSelected, remainingMenuHeadingIds)[0];
      const newGroupHeadings = pickBy(groupHeadings, (g, k) => g.menuHeadingId !== excludedMenuId);
      if (keys(newGroupHeadings).length > 0) {
        form.change(`${path}.groupHeadings`, newGroupHeadings);
      } else {
        form.change(`${path}.groupHeadings`, undefined);
      }
    } else if (difference(ids, menuHeadingIdSelected).length > 0) {
      const newMenuHeadingIds = difference(ids, menuHeadingIdSelected);
      const { client } = getConfig();
      const groupHeadingId = firebase.firestore().collection(`${client}/menus/menus`).doc().id;
      const newMenuHeadingId = newMenuHeadingIds[0];
      const groupHeading: GroupHeading = { menuHeadingId: newMenuHeadingId, groupHeadingType: getGroupHeadingType(type) };
      if (menuHeadings[newMenuHeadingId].type === "dynamic") {
        const { groupHeading: newGroupHeading } = createDynamicGroupHeadings(menuHeadings[newMenuHeadingId], groupHeading, groupHeadingId, productGroups, products, productSizes, getGroupHeadingType(type), newMenuHeadingId);
        form.change(`${path}.groupHeadings.${groupHeadingId}`, newGroupHeading);
      } else {
        const newGroupHeading = createStaticGroupHeadings(menuHeadings[newMenuHeadingId], groupHeading, getGroupHeadingType(type), newMenuHeadingId);
        form.change(`${path}.groupHeadings.${groupHeadingId}`, newGroupHeading);
      }
    }
    setMenuHeadingIdSelected(remainingMenuHeadingIds);
  };

  const handleRemoveHeading = (headingToRemove) => {
    handleHeadingId(menuHeadingIdSelected.filter((id) => id !== headingToRemove))
  }

  const handleHeadingIdSingle = (ids: string[]) => {
    const remainingMenuHeadingIds = [...ids];
    const { client } = getConfig();
    const groupHeadingId = firebase.firestore().collection(`${client}/menus/menus`).doc().id;
    if (remainingMenuHeadingIds.length > 0) {
      const newMenuHeadingId = remainingMenuHeadingIds[0];
      const groupHeading: GroupHeading = { menuHeadingId: newMenuHeadingId, groupHeadingType: getGroupHeadingType(type) };
      if (menuHeadings[newMenuHeadingId].type === "dynamic") {
        const { groupHeading: newGroupHeading } = createDynamicGroupHeadings(menuHeadings[newMenuHeadingId], groupHeading, groupHeadingId, productGroups, products, productSizes, getGroupHeadingType(type), newMenuHeadingId);
        form.change(`${path}.groupHeadings`, { [groupHeadingId]: newGroupHeading });
      } else {
        const newGroupHeading = createStaticGroupHeadings(menuHeadings[newMenuHeadingId], groupHeading, getGroupHeadingType(type), newMenuHeadingId);
        form.change(`${path}.groupHeadings`, { [groupHeadingId]: newGroupHeading });
      }
    } else {
      form.change(`${path}.groupHeadings`, undefined);
    }
    setMenuHeadingIdSelected(remainingMenuHeadingIds);
  };

  const handleAllVisible = (groupHeadingId: string) => {
    const menuHeadingId = groupHeadings[groupHeadingId]?.menuHeadingId;
    if (menuHeadings[menuHeadingId]?.type === "dynamic") {
      const { groupHeading: newGroupHeading } = createDynamicGroupHeadings(menuHeadings[menuHeadingId], groupHeadings[groupHeadingId], groupHeadingId, productGroups, products, productSizes, getGroupHeadingType(type), groupHeadings[groupHeadingId].menuHeadingId, true);
      form.change(`${path}.groupHeadings.${groupHeadingId}`, newGroupHeading);
    } else {
      const newGroupHeading = createStaticGroupHeadings(menuHeadings[menuHeadingId], groupHeadings[groupHeadingId], getGroupHeadingType(type), groupHeadings[groupHeadingId].menuHeadingId, true);
      form.change(`${path}.groupHeadings.${groupHeadingId}`, newGroupHeading);
    }
  };

  const getIsInclusion = () => {
    if (functionPackageType === "functionAdditionals") {
      const package1 = menuOrPackage as Package;
      return package1?.priceType === "fixedProductPrice";
    } else {
      const typeMappings = {
        pkg: ["food", "beverage", "foodbeverage"],
        other: ["foodInclusions", "drinkInclusions"],
      };
      const mappings = typeMappings[pkg ? "pkg" : "other"];
      return mappings.includes(type);
    }
  };

  const getIsInclusionFoodOrDrink = () => {
    const isInclusion = type === "foodInclusions" || type === "drinkInclusions";
    return isInclusion;
  };

  return (
    <>
      <Card className="card-default">
        <CardBody>
          <Row className="form-group">
            <Col>
              <label>Menu Headings</label>
              <br />
              <TreeSelect
                className="form-control tree-select"
                style={{ width: "50%" }}
                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                dropdownStyle={{ height: 250, overflow: "auto" }}
                dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                treeLine
                value={menuHeadingIdSelected}
                placeholder={"Select menu"}
                showIcon={false}
                maxTagTextLength={25}
                autoClearSearchValue
                treeData={menuHeadingTree}
                treeCheckable
                showCheckedStrategy={SHOW_PARENT}
                treeDefaultExpandAll={true}
                maxTagCount={20}
                onChange={(ids, label, extra) => {
                  functionPackageType == "functionAdditionals" ? (extra.checked ? handleHeadingIdSingle([extra.triggerValue]) : handleHeadingIdSingle([])) : handleHeadingId(ids);
                }}
                maxTagPlaceholder={(valueList) => {
                  return `+${valueList.length}`;
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {menuHeadingIdSelected.map((headingId, index) => {
        const groupHeadingId = findKey(groupHeadings, (g) => g.menuHeadingId === headingId);
        return (
          <div key={headingId}>
            <Card className="card-default mt-2">
              <CardHeader>
                <Row>
                  <Col lg={6} className="d-flex align-items-center">
                    <Button type="button" className="mr-2" onClick={() => setCollapseGroupHeadingId(collapseGroupHeadingId === headingId ? "" : headingId)}>
                      <i className={classNames("fa", { "fa-chevron-right": collapseGroupHeadingId !== headingId, "fa-chevron-down": collapseGroupHeadingId === headingId })} aria-hidden="true" />
                    </Button>
                    <strong className="d-block">Menu Heading: {menuHeadings[headingId]?.name}</strong>
                  </Col>
                  <Col lg={6} className="text-right">
                    <div className="d-flex gap-sm justify-content-end">
                      <Field name={`${path}.groupHeadings.${groupHeadingId}.order`} initialValue={groupHeadings?.[groupHeadingId]?.order ? groupHeadings[groupHeadingId].order : index + 1} type="hidden" component="input" />
                      
                      {/* Order Buttons */}
                      <div className="d-flex gap-sm">
                        <button className="btn btn-secondary btn-sm" type="button" onClick={() => updateMenuHeadingOrder("up", headingId)}>
                          <i className="fa fa-arrow-up"></i>
                        </button>
                        <button className="btn btn-secondary btn-sm" type="button" onClick={() => updateMenuHeadingOrder("down", headingId)}>
                          <i className="fa fa-arrow-down"></i>
                        </button>
                      </div>

                      {/* Action Buttons */}
                      <Button type="button" color="secondary" onClick={() => handleAllVisible(groupHeadingId)}>
                        All Visible
                      </Button>
                      <Button id={`menu-heading-remove${headingId}`} color="danger" onClick={() => handleRemoveHeading(headingId)} size="sm">
                        <i className="fa fa-trash" aria-hidden="true" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {collapseGroupHeadingId === headingId ? (
                <CardBody>
                  {menuHeadings[headingId]?.type === "dynamic" ? (
                    keys(groupHeadings).length > 0 && (
                      <MenuItemDynamic
                        groupHeadings={groupHeadings}
                        path={path}
                        menuOrPackage={menuOrPackage}
                        groupHeadingId={groupHeadingId}
                        pkg={pkg}
                        menuHeading={menuHeadings[headingId]}
                        isInclusion={getIsInclusion()}
                        isInclusionFoodOrDrink={getIsInclusionFoodOrDrink()}
                        removedProducts={removedProducts}
                      />
                    )
                  ) : (
                    <MenuHeadingStatic
                      groupHeadings={groupHeadings}
                      pathToGroupHeadings={path}
                      groupHeadingId={groupHeadingId}
                      sizes={productSizes}
                      pkg={pkg}
                      menuHeading={menuHeadings[headingId]}
                      isInclusion={getIsInclusion()}
                      menuOrPackage={menuOrPackage}
                      isInclusionFoodOrDrink={getIsInclusionFoodOrDrink()}
                    />
                  )}
                  {pkg === true ? (
                    <div className="pt-3">
                      <Row className="form-group">
                        <Col>
                          <Field name={`${path}.groupHeadings.${groupHeadingId}.special.isEnabled`} type="checkbox">
                            {({ input }) => (
                              <div className="checkbox c-checkbox">
                                <label className="m-0">
                                  <input type="checkbox" className="form-check-input" {...input} />
                                  <span className="fa fa-check"></span>Special Requirement Options
                                </label>
                              </div>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      {groupHeadings?.[groupHeadingId]?.special?.isEnabled && (
                        <Row className="form-group">
                          <Col>
                            <SpecialProduct type={type} fieldName={`${path}.groupHeadings.${groupHeadingId}.special`} sizes={productSizes} products={products} />
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : null}
                </CardBody>
              ) : null}
            </Card>
          </div>
        );
      })}
    </>
  );
};

export default MenuItem;
