import algoliasearch from "algoliasearch/lite";
import classNames from "classnames";
import { useAppSelector } from "components/Hooks/hooks";
import { getConfig } from "core/utilities";
import "firebase/compat/firestore";
import _, { entries, filter, findIndex, get, keys, map, pickBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { Button, Col, Row, Table } from "reactstrap";
import { AdditionalItemsCategories, BeverageMenuPackage, GroupHeadingProductSize, GroupHeadingProducts, Menu, Package } from "resbutler-utils/types/Menu";
import { Product, ProductSizes } from "resbutler-utils/types/product";
import { ProductSearchModal } from "../../resbutler-ui/components/ProductSearch/ProductSearchModal";
// Product Types
const FOOD_AND_BEVERAGE_TYPEID = 1;
const COMBINATION_FOOD_AND_BEVERAGE_TYPEID = 8;
const BEVERAGE_TYPEID = 16;

// Currency localization
const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

// eslint-disable-next-line no-undef
const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA);

export default function SpecialProduct({
  type,
  fieldName,
  sizes,
  products: allProducts,
  additionalItemsCategories = {},
  special = true,
}: {
  type: "food" | "beverage" | "foodbeverage" | "beverageMenus" | "foodInclusions" | "drinkInclusions";
  fieldName: string;
  sizes: ProductSizes;
  products: Product[];
  additionalItemsCategories?: AdditionalItemsCategories;
  special?: boolean;
}) {
  const {
    client: clientId,
    tenantId,
    config: { publicStorageBucket },
  } = getConfig();
  const { restaurantId } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
    };
  });
  const form = useForm();
  const values = useFormState<Menu | Package | BeverageMenuPackage>().values;
  const [collapseProductId, setCollapseProductId] = useState({});
  const [shouldFetchProduct, setShouldFetchProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<
    {
      productId: string;
      sizes: {
        [productSizeId: string]: boolean;
      };
    }[]
  >([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [showSearchProductModal, setSearchProductModal] = useState(false);
  const [additionalItemCategoryId, setAdditionalItemCategoryId] = useState<string>();

  useEffect(() => {
    const enabledProducts: Product[] = filter(allProducts, (p) => p.enabled && findIndex(selectedProducts, (s) => s.productId === p.id) > -1);
    // push product sizeIds to form product sizeId fields
    enabledProducts.forEach(({ id }) => {
      const selected = selectedProducts.find((product) => product.productId === id);
      const productSizeIds: { [sizeId: string]: { enabled: boolean; upgradePrice?: number; notReducedMenu?: boolean } } = {};
      for (const sizeId in selected.sizes) {
        const groupHeadingProductSize: GroupHeadingProductSize = get(values, fieldName)?.products?.[id]?.productSizeIds?.[sizeId];
        productSizeIds[sizeId] = { ...groupHeadingProductSize, enabled: true };
      }
      form.change(`${fieldName}.products.${id}.productSizeIds`, productSizeIds);
      if (additionalItemCategoryId && !get(values, `${fieldName}.products.${id}.categoryId`)) {
        form.change(`${fieldName}.products.${id}.categoryId`, additionalItemCategoryId);
      }
    });
    setProducts(sortProductByOrder(enabledProducts));
    setAdditionalItemCategoryId("");
  }, [selectedProducts, shouldFetchProduct]);

  useEffect(() => {
    if (values[type]) {
      const products1: GroupHeadingProducts = _.get(values, fieldName).products;
      if (products1) {
        const selectedProducts = entries(products1).map(([productId, { productSizeIds }]) => {
          const sizes: { [productSizeId: string]: boolean } = {};
          for (const k in productSizeIds) {
            if (productSizeIds[k].enabled) sizes[k] = true;
          }
          return { productId, sizes };
        });
        setShouldFetchProduct(true);
        setSelectedProducts(selectedProducts);
      }
    }
  }, []);

  const removeProduct = (productId) => {
    const removeProductOrder = _.get(values, fieldName).products[productId]?.order;

    const filteredProducts = _.filter(products, (product) => {
      const predicate = product.id !== productId;

      if (predicate) {
        const productOrder = _.get(values, fieldName).products[product.id]?.order;
        if (productOrder >= 2 && removeProductOrder < productOrder) {
          const order = productOrder - 1;

          // change product order state on form
          form.change(`${fieldName}.products.${product.id}.order`, order);
        }
      }

      return predicate;
    });

    const filterSelectedProducts = selectedProducts.filter((product) => product.productId !== productId);

    // remove product field state
    form.change(`${fieldName}.products.${productId}`, undefined);

    setSelectedProducts(filterSelectedProducts);
    setProducts(filteredProducts);
  };

  function sortProductByOrder(products1) {
    if (products1.length) {
      const sortedProducts = products1.sort((product1, product2) => {
        const product1Order = _.get(values, fieldName)?.products?.[product1.id]?.order;
        const product2Order = _.get(values, fieldName)?.products?.[product2.id]?.order;

        if (typeof product1Order === "number" && typeof product2Order === "number") {
          return product1Order - product2Order;
        }

        // keep original order of product1 and product2
        return 0;
      });

      return sortedProducts;
    }

    return products1;
  }

  const updateProductOrder = (action, productId) => {
    const orderProducts = [...products];
    const productIndex = orderProducts.findIndex((product) => product.id === productId);
    const requiredIndex = action === "up" ? productIndex - 1 : productIndex + 1;

    if (requiredIndex >= 0 && requiredIndex < orderProducts.length) {
      const product = orderProducts[productIndex];
      const swapProduct = orderProducts[requiredIndex];

      orderProducts[requiredIndex] = product;
      orderProducts[productIndex] = swapProduct;

      form.change(`${fieldName}.products.${product.id}.order`, requiredIndex + 1);
      form.change(`${fieldName}.products.${swapProduct.id}.order`, productIndex + 1);

      setProducts(orderProducts);
    }
  };

  return (
    <>
      <div className="input-group">
        {keys(additionalItemsCategories).length > 0 ? (
          <select onChange={(e) => setAdditionalItemCategoryId(e.target.value)} value={additionalItemCategoryId} className="form-control col-lg-3">
            <option value="">Please Select</option>
            {keys(additionalItemsCategories).map((categoryId) => {
              return (
                <option key={categoryId} value={categoryId}>
                  {additionalItemsCategories[categoryId].name}
                </option>
              );
            })}
          </select>
        ) : null}
        <Button className="mb-0" type="button" color="primary" onClick={() => setSearchProductModal(true)} disabled={!special && !additionalItemCategoryId}>
          Add Product
        </Button>
      </div>

      {/* Product Table */}
      {products.length > 0 && (
        <Row>
          <Col md="12">
            <Table bordered className="mt-3">
              <thead className="parent-header">
                <tr>
                  <th>Product name</th>
                  {!special ? <th></th> : null}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {map(products, (product, index) => {
                  const productId = product.id;
                  return (
                    <React.Fragment key={product.id + index}>
                      <tr>
                        <td className="col-6">
                          <Field name={`${fieldName}.products.${productId}.order`} defaultValue={product.order ? product.order : index + 1} type="hidden" component="input" />
                          <Button
                            type="button"
                            className="mr-2"
                            onClick={() =>
                              setCollapseProductId((state) => {
                                return { ...state, [productId]: state[productId] !== undefined ? !state[productId] : true };
                              })
                            }
                          >
                            <i className={classNames("fa", { "fa-chevron-right": !collapseProductId[productId], "fa-chevron-down": collapseProductId[productId] })} aria-hidden="true" />
                          </Button>
                          {product.name}
                        </td>
                        {!special ? <td>{additionalItemsCategories[get(values, `${fieldName}.products.${product.id}.categoryId`) as string]?.name}</td> : null}
                        <td>
                          <Row className="justify-content-end">
                            <Col className="col-auto p-0">
                              <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => updateProductOrder("up", productId)}>
                                <i className="fa fa-arrow-up"></i>
                              </button>
                            </Col>
                            <Col className="col-auto ps-1">
                              <button className="btn btn-secondary btn-sm border-0" type="button" onClick={() => updateProductOrder("down", productId)}>
                                <i className="fa fa-arrow-down"></i>
                              </button>
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <Button color="danger" type="button" onClick={() => removeProduct(productId)}>
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                      {collapseProductId[productId] && keys(product.sizes).length > 0 && (
                        <tr className="p-3">
                          <td colSpan={4}>
                            <Table bordered className="m-0">
                              <thead>
                                <tr>
                                  <th>Product Size</th>
                                  <th>Standard Pricing</th>
                                  <th>Override Price</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <Field name={`${fieldName}.products.${productId}.productSizeIds`}>
                                  {({ input: { onChange, value, name } }) => {
                                    return keys(value).map((productSizeId) => {
                                      return (
                                        <tr key={name}>
                                          <td>{sizes?.[productSizeId]?.name}</td>
                                          <td>{product.restaurants?.[restaurantId]?.price?.[productSizeId] ? formatter.format(product.restaurants[restaurantId]?.price?.[productSizeId]) : ""}</td>
                                          <td>
                                            <input type="number" className="form-control" onChange={(e) => onChange({ ...value, [productSizeId]: { ...value[productSizeId], upgradePrice: e.target.value ? Number(e.target.value) : null } })} value={value[productSizeId]?.upgradePrice} />
                                          </td>
                                          <td>
                                            <Button color="danger" type="button" onClick={() => onChange(pickBy(value, (v, k) => k !== productSizeId))}>
                                              <i className="fa fa-trash" />
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    });
                                  }}
                                </Field>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {/* Product Table:End */}

      {/* Product Search Modal */}
      <ProductSearchModal
        clientId={clientId}
        publicStorageBucket={publicStorageBucket}
        initialValues={selectedProducts}
        open={showSearchProductModal}
        setOpen={setSearchProductModal}
        onCloseData={(selectedProducts) => {
          if (selectedProducts.length) {
            setShouldFetchProduct(true);
            setSelectedProducts(selectedProducts);
          }
          setSearchProductModal(false);
        }}
        query={`typeId:${FOOD_AND_BEVERAGE_TYPEID} OR typeId:${COMBINATION_FOOD_AND_BEVERAGE_TYPEID} OR typeId:${BEVERAGE_TYPEID}`}
        tenantId={tenantId}
        searchClient={searchClient}
        restaurantId={restaurantId}
        productSizes={sizes}
      />
      {/* Product Search Modal: End */}
    </>
  );
}
