import classNames from "classnames";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, FormSpy } from "react-final-form";
// import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import { catchExceptionCallback } from "../../../resbutler-ui/utilities";
import { priceFormatter } from "../../../resbutler-ui/utils/transactionDetail";
import { GiftCertificate } from "../../../resbutler-utils/types/GiftCertificate";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { Tills } from "../../../resbutler-utils/types/Till";
import { Transaction } from "../../../resbutler-utils/types/transaction";
import TransactionModal from "../TransactionModals/TransactionModal";
import TransactionRefundModal from "../TransactionRefundModal/TransactionRefundModal";
import AmountDeductModal from "./AmountDeductModal";
import RedeemTransaction from "./RedeemTransaction";

export const Customer = ({ customerId, clientId }) => {
  const [customer, setCustomer] = useState({}); // loaded customer
  const { firstName, lastName } = customer || ({ firstName: "", lastName: "" } as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const doc = await firebase.firestore().doc(`${clientId}/crm/customers/${customerId}`).get();
        setCustomer(doc.data());
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (customerId) fetchData();
  }, [customerId]);

  return !loading ? (
    <p
      style={{ color: "blue" }}
      onClick={() => {
        window.open(`/crm/customers/${customerId}`, "_blank");
      }}
    >
      {firstName} {lastName}
    </p>
  ) : null;
};

interface IssuedGiftCertificatesDetailsProps {
  giftCertificate: GiftCertificate;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  giftCertificateId: string;
  restaurantId?: string;
  mealId?: string;
  date?: string;
  tills?: Tills;
  tillId?: string;
  restaurants: Restaurants;
  clientId: string;
  resbutlerApis: string;
}

const IssuedGiftCertificatesDetails = (props: IssuedGiftCertificatesDetailsProps) => {
  const [modal, setModal] = useState(false);
  const { giftCertificate, giftCertificateId, setModalOpen, date, mealId, tills, tillId, restaurants, clientId, resbutlerApis } = props;
  const [collapseTransactionId, setCollapseTransactionId] = useState({});
  const [transaction, setTransaction] = useState<Transaction>(null);
  const [transOpen, setTransOpen] = useState(false);
  // const [selectedPaymentRefund, setSelectedPaymentRefund] = useState<PaymentType>(null);
  const [transactionPaymentForRefund, setTransactionPaymentForRefund] = useState(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(giftCertificate.transactionId);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getTransaction = async () => {
      setLoading(true);
      const [transactionDoc] = await Promise.all([firebase.firestore().doc(`${clientId}/payments/transactions/${giftCertificate.transactionId}`).get()]);
      if (transactionDoc.exists) {
        setTransaction({ ...transactionDoc.data(), _key: giftCertificate.transactionId } as Transaction);
      }
      setLoading(false);
    };
    getTransaction();
  }, []);

  if (loading) return null;

  return (
    <FormSpy subscription={{ values: true }}>
      {() => (
        <div>
          <p>Gift Certificate Number</p>
          <h4 className="fw-bold pb-4">{giftCertificate.voucherNumber}</h4>
          <h4 className="fw-bold">Summary</h4>
          <Table striped responsive className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Issuer</th>
                <th>Gift Certificate Number</th>
                <th>3rd Party Gift Certificate Number</th>
                <th>Amount Issued</th>
                <th>Amount Redeemed</th>
                <th>Amount Remaining</th>
                <th>Redeem Times</th>
                <th>Times Redeemed</th>
                <th>Issued Date</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>{giftCertificate.voucherNumber}</td>
                <td></td>
                <td>{priceFormatter.format(giftCertificate.amount)}</td>
                <td>{priceFormatter.format(giftCertificate.amountRedeemed)}</td>
                <td>{priceFormatter.format(giftCertificate.amount - giftCertificate.amountRedeemed)}</td>
                <td>{giftCertificate.redeemQuantity}</td>
                <td>{giftCertificate.quantityRedeemed}</td>
                <td>{moment(giftCertificate.selectedDate, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                <td>{moment(giftCertificate.expiryDate, "YYYYMMDD").format("DD/MM/YYYY")}</td>
              </tr>
            </tbody>
          </Table>
          <Button type="button" color="primary" className="float-end" onClick={() => setModal(true)}>
            Manual Redeem
          </Button>
          <AmountDeductModal modal={modal} setModal={setModal} setModalOpen={setModalOpen} giftCertificate={giftCertificate} giftCertificateId={giftCertificateId} resbutlerApis={resbutlerApis} clientId={clientId} />
          {giftCertificate.redeemedInfo && giftCertificate.redeemedInfo.length > 0 && (
            <>
              <h4 className="fw-bold pt-4">Redeems</h4>
              <Table striped responsive className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Restaurant</th>
                    <th>Date</th>
                    <th>Amount Redeemed</th>
                  </tr>
                </thead>
                {giftCertificate.redeemedInfo &&
                  giftCertificate.redeemedInfo.length > 0 &&
                  giftCertificate.redeemedInfo.map((redeem) => {
                    return (
                      redeem.transactionId && (
                        <tbody key={redeem.transactionId}>
                          <tr key={redeem.transactionId}>
                            <td>
                              <Button
                                type="button"
                                onClick={() =>
                                  setCollapseTransactionId((state) => {
                                    return { ...state, [redeem.transactionId]: state[redeem.transactionId] !== undefined ? !state[redeem.transactionId] : true };
                                  })
                                }
                              >
                                <i className={classNames("fa", { "fa-chevron-right": !collapseTransactionId[redeem.transactionId], "fa-chevron-down": collapseTransactionId[redeem.transactionId] })} aria-hidden="true" />
                              </Button>
                            </td>
                            <td>{restaurants[giftCertificate.restaurantId].name}</td>
                            <td>{moment(redeem.redeemedDate, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                            <td>{priceFormatter.format(redeem.redeemedAmount)}</td>
                          </tr>
                          {collapseTransactionId[redeem.transactionId] && (
                            <tr key={redeem.transactionId}>
                              <td colSpan={5}>
                                <RedeemTransaction key={redeem.transactionId} collapseTransactionId={collapseTransactionId} redeem={redeem} clientId={clientId} resbutlerApis={resbutlerApis} restaurants={restaurants} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )
                    );
                  })}

                <tbody>
                  <tr>
                    <td colSpan={4} className="fw-bold">
                      Total Amount Issued
                    </td>
                    <td>{priceFormatter.format(giftCertificate.amount)}</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="fw-bold">
                      Total Amount Redeemed
                    </td>
                    <td>-{priceFormatter.format(giftCertificate.amountRedeemed)}</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="fw-bold">
                      Total Amount Remaining
                    </td>
                    <td>{priceFormatter.format(giftCertificate.amount - giftCertificate.amountRedeemed)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          <Row>
            <Col>
              <h4 className="fw-bold pt-4">Purchase Summary</h4>
              <Table striped responsive className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>Purchaser</td>
                    <td>
                      <Customer customerId={giftCertificate.customerId} clientId={clientId} />
                    </td>
                  </tr>
                  <tr>
                    <td>Recipient</td>
                    <td>
                      <Customer customerId={giftCertificate.recipientCustomerId} clientId={clientId} />
                    </td>
                  </tr>
                  <tr>
                    <td>Amount Charged</td>
                    <td>{transaction ? priceFormatter.format(transaction.grandTotal) : "-"}</td>
                  </tr>
                  <tr>
                    <td>Fees & Surcharges</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Transaction</td>
                    <td onClick={() => setTransOpen(true)} style={{ color: "blue" }}>
                      {giftCertificate.transactionId}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <h4 className="fw-bold pt-4">Delivery Summary</h4>
              <Table striped responsive className="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>Delivery Method</td>
                    <td>{giftCertificate.dMethod}</td>
                  </tr>
                  <tr>
                    <td>Send Date</td>
                    <td>{moment(giftCertificate.selectedDate, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <td>Sent</td>
                    <td>{giftCertificate.sent === true ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <label>Note</label>
          <Field name="note" className="form-control">
            {({ input: { ...rest } }) => (
              <div className="mb-3 mt-3">
                <textarea rows={5} {...rest} className="form-control" />
              </div>
            )}
          </Field>
          <div className="checkbox c-checkbox">
            <label className="mb-1" htmlFor="enabled1">
              <Field id="enabled1" name="enabled" component="input" type="checkbox" />
              <span className="fa fa-check" />
              Enabled
            </label>
          </div>
          {transOpen ? <TransactionModal transaction={transaction} onClose={setTransOpen} setSelectedTransactionId={setSelectedTransactionId} setTransactionPaymentForRefund={setTransactionPaymentForRefund} resbutlerApis={resbutlerApis} tills={tills} tillId={tillId} restaurants={restaurants} restaurantId={giftCertificate.restaurantId} clientId={clientId} /> : null}
          {transactionPaymentForRefund ? <TransactionRefundModal resbutlerApis={resbutlerApis} payment={transactionPaymentForRefund} date={date} mealId={mealId} refundTransactions={[transaction]} transaction={transaction} selectedTransactionId={selectedTransactionId} setTransactionOpen={setTransOpen} setTransactionPaymentForRefund={setTransactionPaymentForRefund} restaurantId={giftCertificate.restaurantId} paymentProvider={null} offlineMode={false} operatorId="" tillId="" transactionNumber={null} /> : null}
        </div>
      )}
    </FormSpy>
  );
};

export default IssuedGiftCertificatesDetails;
