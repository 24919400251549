import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { filter } from "lodash";
import { useState } from "react";
import { Field, Form, useForm, useFormState } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { VoucherSetup } from "../../../resbutler-utils/types/Voucher";
import { Customer } from "../../../resbutler-utils/types/customer";
import { catchExceptionCallback } from "../../utilities";
import { validateEmail } from "../../utils/validators";

export function getCustomerId(clientId: string) {
  return firebase.firestore().collection(`${clientId}/crm/customers`).doc().id;
}

const CustomerModal = ({ openModal, setOpenModal, onSave, resbutlerApis }: { openModal: boolean; setOpenModal: any; onSave: any; resbutlerApis: string }) => {
  const toogle = () => setOpenModal(!openModal);
  const [initialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    mobileCode: "au",
    company: "",
  });

  const handleSubmit = async (values: Customer) => {
    try {
      const response = await axios.post(`${resbutlerApis}/crm/customer/get-or-upsert`, [{ customer: values, customerId: "" }]);
      if (response.data?.length) {
        const { customer, customerId } = response.data[0];
        onSave({
          label: `${customer.lastName} ${customer.firstName} (${customer.email})`,
          value: customerId,
        });
      }
      setOpenModal(false);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  const validate = (values) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = "First name is empty";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is empty";
    }
    if (!values.email) {
      errors.email = "Email is empty";
    } else if (!validateEmail(values.email)) {
      errors.email = "Email invalid format";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile is required";
    }
    return errors;
  };

  return (
    <Modal scrollable isOpen={openModal} size="lg" style={{ maxWidth: "800px", width: "100%" }} toggle={toogle} centered>
      <ModalHeader toggle={toogle}>New Customer</ModalHeader>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <Field name="firstName">
                {({ input: { onChange, value }, meta }) => {
                  return (
                    <FormGroup>
                      <Label>
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <input type="text" className="form-control" value={value} onChange={onChange} />
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="lastName">
                {({ input: { onChange, value }, meta }) => {
                  return (
                    <FormGroup>
                      <Label>
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <input type="text" className="form-control" value={value} onChange={onChange} />
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="email">
                {({ input: { onChange, value }, meta }) => {
                  return (
                    <FormGroup>
                      <Label>
                        Email <span className="text-danger">*</span>
                      </Label>
                      <input type="text" className="form-control" value={value} onChange={onChange} />
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="mobile">
                {({ input: { onChange, value, onBlur }, meta }) => {
                  return (
                    <FormGroup>
                      <Label>
                        Mobile <span className="text-danger">*</span>
                      </Label>
                      <PhoneInput inputClass="form-control" country={"au"} value={String(value)} onlyCountries={["au", "nz", "us", "ca", "uk", "cn", "jp", "kr", "sg", "ph"]} onBlur={onBlur} onChange={onChange} masks={{ au: "... ... ..." }} />
                      <div>{meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}</div>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="company">
                {({ input: { onChange, value }, meta }) => {
                  return (
                    <FormGroup>
                      <Label>Company</Label>
                      <input type="text" className="form-control" value={value} onChange={onChange} />
                    </FormGroup>
                  );
                }}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button color="light" onClick={toogle}>
                Close
              </Button>
              <Button color="success" type="submit" disabled={submitting}>
                Save {submitting && <Spinner size="sm" />}
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};

const VoucherCustomerFiltersForm = ({ disabled, clientId, resbutlerApis, customerGroups }: { disabled: boolean; clientId: string; resbutlerApis: string; customerGroups: any }) => {
  const [openModal, setOpenModal] = useState(false);
  const enabledMembershipOptions = filter(customerGroups, (level) => level.isNonMember !== true && level.enabled).map((level) => ({ value: level._key, label: level.accountName }));

  const filterCustomers = async (inputValue) => {
    if (!inputValue) return [];

    const snap = await firebase.firestore().collection(`${clientId}/crm/customers`).where("lastName_low", ">=", inputValue.toLowerCase()).where("lastName_low", "<=", `${inputValue.toLowerCase()}\uf8ff`).get();
    const customers = snap.docs.map((snap1) => {
      const data = snap1.data();
      return { label: `${data.lastName} ${data.firstName} (${data.email})`, value: snap1.id };
    });
    return customers;
  };

  function getReactSelectValue(opts, val) {
    const parsedVal = val ? val : [];
    return opts.filter((o) => parsedVal.includes(o.value));
  }

  const values = useFormState<VoucherSetup>().values;
  const form = useForm();

  const onSave = (option: { label: string; value: string }) => {
    if (option) {
      form.change("customerFilters.specificCustomerObjects", [...(values.customerFilters?.specificCustomerObjects || []), option]);
    }
  };
  return (
    <div>
      <h5>
        <b>Customers</b>
      </h5>

      {values.type == "oneOff" ? (
        <>
          <Field name="customerFilters.specificCustomerObjects">
            {({ input: { onChange, value } }) => {
              return (
                <FormGroup className="col-md-12 pl-0">
                  <Label>Existing Customers</Label>
                  <AsyncSelect isMulti blurInputOnSelect={false} closeMenuOnSelect={false} cacheOptions value={value} loadOptions={filterCustomers} onChange={(v) => onChange(v)} isDisabled={disabled} />
                </FormGroup>
              );
            }}
          </Field>
          <Button className={disabled && "d-none"} id="campaign-save" type="button" color="primary" onClick={() => setOpenModal(true)} disabled={disabled}>
            Create New Customer
          </Button>
        </>
      ) : (
        <Row>
          <Col className="col-md-10 mb-3 pl-2">
            <Field name="customerFilters.filterType">
              {({ input: { onChange, value } }) => (
                <>
                  {[
                    { label: "All Customers", value: "all" },
                    { label: "Specific Customers", value: "specific" },
                    { label: "Customers by Filters", value: "filter" },
                  ].map(({ label, value: v }) => (
                    <Label key={`render-radio-${v}`} check className="ml-4">
                      &nbsp; <Input disabled={disabled} type="radio" id={v} checked={v === value} onChange={() => onChange(v)} /> {label}
                    </Label>
                  ))}
                </>
              )}
            </Field>
          </Col>

          <Field name="customerFilters.specificCustomerObjects">
            {({ input: { onChange, value } }) => {
              return values.customerFilters.filterType === "specific" ? (
                <FormGroup className="col-md-10">
                  <Label>Customers</Label>
                  <AsyncSelect isMulti blurInputOnSelect={false} closeMenuOnSelect={false} cacheOptions value={value} loadOptions={filterCustomers} onChange={(v) => onChange(v)} isDisabled={disabled} />
                </FormGroup>
              ) : null;
            }}
          </Field>

          <Field name="customerFilters.selectedFilters">
            {({ input: { onChange, value } }) => {
              return values.customerFilters.filterType === "filter" ? (
                <FormGroup className="col-md-10">
                  <Label>
                    Select Filters <span className="text-danger">*</span>
                  </Label>
                  <Select isMulti blurInputOnSelect={false} closeMenuOnSelect={false} options={filterOptions} value={getReactSelectValue(filterOptions, value)} onChange={(e) => onChange(e ? e.map((x) => x.value) : [])} isDisabled={disabled} />
                </FormGroup>
              ) : null;
            }}
          </Field>

          <Field name="customerFilters.membershipLevels">
            {({ input: { onChange, value } }) => {
              return values.customerFilters.filterType === "filter" && values.customerFilters.selectedFilters?.includes("Membership") ? (
                <FormGroup className="col-md-10">
                  <Label>Membership</Label>
                  <span className="text-danger">*</span>
                  <Select isMulti blurInputOnSelect={false} closeMenuOnSelect={false} options={enabledMembershipOptions} value={getReactSelectValue(enabledMembershipOptions, value)} onChange={(e) => onChange(e ? e.map((x) => x.value) : [])} isDisabled={disabled} />
                </FormGroup>
              ) : null;
            }}
          </Field>

          <Field name="customerFilters.birthdayMonth">
            {({ input: { onChange, value } }) => {
              return values.customerFilters.filterType === "filter" && values.customerFilters.selectedFilters?.includes("Birthday") ? (
                <FormGroup className="col-md-10">
                  <Label>Birthday</Label>
                  <div className="row">
                    {relativeMonthOptions.map(({ label, value: v }) => {
                      return (
                        <div key={`key-${v}`} className="col-3 c-radio">
                          <label>
                            <input type="radio" name="birthdayMonth" value={value} checked={v === value} onChange={() => onChange(v)} key={value} disabled={disabled} />
                            <span className="fa fa-circle"></span>
                            {label}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </FormGroup>
              ) : null;
            }}
          </Field>

          <Field name="customerFilters.age">
            {({ input: { onChange, value } }) => {
              return values.customerFilters.filterType === "filter" && values.customerFilters.selectedFilters?.includes("Age") ? (
                <Row style={{ paddingLeft: "18px" }}>
                  <Col>
                    <FormGroup>
                      <span>
                        <label htmlFor="minAge">Min Age</label>
                        <Input type="number" min="0" max="120" onChange={(e) => onChange({ ...value.age, minAge: e.target.value })} disabled={disabled} />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <span>
                        <label htmlFor="maxAge">Max Age</label>
                        <Input type="number" min="0" max="120" onChange={(e) => onChange({ ...value.age, maxAge: e.target.value })} disabled={disabled} />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              ) : null;
            }}
          </Field>
        </Row>
      )}
      {openModal && <CustomerModal openModal={openModal} setOpenModal={setOpenModal} onSave={onSave} resbutlerApis={resbutlerApis} />}
    </div>
  );
};

const filterOptions = [
  { value: "Membership", label: "Membership" },
  { value: "Birthday", label: "Birthday" },
  { value: "Age", label: "Age" },
];

const relativeMonthOptions = [
  { label: "Current Month", value: "currentMonth" },
  { label: "Next Month", value: "nextMonth" },
];

export default VoucherCustomerFiltersForm;
