import { keys, pickBy } from "lodash";
/**
 * @param val: Number
 * @param isCents: Boolean
 */
export const toCurrency = (val, isCents = false) =>
  new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(val / (isCents ? 100 : 1));

export const priceFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  minimumFractionDigits: 2,
});

export const redemptionCodeFormat = (code) => {
  return code?.substr(2);
};

export const PAYMENT_METHODS = {
  EFTPOS: { text: "EFTPOS", value: 7 },
  CASH: { text: "Cash", value: 1 },
};

export const getServerCacheBust = (servers, restaurantId) => {
  const restaurantServers = pickBy(servers, (s) => s.restaurantId === restaurantId);
  if (keys(restaurantServers).length > 0) {
    const serverId = keys(restaurantServers)[0];
    const server = servers[serverId];
    return btoa(server.updatedAt);
  }
  return "";
};
