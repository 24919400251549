import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import IssuedGiftCertificatesDetails from "resbutler-ui/components/GiftCertificateModal/IssuedGiftCertificatesDetails";
import IssuedGiftCertificatesRestaurants from "resbutler-ui/components/GiftCertificateModal/IssuedGiftCertificatesRestaurants";
import { getConfig } from "core/utilities";

function IssuedGiftCertificatesForm(props) {
  const { giftCertificateId, giftCertificate } = props;

  const { client: clientId, resbutlerApis } = getConfig();

  const [tabs, setTabs] = useState(1);
  const allRestaurants = useSelector((state: any) => state.root.restaurants);

  return (
    <Row>
      <Col sm={12}>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: tabs === 1 })} onClick={() => setTabs(1)}>
              <span className="px-1">Details</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: tabs === 2 })} onClick={() => setTabs(2)}>
              <span className="px-1">Restaurants</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent id="issued-gift-certificate-details" activeTab={tabs} className={tabs !== 1 ? "hide-tab" : ""}>
          <TabPane tabId={1}>{tabs === 1 ? <IssuedGiftCertificatesDetails giftCertificate={giftCertificate} giftCertificateId={giftCertificateId} restaurants={allRestaurants} clientId={clientId} resbutlerApis={resbutlerApis} /> : null}</TabPane>
        </TabContent>
        <TabContent id="issued-gift-certificate-restaurant" activeTab={tabs} className={tabs !== 2 ? "hide-tab" : ""}>
          <TabPane tabId={2}>{tabs === 2 ? <IssuedGiftCertificatesRestaurants giftCertificateId={giftCertificateId} restaurants={allRestaurants} giftCertificate={giftCertificate} /> : null}</TabPane>
        </TabContent>
      </Col>
    </Row>
  );
}

export default IssuedGiftCertificatesForm;
