import { catchExceptionCallback } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";

const Offsidebar = () => {
  const [offsidebarReady, setOffsidebarReady] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  useEffect(() => {
    setOffsidebarReady(true);
  }, []);

  function logout() {
    setLoadingLogout(true);

    firebase
      .auth()
      .signOut()
      .then(() => {
        setLoadingLogout(false);
        window.location.href = "/login";
      })
      .catch((error) => {
        catchExceptionCallback(error);
        setLoadingLogout(false);
      });
  }

  return (
    offsidebarReady && (
      <aside className="offsidebar">
        <div className="aside-inner">
          {/* START Off Sidebar (right) */}
          <div className="p-3">
            <Button type="button" className="col-lg-12 btn-block" onClick={() => logout()}>
              <em className="icon-logout"></em>
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                Logout {loadingLogout && <Spinner size="sm" />}
              </span>
            </Button>
          </div>
          {/* END Off Sidebar (right) */}
        </div>
      </aside>
    )
  );
};

Offsidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  theme: PropTypes.object,
};

export default Offsidebar;
