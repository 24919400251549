import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { Tills } from "../../../resbutler-utils/types/Till";
import TransactionModal from "../TransactionModals/TransactionModal";

interface RedeemTransactionProps {
  collapseTransactionId: { [transactionId: string]: boolean };
  redeem: any;
  clientId: string;
  resbutlerApis: string;
  tillId?: string;
  tills?: Tills;
  restaurants: Restaurants;
}

const RedeemTransaction = ({ collapseTransactionId, redeem, clientId, resbutlerApis, tillId, tills, restaurants }: RedeemTransactionProps) => {
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [transactionOpen, setTransactionOpen] = useState(false);

  useEffect(() => {
    const getTransaction = async () => {
      setLoading(true);
      const transactionSnap = await firebase.firestore().doc(`${clientId}/payments/transactions/${redeem.transactionId}`).get();
      setTransaction({ ...transactionSnap.data(), _key: transactionSnap.id });
      setLoading(false);
    };
    getTransaction();
  }, [collapseTransactionId[redeem.transactionId]]);

  return loading ? (
    <>
      <div className="fa-2x" style={{ textAlign: "center" }}>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    </>
  ) : (
    <Table striped responsive className="table table-striped table-bordered">
      <tbody>
        <tr>
          <td>Transaction</td>
          <td onClick={() => setTransactionOpen(true)} style={{ color: "blue" }}>
            {transaction._key}
          </td>
        </tr>
      </tbody>
      {transactionOpen ? <TransactionModal transaction={transaction} onClose={setTransactionOpen} setSelectedTransactionId={null} setTransactionPaymentForRefund={setTransactionOpen} resbutlerApis={resbutlerApis} tills={tills} tillId={tillId} restaurants={restaurants} restaurantId={transaction.restaurantId} clientId={clientId} /> : null}
    </Table>
  );
};

export default RedeemTransaction;
