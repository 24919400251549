import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { find } from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from "reactstrap";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { Tills } from "../../../resbutler-utils/types/Till";
import { PaymentTypeId, Transaction, TransactionType } from "../../../resbutler-utils/types/transaction";
import { catchExceptionCallback, transactionNumberPad } from "../../utilities";
import { priceFormatter } from "../../utils/transactionDetail";
import SendEmailModal from "../Till/SendEmailModal";
import { PAYMENT_METHODS, onReceiptPrintClick } from "./TransactionUtils";

const EftposReceiptModal = ({ setEftposReceiptOpen, restaurantId, printerId, purchaseId, clientId }) => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [purchase, setPurchase] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const purchasesSnap = await firebase.firestore().doc(`${clientId}/payments/purchases/${purchaseId}`).get();
        setPurchase({ ...purchasesSnap.data(), _key: purchasesSnap.id });
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <Modal size="lg" scrollable isOpen zIndex={9999}>
      <ModalHeader toggle={() => setEftposReceiptOpen(false)}>EFTPOS Receipt</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <pre>{!loading && purchase?.response ? purchase.response.customerReceipt : "No Receipt to print"}</pre>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="col-lg-2 btn-lg text-nowrap" onClick={() => setEftposReceiptOpen(false)}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={loading1}
          className="col-lg-2 btn-lg text-nowrap"
          onClick={async () => {
            try {
              setLoading1(true);
              await onReceiptPrintClick(printerId, restaurantId, purchase._key);
            } catch (e) {
              catchExceptionCallback(e);
            } finally {
              setLoading1(false);
            }
          }}
        >
          Print
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const PaymentTypeDetails = ({ item, paymentTypeOpen, restaurantId, printerId, clientId }) => {
  const [eftposReceiptOpen, setEftposReceiptOpen] = useState(false);
  return (
    paymentTypeOpen && (
      <>
        {item.paymentTypeId === PAYMENT_METHODS.CASH.value && (
          <>
            <Table>
              <tbody>
                <tr>
                  <td>Tip</td>
                  <td>{priceFormatter.format(item.tipAmount || 0)}</td>
                </tr>
                <tr>
                  <td>Cash Received</td>
                  <td>{priceFormatter.format(item.cashReceived)}</td>
                </tr>
                <tr>
                  <td>Change</td>
                  <td>{priceFormatter.format(item.cashChange)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{priceFormatter.format(item.value)}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
        {(item.paymentTypeId === PAYMENT_METHODS.EFTPOS.value || item.paymentTypeId === PAYMENT_METHODS.OFFLINE.value) && (
          <>
            <Table>
              <tbody>
                <tr>
                  <td>Card Ending with</td>
                  <td>{item.card}</td>
                </tr>
                <tr>
                  <td>Tip</td>
                  <td>{priceFormatter.format(item.tipAmount || 0)}</td>
                </tr>
                <tr>
                  <td>Payment Service Fee</td>
                  <td>{priceFormatter.format(item.serviceFee || 0)}</td>
                </tr>
                <tr>
                  <td>Transaction Service Fee</td>
                  <td>{priceFormatter.format(item.transactionFee || 0)}</td>
                </tr>
                <tr>
                  <td>Cashout Amount</td>
                  <td>{priceFormatter.format(item.cashoutAmount || 0)}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{priceFormatter.format(item.value)}</td>
                </tr>
              </tbody>
            </Table>
            <Button
              type="button"
              color="primary"
              style={{ margin: "10px" }}
              onClick={() => {
                setEftposReceiptOpen(true);
              }}
            >
              EFTPOS Receipt
            </Button>
          </>
        )}
        {item.paymentTypeId === PAYMENT_METHODS.GIFTCERTIFICATE.value && (
          <Table>
            <tbody>
              <tr>
                <td>Total</td>
                <td>{priceFormatter.format(item.value)}</td>
              </tr>
            </tbody>
          </Table>
        )}
        {eftposReceiptOpen ? <EftposReceiptModal setEftposReceiptOpen={setEftposReceiptOpen} restaurantId={restaurantId} printerId={printerId} purchaseId={item.purchaseId} clientId={clientId} /> : null}
      </>
    )
  );
};

interface TransactionModalProps {
  onClose: (close: boolean) => void;
  transaction: Transaction;
  setTransactionPaymentForRefund: any;
  setSelectedTransactionId: any;
  clientId: string;
  resbutlerApis: string;
  tills?: Tills;
  tillId?: string;
  restaurants: Restaurants;
  restaurantId: string;
}

const TransactionModal = ({ onClose, transaction, setTransactionPaymentForRefund, setSelectedTransactionId, clientId, resbutlerApis, tills, tillId, restaurants, restaurantId }: TransactionModalProps) => {
  const [paymentTypeOpen, setPaymentTypeOpen] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const printerId = tills?.[tillId]?.printerId || null;
  let total = 0;
  const lineItems = transaction.lineItems;
  if (lineItems.length > 0) {
    lineItems.forEach((item) => (total += item.total));
  }
  const restaurant = restaurants[restaurantId];

  const sendInvoiceEmail = async (email) => {
    try {
      setLoading(true);
      await axios.post(`${resbutlerApis}/onsite/payments/emailPaymentInvoice`, {
        email,
        transactionId: transaction._key,
        restaurantId: transaction.restaurantId,
        mealId: transaction.mealId,
        date: transaction.date,
      });
      toast.success(`Email Success`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setLoading(false);
      setSendEmailModal(false);
    }
  };

  return (
    <Modal isOpen scrollable size="lg" zIndex={9999}>
      <ModalHeader>Transaction Details</ModalHeader>
      <ModalBody>
        <Table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>Transaction Number</td>
              <td>{transaction.transactionNumber && transactionNumberPad(transaction.transactionNumber)}</td>
            </tr>
            <tr>
              <td>Transaction ID</td>
              <td>{transaction._key}</td>
            </tr>
            <tr>
              <td>Date</td>
              <td>
                {moment(transaction.date, "YYYYMMDD").format("DD/MM/YYYY")}, {transaction.time}
              </td>
            </tr>
            <tr>
              <td>Line Items</td>
              <td>
                <Table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <td>Item</td>
                      <td>Quantity</td>
                      <td>Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    {(lineItems || []).map((item, key) => (
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{priceFormatter.format(item.price)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody>
                    <tr>
                      <td colSpan={2}>Total</td>
                      <td>{priceFormatter.format(total)}</td>
                    </tr>
                    {transaction.tipAmount > 0 && (
                      <tr>
                        <td colSpan={2}>Tip Amount</td>
                        <td>{priceFormatter.format(transaction.tipAmount)}</td>
                      </tr>
                    )}
                    {(transaction.surcharges || []).map((surcharge) => (
                      <tr key={`surcharge-${surcharge.id}`}>
                        <td colSpan={2}>{surcharge.name}</td>
                        <td>{priceFormatter.format(surcharge.value)}</td>
                      </tr>
                    ))}
                    {transaction.serviceCharge && (
                      <tr>
                        <td colSpan={2}>{transaction.serviceCharge.name}</td>
                        <td>{priceFormatter.format(transaction.serviceCharge.value)}</td>
                      </tr>
                    )}
                    {transaction.voucherAmount > 0 && (
                      <tr className="text text-success">
                        <td colSpan={2}>Voucher Amount</td>
                        <td>-{priceFormatter.format(transaction.voucherAmount)}</td>
                      </tr>
                    )}
                    {transaction.promoCodeAmount > 0 && (
                      <tr className="text text-success">
                        <td colSpan={2}>Promo Code Amount</td>
                        <td>-{priceFormatter.format(transaction.promoCodeAmount)}</td>
                      </tr>
                    )}
                    {transaction.serviceFee > 0 && (
                      <tr>
                        <td colSpan={2}>Service Fee</td>
                        <td>{priceFormatter.format(transaction.serviceFee)}</td>
                      </tr>
                    )}
                    {transaction.transactionFee > 0 && (
                      <tr>
                        <td colSpan={2}>Transaction Fee</td>
                        <td>{priceFormatter.format(transaction.transactionFee)}</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2}>Grand Total</td>
                      <td>{priceFormatter.format(transaction.grandTotal)}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>Payments</td>
              <td>
                <Table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <td>Payment Type</td>
                      <td>Time</td>
                      <td>Value</td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {(transaction.payments || []).map((item, key) => (
                      <React.Fragment key={key}>
                        <tr key={key}>
                          <td>
                            <Button type="button" className="me-2" onClick={() => setPaymentTypeOpen({ ...paymentTypeOpen, [key]: !paymentTypeOpen[key] })}>
                              <i className={!paymentTypeOpen[key] ? "fa fa-chevron-right" : "fa fa-chevron-down"} aria-hidden="true" />{" "}
                            </Button>
                            {find(PAYMENT_METHODS, (pm) => pm.value === item.paymentTypeId)?.text || ""}
                          </td>
                          <td>
                            {item.timeStamp && moment.unix(item.timeStamp / 1000).format("DD/MM/YYYY")}, {moment.unix(item.timeStamp / 1000).format("hh:mmA")}
                          </td>
                          <td>{priceFormatter.format(item.value)}</td>
                          <td>
                            {(printerId || item.paymentTypeId === PaymentTypeId.Stripe) && [PaymentTypeId.Eftpos, PaymentTypeId.Cash, PaymentTypeId.Stripe].includes(item.paymentTypeId) && transaction.typeId !== TransactionType.Refund && (
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                  setTransactionPaymentForRefund(item);
                                  setSelectedTransactionId(transaction._key);
                                }}
                              >
                                Refund
                              </Button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <PaymentTypeDetails item={item} paymentTypeOpen={paymentTypeOpen[key] || false} restaurantId={restaurantId} printerId={printerId} clientId={clientId} />
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>Restaurant</td>
              <td>{restaurant?.name}</td>
            </tr>
            <tr>
              <td>ResButler Fee</td>
              <td>{priceFormatter.format(transaction.resbutlerFee || 0)}</td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="col-lg-2 col-sm-4 btn-lg text-nowrap" color="light" onClick={() => onClose(false)}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          className="col-lg-2 col-sm-4 btn-lg text-nowrap"
          onClick={() => {
            setSendEmailModal(true);
          }}
        >
          Email Invoice
        </Button>
        {printerId && (
          <Button
            type="button"
            disabled={loading}
            color="primary"
            className="col-lg-2 col-sm-4 btn-lg text-nowrap"
            onClick={async () => {
              try {
                setLoading(true);
                await onReceiptPrintClick(printerId, restaurantId, null, transaction._key);
              } catch (e) {
                catchExceptionCallback(e);
              } finally {
                setLoading(false);
              }
            }}
          >
            Print Invoice {loading && <Spinner type="grow" size="sm" />}
          </Button>
        )}
      </ModalFooter>
      {sendEmailModal && <SendEmailModal onSend={sendInvoiceEmail} setSendEmailModal={setSendEmailModal} loading={loading} />}
    </Modal>
  );
};

export default TransactionModal;
