import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import "firebase/compat/auth";
import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Col, Modal, ModalBody, ModalFooter, Row, Spinner } from "reactstrap";
import { catchExceptionCallback } from "../../utilities";

const OperatorLockScreen = ({ serverSidePermissionCheck, permission, callback, onClose, disableClose, config }) => {
  const inputPinRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputPinRef.current) {
        inputPinRef.current.focus();
      }
    }, 0);
  }, [inputPinRef]);

  function validate(values) {
    const errors: any = {};
    if (!values.code || values.code.length > 4 || !Number.isInteger(Number(values.code))) {
      errors.code = "Invalid Operator code";
    }
    return errors;
  }

  async function onSubmit(values) {
    let operatorId = "";
    try {
      if (!serverSidePermissionCheck) {
        const { functions, client } = config;
        const result = await axios.post(`${functions}/bookings/check-operator-permission`, { operatorCode: values.code, permission }, { headers: { "client-id": client } });
        operatorId = result.data.operatorId;
      }
      await callback(values.code, operatorId);
    } catch (error) {
      catchExceptionCallback(error);
    }
  }

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className="modal-content">
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <div className="flex align-items-center">
                    <FontAwesomeIcon icon={faLock as any} className="mr-2" /> <h4 className="mb-0">Operator Pin</h4>
                  </div>
                  <p className="mb-3">Enter your operator pin to access this feature.</p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={12}>
                      <label htmlFor="pin1" className="mb-0">
                        Pin <span className="text-danger">*</span> &nbsp;
                      </label>
                      <Field name="code" component="input" type="text">
                        {({ input: { onChange, value }, meta: { touched, error } }) => {
                          return (
                            <div className="form-group">
                              <div className="input-group">
                                <input id="pin1" ref={inputPinRef} type="text" className="form-control" maxLength={4} value={value} onChange={onChange} autoComplete="off" style={{ WebkitTextSecurity: "disc" } as any} />
                              </div>
                              {touched && error && <div className="validate-form">{error}</div>}
                            </div>
                          );
                        }}
                      </Field>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="flex items-center">
                {!disableClose && (
                  <Button type="button" onClick={onClose} disabled={submitting} className="mr-2">
                    Cancel
                  </Button>
                )}

                <Button color="primary" className="btn-block" type="submit" disabled={submitting}>
                  Continue&nbsp;
                  {submitting ? <Spinner size="sm" /> : null}
                </Button>
              </div>
            </ModalFooter>
          </form>
        );
      }}
    ></Form>
  );
};

const WithModal = (props) => {
  return (
    <Modal isOpen centered size="sm" zIndex={9999}>
      <OperatorLockScreen {...props} />
    </Modal>
  );
};

export const useOperatorLockScreen = ({ config }: { config: any }) => {
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState(null);
  const [callback, setCallback] = useState(null);
  const [serverSidePermissionCheck, setServerSidePermissionCheck] = useState(false);

  const handleOpenLock = async ({ permission, callback, serverSidePermissionCheck = false }) => {
    setServerSidePermissionCheck(serverSidePermissionCheck);
    setPermission(permission);
    setCallback(() => callback);
    setOpen(true);
  };

  const handleCloseLock = () => {
    setOpen(false);
    setPermission(null);
    setCallback(null);
  };

  const showComponent = open && permission && callback;

  const data = useMemo(
    () => ({
      open,
      Component: ({ disableClose }: { disableClose?: boolean }) =>
        showComponent ? <WithModal config={config} serverSidePermissionCheck={serverSidePermissionCheck} permission={permission} callback={callback} disableClose={disableClose} onClose={handleCloseLock} /> : null,
      ComponentSimple: ({ disableClose }: { disableClose?: boolean }) =>
        showComponent ? <OperatorLockScreen config={config} serverSidePermissionCheck={serverSidePermissionCheck} permission={permission} callback={callback} disableClose={disableClose} onClose={handleCloseLock} /> : null,
      handleOpenLock,
      handleCloseLock
    }),
    [open]
  );

  return data;
};

export default OperatorLockScreen;
