import { GET_ROOT_PRODUCT_SIZES } from "ActionTypes";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ProductSizes } from "resbutler-utils/types/product";
import { buildDataFromDocs } from "resbutler-utils/utils";
import { catchExceptionCallback, getConfig } from "../../core/utilities";

const useProductSizes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { client } = getConfig();
    const unsubscribe = firebase
      .firestore()
      .collection(`${client}/products/productSizes`)
      .onSnapshot((snap) => {
        dispatch({ type: GET_ROOT_PRODUCT_SIZES, productSizes: buildDataFromDocs(snap.docs) as ProductSizes });
      }, catchExceptionCallback);

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);
};

export default useProductSizes;
