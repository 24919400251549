import { catchExceptionCallback } from "core/utils";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useState } from "react";
import { Button } from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";

const Logout = () => {
  const [loadingLogout, setLoadingLogout] = useState(false);
  function logout() {
    setLoadingLogout(true);

    firebase
      .auth()
      .signOut()
      .then(() => {
        setLoadingLogout(false);
        window.location.href = "/login";
      })
      .catch((error) => {
        catchExceptionCallback(error);
        setLoadingLogout(false);
      });
  }

  return (
    <Button type="button" className="col-lg-12 btn-block" onClick={() => logout()}>
      <em className="icon-logout"></em>
      <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
        Logout {loadingLogout && <Spinner size="sm" />}
      </span>
    </Button>
  );
};

export default Logout;
