import { BUTTON_ERROR, BUTTON_SAVED, BUTTON_SAVING } from "components/Buttons/ActionTypes";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment-timezone";
import { hideLoading, showLoading } from "react-redux-loading-bar";
/*
 Redux Action Types
*/
export const GET_MEMBERSHIP_OFFER_BY_ID = "GET_MEMBERSHIP_OFFER_BY_ID";
export const GET_MEMBERSHIP_OFFERS = "GET_MEMBERSHIP_OFFERS";
export const GET_ALL_MEMBERSHIP_OFFERS = "GET_ALL_MEMBERSHIP_OFFERS";
export const UPDATE_MEMBERSHIP_OFFER = "UPDATE_MEMBERSHIP_OFFER";
export const GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE = "GET_MEMBERSHIP_OFFER_DESCRIPTION_EDITOR_STATE";

/*
 Redux Actions
*/

export const saveMembershipOffer = (membershipOffer, membershipOfferId, saveId) => (dispatch) => {
  try {
    dispatch({ type: BUTTON_SAVING, id: saveId });
    const { client } = getConfig();

    const membershipOfferParsed = {
      ...membershipOffer,
      expiry: moment.isMoment(membershipOffer.expiry) ? membershipOffer.expiry.toDate() : membershipOffer.expiry,
    };

    if (!membershipOfferParsed.expiry) delete membershipOfferParsed.expiry;

    firebase
      .firestore()
      .doc(`${client}/crm/memberOffers/${membershipOfferId}`)
      .set(membershipOfferParsed)
      .then(() => {
        dispatch({ type: BUTTON_SAVED, id: saveId });
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch({ type: BUTTON_ERROR, id: saveId });
      });
  } catch (error) {
    dispatch({ type: BUTTON_ERROR, id: saveId });
    catchExceptionCallback(error);
  }
};

export const getMembershipOfferId = () => {
  const { client } = getConfig();
  return firebase.firestore().collection(`${client}/crm/memberOffers`).doc().id;
};

export const getMembershipOffers = () => (dispatch) => {
  try {
    dispatch(showLoading());
    const { client } = getConfig();
    firebase
      .firestore()
      .collection(`${client}/crm/memberOffers`)
      .get()
      .then((snapshot) => {
        const membershipOffers = snapshot.docs.map((snap1) => ({ ...snap1.data(), _key: snap1.id }));
        dispatch({ type: GET_MEMBERSHIP_OFFERS, membershipOffers });
        dispatch({ type: GET_ALL_MEMBERSHIP_OFFERS, allMembershipOffers: membershipOffers });
        dispatch(hideLoading());
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch(hideLoading());
      });
  } catch (error) {
    catchExceptionCallback(error);
  }
};

export const getMembershipOfferById = (id) => (dispatch) => {
  try {
    dispatch(showLoading());
    const { client } = getConfig();
    firebase
      .firestore()
      .doc(`${client}/crm/memberOffers/${id}`)
      .get()
      .then((snapshot) => {
        const membershipOffer = snapshot.data();
        const membershipOfferParsed = {
          ...membershipOffer,
          expiry: membershipOffer && membershipOffer.expiry ? moment.unix(membershipOffer.expiry.seconds) : null,
        };
        dispatch({ type: GET_MEMBERSHIP_OFFER_BY_ID, membershipOffer: membershipOfferParsed });
        dispatch(hideLoading());
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch(hideLoading());
      });
  } catch (error) {
    catchExceptionCallback(error);
  }
};
