import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import TransactionModal from './TransactionModal';

const showModalTransaction = props => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = (result) => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<TransactionModal {...props} onClose={handleResolve} />, el);
    });
};


export default showModalTransaction;
