import { map, reduce } from "lodash";
import { CashPaymentConfig, CashPaymentSetting, LineItem, LineItemTypeId, Transaction } from "../types/transaction";

export function getLineItemProductId(lineItem: LineItem) {
  let productId = null;
  if ([LineItemTypeId.Butler, LineItemTypeId.Product, LineItemTypeId.Function].includes(lineItem.lineItemTypeId)) {
    productId = lineItem.id1;
  } else if ([LineItemTypeId.Menu, LineItemTypeId.Deposit].includes(lineItem.lineItemTypeId)) {
    productId = lineItem.id3;
  }
  return productId || null;
}

export function getLineItemSizeId(lineItem: LineItem) {
  let sizeId = null;
  if ([LineItemTypeId.Butler, LineItemTypeId.Product, LineItemTypeId.Function].includes(lineItem.lineItemTypeId)) {
    sizeId = lineItem.id2;
  }
  return sizeId || null;
}

export function getLineItemMenuId(lineItem: LineItem) {
  let menuId = null;
  if ([LineItemTypeId.Butler].includes(lineItem.lineItemTypeId)) {
    menuId = lineItem.id3;
  } else if ([LineItemTypeId.Menu, LineItemTypeId.Deposit].includes(lineItem.lineItemTypeId)) {
    menuId = lineItem.id1;
  }
  return menuId || null;
}

export function getLineItemOrderId(lineItem: LineItem) {
  let orderId = "";
  if ([LineItemTypeId.Product].includes(lineItem.lineItemTypeId)) {
    orderId = lineItem.id4;
  }
  return orderId || "";
}

export function getLineItemOrderItemId(lineItem: LineItem) {
  let orderId = "";
  if ([LineItemTypeId.Product].includes(lineItem.lineItemTypeId)) {
    orderId = lineItem.id3;
  }
  return orderId || "";
}

export function getTaxAmount(taxRate: number, amount: number) {
  const totalTax = amount - amount / (1 + taxRate);
  return +totalTax.toFixed(2);
}

/**
 * In given list of transactions, check if they contain given set of LineItemTypeIds
 * @return boolean
 */
export function transactionHasLineItemTypes(transactions: Transaction[], lineItemTypes: LineItemTypeId[]): boolean {
  return map(transactions, (t: Transaction) => map(t.lineItems, (item: LineItem) => lineItemTypes.includes(item.lineItemTypeId))).length > 0;
}

export function getLineItemTotalByTypes(transactions: Transaction[], lineItemTypes: LineItemTypeId[]) {
  const totalSum = reduce(
      transactions,
      (result, item) => {
        const lineItemTotal = reduce(
            item.lineItems,
            (lineItemSum, lineItem) => {
              if (lineItemTypes.includes(lineItem.lineItemTypeId)) {
                return lineItemSum + lineItem.total;
              }
              return lineItemSum;
            },
            0
        );

        return result + lineItemTotal;
      },
      0
  );
  return totalSum;
}

/**
 * Compute CashPaymentConfig based on Cash payment configs
 * rule heirrarchy:
 *  enableCashPaymentForMealPeriod overrides bookingCashPaymentSetting
 *  restaurantCashPaymentSetting overrides enableCashPaymentForMealPeriod
 *
 *
 */
export const getCashPaymentConfigs = (
    restaurantCashPaymentSetting: CashPaymentSetting,
    enableCashPaymentForMealPeriod?: boolean,
    bookingCashPaymentSetting?: CashPaymentSetting
): CashPaymentConfig => {
  // default
  const setting = bookingCashPaymentSetting || restaurantCashPaymentSetting;
  let allow = [CashPaymentSetting.PayAsYouGo, CashPaymentSetting.PayAtTheEnd].includes(bookingCashPaymentSetting || restaurantCashPaymentSetting);
  let visible = restaurantCashPaymentSetting !== CashPaymentSetting.NoCash; // should toggle control be visible ?

  // Conditionally override
  if (bookingCashPaymentSetting !== undefined && bookingCashPaymentSetting !== restaurantCashPaymentSetting) allow = false;
  if (enableCashPaymentForMealPeriod !== undefined && !enableCashPaymentForMealPeriod) allow = false;
  if (restaurantCashPaymentSetting === CashPaymentSetting.NoCash) {
    allow = false;
    visible = false;
  }
  return {
    setting,
    visible, // should toggle control be visible ?
    allow, // is cash payment allowed?
    label: restaurantCashPaymentSetting === CashPaymentSetting.PayAsYouGo ? CashPaymentSetting.PayAsYouGo : CashPaymentSetting.PayAtTheEnd, // computed label for cash payment
  };
};
