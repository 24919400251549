import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import ContentWrapper from "components/Layout/ContentWrapper";
import ContentHeading from "components/Layout/ContentHeading";
import { GiftCertificateLinkBuilder } from "./GiftCertificateLinkBuilder";
import { getRestaurants } from "system/RestaurantReducer";

export class GiftCertificateLinkBuilderForm extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <ContentHeading parentText="Gift Certificate Widget Link Builder" headerText="Gift Certificates" subHeaderText="Gift Certificate Widget" showRestaurants={false} />
        <Row>
          <Col lg={6} sm={12}>
            <GiftCertificateLinkBuilder {...this.props} />
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurants: getRestaurants(state.root.restaurants, state.root.user),
    clientWidgetLink: state.giftCertificateLinkBuilder.clientWidgetLink,
    clientCodeBlock: state.giftCertificateLinkBuilder.clientCodeBlock,
    clientButtonLink: state.giftCertificateLinkBuilder.clientButtonLink,
    clientjQueryCode: state.giftCertificateLinkBuilder.clientjQueryCode,
    clientJavaScriptCode: state.giftCertificateLinkBuilder.clientJavaScriptCode,
    clientHtmlCode: state.giftCertificateLinkBuilder.clientHtmlCode,
  };
}

export default connect(mapStateToProps)(GiftCertificateLinkBuilderForm);
