import { DropdownListFilter, filterList, getFilterName } from "components/CustomFilter";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import { GET_FILTER_DISABLED } from "../../ActionTypes";
import { findAllLevels, generateLevelId } from "./LevelAction";

const renderLevelList = (levels, filterDisabled) => {
  const levelList = filterList(levels, filterDisabled);
  const results = [];
  for (let i = 0; i < levelList.length; i++) {
    const level = levelList[i];
    results.push(
      <tr key={level._key}>
        <td>{level.accountName}</td>
        <td>{level.membershipType}</td>
        <td>{level.allocationPriority}</td>
        <td>{level.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
        <td>{level.default ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
        <td>
          <Link to={`/loyalty/membership-levels/${level._key}`}>Edit</Link>
        </td>
      </tr>
    );
  }
  return results;
};

const MembershipLevels = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { levels, filterDisabled } = useSelector((state: any) => ({
    levels: state.levels.byId,
    filterDisabled: state.root.filterDisabled,
  }));

  useEffect(() => {
    dispatch(findAllLevels());
  }, []);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" headerText="Membership Levels" showRestaurants={false} />
      <Row>
        <Col lg={8} sm={12}>
          <div className="justify-content-between row">
            <Col>
              <Button color="primary" className="text-nowrap" onClick={() => navigate(`/loyalty/membership-levels/${generateLevelId()}`)}>
                New Membership Level
              </Button>
            </Col>
            <div className="col-12 col-sm-3">
              <DropdownListFilter id="tableFilter1" name="tableFilter" className="form-group" getName={getFilterName} onSelect={(e) => dispatch({ type: GET_FILTER_DISABLED, value: e })} />
            </div>
          </div>
          <Card className="card-default">
            <CardBody>
              <Table bordered striped>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Membership Type</td>
                    <td>Allocation Priority</td>
                    <td>Enabled</td>
                    <td>Default</td>
                    <td />
                  </tr>
                </thead>
                <tbody>{renderLevelList(levels, filterDisabled)}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default MembershipLevels;
