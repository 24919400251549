import axios from "axios";
import { getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useEffect } from "react";

const useRefreshToken = () => {
  // Get the ID token and store it in local storage
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      const { client } = getConfig();
      if (firebaseUser) {
        axios.interceptors.request.use(async (config) => {
          const token = await firebase.auth().currentUser.getIdToken();
          if (config.method === "get") config.headers.get["client-id"] = client;
          else if (config.method === "post") config.headers.post["client-id"] = client;
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      }
    });
    return () => unsubscribe();
  }, []);
};

export default useRefreshToken;
