export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const GET_SUBSCRIPTION_DETAILS_BY_ID = 'GET_SUBSCRIPTION_DETAILS_BY_ID';

export const GET_SUBSCRIPTION_TABS = 'GET_SUBSCRIPTION_TABS';

export const GET_SYSTEM_PRODUCTS = 'GET_SYSTEM_PRODUCTS';

export const GET_GROUP_SUBSCRIPTION_DETAILS = 'GET_GROUP_SUBSCRIPTION_DETAILS';

export const GET_GROUP_APPLICABLE_PERIOD_START = 'GET_GROUP_APPLICABLE_PERIOD_START';
export const GET_GROUP_APPLICABLE_PERIOD_END = 'GET_GROUP_APPLICABLE_PERIOD_END';
export const GET_GROUP_REVIEW_DATE = 'GET_GROUP_REVIEW_DATE';

export const GET_RESTAURANT_MODULES_CHECKBOX_FIELDS = 'GET_RESTAURANT_MODULES_CHECKBOX_FIELDS';
export const GET_GROUP_MODULES_CHECKBOX_FIELDS = 'GET_GROUP_MODULES_CHECKBOX_FIELDS';
export const GET_RESTAURANT_SUBDETAILS_FIELDS = 'GET_RESTAURANT_SUBDETAILS_FIELDS';

export const GET_RESTAURANT_APPLICABLE_PERIOD_START = 'GET_RESTAURANT_APPLICABLE_PERIOD_START';
export const GET_RESTAURANT_APPLICABLE_PERIOD_END = 'GET_RESTAURANT_APPLICABLE_PERIOD_END';
export const GET_RESTAURANT_REVIEW_DATE = 'GET_RESTAURANT_REVIEW_DATE';

