import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { toast } from "react-toastify";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { catchExceptionCallback } from "../../../resbutler-ui/utilities";

const required = (value) => (value ? undefined : "Required");
const deductGiftCertificateAmount = async (values, resbutlerApis, clientId, setLoading, setModal, setModalOpen) => {
  try {
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken(true);
    const uninterceptedAxiosInstance = axios.create();
    await uninterceptedAxiosInstance.post(`${resbutlerApis}/crm/gift-certificate/deductAmountFromGiftCertificate`, values, { headers: { authorization: `Bearer ${token}`, "client-id": clientId } });
    toast.success(`Amount deducted successfully`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    setModal(false);
    setModalOpen(false);
  } catch (error) {
    catchExceptionCallback(error);
  } finally {
    setLoading(false);
  }
};

const AmountDeductModal = ({ modal, setModal, giftCertificate, giftCertificateId, resbutlerApis, clientId, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Container fluid>
      <Form
        onSubmit={async (values) => {
          await deductGiftCertificateAmount({ ...values, giftCertificate, giftCertificateId }, resbutlerApis, clientId, setLoading, setModal, setModalOpen);
          setModal(false);
        }}
        render={({ handleSubmit, submitting }) => (
          <Modal isOpen={modal} toggle={() => setModal(false)} loading={true} zIndex={9999}>
            <form onSubmit={handleSubmit} className="modal-content">
              <ModalHeader toggle={() => setModal(false)}>Deduct Amount</ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    <Field name="redeemAmount" validate={required}>
                      {({ input, meta }) => (
                        <div className="mb-3 mt-3">
                          <label>Amount</label>
                          <input {...input} type="number" className="form-control" />
                          {meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field name="operator" validate={required}>
                      {({ input, meta }) => (
                        <div className="mb-3 mt-3">
                          <label>Operator Name</label>
                          <input {...input} type="text" className="form-control" />
                          {meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field name="reason" validate={required}>
                      {({ input, meta }) => (
                        <div className="mb-3 mt-3">
                          <label>Reason</label>
                          <input {...input} type="text" className="form-control" />
                          {meta.error && meta.touched && <span className="validate-form">{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col lg={12}>
                    <Button type="submit" color="success">
                      Save {(submitting || loading) && <Spinner size="sm" />}
                    </Button>
                  </Col>
                </Row>
              </ModalFooter>
            </form>
          </Modal>
        )}
      />
    </Container>
  );
};

export default AmountDeductModal;
