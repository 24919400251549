export const GIFT_CERTIFICATE_IMAGES = "GIFT_CERTIFICATE_IMAGES";
export const REMOVE_EMPTY_GIFT_CERTIFICATE_IMAGE = "REMOVE_EMPTY_GIFT_CERTIFICATE_IMAGE";
export const GIFT_CERTIFICATE_SAVING = "GIFT_CERTIFICATE_SAVING";
export const GIFT_CERTIFICATE_SAVED = "GIFT_CERTIFICATE_SAVED";
export const GIFT_CERTIFICATE_SETTINGS = "GIFT_CERTIFICATE_SETTINGS";
export const GET_CERTIFICATE_EDITOR_STATE = "GET_CERTIFICATE_EDITOR_STATE";
export const GET_GIFT_CERTIFICATES = "GET_GIFT_CERTIFICATES";
export const GET_GIFT_CERTIFICATES_SEARCH_RESULTS = "GET_GIFT_CERTIFICATES_SEARCH_RESULTS";
export const GET_GIFT_CERTIFICATE_BY_ID = "GET_GIFT_CERTIFICATE_BY_ID";
export const UPDATE_GIFT_CERTIFICATE = "UPDATE_GIFT_CERTIFICATE";
export const GIFT_CERTIFICATE_FORM_TAB = "GIFT_CERTIFICATE_FORM_TAB";
export const GET_GIFT_CERTIFICATE_CUSTOMERS = "GET_GIFT_CERTIFICATE_CUSTOMERS";
// Restaurant
export const GIFT_CERTIFICATE_RESTAURANT_SETTINGS = "GIFT_CERTIFICATE_RESTAURANT_SETTINGS";
export const GIFT_CERTIFICATE_RESTAURANT_IMAGES = "GIFT_CERTIFICATE_RESTAURANT_IMAGES";
export const REMOVE_EMPTY_GIFT_CERTIFICATE_RESTAURANT_IMAGE = "REMOVE_EMPTY_GIFT_CERTIFICATE_RESTAURANT_IMAGE";
export const GIFT_CERTIFICATE_RESTAURANT_SAVING = "GIFT_CERTIFICATE_RESTAURANT_SAVING";
export const GIFT_CERTIFICATE_RESTAURANT_SAVED = "GIFT_CERTIFICATE_RESTAURANT_SAVED";
export const GET_CERTIFICATE_RESTAURANT_EDITOR_STATE = "GET_CERTIFICATE_RESTAURANT_EDITOR_STATE";
export const GIFT_CERTIFICATE_RESTAURANTS_IMAGES = "GIFT_CERTIFICATE_RESTAURANTS_IMAGES";
export const UPDATE_GIFT_CERTIFICATE_RESTAURANT = "UPDATE_GIFT_CERTIFICATE_RESTAURANT";
export const GET_ISSUED_GIFT_CERTIFICATE_TAB = "GET_ISSUED_GIFT_CERTIFICATE_TAB";
export const LOAD_RESTAURANT_GALLERY = "LOAD_RESTAURANT_GALLERY";
export const DISPLAY_PROGRESS_BAR_RESTAURANT_IMAGES = "DISPLAY_PROGRESS_BAR_RESTAURANT_IMAGES";
