import { useAppSelector } from "components/Hooks/hooks";
import ContentHeading from "components/Layout/ContentHeading";
import ContentWrapper from "components/Layout/ContentWrapper";
import { ADMIN_URL } from "core/auth";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Col, Row } from "reactstrap";
import VoucherFormCard from "resbutler-ui/components/VoucherForm/VoucherFormCard";
import { GenerationType, VoucherSetup, VoucherTypes } from "resbutler-utils/types/Voucher";

const LoyaltyVoucherForm = () => {
  const { id } = useParams();
  const params = useLocation();
  const disabled = params.pathname.includes("view") ? true : false;
  const [templates, setTemplates] = useState([]);

  const {
    tenantId,
    client,
    resbutlerApis,
    config: { publicStorageBucket },
  } = getConfig();

  const { restaurantId, productSizes, products, restaurants, allLevels } = useAppSelector((state) => {
    return {
      restaurantId: state.root.restaurantId,
      productSizes: state.root.productSizes,
      products: state.root.products,
      restaurants: state.root.restaurants,
      allLevels: state.levels.allLevels,
    };
  });

  const [voucher, setVoucher] = useState<VoucherSetup>({
    name: "",
    description: "",
    restaurants: [],
    products: [],
    subjectLine: "",
    type: "loyalty",
    voucherType: VoucherTypes.DeferredBookingVoucher,
    voucherAmount: 0,
    discountType: "%",
    discountAmount: 100,
    allProducts: true,
    redeemQuantity: 1,
    voucherExpiryNumber: 1,
    voucherExpiryType: "month",
    generationType: GenerationType.recurring,
    isExpirable: false,
    voucherGenerateOn: "",
    voucherGenerateExpiry: firebase.firestore.Timestamp.now(),
    isEnabled: true,
    sendEmail: false,
    staffNote: "",
    template: {
      templateId: "",
      headerText1: "",
      bodyText1: "",
    },
    customerFilters: {
      filterType: "all",
      selectedFilters: [],
      membershipLevels: [],
      age: {
        minAge: "",
        maxAge: "",
      },
      birthdayMonth: "",
      specificCustomers: [],
      specificCustomerObjects: [],
    },
  });

  useEffect(() => {
    const load = async () => {
      try {
        const { client } = getConfig();
        const [snapshot, snap1] = await Promise.all([firebase.firestore().doc(`${client}/crm/voucherSetups/${id}`).get(), firebase.firestore().collection(`${client}/crm/emailBranding`).get()]);
        if (snapshot.exists) {
          setVoucher(snapshot.data() as VoucherSetup);
        }
        setTemplates(snap1.docs.map((doc) => ({ ...doc.data(), _key: doc.id })));
      } catch (e) {
        catchExceptionCallback(e);
      }
    };
    load();
  }, [id]);

  return (
    <ContentWrapper>
      <ContentHeading parentText="Loyalty" subHeaderText="Loyalty Vouchers" subHeaderHref="/loyalty/loyalty-vouchers" subText={voucher?.name || "New Loyalty Voucher"} />
      <Row>
        <Col lg={8} sm={12}>
          <VoucherFormCard
            publicStorageBucket={publicStorageBucket}
            voucher={voucher}
            disabled={disabled}
            voucherId={id}
            templates={templates}
            restaurantId={restaurantId}
            ADMIN_URL={ADMIN_URL}
            clientId={client}
            productSizes={productSizes}
            products={products}
            restaurants={restaurants}
            tenantId={tenantId}
            resbutlerApis={resbutlerApis}
            customerGroups={allLevels}
          />
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default LoyaltyVoucherForm;
