import { BUTTON_ERROR, BUTTON_SAVED, BUTTON_SAVING } from "components/Buttons/ActionTypes";
import { catchExceptionCallback, getConfig } from "core/utilities";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { hideLoading, showLoading } from "react-redux-loading-bar";

/*
 Redux Action Types
*/
export const GET_LEVEL_BY_ID = "GET_LEVEL_BY_ID";
export const GET_LEVELS = "GET_LEVELS";
export const GET_ALL_LEVELS = "GET_ALL_LEVELS";
export const UPDATE_LEVEL = "UPDATE_LEVEL";
export const GET_LEVEL_DESCRIPTION_EDITOR_STATE = "GET_LEVEL_DESCRIPTION_EDITOR_STATE";

/*
 Helper Actions
*/
export const generateLevelId = () => {
  const { client } = getConfig();
  return firebase.firestore().collection(`${client}/crm/levels`).doc().id;
};

/*
 Redux Actions
*/
export const saveLevel = (level, id, saveId) => (dispatch) => {
  try {
    dispatch({ type: BUTTON_SAVING, id: saveId });
    const { client } = getConfig();
    firebase
      .firestore()
      .doc(`${client}/crm/customerGroups/${id}`)
      .set(level)
      .then(() => {
        dispatch({ type: BUTTON_SAVED, id: saveId });
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch({ type: BUTTON_ERROR, id: saveId });
      });
  } catch (error) {
    dispatch({ type: BUTTON_ERROR, id: saveId });
    catchExceptionCallback(error);
  }
};

export const findAllLevels = () => (dispatch) => {
  try {
    dispatch(showLoading());
    const { client } = getConfig();
    firebase
      .firestore()
      .collection(`${client}/crm/customerGroups`)
      .get()
      .then((snapshot) => {
        const levels = snapshot.docs.map((snap1) => ({ ...snap1.data(), _key: snap1.id }));
        dispatch({ type: GET_LEVELS, levels });
        dispatch({ type: GET_ALL_LEVELS, allLevels: levels });
        dispatch(hideLoading());
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch(hideLoading());
      });
  } catch (error) {
    catchExceptionCallback(error);
  }
};

export const findOneLevel = (id) => (dispatch) => {
  try {
    dispatch(showLoading());
    const { client } = getConfig();
    firebase
      .firestore()
      .doc(`${client}/crm/customerGroups/${id}`)
      .get()
      .then((snapshot) => {
        const level = snapshot.data();
        dispatch({ type: GET_LEVEL_BY_ID, level: level || null });
        dispatch(hideLoading());
      })
      .catch((error) => {
        catchExceptionCallback(error);
        dispatch(hideLoading());
      });
  } catch (error) {
    catchExceptionCallback(error);
  }
};
